import { PropertyBookingEntry } from "../../Types/bookingTypes"
import { PropertyLinkedPropertyType } from "../../Types/propertyTypes"
import { useGetBookingsQuery } from "../../services/bClientApi"

export default function useRelatedBookings(
  bookingId: string | undefined,
  bookingData: Partial<PropertyBookingEntry>,
  myProperties: { [propertyId: string]: any }
): PropertyBookingEntry[] {

  const {
    propertyId: selectedPropertyId = '',
    checkIn = '',
    checkOut = '',
  } = bookingData || {}

  const propertyId = [selectedPropertyId]

  if (selectedPropertyId && myProperties[selectedPropertyId]) {
    const {
      multiUnit: {
        isMultiUnit,
        linkedUnits,
      }
    } = myProperties[selectedPropertyId]
    if (isMultiUnit) {
      linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
        propertyId.push(unit.propertyId)
      })
    }
  }

  const relatedBookings: PropertyBookingEntry[] = []

  const {
    data: relatedBookingData = {},
    // isLoading,
    // error,
  } = useGetBookingsQuery({
    bookingsQuery: {
      propertyId,
      checkIn: checkIn,
      checkOut: checkOut,
      bookingStatus: ['new', 'confirmed', 'pending']
    },
    pagination: {
      limit: 100,
      page: 1
    }
  }, { skip: propertyId.length <= 1 || !checkIn || !checkOut })

  if (propertyId.length > 1 && myProperties[selectedPropertyId] && bookingData && checkIn && checkOut) {
    if (relatedBookingData && relatedBookingData.docs && relatedBookingData.docs.length > 0) {
      const currentCheckIn = (new Date(checkIn)).getTime()
      const currentCheckOut = (new Date(checkOut)).getTime()
      relatedBookingData.docs.forEach((booking: PropertyBookingEntry) => {
        const bookingCheckIn = (new Date(checkIn)).getTime()
        const bookingCheckOut = (new Date(checkOut)).getTime()
        if (
          booking._id && booking._id !== bookingId &&
          currentCheckIn === bookingCheckIn &&
          currentCheckOut === bookingCheckOut
        ) {
          relatedBookings.push(booking)
        }
      })
    }
  }

  return relatedBookings
}