import QuickForm from "../QuickForm/QuickForm";

export default function IcalConversionForm(
    props: {
        icalData: {
            summary: string,
            earnings: number,
            sourceConfirmation: string
        },
        disabled: boolean,
        onSubmit: (e: { summary: string, earnings: number, sourceConfirmation: string }) => Promise<void> | void,
    }
) {
    const {
        icalData: { 
            summary,
            earnings,
            sourceConfirmation 
        },
        disabled,
        onSubmit
    } = props

    return (
        <div>
            <QuickForm
                initialData={{ summary, earnings, sourceConfirmation }}
                fields={[
                    {
                        fieldName: 'summary',
                        fieldLabel: 'Name',
                        fieldType: 'text',
                        required: true,
                    },
                    {
                        fieldName: 'earnings',
                        fieldLabel: 'Earnings',
                        fieldType: 'number',
                        required: true,
                    },
                    {
                        fieldName: 'sourceConfirmation',
                        fieldLabel: 'Source Confirmation',
                        fieldType: 'text',
                        required: true,
                    },
                ]}
                onSubmit={onSubmit}
                disabled={disabled}
                submitText={'Convert'}
            />
        </div>
    )
}