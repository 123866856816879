import { Field } from "formik"
import { FloatingLabel, Form } from "react-bootstrap"
import { checkVal } from "../../../Helpers/Parsers/checkVal"

export const TextInputField = (props: {
  fieldLabel: string,
  fieldName: string,
  placeholder: string,
  customClass?: string,
  required?: boolean,
  disabled?: boolean,
  errors?: any,
  touched?: any,
  fieldType?: string,
  subText?: string,
  onKeyDown?: (e: any) => void,
  onChange?: (e: any) => void,
  extra?: any,
}) => {
  const {
    fieldLabel,
    fieldName,
    placeholder,
    customClass,
    fieldType,
    required,
    subText,
    disabled,
    onChange,
    onKeyDown,
    // extra = {},
  } = props
  return (
    <>
      <Field name={fieldName} >
        {({
          field: { value },
          form
        }: any) => (
          <Form.Group className={`mb-3 ${customClass ? customClass : ''}`}>
            {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
            <FloatingLabel
              controlId={fieldName}
              label={`${fieldLabel}${required ? '*' : ''}`}>
              <Form.Control
                type={fieldType && fieldType !== 'textarea' ? fieldType : "text"}
                placeholder={placeholder}
                {...(fieldType === 'textarea' ? { as: 'textarea' } : {})}
                name={fieldName}
                required={required}
                disabled={disabled}
                value={value || value === 0 ? value : fieldType === 'number' ? '' : ''}
                onKeyDown={(e: any) => {
                  if (onKeyDown) {
                    onKeyDown(e)
                  }
                }}
                onChange={(e: any) => {
                  if (onChange) {
                    onChange(e)
                  }
                  form.handleChange(e)
                }}
                onBlur={form.handleBlur}
                isInvalid={checkVal(form.touched, fieldName) && checkVal(form.errors, fieldName)}
                isValid={!checkVal(form.errors, fieldName) && checkVal(form.touched, fieldName)}
                style={fieldType === 'textarea' ? { minHeight: '100px' } : {}}
              />
            </FloatingLabel>
            {subText && (
              <Form.Text className="text-muted">
                {subText}
              </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
              {checkVal(form.errors, fieldName)}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </Field>

      {/*<div className={`field-container text-input-container ${customClass ? customClass : ''}`}>
      <label htmlFor={fieldLabel}>{fieldLabel}{required ? '*' : ''}</label><br />
      <Field
      className={`${checkVal(touched, fieldName) ? !checkVal(errors, fieldName) ? 'valid' : 'error' : ''}`}
      type={fieldType ? fieldType : "text" }
      placeholder={placeholder}
      name={fieldName} /><br />
      <ErrorMessage name={fieldName} render={ErrorComponent} />
    </div>*/}
    </>
  )
}
