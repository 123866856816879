import { useMemo } from "react";
import { PropertyBookingEntry } from "../../Types/bookingTypes";

export default function useBookingsByPropertyMemo(bookingsList: any[] = [], options: any = {}) {
    const {
        propertyData,
        combineMulti,
        bookingProcessor,
    } = options
    const bookings = useMemo(() => {
        const propertyHash: any = {}
        bookingsList.forEach((booking: PropertyBookingEntry) => {
            if (!propertyHash[booking.propertyId]) {
                propertyHash[booking.propertyId] = []
            }
            propertyHash[booking.propertyId].push(booking)
        })

        // Throws the child property data into the parents
        if (propertyData && combineMulti) {
            Object.keys(propertyHash).forEach((propertyId) => {
                const property = propertyData[propertyId]
                if (property && property.multiUnit?.isMultiUnit) {
                    property.multiUnit?.linkedUnits.forEach((linkedProperty: any) => {
                        if (linkedProperty.relationship === 'parent') {
                            if (!propertyHash[linkedProperty.propertyId]) {
                                propertyHash[linkedProperty.propertyId] = []
                            }
                            if (propertyHash[linkedProperty.propertyId]) {
                                propertyHash[linkedProperty.propertyId] = [...propertyHash[propertyId], ...propertyHash[linkedProperty.propertyId]]
                            }
                        }
                    })
                }
            })
        }

        return Object.keys(propertyHash).map(propertyId => {
            return {
                propertyId,
                bookings: bookingProcessor ? bookingProcessor(propertyHash[propertyId]) : propertyHash[propertyId]
            }
        }).sort((x, y) => {
            if (propertyData && propertyData[x.propertyId] && propertyData[y.propertyId]) {
                return propertyData[x.propertyId].title < propertyData[y.propertyId].title ? -1 : 1
            }
            return 0
        })
    }, [bookingsList, propertyData, bookingProcessor, combineMulti])

    return bookings
}