import { Col, Container, Row } from "react-bootstrap";
import PurchaseForm from "../../../Components/Forms/PurchaseForm/PurchaseForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewPurchaseMutation } from "../../../services/bClientApi";

export default function AddPurchasePage() {

  const [
    addNewPurchase,
    {
      isLoading,
      error
    }
  ] = useAddNewPurchaseMutation()

  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newPurchase = await addNewPurchase(values).unwrap()
    if (newPurchase._id) {
      dispatch(navigateTo(`/purchase/${newPurchase._id}`))
    }
    return newPurchase
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Purchase</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <PurchaseForm
            initialValues={{
                firstName: '',
            }}
            disabled={isLoading}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
