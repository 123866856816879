import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteBukibleMutation, useGetBukibleByIdQuery } from "../../../services/bClientApi"

export default function DeleteBukiblePage() {
    const [
        deleteBukible,
        {
            isLoading: deleteLoading,
            error: deleteError,
        }
    ] = useDeleteBukibleMutation()
    const { bukibleId = '' } = useParams()
    const dispatch = useAppDispatch()
    const [deleteClicked, setDeleteClicked] = useState(false)

    const {
        data,
        isLoading,
        error,
      } = useGetBukibleByIdQuery(bukibleId, { skip: !bukibleId || deleteClicked })
      const bukibleData = data || {}


    const bukibleDeleteFn = async () => {
        if (bukibleId) {
            const deleteValue = await deleteBukible(bukibleId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/bukibles/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Bukible Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}

                    {bukibleId && bukibleData && !isLoading && (
                        <div className="bukible-info">
                            {JSON.stringify(bukibleData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Bukible: {bukibleId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Bukible</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this bukible it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={deleteLoading} onClick={() => {
                                            bukibleDeleteFn()
                                        }}> Confirm Delete Bukible </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
