import QuickForm from "../QuickForm/QuickForm"
import { BukinEntryType } from "../../../Types/bukinTypes"

export const generateBlankBukin = () => ({
  dateRange: null,
  checkIn: null,
  checkOut: null,
  bukibleId: '',
  bookingId: '',
  referenceNumber: '',
  status: '',
  earnings: 0,
  contactInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  notes: '',
})

export default function BukinForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<BukinEntryType>,
  bukibleSelectOptions?: { value: string, text: string }[],
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    bukibleSelectOptions,
  } = props

  return (
    <div className="bukin-form">
      <QuickForm
        initialData={{
          ...generateBlankBukin(),
          ...(initialValues ? initialValues : {})
        }}
        disabled={disabled}
        onSubmit={(v: any) => {
          const newValues = {
            ...v,
          }
          delete newValues.dateRange
          onSubmit(newValues)
        }}
        fields={[
          {
            fieldName: 'dateRange',
            fieldLabel: 'Date Range',
            fieldType: 'dateRange',
            placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
            extra: {
              startDate: 'checkIn',
              endDate: 'checkOut',
            },
          },
          {
            fieldName: 'checkIn',
            fieldLabel: 'Check In',
            fieldType: 'date',
            placeholder: 'Check In',
            required: true,
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'checkOut',
            fieldLabel: 'Check Out',
            fieldType: 'date',
            placeholder: 'Check Out',
            required: true,
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'bukibleId',
            fieldLabel: 'Bukible',
            fieldType: bukibleSelectOptions && bukibleSelectOptions.length > 0 ? 'select' : 'text',
            placeholder: bukibleSelectOptions && bukibleSelectOptions.length > 0 ? '- Select Bukible -' : 'Bukble Id',
            required: true,
            options: bukibleSelectOptions,
          },
          {
            fieldName: 'referenceNumber',
            fieldLabel: 'Reference Number',
            fieldType: 'text',
            placeholder: 'Reference Number',
            // required: true,
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'bookingId',
            fieldLabel: 'Booking Id',
            fieldType: 'text',
            placeholder: 'Booking Id',
            // required: true,
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'status',
            fieldLabel: 'Status',
            fieldType: 'select',
            placeholder: '- Select Status -',
            required: true,
            options: [
              { value: 'new', text: 'New' },
              { value: 'pending', text: 'Pending' },
              { value: 'confirmed', text: 'Confirmed' },
              { value: 'canceled', text: 'Canceled' },
              { value: 'declined', text: 'Declined' },
              { value: 'expired', text: 'Expired' },
              { value: 'request', text: 'Request' },
              { value: 'ignore', text: 'Ignore' }
            ]
          },
          {
            fieldName: 'earnings',
            fieldLabel: 'Earnings',
            fieldType: 'number',
            placeholder: 'Earnings',
            // required: true,
          },
          {
            fieldName: 'contactInfo.firstName',
            fieldLabel: 'First Name',
            fieldType: 'text',
            placeholder: 'First Name',
            required: true,
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'contactInfo.lastName',
            fieldLabel: 'Last Name',
            fieldType: 'text',
            placeholder: 'Last Name',
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'contactInfo.email',
            fieldLabel: 'Email',
            fieldType: 'text',
            placeholder: 'Email',
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'contactInfo.phone',
            fieldLabel: 'Phone',
            fieldType: 'text',
            placeholder: 'Phone',
            layout: {
              xs: 12,
              md: 6,
            }
          },
          {
            fieldName: 'notes',
            fieldLabel: 'Notes',
            fieldType: 'textarea',
            placeholder: 'Notes',
          },

        ]}
      />
    </div>
  )
}
