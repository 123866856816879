import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteDateBlockMutation, useGetDateBlockByIdQuery } from "../../../services/bClientApi"

export default function DeleteDateBlockPage() {
    // const { _id, dateBlockData, message, loading, error } = useSelector(dateBlockSelector)
    const { dateBlockId = '' } = useParams()
    const dispatch = useAppDispatch()

    const [
        deleteDateBlock,
        {
            error: deleteError,
            isLoading: deleteLoading
        }
    ] = useDeleteDateBlockMutation()
    const [deleteClicked, setDeleteClicked] = useState(false)

    const {
        data,
        isLoading,
        error
      } = useGetDateBlockByIdQuery(dateBlockId, { skip: !dateBlockId || deleteClicked })
      const dateBlockData = data || {}

    const dateBlockDeleteFn = async () => {
        if (dateBlockId) {
            const deleteValue = await deleteDateBlock(dateBlockId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/date-blocks/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete DateBlock Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}

                    {dateBlockId && dateBlockData && !isLoading && (
                        <div className="dateBlock-info">
                            {JSON.stringify(dateBlockData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete DateBlock: {dateBlockId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete DateBlock</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this dateBlock it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={deleteLoading} onClick={() => {
                                            dateBlockDeleteFn()
                                        }}> Confirm Delete DateBlock </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
