import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { UserSearchForm } from "../../Components/Forms/UserSearchForm/UserSearchForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers"
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import './usersPage.scss'
import { useGetUsersQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"
import timeService from "../../services/time/timeService"

export default function UsersPage() {

  const dispatch = useAppDispatch()

  const { activeQuery } = useActiveQueryHook({ 
    limit: 10,
    page: 1,
  })

  const {
    data,
    error,
    isLoading,
  } = useGetUsersQuery({
    usersQuery: {
      ...activeQuery.query,
    },
    pagination: {
      page: activeQuery.page,
      limit: activeQuery.limit,
    }
  })
  const userList = data?.docs || []

  const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
    const destination = `/users?${convertActiveQueryToUrl(pageParams)}`
    dispatch(navigateTo(destination))
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Users Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <UserSearchForm
            disabled={isLoading}
            onSubmit={(values: any) => {
              loadPage({
                query: values,
                page: 1,
                limit: activeQuery.limit
              })
            }}
            firstName={activeQuery.query.firstName}
            lastName={activeQuery.query.lastName}
            email={activeQuery.query.email}
          />
          <hr />

          {userList && userList.length > 0 && (
            <div className="user-list-container">
              {userList.map((user: any, _idx: number) => (
                <UserEntry
                  key={user._id}
                  {...user} />
              ))}
            </div>
          )}

            <MongoPagination
              data={data}
              loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                loadPage({
                  query: activeQuery.query,
                  limit: activeQuery.limit,
                  ...pageLoadInfo
                })
              }}
            />
        </Col>
      </Row>
    </Container>
  )
}


const UserEntry = (props: { _id: string, firstName: string, lastName: string, email: string, lastLogin?: Date, profilePic: string, role: string }) => {
  const {
    _id,
    firstName,
    lastName,
    email,
    lastLogin,
    role
    // profilePic,
  } = props

  return (
    <div className="user-entry">
      Name: {firstName} {lastName} <br />
      Email: {email} <br />
      Role: {role} <br />
      {lastLogin && (
        <small>
          Last Login: {timeService.getFormattedTime(new Date(lastLogin))} <br />
        </small>
      )}
      <Link to={`/user/${_id}`} >View</Link>
    </div>
  )
}
