import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import PropertyTaskEntryView from "../../../Components/PropertyTasks/PropertyTaskEntryView"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useOccupancyRef from "../../../Hooks/UseOccupancyRef/UseOccupancyRef"
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs"
import { accountSelector } from "../../../redux/account/accountSlice"
import { PropertyTaskType } from "../../../Types/propertyTaskType"
import { useEditPropertyTaskMutation, useGetPropertyTaskByIdQuery } from "../../../services/bClientApi"
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook"

export default function PropertyTaskPage() {
  const { username, role } = useSelector(accountSelector)

  const [
    editPropertyTask,
    { isLoading: editPropertyTaskLoading , error: editPropertyTaskError }
  ] = useEditPropertyTaskMutation()

  const { myProperties } = useMyProperties()
  const { propertyTaskId = '' } = useParams()

  const {
    // uploadImageFn,
    imageUploadProgress,
    uploadImageMultiFn,
    // isLoading: uploadImageLoading,
    error: uploadImageError,
  } = useUploadImage()

  const userRef = useUserRefs()

  const propertyOccupancyRef = useOccupancyRef({
    myProperties
  })

  const {
    data,
    error,
    isLoading
  } = useGetPropertyTaskByIdQuery(propertyTaskId, { skip: !propertyTaskId })
  const propertyTaskData = data || {}

  const propertyInfo = myProperties && propertyTaskData && propertyTaskData.propertyId ? myProperties[propertyTaskData.propertyId] : undefined

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single PropertyTask Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editPropertyTaskError && (<>{JSON.stringify(editPropertyTaskError)}<br /></>)}
          {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}
          
          {propertyTaskId && propertyTaskData && !isLoading && (
            <PropertyTaskEntryView
              taskInfo={propertyTaskData}
              userRef={userRef}
              propertyInfo={propertyInfo}
              username={username}
              occupancyInfo={propertyOccupancyRef[propertyTaskData.propertyId]}
              disabled={isLoading || editPropertyTaskLoading}
              role={role}
              updateTask={async (updateTaskPayload: {
                _id: string,
                propertyTaskInfo: Partial<PropertyTaskType>
              }) => {
                await editPropertyTask({
                  propertyTaskId: updateTaskPayload._id,
                  propertyTaskInfo: updateTaskPayload.propertyTaskInfo
                }).unwrap()
              }}
              imageUploadProgress={imageUploadProgress}
              uploadImageMultiFn={uploadImageMultiFn}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

