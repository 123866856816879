import { useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { useAppDispatch } from "../../../redux/store"
import DateBlockEntry from "../../../Components/DateBlocks/DateBlockEntry/DateBlockEntry"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import { DateBlockEntryType } from "../../../Types/dateBlockTypes"
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm"
import { bookingSourceFieldValues } from "../../../Components/Forms/BookingForm/BookingFormConstants"
import { PropertyBookingEntry } from "../../../Types/bookingTypes"
import { convertIcalToBookings } from "../../../Helpers/Icals/IcalHelpers"
import { navigateTo } from "../../../redux/router/routerActions"
import { useEditDateBlockMutation, useGetDateBlockByIdQuery, useImportBookingsMutation } from "../../../services/bClientApi"

export default function DateBlockPage() {
  const [importBookings] = useImportBookingsMutation()
  const [
    editDateBlock,
    {
      isLoading: editDateBlockIsLoading,
      error: editDateBlockError,
    }
  ] = useEditDateBlockMutation()

  const {
    myProperties,
  } = useMyProperties()
  const { dateBlockId = '' } = useParams()
  const dispatch = useAppDispatch()

  const [convertToBookingMode, setConvertToBookingMode] = useState(false)

  const {
    data,
    isLoading,
    error
  } = useGetDateBlockByIdQuery(dateBlockId, { skip: !dateBlockId })
  const dateBlockData = data || {}


  const cancelBlockAndCreateBookings = async (bookingsToAdd: any[]) => {
    console.log('cancelBlockAndCreateBookings', bookingsToAdd)
    const updatedDateBlock = {
      ...dateBlockData,
      status: 'canceled'
    }
    if (!dateBlockId) {
      console.error('no dateBlockId')
      return
    }
    const checkInMillis = dateBlockData.checkIn ? new Date(dateBlockData.checkIn).getTime() : 0
    const dateBlockPropertyId = dateBlockData.propertyId
    const editedDateBlock = await editDateBlock({ dateBlockId, dateBlockInfo: updatedDateBlock }).unwrap()
    console.log('updatedBlockResponse', editedDateBlock)
    const newBookings = await importBookings(bookingsToAdd).unwrap()
    if (newBookings && newBookings.acknowledged) {
      dispatch(navigateTo(`/bookings?query={"firstName":"${updatedDateBlock?.firstName}","lastName":"${updatedDateBlock?.lastName}", "checkIn": ${checkInMillis}, "propertyId": "${dateBlockPropertyId}"}`))
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single DateBlock Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editDateBlockError && (<>{JSON.stringify(editDateBlockError)}<br /></>)}

          {dateBlockId && dateBlockData && !isLoading && (
            <div className="date-blockinfo">
              {/* <pre>
                {JSON.stringify(dateBlockData, null, 2)}            
              </pre> */}
              <DateBlockEntry
                dateBlockInfo={dateBlockData}
                propertyName={myProperties[dateBlockData.propertyId]?.title || 'Unknown'}
                full={true} />


              {!convertToBookingMode && (
                <Button
                  onClick={() => {
                    setConvertToBookingMode(true)
                  }}>
                  Convert to Booking
                </Button>
              )}

              {convertToBookingMode && (
                <>
                  <h3 className="mb-4">
                    Convert to Booking
                  </h3>

                  <QuickForm
                    disabled={editDateBlockIsLoading}
                    initialData={{
                      firstName: dateBlockData.firstName,
                      lastName: dateBlockData.lastName,
                      earnings: 0,
                      source: 'direct',
                      sourceConfirmation: `BCD${Date.now()}`,
                    }}
                    fields={[
                      {
                        fieldName: 'firstName',
                        fieldLabel: 'First Name',
                        fieldType: 'text',
                        required: true,
                        layout: {
                          md: 6
                        }
                      },
                      {
                        fieldName: 'lastName',
                        fieldLabel: 'Last Name',
                        fieldType: 'text',
                        required: true,
                        layout: {
                          md: 6
                        }
                      },
                      {
                        fieldName: 'source',
                        fieldLabel: 'Source',
                        fieldType: 'select',
                        required: true,
                        options: bookingSourceFieldValues,
                        layout: {
                          md: 4
                        }
                      },
                      {
                        fieldName: 'sourceConfirmation',
                        fieldLabel: 'Source Confirmation',
                        fieldType: 'text',
                        required: true,
                        layout: {
                          md: 4
                        }
                      },
                      {
                        fieldName: 'earnings',
                        fieldLabel: 'Earnings',
                        fieldType: 'number',
                        // required: true,
                        layout: {
                          md: 4
                        }
                      },
                    ]}
                    onSubmit={(e: any) => {
                      console.log('submit', e)
                      const propertyInfo = myProperties[dateBlockData.propertyId]
                      const convertedBookingData = {
                        checkIn: new Date(dateBlockData.checkIn),
                        checkOut: new Date(dateBlockData.checkOut),
                        propertyId: dateBlockData.propertyId,
                        earnings: e.earnings,
                        source: e.source,
                        summary: `${e.firstName} ${e.lastName}`,
                        cleaning: propertyInfo?.pricing?.cleaning || 0,
                        sourceConfirmation: e.sourceConfirmation,
                      }
                      const convertedBookings = convertIcalToBookings(convertedBookingData, myProperties).map(x => {
                        return {
                          ...x,
                          dateBooked: dateBlockData.created ? new Date(dateBlockData.created) : new Date(),
                          dateInquired: dateBlockData.created ? new Date(dateBlockData.created) : new Date(),
                          bookingStatus: dateBlockData.confirmationCode && dateBlockData.confirmationCode !== 'submitted' ? 'confirmed' : 'new',
                          confirmationCode: dateBlockData.confirmationCode ? dateBlockData.confirmationCode : '',
                        }
                      })
                      if(convertedBookings.length === 0) {
                        console.error('no bookings to convert')
                        return
                      }
                      cancelBlockAndCreateBookings(convertedBookings)
                    }}
                    onChange={(e: any, api: any) => {
                      if (e.target.name === 'source') {
                        if (e.target.value === 'direct' || e.target.value === 'website') {
                          api.setFieldValue('sourceConfirmation', `BCD${Date.now()}`)
                        } else {
                          api.setFieldValue('sourceConfirmation', '')
                        }
                      }
                    }}
                    onCancel={() => {
                      setConvertToBookingMode(false)
                    }}
                    submitText="Convert"
                    cancelText="Cancel"
                  />
                </>
              )}

            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export const convertDateBlockToBooking = (dateBlockData: DateBlockEntryType): Partial<PropertyBookingEntry> => {
  const {
    checkIn,
    checkOut,
    propertyId,
    // source,
    status,
    firstName,
    lastName,
    confirmationCode,
    // tags,
  } = dateBlockData

  return {
    propertyId: propertyId,
    contactInfo: {
      firstName: firstName,
      lastName: lastName,
      email: '',
      phone: ''
    },
    checkIn: checkIn,
    checkOut: checkOut,
    confirmationCode: confirmationCode,
    bookingStatus: status,
  }
}
