import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PropertyForm } from "../../../Components/Forms/PropertyForm/PropertyForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { accountSelector } from "../../../redux/account/accountSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { PropertyType } from "../../../Types/propertyTypes";
import './addProperty.scss'
import { useAddNewPropertyMutation } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function AddPropertyPage() {
  const { email, role } = useSelector(accountSelector)
  const dispatch = useAppDispatch()
  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const {
    uploadImageFn,
    imageUploadProgress,
    uploadImageMultiFn,
    // isLoading: uploadImageLoading,
    error: uploadImageError,
  } = useUploadImage()

  const [
    addNewProperty,
    {
      isLoading,
      error,
    }
  ] = useAddNewPropertyMutation()

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Property</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}

          {email && role && (
            <PropertyForm
              disabled={isLoading === true}
              propertyOptionList={propertyOptions}
              onSubmit={async (values: Partial<PropertyType>) => {
                if (!values.authorizedUsers) {
                  values.authorizedUsers = [{
                    email: email,
                    role: role
                  }]
                } else if (values.authorizedUsers.filter((x) => {
                  return x.email.toLowerCase() === email.toLowerCase()
                }).length === 0) {
                  values.authorizedUsers.push({
                    email: email,
                    role: role
                  })
                }
                const newProperty = await addNewProperty(values).unwrap()
                if (newProperty._id) {
                  dispatch(navigateTo(`/property/${newProperty._id}`))
                }
                return newProperty
              }}
              uploadImageFn={uploadImageFn}
              imageUploadProgress={imageUploadProgress}
              uploadImageMultiFn={uploadImageMultiFn}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
