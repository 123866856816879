import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function RankuuPage() {

    const [player1, setPlayer1] = useState<any>({})
    const [player2, setPlayer2] = useState<any>({})

    const [showRankings, setShowRankings] = useState<boolean>(false)

    const [mode, setMode] = useState<string>('random')

    const [playerPoolText, setPlayerPoolText] = useState<string>('')

    const [playerPool, setPlayerPool] = useState<any>({})
    // const [playerPool, setPlayerPool] = useState(generateInitialRankings())

    const reset = () => {
        setPlayer1({})
        setPlayer2({})
        setPlayerPool({})
        setPlayerPoolText('')
    }

    const convertPlayerPoolTextToPlayerPool = () => {
        const playerPoolTextLines = playerPoolText.split('\n')
        const newPlayerPool: any = {}
        playerPoolTextLines.forEach((x: string) => {
            // const [id, score, wins, losses, draws, played] = x.split(',')
            newPlayerPool[x] = {
                id: x,
                score: 1000, //parseInt(score),
                wins: 0, //parseInt(wins),
                losses: 0, //parseInt(losses),
                draws: 0, //parseInt(draws),
                played: 0, //parseInt(played),
            }
        })
        setPlayerPool(newPlayerPool)
    }

    const generateRandomMatchup = () => {
        console.log('-=== generateRandomMatchup ===-')
        const player1: PlayerType = getRandomPlayer('')
        const player2: PlayerType = getRandomPlayer(player1.id)
        setPlayer1(player1)
        setPlayer2(player2)
    }

    const switchMode = () => {
        if (mode === 'random') {
            setMode('plays')
            generateMatchupBasedOnPlays()
        } else {
            setMode('random')
            generateRandomMatchup()
        }
    }

    const getRandomPlayer = (ignore: string): PlayerType => {
        const keys = Object.keys(playerPool)
        const randomKey = keys[Math.floor(Math.random() * keys.length)]
        console.log('randomKey', randomKey)
        if (randomKey === undefined || randomKey === ignore) { return getRandomPlayer(ignore) }
        return playerPool[randomKey]
    }

    const generateMatchupBasedOnPlays = () => {
        let lowestPlayed = Infinity
        const keysSortedOnPlayed = Object.keys(playerPool).sort((a: any, b: any) => {
            if (playerPool[a].played < lowestPlayed) {
                lowestPlayed = playerPool[a].played
            }
            if (playerPool[b].played < lowestPlayed) {
                lowestPlayed = playerPool[b].played
            }
            return playerPool[a].played - playerPool[b].played
        })

        const player1 = playerPool[keysSortedOnPlayed[0]]
        const player2 = playerPool[keysSortedOnPlayed[1]]

        setPlayer1(player1)
        setPlayer2(player2)
    }

    const updateEloForBoth = (winner: PlayerType, loser: PlayerType) => {
        const expectedPlayer1 = eloRanker.getExpected(winner.score, loser.score);
        const expectedPlayer2 = eloRanker.getExpected(loser.score, winner.score);

        const newRatingPlayer1 = eloRanker.updateRating(expectedPlayer1, 1, winner.score);
        const newRatingPlayer2 = eloRanker.updateRating(expectedPlayer2, 0, loser.score);

        winner.score = newRatingPlayer1
        winner.wins += 1
        winner.played += 1

        loser.score = newRatingPlayer2
        loser.losses += 1
        loser.played += 1

        setPlayerPool({
            ...playerPool,
            [winner.id]: winner,
            [loser.id]: loser,
        })
        if (mode === 'random') {
            generateRandomMatchup()
            return
        }
        generateMatchupBasedOnPlays()
    }

    let lowestPlayed = Infinity

    const sortedPlayerPool = playerPool && Object.keys(playerPool).length > 1 ? Object.keys(playerPool).sort((a: any, b: any) => {
        if (playerPool[a].played < lowestPlayed) {
            lowestPlayed = playerPool[a].played
        }
        if (playerPool[b].played < lowestPlayed) {
            lowestPlayed = playerPool[b].played
        }

        return playerPool[b].score - playerPool[a].score
    }).map((x: any) => playerPool[x]) : [];

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Rankuu</h1>
                    Mode: {mode}
                    <br />
                    Lowest Played: {lowestPlayed}
                </Col>
                <Col>
                    <Button onClick={() => setShowRankings(!showRankings)} variant={'success'}>
                        {showRankings ? 'Hide' : 'Show'} Rankings
                    </Button>
                    &nbsp;
                    <Button onClick={switchMode}>Switch Mode</Button>
                    &nbsp;
                    <Button onClick={reset} variant={'danger'}>Reset</Button>
                </Col>
            </Row>

            <hr />

            {(!playerPool || sortedPlayerPool.length < 2) && (
                <Row>
                    <Col>
                        <h2>Not enough players</h2>
                        <br />
                        <textarea
                            value={playerPoolText}
                            onChange={(e) => {
                                setPlayerPoolText(e.target.value)
                            }}
                            placeholder="Paste in a list of players"
                            rows={10}
                            cols={50}
                        />
                        <br />
                        <Button onClick={convertPlayerPoolTextToPlayerPool}>
                            Play
                        </Button>
                    </Col>
                </Row>
            )}

            {Object.keys(playerPool).length > 1 && !player1.id && !player2.id && (
                <Row>
                    <Col>
                        <Button onClick={generateRandomMatchup}>Generate Matchup</Button>
                    </Col>
                </Row>
            )}

            {player1.id && player2.id && (
                <Row>
                    <Col>
                        <h2>{player1.id}</h2>
                        <Button
                            onClick={() => updateEloForBoth(player1, player2)}
                        >
                            Win
                        </Button>
                    </Col>
                    <Col>
                        <h2>{player2.id}</h2>
                        <Button
                            onClick={() => updateEloForBoth(player2, player1)}
                        >
                            Win
                        </Button>
                    </Col>
                </Row>
            )}

            <hr />

            {showRankings && sortedPlayerPool.map((x: any, idx: number) => (
                <Row key={idx}>
                    <Col>
                        {x.id}
                    </Col>
                    <Col>
                        Score: {x.score}
                    </Col>
                    <Col>
                        Wins: {x.wins}
                    </Col>
                    <Col>
                        Losses: {x.losses}
                    </Col>
                    <Col>
                        Draws: {x.draws}
                    </Col>
                    <Col>
                        Played: {x.played}
                    </Col>
                </Row>
            ))}

        </Container>
    )
}

// const taskList = [
//     'Smoke Weed',
//     'Eat Food',
//     'Drink Water',
//     'Sleep',
//     'Exercise',
//     'Meditate',
//     'Read',
//     'Write',
//     'Learn',
//     'Play',
//     'Create',
//     'Socialise',
//     'Work',
//     'Clean',
//     'Hygiene',
// ]

// const generateInitialRankings = () => {
//     const rankings: any = {}
//     taskList.forEach((x: any) => {
//         rankings[x] = {
//             id: x,
//             score: 1000,
//             wins: 0,
//             losses: 0,
//             draws: 0,
//             played: 0,
//         }
//     })
//     return rankings
// }

interface PlayerType {
    id: string,
    score: number,
    wins: number,
    losses: number,
    draws: number,
    played: number,
}


class EloRank {

    k: number;

    constructor(k?: number) {
        this.k = k || 32;
    }

    setKFactor(k: number) {
        this.k = k;
    }
    getKFactor() {
        return this.k;
    }

    getExpected(a: number, b: number) {
        return 1 / (1 + Math.pow(10, ((b - a) / 400)));
    }
    updateRating(expected: number, actual: number, current: number) {
        return Math.round(current + this.k * (actual - expected));
    }
}

const eloRanker = new EloRank(32);