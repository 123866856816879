import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { useGetBuildingByIdQuery } from "../../../services/bClientApi"

export default function BuildingPage() {
  const { buildingId = '' } = useParams()

  const {
    data,
    // isLoading,
    error,
  } = useGetBuildingByIdQuery(buildingId, { skip: !buildingId })

  const buildingData = data || {}

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Building Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {buildingId && buildingData && (
            <div className="buildinginfo">
              <pre>
                {JSON.stringify(buildingData, null, 2)}            
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
