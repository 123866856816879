import * as yup from 'yup'

export const blankTask = () => {
    return {
      propertyId: '',
      title: '',
      description: '',
      picture: '',
      status: 'new',
      priority: 4,
      dueDate: '',
      createdBy: '',
      createdAt: new Date(),
      assignedTo: '',
      completedDate: '',
      workLog: [],
      progressPics: [],
      tags: [],
  
      tempTag: ''
    }
  }

export const propertyTaskSchema = yup.object().shape({
    propertyId: yup.string().required(),
    title: yup.string().required(),
    description: yup.string(),
    picture: yup.string().url(),
    status: yup.string().required(),
    priority: yup.number().required(),
    dueDate: yup.date().nullable(),
    createdBy: yup.string(),
    createdAt: yup.date(),
    assignedTo: yup.string(),
    completedDate: yup.date().nullable(),
    workLog: yup.array().of(yup.object().shape({
        start: yup.date().required(),
        end: yup.date().nullable(),
        description: yup.string().required().nullable(),
        person: yup.string().required()
    })),
    progressPics: yup.array().of(yup.object().shape({
        imgUrl: yup.string().url().required(),
        description: yup.string().required(),
        timeStamp: yup.date(),
    })),
    tags: yup.array().of(yup.object().shape({
        tag: yup.string().required()
    }))
})