import { Col, Row } from "react-bootstrap";
import { TextInputField } from "../TextInput/TextInputField";

export default function AddressInputField(props: {
    fieldName?: string,
    required?: boolean
}) {

    const {
        fieldName = 'address',
        required = false        
    } = props

    return (
        <>
            <Row>
                <Col xs={12} md={9}>
                    <TextInputField
                        fieldLabel="Address"
                        required={required}
                        placeholder="Address"
                        fieldName={`${fieldName}.address1`} />

                </Col>
                <Col xs={12} md={3}>
                    <TextInputField
                        fieldLabel="Address 2"
                        placeholder="Address 2"
                        fieldName={`${fieldName}.address2`} />

                </Col>
            </Row>
            <Row>
                <Col xs={12} md={3}>
                    <TextInputField
                        fieldLabel="City"
                        required={required}
                        placeholder="City"
                        fieldName={`${fieldName}.city`} />
                </Col>
                <Col xs={12} md={3}>
                    <TextInputField
                        fieldLabel="State"
                        required={required}
                        placeholder="State"
                        fieldName={`${fieldName}.state`} />
                </Col>
                <Col xs={12} md={3}>
                    <TextInputField
                        fieldLabel="Zip"
                        required={required}
                        placeholder="Zip"
                        fieldName={`${fieldName}.zip`} />
                </Col>
                <Col xs={12} md={3}>
                    <TextInputField
                        fieldLabel="Country"
                        required={required}
                        placeholder="Country"
                        fieldName={`${fieldName}.country`} />
                </Col>
            </Row>
        </>

    )
}