import { Form, Formik } from "formik"
import { useState } from "react";
import { Button } from "react-bootstrap";
import { PropertyTaskType } from "../../../Types/propertyTaskType";
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput";
import { DateInputField } from "../../FormFields/DateInput/DateInputField";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import { blankTask, propertyTaskSchema } from "./PropertyTaskFormHelpers";
import QuickTag from "../../FormFields/QuickTag/QuickTag";

export default function PropertyTaskForm(props: {
    disabled: boolean,
    onSubmit: (v: any) => Promise<any>,
    uploadImageFn: (v: any) => Promise<string>,
    uploadImageMultiFn: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
    imageUploadProgress: string,
    initialValues?: Partial<PropertyTaskType>,
    propertyOptionList: { value: string, text: string }[]
    quickTask?: boolean,
    userSelectOptions?: { value: string, text: string }[]
}) {
    const {
        disabled,
        onSubmit,
        uploadImageFn,
        uploadImageMultiFn,
        imageUploadProgress,
        initialValues,
        propertyOptionList,
        quickTask,
        userSelectOptions
    } = props

    const [uploadingImage, setUploadingImage] = useState(false)


    return (
        <div className="property-task-form">
            <Formik
                initialValues={{
                    ...blankTask(),
                    ...(initialValues ? initialValues : {})
                }}
                validationSchema={propertyTaskSchema}
                // validate={(values) => { const errors: any = {}; return errors; }}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const submissionValues: any = { ...values }
                        delete submissionValues.tempTag
                        const submissionResponse = await onSubmit(submissionValues)
                        if (!submissionResponse.error) {
                            return
                        }
                    } catch (err) {
                        // custom error handler
                    }
                    setSubmitting(false)
                }}
            >
                {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                    <Form>

                        <SelectInputField
                            fieldLabel={'Property Id'}
                            placeholder={'- Select Property -'}
                            required={true}
                            fieldName={`propertyId`}
                            options={propertyOptionList}
                        />

                        <TextInputField
                            fieldLabel={'Title'}
                            fieldName={'title'}
                            placeholder={'Title'}
                            required={true}
                        />


                        <h3>Image</h3>

                        {values.picture && (
                            <div className="image-preview">
                                <img src={values.picture} alt="preview" />
                            </div>
                        )}

                        <ImageUploadField
                            key={'taskPictureUpload'}
                            uploadedImageCallback={async (imageInfo: any) => {
                                setUploadingImage(true)
                                try {
                                    const uploadedImageUrl = await uploadImageFn(imageInfo)
                                    if (uploadedImageUrl) {
                                        setFieldValue('picture', uploadedImageUrl)
                                    }
                                } catch (err) {
                                    // something went wrong
                                }
                                setUploadingImage(false)
                            }}
                        />

                        <hr />

                        <h3>Priority</h3>

                        {/* <TextInputField
                            fieldLabel={'Priority'}
                            fieldName={'priority'}
                            placeholder={'Priority'}
                            fieldType={'number'}
                            required={true}
                        /> */}

                        {[
                            { priority: 1, text: '1', color: 'danger' },
                            { priority: 2, text: '2', color: 'warning' },
                            { priority: 3, text: '3', color: 'info' },
                            { priority: 4, text: '4', color: 'secondary' },
                        ].map((p: any, index: number) => {
                            return (
                                <Button
                                    key={index}
                                    className="me-2 mb-2"
                                    onClick={() => {
                                        setFieldValue('priority', p.priority)
                                    }}
                                    size="sm"
                                    variant={values.priority === p.priority ? p.color : 'dark'}>
                                    {p.text}
                                </Button>
                            )
                        })}

                        <hr />

                        {!quickTask && (
                            <>
                                <h3>Status</h3>

                                <SelectInputField
                                    fieldLabel={'Status'}
                                    placeholder={'- Select Status -'}
                                    required={true}
                                    fieldName={`status`}
                                    options={[
                                        { value: 'new', text: 'New' },
                                        { value: 'approved', text: 'Approved' },
                                        { value: 'in progress', text: 'In Progress' },
                                        { value: 'blocked', text: 'Blocked' },
                                        { value: 'complete', text: 'Complete' },
                                        { value: 'confirmed', text: 'Confirmed' },
                                    ]}
                                />
                                <hr />
                            </>
                        )}


                        <h3>Tags</h3>

                        <QuickTag
                            tags={values.tags}
                            tempTag={values.tempTag}
                            tagFieldName={'tags'}
                            tempTagFieldName={'tempTag'}
                            tempTagFieldLabel={'Quick Tag'}
                            setFieldValue={setFieldValue}
                            buttons={[
                                { tag: 'building', text: 'Building' },
                                { tag: 'maintenance', text: 'Maintenance' },
                                { tag: 'cleaning', text: 'Cleaning' },
                            ]}
                        />


                        <hr />

                        {/* description field */}
                        <TextInputField
                            fieldLabel={'Description'}
                            fieldName={'description'}
                            placeholder={'Description'}
                        />

                        <hr />

                        {/* completedDate */}
                        {!quickTask && (
                            <DateInputField
                                fieldLabel={'Completed Date'}
                                fieldName={'completedDate'}
                                placeholder={'Completed Date'}
                            />
                        )}

                        {/* assignedTo */}
                        {userSelectOptions ? (
                            <SelectInputField
                                fieldLabel={'Assigned To'}
                                placeholder={'- Select Assigned To -'}
                                fieldName={`assignedTo`}
                                options={userSelectOptions}
                            />
                        ) : (
                            <TextInputField
                                fieldLabel={'Assigned To'}
                                fieldName={'assignedTo'}
                                placeholder={'Assigned To'}
                            />
                        )}

                        {!quickTask && (
                            <>
                                {/* dueDate */}
                                <DateInputField
                                    fieldLabel={'Due Date'}
                                    fieldName={'dueDate'}
                                    placeholder={'Due Date'}
                                />

                                {/* createdBy */}
                                <TextInputField
                                    fieldLabel={'Created By'}
                                    fieldName={'createdBy'}
                                    placeholder={'Created By'}
                                />

                                {/* createdAt */}
                                <DateInputField
                                    fieldLabel={'Created At'}
                                    fieldName={'createdAt'}
                                    placeholder={'Created At'}
                                />

                                <hr />

                                {/* workLog */}
                                <h3>Work Log</h3>

                                <ArrayFieldInput
                                    fieldName={'workLog'}
                                    values={values.workLog}
                                    setFieldValue={setFieldValue}
                                    blankEntryFn={() => ({
                                        start: '',
                                        end: '',
                                        description: '',
                                        person: '',
                                    })}
                                    fields={[
                                        {
                                            fieldType: 'date',
                                            fieldLabel: 'start',
                                            placeholder: 'Start Time',
                                            fieldName: 'start',
                                            required: true,
                                            extra: {
                                                dateFormat: "MMMM d, yyyy h:mm aa",
                                                // showTimeInput: true
                                            }
                                        },
                                        {
                                            fieldType: 'date',
                                            fieldLabel: 'end',
                                            placeholder: 'End Time',
                                            fieldName: 'end',
                                            extra: {
                                                dateFormat: "MMMM d, yyyy h:mm aa",
                                                // showTimeInput: true
                                            }
                                        },
                                        {
                                            fieldType: 'text',
                                            fieldLabel: 'description',
                                            placeholder: 'Description',
                                            fieldName: 'description',
                                            required: true,
                                        },
                                        {
                                            fieldType: 'text',
                                            fieldLabel: 'person',
                                            placeholder: 'Person',
                                            fieldName: 'person',
                                            required: true,
                                        },
                                    ]} />

                                <hr />

                                <h3>Progress Pictures</h3>
                                {imageUploadProgress && (<>{imageUploadProgress} <br /></>)}
                                <ImageUploadField
                                    key={'progressPicsUpload'}
                                    multipleImages={true}
                                    uploadedImageCallback={async (imagesInfo: any[]) => {
                                        setUploadingImage(true)
                                        try {
                                            const uploadedImageList = await uploadImageMultiFn(imagesInfo)
                                            if (uploadedImageList) {
                                                const mappedUploadedImages = uploadedImageList.map((imgUrl: string) => {
                                                    return {
                                                        imgUrl: imgUrl,
                                                        description: 'uploaded image',
                                                        timeStamp: new Date()
                                                    }
                                                })
                                                setFieldValue('progressPics', [...values.progressPics, ...mappedUploadedImages])
                                            }
                                        } catch (err) {
                                            // something went wrong
                                        }
                                        setUploadingImage(false)
                                    }}
                                />
                                <ArrayFieldInput
                                    fieldName={'progressPics'}
                                    customClass={'image-gallery-drag-container'}
                                    values={values.progressPics}
                                    setFieldValue={setFieldValue}
                                    blankEntryFn={() => ({ url: '', description: '', timeStamp: new Date() })}
                                    imgPreview={{
                                        srcVal: 'imgUrl'
                                    }}
                                    fields={[
                                        {
                                            fieldType: 'text',
                                            fieldLabel: 'imgUrl',
                                            placeholder: 'Image Url',
                                            fieldName: 'imgUrl',
                                            required: true,
                                        },
                                        {
                                            fieldType: 'text',
                                            fieldLabel: 'description',
                                            placeholder: 'Description',
                                            fieldName: 'description',
                                            required: true,
                                        },
                                        {
                                            fieldType: 'date',
                                            fieldLabel: 'timeStamp',
                                            placeholder: 'Time Stamp',
                                            fieldName: 'timeStamp',
                                            required: true,
                                            extra: {
                                                dateFormat: "MMMM d, yyyy h:mm aa",
                                                // showTimeInput: true
                                            }
                                        },
                                    ]} />
                            </>
                        )}


                        <hr />

                        Errors: {JSON.stringify(errors)}
                        <br />
                        {uploadingImage && (<><br />Uploading image please wait...</>)}

                        <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled || uploadingImage}>
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}