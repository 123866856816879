import { Col, Container, Row } from "react-bootstrap";
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry";
import DataImporter from "../../../Components/Data/DataImporter/DataImporter";
import timeService from "../../../services/time/timeService";
import { useBulkEditBookingsMutation, useImportBookingsMutation } from "../../../services/bClientApi";

export default function ImportBookingsPage() {
    const [importBookings] = useImportBookingsMutation()
    const [bulkEditBookings] = useBulkEditBookingsMutation()
    return (
        <Container>
            <Row>
                <Col>
                    <h1>Import Bookings</h1>
                    <hr />
                    You can use this tool to import properties.
                    <br /><br />
                    <a href="/assets/sample-booking-import.csv" download="sample-booking-import.csv">Click here to download a sample template</a>
                    <br /><br />
                    <b>Instructions:</b>
                    <ol>
                        <li>Download the sample template above.</li>
                        <li>Fill in the data.
                            <ul>
                                <li>Each entry requires a valid checkin and checkout date, formatted YYYY-MM-DD ex: 2019-12-25</li>
                                <li>
                                    Each entry requires a valid propertyId associated with your account.
                                    <ul>
                                        <li>
                                            This can be found in your properties list or the public link for the property you want to add a booking for.
                                        </li>
                                        <li>
                                            You can also use the nickName or title field in the file in place of the propertyId field.  These values must match up with
                                            the internal name or internal title used in the listed section under the property.
                                            <ul>
                                                <li>
                                                    Be sure your values are unique for each property if you intend to use this method. The nickName / internal name
                                                    field is checked before the title / internal title will be checked.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Make sure requiresConfirmation field you use either TRUE of FALSE values, otherwise it will be marked false.</li>
                            </ul>
                        </li>
                        <li>Upload the file below.</li>
                        <li>If there are any errors, they will be listed below. Fix them and upload the file again.</li>
                        <li>Review your entries below to confirm all the data was parsed correctly.</li>
                    </ol>

                    <b>Troubleshooting:</b>
                    <ul>
                        <li>
                            If you are having issues with your csv file, try pasting your data into a new&nbsp;
                            <a href="https://sheets.google.com" rel="noreferrer" target="_blank">google sheet</a> and then clicking
                            File &rarr; Download as &rarr; comma separated values (csv).
                        </li>
                    </ul>

                    <hr />
                    <DataImporter
                        importFn={async (x: any) => {
                            return await importBookings(x)
                        }}
                        bulkEditFn={async (x: any) => {
                            return await bulkEditBookings(x)
                        }}
                        complexSplitFields={[]}
                        entryRenderFn={(x: any) => (
                            <BookingEntry bookingInfo={x} propertyName={'Not provided'} />
                        )}
                        validationFn={(x: any) => {
                            let errors = []
                            if (!x.propertyId) {
                                // if (!validIds.includes(x.propertyId)) {
                                errors.push('Invalid Property Id ' + (x.propertyId ? x.propertyId : 'no value entered'))
                            }
                            if (x.checkIn == null) {
                                errors.push('Missing checkin date')
                            }
                            if (x.checkOut == null) {
                                errors.push('Missing checkout date')
                            }
                            return errors
                        }}
                        processEntryFn={(x: any) => {
                            const dataEntry: any = {
                                checkIn: x.checkIn ? timeService.setDateTime(x.checkIn, { hour: 16 }) : null,
                                checkOut: x.checkOut ? timeService.setDateTime(x.checkOut, { hour: 11 }) : null,
                                propertyId: x.propertyId ? x.propertyId : null,
                                pricePerNight: x.pricePerNight ? Number(x.pricePerNight) : null,
                                // TODO: some kind of airbnb homeaway parser - if contains either default to lowercase value and other booking listed places
                                source: x.source ? x.source.toLowerCase() : null,
                                sourceConfirmation: x.sourceConfirmation,
                                contactInfo: {
                                    firstName: x.firstName,
                                    lastName: x.lastName,
                                    email: x.email,
                                    phone: x.phone
                                },
                                dateBooked: x.dateBooked ? timeService.createLuxonDate(x.dateBooked).toJSDate() : null,
                                dateInquired: x.dateInquired ? timeService.createLuxonDate(x.dateInquired).toJSDate() : null,
                                bookingStatus: x.bookingStatus,
                                requiresConfirmation: x.requiresConfirmation ? x.requiresConfirmation.toLowerCase() === 'true' ? true : false : false,
                                confirmationCode: x.confirmationCode,
                                bookingGuestInfoId: x.bookingGuestInfoId,
                                requiresBookingGuestInfo: x.requiresBookingGuestInfo ? x.requiresBookingGuestInfo.toLowerCase() === 'true' ? true : false : false,
                                guestInfo: {
                                    guestTotal: x.guestTotal ? Number(x.guestTotal) : null,
                                    adults: x.adults ? Number(x.adults) : null,
                                    children: x.children ? Number(x.children) : null,
                                    infants: x.infants ? Number(x.infants) : null
                                },
                                cancellation: {
                                    amount: x.cancellationAmount ? x.cancellationAmount : 0,
                                    amountType: x.cancellationAmountType ? x.cancellationAmountType : 'percentage'
                                }
                            }
                            return dataEntry
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}