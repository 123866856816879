import { Badge, Button } from "react-bootstrap"
import { TextInputField } from "../TextInput/TextInputField"

export default function QuickTag(props: {
    className?: string,
    tempTag: string,
    tags: any[],
    buttons?: { tag: string, text: string }[],
    setFieldValue: (field: string, value: any) => void,

    tagFieldName?: string,
    tagEntryName?: string,
    tagFieldLabel?: string,

    tempTagFieldName?: string,
    tempTagFieldLabel?: string,

    options?: {
        hideInput?: boolean,
        hideButtons?: boolean,
        hideBadges?: boolean
    }
}) {
    const {
        tags,
        buttons = [],
        setFieldValue,
        tempTag,
        tagFieldName = 'tags',
        tagEntryName = 'tag',
        // tagFieldLabel = 'Tags',
        tempTagFieldName = 'tempTag',
        tempTagFieldLabel = 'Quick Tag',
        options = {},
        className
    } = props

    return (
        <div className={className ? className : ''}>
            {!options.hideButtons && (
                <div className="quick-tag-buttons">
                    {buttons.map((tag, index) => {
                        const tagSelected = tags.find((t: any) => t[tagEntryName] === tag.tag)
                        return (
                            <Button
                                key={index}
                                className="me-2 mb-2"
                                onClick={() => {
                                    if (tagSelected) {
                                        setFieldValue(tagFieldName, tags.filter((t: any) => t[tagEntryName] !== tag.tag))
                                    } else {
                                        setFieldValue(tagFieldName, [...tags, { [tagEntryName]: tag.tag }])
                                    }
                                }}
                                size="sm"
                                variant={tagSelected ? 'success' : 'secondary'}>
                                {tag.text}
                            </Button>
                        )
                    })}
                </div>
            )}
            {!options.hideInput && (
                <TextInputField
                    fieldLabel={tempTagFieldLabel}
                    fieldName={tempTagFieldName}
                    placeholder={tempTagFieldLabel}
                    fieldType={'text'}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            if (tempTag && !tags.find((t: any) => t[tagEntryName] === tempTag)) {
                                setFieldValue(tagFieldName, [...tags, { [tagEntryName]: tempTag }])
                                setFieldValue(tempTagFieldName, '')
                            }
                        }
                    }}
                />
            )}

            {!options.hideBadges && (
                <div className="property-tag-container">
                    {tags.map((tag: any, index: number) => (
                        <Badge key={index} className="me-2 mb-2">
                            {tag[tagEntryName]} &nbsp;
                            <span
                                onClick={() => {
                                    setFieldValue(tagFieldName, tags.filter((t: any, i: number) => i !== index))
                                }}
                                role="button">
                                X
                            </span>
                        </Badge>
                    ))}
                    {tags.length === 0 && (
                        <div className="text-muted">
                            <small>No {tagFieldName} selected</small>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}