import { showError, showSuccess } from "../../redux/counter/counterSlice"
import { store } from "../../redux/store"

export const copyToClipboard = (text: string) => {

    const dispatch = store.dispatch

    if (navigator?.clipboard?.writeText && window.location.protocol === 'https:') {
      navigator.clipboard.writeText(text)
      dispatch(showSuccess('Copied'))
      return
    }

    const textArea = document.createElement("textarea");
    textArea.value = text ? text : 'Nothing to copy';

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
      dispatch(showSuccess('Copied'))
    } catch (err) {
      console.error('Oops, unable to copy', err);
      dispatch(showError('Failed to copy'))
    }

    document.body.removeChild(textArea);
  }