import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import PurchaseForm from "../../../Components/Forms/PurchaseForm/PurchaseForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditPurchaseMutation, useGetPurchaseByIdQuery } from "../../../services/bClientApi";

export default function EditPurchasePage() {

  const [
    editPurchase,
    {
      isLoading: editPurchaseLoading,
      error: editPurchaseError,
    }
  ] = useEditPurchaseMutation()

  const { purchaseId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data,
    error,
    isLoading
  } = useGetPurchaseByIdQuery(purchaseId, { skip: !purchaseId })
  const purchaseData = data || {}


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (purchaseId) {
      const editedPurchase = await editPurchase({ purchaseId, purchaseInfo: values }).unwrap()
      if (editedPurchase._id) {
        dispatch(navigateTo(`/purchase/${editedPurchase._id}`))
      }
      return editedPurchase
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Purchase</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editPurchaseError && (<>{JSON.stringify(editPurchaseError)}<br /></>)}

          {purchaseId && purchaseData && !isLoading && (
            <PurchaseForm
              disabled={editPurchaseLoading}
              initialValues={purchaseData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
