import { Badge, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters";
import timeService from "../../services/time/timeService";
import { PropertyTaskType } from "../../Types/propertyTaskType";
import { PropertyType } from "../../Types/propertyTypes";
import PropertyTaskBadges from "./PropertyTaskBadges";
import PropertyTaskByPersonTable from "./PropertyTaskByPersonTable";

export default function PropertyTaskEntry(
    props: {
        taskInfo: PropertyTaskType,
        propertyInfo?: PropertyType,
        occupancyInfo?: any,
        userRef?: any,
        showTaskByPerson?: boolean,
        hideBody?: boolean,
        hideButtons?: {
            view?: boolean,
            edit?: boolean,
            delete?: boolean,
        },
        customButtons?: {
            text: string,
            onClick: (taskInfo: any) => void,
            disabled?: boolean,
            variant?: string
        }[]
    }
) {

    const {
        taskInfo,
        propertyInfo,
        occupancyInfo,
        userRef,
        hideButtons,
        hideBody,
        showTaskByPerson,
        customButtons,
    } = props;

    const {
        nextCheckIn,
        nextCheckInToday,
        // checkOutToday,
        // occupied,
    } = occupancyInfo || {};

    const parseUserName = (userId: string) => {
        if (userRef && userRef[userId]) {
            return userRef[userId].firstName + ' ' + userRef[userId].lastName
        }
        return userId
    }

    return (
        <Card 
        style={{backgroundColor: `#f9f9f9`}}
        className="mb-3">
            <Card.Body>
                <Card.Title>
                    {taskInfo.title}
                </Card.Title>
                {/* <Card.Subtitle className="fw-bold mb-2">
                    {taskInfo.title}
                </Card.Subtitle> */}

                {propertyInfo && (
                    <>
                        {/* <Card.Subtitle>
                            {propertyInfo?.title}
                        </Card.Subtitle> */}
                        <Card.Text className="mb-0 small">
                            {propertyInfo?.title}
                        </Card.Text>
                        <Card.Link
                            className={`small text-decoration-none`}
                            // className={`small text-decoration-none ${colorBg && !isOccupied ? 'text-light' : 'text-dark'}`}
                            href={`${googleAddress(propertyInfo.address)}`}
                            target="_blank">
                            {formatAddress(propertyInfo.address)}
                        </Card.Link>
                    </>
                )}
                {showTaskByPerson && (
                    <PropertyTaskByPersonTable
                        propertyTasksList={[taskInfo]}
                        userRef={userRef}
                        tableProps={{
                            size: "sm",
                        }}
                    />
                )}
                {!hideBody && (
                    <Card.Text>
                        {taskInfo.description && (
                            <>
                                {taskInfo.description} <br />
                            </>
                        )}

                        <b>Created: </b> {timeService.getFormattedTime(taskInfo.createdAt)} <br />
                        {/* <b>Property: </b> {taskInfo.propertyId} <br /> */}

                        {taskInfo.assignedTo && (
                            <>
                                <b>Assigned To: </b> {parseUserName(taskInfo.assignedTo)} <br />
                            </>
                        )}

                        {nextCheckIn && (
                            <>
                                <b>Next Check In: </b> {timeService.getFormattedTime(nextCheckIn)} {nextCheckInToday && (
                                    <Badge className="me-2 mb-2">
                                        today
                                    </Badge>
                                )} <br />
                            </>
                        )}
                    </Card.Text>
                )}
            </Card.Body>
            <Card.Footer>
                <PropertyTaskBadges
                    status={taskInfo.status}
                    priority={taskInfo.priority}
                    tags={taskInfo.tags}
                    occupancyInfo={occupancyInfo}
                    workLog={taskInfo.workLog}
                />

                {customButtons?.map((button, index) => (
                    <Button
                        key={index}
                        className="me-2 mb-2"
                        variant={button.variant || "primary"}
                        size="sm"
                        onClick={() => {
                            button.onClick(taskInfo)
                        }}
                        disabled={button.disabled}>
                        {button.text}
                    </Button>
                ))}

                {!hideButtons?.view && (
                    <Link className="btn btn-primary btn-sm me-2 mb-2" to={`/property-task/${taskInfo._id}`}>View</Link>
                )}
                {!hideButtons?.edit && (
                    <Link className="btn btn-secondary btn-sm me-2 mb-2" to={`/edit-property-task/${taskInfo._id}`}>Edit</Link>
                )}
                {!hideButtons?.delete && (
                    <Link className="btn btn-danger btn-sm me-2 mb-2" to={`/delete-property-task/${taskInfo._id}`}>Delete</Link>
                )}


            </Card.Footer>
        </Card>
    )
}