import { currencyFormatter } from "../../Helpers/Parsers/currencyFormatter"
import { expenseMultiplier, nightRateMultiplier } from "./DealConstants"

export const DealComponent = (props: {
    dealInfo: any,
    timeFrame: string
}) => {
    const {
        dealInfo,
        timeFrame
    } = props

    const {
        // name,
        // location,
        nightlyRate,
        occupancy,
        rent,
        electricity,
        maintenance,
        garbage,
        water,
        internet,
        repairs,
        association,
        propertyTax,
        insurance,
        // purchasePrice,
        // downPayment,
        // loanAmount,
        // interestRate,
        splitRatePercentage,
    } = dealInfo

    const totalIncome = (nightlyRate * nightRateMultiplier[timeFrame]) * occupancy / 100
    const totalVariableCosts = (rent + electricity + maintenance + garbage + water + internet + repairs + association) * expenseMultiplier[timeFrame]
    const totalFixedCosts = (propertyTax + insurance) * expenseMultiplier[timeFrame]
    const totalExpenses = totalVariableCosts + totalFixedCosts
    const netProfit = totalIncome - totalExpenses
    const splitRateAmount = netProfit * (splitRatePercentage / 100)
    const profitAfterSplit = netProfit - splitRateAmount
    const dealSections = [
        {
            title: 'Income',
            lineItems: [
                {
                    title: 'Nightly Rate',
                    value: nightlyRate,
                },
                {
                    title: 'Occupancy',
                    value: occupancy,
                },
                {
                    title: 'TOTAL',
                    value: totalIncome,
                },

            ]
        },
        {
            title: 'Variable Expenses',
            lineItems: [
                {
                    title: 'Rent',
                    value: rent * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Electricity',
                    value: electricity * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Maintenance',
                    value: maintenance * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Garbage',
                    value: garbage * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Water & Sewer',
                    value: water * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Internet & Cable',
                    value: internet * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Repairs & Incidentals',
                    value: repairs * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Association Fees',
                    value: association * expenseMultiplier[timeFrame],
                },
                {
                    title: 'TOTAL',
                    value: totalVariableCosts,
                },
            ]
        },
        {
            title: 'Fixed Expenses',
            lineItems: [
                {
                    title: 'Property Tax',
                    value: propertyTax * expenseMultiplier[timeFrame],
                },
                {
                    title: 'Insurance',
                    value: insurance * expenseMultiplier[timeFrame],
                },
                {
                    title: 'TOTAL',
                    value: totalFixedCosts,
                },
            ]
        },
        {
            title: 'Total Expenses',
            lineItems: [
                {
                    title: 'Variable Expenses',
                    value: totalVariableCosts,
                },
                {
                    title: 'Fixed Expenses',
                    value: totalFixedCosts,
                },
                {
                    title: 'TOTAL',
                    value: totalExpenses,
                },
            ]
        },
        {
            title: 'Net Profit',
            lineItems: [
                {
                    title: 'Income',
                    value: totalIncome,
                },
                {
                    title: 'Expenses',
                    value: totalExpenses,
                },
                {
                    title: 'TOTAL',
                    value: netProfit,
                },
            ]
        },
        {
            title: 'Profit After Split',
            lineItems: [
                {
                    title: 'Net Profit',
                    value: netProfit,
                },
                {
                    title: 'Split Rate',
                    value: splitRateAmount,
                },
                {
                    title: 'TOTAL',
                    value: profitAfterSplit,
                },
            ]
        }
    ]

    return (
        <div className="deal-section-entry-wrapper">
            <h3>{dealInfo.name}</h3>
            {dealSections.map((dealSection: any, idx: number) => (
                <div key={idx} className="deal-section-entry">
                    <h5>{dealSection.title} {timeFrame}</h5>
                    <ul>
                        {dealSection.lineItems.map((lineItem: any, idx: number) => (
                            <li key={idx} className={`deal-line-item ${lineItem.title === 'TOTAL' ? 'total-line' : ''}`}>
                                <div className="item-label">{lineItem.title}</div>
                                <div className="item-value">{currencyFormatter(lineItem.value)}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))
            }
        </div>
    )
}