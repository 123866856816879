import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { useGetPropertyPriceByIdQuery } from "../../../services/bClientApi"

export default function PropertyPricePage() {
  const { propertyPriceId = '' } = useParams()

  const {
    data,
    error,
    isLoading,
  } = useGetPropertyPriceByIdQuery(propertyPriceId, { skip: !propertyPriceId })
  const propertyPriceData = data || {}

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single PropertyPrice Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {propertyPriceId && propertyPriceData && !isLoading && (
            <div className="property-priceinfo">
              <pre>
                {JSON.stringify(propertyPriceData, null, 2)}            
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
