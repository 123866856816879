export const writeVal = (checkObject: any, accessor: string, value: any) => {
    let valRef = checkObject
    const splitName = accessor.split(/[.[\]]/)
    splitName.forEach((x, i) => {
        if (!valRef[x]) {
            valRef[x] = i === splitName.length - 1 ? value : {}
        }
        valRef = valRef[x]
    })

    return valRef
}
