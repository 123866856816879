import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry"
import { isParent } from "../../../Helpers/Property/isParent"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useRelatedBookings from "../../../Hooks/UseRelatedBookings/UseRelatedBookings"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteBookingMutation, useGetBookingByIdQuery } from "../../../services/bClientApi"

export default function DeleteBookingPage() {
  const { bookingId = '' } = useParams()

  const {
    data: bookingData,
    isLoading,
    error,
  } = useGetBookingByIdQuery(bookingId)

  const {
    _id,
  } = bookingData || {}

  const [
    deleteBooking,
    {
      error: deleteError,
      isLoading: deleteLoading,
    }
  ] = useDeleteBookingMutation()

  const dispatch = useAppDispatch()

  const [deleteClicked, setDeleteClicked] = useState(false)

  const { myProperties } = useMyProperties()
  const relatedBookings = useRelatedBookings(bookingId, bookingData, myProperties)

  const bookingDeleteFn = async (settings: {
    deleteRelated?: boolean,
  } = {}) => {
    const {
      deleteRelated
    } = settings

    if (deleteRelated && relatedBookings && relatedBookings.length > 0) {
      const bookingsToDelete: string[] = []
      relatedBookings.forEach(relatedBooking => {
        if (relatedBooking._id) bookingsToDelete.push(relatedBooking._id)
      })
      bookingsToDelete.push(bookingId)

      const deletedEntries: any[] = []
      for (let i = 0; i < bookingsToDelete.length; i++) {
        const deleteValue = await deleteBooking(bookingsToDelete[i]).unwrap()
        deletedEntries.push(deleteValue)
      }
      dispatch(navigateTo('/bookings'))
      return
    }
    if (bookingId) {
      const deleteValue = await deleteBooking(bookingId).unwrap()
      if (deleteValue) {
        dispatch(navigateTo(`/bookings/`))
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Delete Booking Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}

          {bookingId && bookingData && (
            <div className="booking-info">
              <BookingEntry
                bookingInfo={bookingData}
                propertyName={myProperties[bookingData.propertyId]?.title}
                options={{
                  details: true,
                  disableLinks: {
                    delete: true,
                  }
                }}
              />

              {relatedBookings && relatedBookings.length > 0 && (
                <div className="related-bookings">
                  <h3>Related Bookings</h3>
                  <div className="related-bookings-list">
                    {relatedBookings.map(relatedBooking => (
                      <BookingEntry
                        key={relatedBooking._id}
                        bookingInfo={relatedBooking}
                        propertyName={myProperties[relatedBooking.propertyId]?.title}
                        options={{
                          header: isParent(myProperties[relatedBooking.propertyId], myProperties[bookingData.propertyId]) ? 'PARENT' : 'CHILD',
                          details: true,
                          disableLinks: {}
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}

              <Stack gap={3}>
                <div>Are you sure you want to delete Booking: {_id}</div>
                <div>
                  <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Booking</Button>
                </div>
                {deleteClicked && (
                  <div>
                    Once you delete this booking it will be gone forever. <br />
                    <Stack direction="horizontal" gap={3}>
                      <Button
                        variant={'danger'}
                        disabled={isLoading || deleteLoading}
                        onClick={() => {
                          bookingDeleteFn()
                        }}> Confirm Delete Booking </Button>
                      {relatedBookings && relatedBookings.length > 0 && (
                        <Button
                          variant={'danger'}
                          disabled={isLoading || deleteLoading}
                          onClick={() => {
                            bookingDeleteFn({ deleteRelated: true })
                          }}> Confirm Delete Booking and Relatives </Button>
                      )}
                    </Stack>
                  </div>
                )}
              </Stack>

            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
