import { Button, Col, Container, ProgressBar, Row, Stack } from "react-bootstrap";
import { useAppDispatch } from "../../redux/store";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { useCallback, useEffect, useState } from "react";
import IcalEntry from "../../Components/Icals/IcalEntry/IcalEntry";
import timeService from "../../services/time/timeService";
import { UseAllIcalsDecorated } from "../../Hooks/UseIcals/UseIcalsDecorated";
import { convertIcalToBookings } from "../../Helpers/Icals/IcalHelpers";
import { navigateTo } from "../../redux/router/routerActions";
import IcalFilterForm from "../../Components/Forms/IcalFilterForm/IcalFilterForm";
import UseIcalsFilter from "../../Hooks/UseIcals/UseIcalsFilter";
import { IcalEntryType } from "../../Types/icalEntryType";
import { bClientApi, useGetBookingsQuery, useLazyGetPropertyIcalDataQuery, useImportBookingsMutation } from "../../services/bClientApi";

export default function IcalsPage() {

    const [icalDataSet, setIcalData] = useState<any>({
        icalData: {},
        isLoading: false,
        progress: {
            count: 0,
            total: 0
        }
    })

    const progress = icalDataSet.progress
    const icalData = icalDataSet.icalData

    const [getPropertyIcalData] = useLazyGetPropertyIcalDataQuery({
        refetchOnFocus: false,
    })

    const [importBookings] = useImportBookingsMutation()
    const dispatch: any = useAppDispatch()

    const { myProperties, publicPropertyIdKeys } = useMyProperties()

    const gatherAllIcalData = useCallback(async (forceFetch: boolean = false) => {
        if (publicPropertyIdKeys.length === 0) return
        if (icalDataSet.isLoading) return

        // clear ical data
        setIcalData({
            icalData: {},
            isLoading: true,
            progress: {
                count: 0,
                total: 0
            }
        })
        const propertyIds = publicPropertyIdKeys
        const tempIcalData: any = {}
        try {
            for (const propertyId of propertyIds) {
                console.log(propertyId)
                const propertyIcalData = await getPropertyIcalData(`${propertyId}${forceFetch ? '?forceIcal=true' : ''}`).unwrap()
                if (propertyIcalData.error) {
                    tempIcalData[propertyId] = []
                    continue
                }
                tempIcalData[propertyId] = propertyIcalData
                setIcalData({
                    icalData: tempIcalData,
                    isLoading: true,
                    progress: {
                        count: Object.keys(tempIcalData).length,
                        total: Object.keys(propertyIds).length
                    }
                })
            }
            setIcalData({
                icalData: tempIcalData,
                isLoading: false,
                progress: {
                    count: Object.keys(tempIcalData).length,
                    total: Object.keys(propertyIds).length
                }
            })
        } catch (error) {
            console.log('error', error)
        }
    }, [getPropertyIcalData, publicPropertyIdKeys, icalDataSet])

    useEffect(() => {
        if (publicPropertyIdKeys.length > 0 && !icalDataSet.isLoading && icalDataSet.progress.total === 0) {
            gatherAllIcalData()
        }
        return () => {}
    }, [publicPropertyIdKeys, icalDataSet, gatherAllIcalData])


    const startOfYesterday = timeService.getCurrentTime().minus({ days: 1 }).startOf('day').toMillis()
    const activeQuery = {
        query: {
            checkIn: startOfYesterday,
            bookingStatus: ['confirmed', 'pending', 'new'],
        },
        limit: 500,
        page: 1
    }

    const {
        data,
    } = useGetBookingsQuery({
        bookingsQuery: {
            propertyId: publicPropertyIdKeys,
            ...activeQuery.query
        },
        pagination: {
            page: 1,
            limit: 500,
            all: true,
        }
    }, { skip: Object.keys(myProperties).length === 0 })

    const bookingsList = data?.docs || []

    const [icalFilter, setIcalFilter]: any = useState({
        checkIn: new Date(startOfYesterday),
        checkOut: null,
        source: '',
        hideExisting: true,
        hideBlocked: true,
    })

    const allIcalData = UseAllIcalsDecorated({
        icalData,
        bookingsList,
        myProperties,
    })

    const filteredIcalData = UseIcalsFilter(allIcalData, icalFilter)

    const convertIcalBooking = async (iCalEntry: any) => {
        const bookingsToAdd = convertIcalToBookings(iCalEntry, myProperties)
        const newBookings = await importBookings(bookingsToAdd).unwrap()
        if (newBookings && newBookings.acknowledged) {
            dispatch(navigateTo(`/bookings?query={"firstName":"${bookingsToAdd[0].contactInfo?.firstName}","lastName":"${bookingsToAdd[0].contactInfo?.lastName}"}`))
        }
    }

    const updateIcalFilter = (values: any) => {
        setIcalFilter({
            ...values,
        })
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Icals Page</h1>
                    <hr />

                    <h3>All Icals</h3>

                    {progress.total !== progress.count ? (
                        <>
                            Loading <br />
                            <ProgressBar now={progress.total > 0 ? (progress.count / progress.total) * 100 : 0} label={`${progress.count} of ${progress.total}`} />
                        </>
                    ) : (
                        <Button
                            size={'sm'}
                            variant={'warning'}
                            onClick={() => {
                                dispatch(bClientApi.util.invalidateTags(['IcalData']))
                                gatherAllIcalData(true)
                            }}>
                            Hard Refresh
                        </Button>
                    )}

                    {bookingsList && bookingsList.length > 0 && (
                        <div>{bookingsList.length} bookings</div>
                    )}

                    <h3>Ical Bookings</h3>
                    <IcalFilterForm
                        checkIn={icalFilter.checkIn}
                        checkOut={icalFilter.checkOut ? icalFilter.checkOut : null}
                        hideExisting={icalFilter.hideExisting}
                        hideBlocked={icalFilter.hideBlocked}
                        source={icalFilter.source}
                        onSubmit={updateIcalFilter}
                    />

                    {allIcalData && allIcalData.length > 0 && filteredIcalData && (
                        <>
                            <Stack gap={3}>
                                {filteredIcalData.map((icalInfo: IcalEntryType, idx: number) => (
                                    <IcalEntry
                                        key={`${idx}-${icalInfo.unit}-${icalInfo.source}`}
                                        icalData={icalInfo}
                                        convertIcalBooking={convertIcalBooking}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}

                </Col>
            </Row>
        </Container>
    )
}