import { Link } from "react-router-dom";
import { BuildingEntryType } from "../../Types/buildingTypes";
import { Card } from "react-bootstrap";
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters";

export default function BuildingEntry(props: {
    buildingInfo: BuildingEntryType,
}) {
    const {
        buildingInfo,
    } = props

    const {
        _id,
        title,
        address
    } = buildingInfo

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <b>{title}</b>
                </Card.Title>
                <Card.Link
                    className={`small text-decoration-none`}
                    href={`${googleAddress(address)}`}
                    target="_blank">
                    {formatAddress(address)}
                </Card.Link>
            </Card.Body>

            <Card.Footer>
                <Link className="btn btn-primary btn-sm me-1 mb-1" to={`/building/${_id}`}>View</Link>
                <Link className="btn btn-secondary btn-sm me-1 mb-1" to={`/edit-building/${_id}`}>Edit</Link>
                <Link className="btn btn-danger btn-sm me-1 mb-1" to={`/delete-building/${_id}`}>Delete</Link>
            </Card.Footer>

            {/* {JSON.stringify(buildingInfo, null, 2)} <br /> */}
        </Card>
    )
}