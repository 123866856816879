import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BookingMessageEntry from "../../../Components/BookingMessage/BookingMessageEntry";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { useAppDispatch } from "../../../redux/store";
import timeService from "../../../services/time/timeService";
import { PropertyBookingMessageType } from "../../../Types/bookingMessageType";
import { showError } from "../../../redux/counter/counterSlice";
import { useGetBookingByIdQuery, useGetBookingMessagesQuery, useReplyToBookingMessageMutation } from "../../../services/bClientApi";

export default function ViewBookingMessagesPage() {

    const [
        replyBookingMessage,
        {
            error: replyBookingMessageError,
            isLoading: replyBookingMessageLoading,
        }
    ] = useReplyToBookingMessageMutation()

    const {
        bookingId = ''
    } = useParams()

    const {
        data: bookingData,
    } = useGetBookingByIdQuery(bookingId)

    const { myProperties } = useMyProperties()

    const activeQuery = {
        query: {
            bookingId: bookingId
        },
        limit: 500,
        page: 1,
    }

    const {
        data,
        error,
        isLoading
    } = useGetBookingMessagesQuery({
        bookingMessagesQuery: {
            ...activeQuery.query,
        },
        pagination: {
            limit: activeQuery.limit,
            page: activeQuery.page,
        }
    }, { skip: !bookingId })
    const bookingMessagesList = data?.docs || []

    const dispatch = useAppDispatch()


    const propertyInfo: any = bookingData && bookingData.propertyId && myProperties[bookingData.propertyId] ? myProperties[bookingData.propertyId] : {}

    const replyToBookingMessage = async (replyMessage: string) => {
        if (!replyMessage || !propertyInfo || !bookingData || !bookingMessagesList) return

        const lastMessage = bookingMessagesList[bookingMessagesList.length - 1]
        const {
            place,
            // email,
            subjectLine
        } = lastMessage
        const emailRef: { [s: string]: string } = {}
        bookingMessagesList.forEach((m: PropertyBookingMessageType) => {
            if (m.name !== 'Benichay Brothers' && !emailRef[m.name]) {
                emailRef[m.name] = m.email
            }
        })
        const email = [...(Object.keys(emailRef).map((name) => {
            return `"${emailRef[name]}"`
        }))].join(', ')


        const newBookingMessage = {
            propertyId: propertyInfo._id,
            bookingId: bookingData._id,
            email: "benichaybrothers@gmail.com",
            date: new Date(),
            checkIn: bookingData.checkIn,
            checkOut: bookingData.checkOut,
            place: place,
            source: "airbnb",
            name: "Benichay Brothers",
            subjectLine: `RE: ${subjectLine.replace('RE:', '').trim()}`,
            message: replyMessage,
        }

        await replyBookingMessage({
            replyEmail: email,
            bookingMessageInfo: newBookingMessage
        }).unwrap()

        dispatch(showError('this is still in dev mode, the reply email is inactive on the server.'))
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Mesasges for booking: {bookingId}</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {replyBookingMessageError && (<>{JSON.stringify(replyBookingMessageError)}<br /></>)}

                    {bookingData && (
                        <>
                            <b>
                                {propertyInfo && propertyInfo.title ? propertyInfo.title : bookingData.propertyId} <br />
                            </b>
                            <b>Guest Name:</b> {bookingData?.contactInfo?.firstName} {bookingData?.contactInfo?.lastName} <br />
                            <b>Staying:</b> {timeService.getFormattedTime(bookingData.checkIn, 'MM/dd')} - {timeService.getFormattedTime(bookingData.checkOut, 'MM/dd/yyyy')} <br />
                        </>
                    )}
                    <hr />
                    <h3>Messages</h3>
                    {bookingMessagesList && bookingMessagesList.map((message: PropertyBookingMessageType, idx: number) => (
                        <BookingMessageEntry
                            key={idx}
                            bookingMessage={message}
                        />
                    ))}

                    <hr />
                    <QuickForm
                        initialData={{
                            message: ''
                        }}
                        onSubmit={(values: any) => {
                            replyToBookingMessage(values.message)
                        }}
                        fields={[
                            {
                                fieldName: 'message',
                                fieldLabel: 'Message',
                                fieldType: 'text',
                                required: true,
                            }
                        ]}
                        disabled={isLoading || replyBookingMessageLoading}
                        submitText="Send Message"
                    />

                </Col>
            </Row>
        </Container>
    )
}