import { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useMyProperties from '../../../Hooks/UseMyProperties/UseMyPropertiesHook'
import './user.scss'
import AccessLinkButton from '../../../Components/Buttons/AccessLinkButton'
import canAccess from '../../../services/accessService/accessService'
import QuickForm from '../../../Components/Forms/QuickForm/QuickForm'
import { userFieldRoles } from '../../../Components/Forms/EditUserForm/EditUserFormHelpers'
import { useEditPropertyMutation, useGetUserInfoQuery } from '../../../services/bClientApi'

export default function UserPage() {

  const { userId = '' } = useParams()

  const {
    data,
    error,
    isLoading
  } = useGetUserInfoQuery(userId, { skip: !userId })
  const { _id, email, firstName, lastName, role } = data || {}

  const [
    editProperty,
    {
      isLoading: isEditing,
      error: editError
    }
  ] = useEditPropertyMutation()

  const { 
    myProperties, 
    isLoading: myPropertiesLoading,
    isFetching: myPropertiesFetching
  } = useMyProperties()

  const [roleOverride, setRoleOverride] = useState<string | null>(null)

  const processedMyProperties = myProperties ? Object.keys(myProperties).map((pId: string) => {
    const p = myProperties[pId]
    const processedEntry = {
      propertyInfo: p,
      propertyShortName: p.title.split(' - ')[0],
      authorized: false,
      role: ''
    }

    if (p.authorizedUsers) {
      p.authorizedUsers.forEach((aU: any) => {
        if (aU.email.toLowerCase() === email?.toLowerCase()) {
          processedEntry.authorized = true
          processedEntry.role = aU.role
        }
      })
    }

    return processedEntry
  }) : []

  const toggleUserOnProperty = async (propertyId: string, userEmail: string) => {
    const propertyInfo = myProperties[propertyId]
    const { authorizedUsers } = propertyInfo
    if (!propertyInfo || authorizedUsers.length === 0) { return }
    const filteredAuthorizedUsers = authorizedUsers.filter((aU: any) => aU.email.toLowerCase() !== userEmail.toLowerCase())
    const roleValue = roleOverride ? roleOverride : role
    const newAuthorizedUsers = filteredAuthorizedUsers.length === authorizedUsers.length ? [...authorizedUsers, { email: userEmail, role: roleValue }] : filteredAuthorizedUsers
    if (newAuthorizedUsers.length === 0) { return }
    await editProperty({ propertyId, propertyInfo: { authorizedUsers: newAuthorizedUsers } }).unwrap()
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>User Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}

          This is a page for a user: {userId}
          <br />
          {(firstName || lastName) && (<>{firstName} {lastName}</>)}
          <br />
          {email && (<>{email}</>)}
          <br />
          Role: {role && (<>{role}</>)}

          <div>
            <br />
            <AccessLinkButton
              color={'secondary'}
              action={'edit-user'}
              size={'sm'}
              to={`/edit-user/${_id}`}
              text={'Edit User'}
            />
            <AccessLinkButton
              color={'danger'}
              action={'delete-user'}
              size={'sm'}
              to={`/delete-user/${_id}`}
              text={'Delete User'}
            />
          </div>

          <hr />

          <QuickForm
            initialData={{ roleOverride: roleOverride }}
            fields={[
              {
                fieldType: 'select',
                fieldLabel: 'Role Override',
                fieldName: 'roleOverride',
                options: userFieldRoles()
              }
            ]}
            onSubmit={(values: any) => {
              setRoleOverride(values.roleOverride)
            }}
          />

          Current Property Role: {roleOverride ? roleOverride : role}

          <hr />
          {processedMyProperties.map((p: any) => (
            <div key={p.propertyInfo._id} className="user-property-add-remove-entry">
              <div className="property-title">
                {/* {p.propertyInfo.title}  */}
                {p.propertyShortName} - <span className={`${p.authorized ? 'text-success' : 'text-danger'}`}>
                  {p.authorized ? 'Authorized' : 'Not Authorized'}
                  {p.role && ` - ${p.role}`}
                </span>
              </div>
              <div className="add-remove-button">
                {canAccess('user', 'authorizeProperty') &&
                  <Button
                    size="sm"
                    variant={p.authorized ? 'danger' : 'success'}
                    disabled={myPropertiesLoading === true || isLoading || isEditing === true || myPropertiesFetching === true}
                    onClick={() => {
                      toggleUserOnProperty(p.propertyInfo._id, email!)
                    }}>
                    {p.authorized ? 'Remove Access' : 'Grant Access'}
                  </Button>
                }
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}
