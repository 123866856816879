import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import PropertyPriceForm from "../../../Components/Forms/PropertyPriceForm/PropertyPriceForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditPropertyPriceMutation, useGetPropertyPriceByIdQuery } from "../../../services/bClientApi";

export default function EditPropertyPricePage() {
  const [editPropertyPrice,
    {
      error: editError,
      isLoading: editLoading,
    }
  ] = useEditPropertyPriceMutation()
  const { propertyPriceId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data,
    error,
    isLoading,
  } = useGetPropertyPriceByIdQuery(propertyPriceId, { skip: !propertyPriceId })
  const propertyPriceData = data || {}

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (propertyPriceId) {
      const editedPropertyPrice = await editPropertyPrice({ propertyPriceId, propertyPriceInfo: values }).unwrap()
      if (editedPropertyPrice._id) {
        dispatch(navigateTo(`/property-price/${editedPropertyPrice._id}`))
      }
      return editedPropertyPrice
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit PropertyPrice</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}

          {propertyPriceId && propertyPriceData && !isLoading && (
            <PropertyPriceForm
              disabled={editLoading}
              initialValues={propertyPriceData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
