import { useMemo, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { PropertyEntry } from "../../../Components/PropertyEntry/PropertyEntry"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm"
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator"
import { textBooleanHandler } from "../../../Helpers/ValueHandlers/boolHandler"

export default function MyPropertiesPage() {
  const { myProperties } = useMyProperties()

  const [propertyFilters, setPropertyFilters] = useState({
    quickFilter: '',
    publicProperties: true,
  })

  const propertyList = useMemo(() => {
    const propertyList: any[] = []

    Object.keys(myProperties).forEach((propertyId: string) => {
      const property = myProperties[propertyId]
      if (propertyFilters.publicProperties && !property.public) {
        return
      }
      if (propertyFilters.quickFilter && !property.title.toLowerCase().includes(propertyFilters.quickFilter.toLowerCase())) {
        return
      }
      propertyList.push(property)
    })

    return propertyList
  }, [myProperties, propertyFilters])



  return (
    <Container>
      <Row>
        <Col>
          <h1>My Properties</h1>

          <QuickForm
            initialData={{
              quickFilter: '',
              publicProperties: true,
            }}
            fields={homeFormFields}
            onChange={(data: any) => {
              const fieldName = data.target.name
              const fieldValue = data.target.value
              if (quickFilterFields[fieldName]) {
                const newFilters = {
                  ...propertyFilters,
                  [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                }
                setPropertyFilters(newFilters)
              }
            }} />

          {propertyList && propertyList.length > 0 && (
            <div className="property-list-container">
              {propertyList.map((property) => (
                <PropertyEntry
                  key={`property-${property._id}`}
                  propertyInfo={property}
                />
              ))}
            </div>
          )}

        </Col>
      </Row>
    </Container>
  )
}

const quickFilterFields: any = {
  quickFilter: 1,
  publicProperties: {
    handler: textBooleanHandler
  },
}

const homeFormFields: FieldGeneratorFieldType[] = [
  {
    fieldName: 'quickFilter',
    fieldLabel: 'Quick Filter',
    fieldType: 'text',
  },
  {
    fieldName: 'publicProperties',
    fieldLabel: 'Public Properties',
    fieldType: 'checkbox',
    required: false
  }
]