import { useMemo } from "react";
import { useGetUsersQuery } from "../../services/bClientApi";

export default function useUserRefs() {

    const {
        data,
        // error,
        // isLoading,
    } = useGetUsersQuery({
        usersQuery: {},
        pagination: {
            page: 1,
            limit: 500,
        }
    }, {
        refetchOnFocus: false,
        refetchOnMountOrArgChange: 5 * 60 // in seconds
    })
    

    const userRef = useMemo(() => {
        const userList = data?.docs || []
        const userRefData: any = {}
        if (userList) {
            userList.forEach((user: any) => {
                userRefData[user.email] = user
            })
        }
        return userRefData
    }, [data])

    return userRef
}