import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField";
import { DateInputField } from "../../FormFields/DateInput/DateInputField";
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField";

export default function IcalFilterForm(
    props: {
        onSubmit: (values: any) => void,
        checkIn?: Date,
        checkOut?: Date,
        // publicProperty?: boolean,
        disabled?: boolean,
        source?: string,
        hideExisting?: boolean,
        hideBlocked?: boolean,
    }
) {
    const {
        checkIn,
        checkOut,
        source,
        hideExisting,
        hideBlocked,
        // propertyId,
        // publicProperty,
        disabled,
        onSubmit,
    } = props
    return (
        <Formik
            initialValues={{
                checkIn,
                checkOut,
                source,
                hideExisting,
                hideBlocked,
                // publicProperty,
                // propertyId,
            }}
            validate={values => {
                const errors: any = {};
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    onSubmit({
                        checkIn: values.checkIn ? (new Date(values.checkIn)).getTime() : null,
                        checkOut: values.checkOut ? (new Date(values.checkOut)).getTime() : null,
                        source: values.source,
                        hideExisting: values.hideExisting,
                        hideBlocked: values.hideBlocked,
                        // propertyId: values.propertyId,
                        // publicProperty: values.publicProperty

                    })
                } catch (err) {
                    // custom error handler
                }
                setSubmitting(false)
            }}
        >
            {({ values, isSubmitting, errors, touched }) => (
                <Form>
                    <DateInputField
                        fieldLabel="Check In"
                        placeholder="Check In"
                        maxDate={values.checkOut}
                        fieldName="checkIn" />

                    <DateInputField
                        fieldLabel="Check Out"
                        placeholder="Check Out"
                        minDate={values.checkIn}
                        fieldName="checkOut" />

                    <SelectInputField
                        fieldLabel={'Select Source'}
                        placeholder={'- Select Source -'}
                        // required={true}
                        // onChange={(e: any) => {}}
                        options={[
                            { value: 'airbnb', text: 'airbnb' },
                            { value: 'vrbo', text: 'vrbo' },
                            { value: 'booking', text: 'booking' },
                        ]}
                        fieldName={'source'} />
                        
                    <CheckBoxInputField
                        fieldLabel={'Hide Existing'}
                        fieldName={'hideExisting'} />

                    <CheckBoxInputField
                        fieldLabel={'Hide Blocked'}
                        fieldName={'hideBlocked'} />

                    <Button className="mb-3" type="submit" disabled={isSubmitting || disabled}>
                        Search
                    </Button>

                </Form>)}
        </Formik>
    )
}