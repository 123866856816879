import { CheckBoxInputField } from "../CheckBoxInput/CheckBoxInputField"
import { DateInputField } from "../DateInput/DateInputField"
import { SelectInputField } from "../SelectInput/SelectInputField"
import { TextInputField } from "../TextInput/TextInputField"

export interface FieldGeneratorFieldType {
    fieldName: string,
    fieldType: 'text' | 'textarea' | 'number' | 'password' | 'select' | 'email' | 'date' | 'dateRange' | 'checkbox' | 'imageUpload' | 'quickTag' | 'address',
    fieldLabel: string,
    placeholder?: string,
    options?:  { value: string | number, text?: string }[],
    extra?: any,
    layout?: {
        xs?: number,
        sm?: number,
        md?: number,
        lg?: number,
        xl?: number,
        xxl?: number,
    },
    condition?: (values: any) => boolean,
    required?: boolean,
    hidden?: boolean,
    onChange?: (e: any) => void,
}

const textFieldTypes: any = {
    text: 1,
    number: 1,
    password: 1,
    email: 1,
    textarea: 1,
}

export default function FieldGenerator(props: FieldGeneratorFieldType) {
    const {
        fieldType,
        fieldLabel,
        placeholder,
        fieldName,
        options,
        extra,
        required,
        onChange = () => {}
    } = props

    if (textFieldTypes[fieldType]) {
        return (
            <div className="field-gen-wrapper">
                <TextInputField
                    fieldType={fieldType}
                    fieldLabel={fieldLabel}
                    required={required}
                    placeholder={placeholder ? placeholder : fieldLabel}
                    fieldName={`${fieldName}`}
                    extra={extra}
                    onChange={onChange}/>
            </div>
        )
    }

    if (fieldType === 'select') {
        return (
            <div className="field-gen-wrapper">
                <SelectInputField
                    fieldLabel={fieldLabel}
                    placeholder={placeholder ? placeholder : fieldLabel}
                    required={required}
                    fieldName={`${fieldName}`}
                    options={options ? options : []}
                    onChange={onChange}/>
            </div>
        )
    }

    if (fieldType === 'date') {
        return (
            <div className="field-gen-wrapper">
                <DateInputField
                    // fieldType={fieldType}
                    // maxDate={'wtf'}
                    fieldLabel={fieldLabel}
                    required={required}
                    placeholder={placeholder ? placeholder : fieldLabel}
                    fieldName={`${fieldName}`}
                    onChange={onChange}
                    extraOptions={extra}/>
            </div>
        )
    }

    if (fieldType === 'checkbox') {
        return (
            <div className="field-gen-wrapper">
                <CheckBoxInputField
                    fieldLabel={fieldLabel}
                    required={required}
                    fieldName={`${fieldName}`}
                    onChange={onChange}/>
            </div>
        )
    }

    return (
        <div className="field-gen-wrapper">
            Field Type Undefined
            <br />
        </div>
    )
}