import { useSelector } from "react-redux"
import { useGetPropertiesListQuery } from "../../services/bClientApi"
import { PropertyType } from "../../Types/propertyTypes"
import { getSelectedPropertiesFromLocal, saveSelectedPropertiesToLocal } from "../../Pages/AdrOccupancyReporter/adrOccupancyHelpers"
import { accountSelector } from "../../redux/account/accountSlice"

export default function useMyProperties(options: {
  allData?: boolean
} = {}) {

  const {
    allData = false
  } = options

  const { username, token, initialized,
    // firstName, lastName, email, profilePic, role, loading, error, message 
  } = useSelector(accountSelector)

  const myProperties: {
    [propertyId: string]: PropertyType
  } = {}

  const {
    data,
    error,
    isLoading,
    isFetching,
    requestId,
  } = useGetPropertiesListQuery({
    propertiesQuery: {
      authorizedUser: username
    },
    pagination: {
      page: 1,
      limit: 150,
      all: true,
      ...(allData ? {} : { 
        projection: {
          imageGallery: 0,
          description: 0,
        }
      })
    }
  }, {
    skip: !username || !token || !initialized,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: 5 * 60 // in seconds
  })

  if (data?.docs) {
    data.docs.forEach((property: PropertyType) => {
      myProperties[property._id] = property
    })
  }

  const selectedProperties = getSelectedPropertiesFromLocal()
  let foundInvalidProperty = false
  Object.keys(selectedProperties).forEach((propertyId: string) => {
    if (!myProperties[propertyId]) {
      foundInvalidProperty = true
    }
  })
  if (foundInvalidProperty) {
    saveSelectedPropertiesToLocal({})
  }

  const publicPropertyIdKeys = Object.keys(myProperties).filter((propertyId) => myProperties[propertyId].public)

  return { myProperties, publicPropertyIdKeys, isLoading, isFetching, error, requestId }
}
