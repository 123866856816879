import { Col, Container, Row } from "react-bootstrap";
import BukibleForm from "../../../Components/Forms/BukibleForm/BukibleForm";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewBukibleMutation } from "../../../services/bClientApi";

export default function AddBukiblePage() {

  const {
    uploadImageFn,
  } = useUploadImage()

  const [addNewBukible,
    { isLoading, error, }
  ] = useAddNewBukibleMutation()

  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newBukible = await addNewBukible(values).unwrap()
    if (newBukible._id) {
      dispatch(navigateTo(`/bukible/${newBukible._id}`))
    }
    return newBukible
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Bukible</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <BukibleForm
            initialValues={{}}
            disabled={isLoading}
            onSubmit={handleSubmit}
            uploadImageFn={uploadImageFn}
          />

        </Col>
      </Row>
    </Container>
  )
}
