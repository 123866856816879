import { Button, Col, Container, Row } from "react-bootstrap";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { useEditPropertyMutation, useLazyGetBookingsQuery } from "../../services/bClientApi";
import { useState } from "react";
import timeService from "../../services/time/timeService";

export default function QueryWhore() {

    const { myProperties } = useMyProperties()

    const [getBookings] = useLazyGetBookingsQuery()

    const [editProperty] = useEditPropertyMutation()

    const [processing, setProcessing] = useState(false)

    const runProcess = async () => {
        setProcessing(true)
        const propertyIds = [...Object.keys(myProperties)]
        // const propertyData = { ...myProperties }
        for (const propertyId of propertyIds) {
            console.log('Processing property:', propertyId)
            const bookingData = await getBookings({
                bookingsQuery: {
                    propertyId: propertyId,
                    dateBookedStart: timeService.createLuxonDate(new Date()).minus({ years: 10 }).toISODate(),
                    dateBookedEnd: timeService.createLuxonDate(new Date()).plus({ years: 10 }).toISODate(),
                },
                pagination: {
                    limit: 1,
                    page: 1,
                    sort: { dateBooked: 1 }
                }
            }).unwrap()
            if(bookingData.docs && bookingData.docs.length > 0) {
                console.log('Booking found:', bookingData.docs[0].dateBooked)
                await editProperty({
                    propertyId: propertyId,
                    propertyInfo: {
                        created: new Date(bookingData.docs[0].dateBooked),
                    }
                }).unwrap()
            } else {
                console.log('No booking found')
            }
        }
        setProcessing(false)
    }


    return (
        <Container>
            <Row>
                <Col>
                    {myProperties && Object.keys(myProperties).length > 0 && (
                        <>
                            {Object.keys(myProperties).length} properties found <br />
                        </>
                    )}
                    <Button
                        onClick={runProcess}
                        disabled={processing}
                    >
                        Run
                    </Button>

                </Col>
            </Row>
        </Container>
    )
}