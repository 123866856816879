import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";

export default function PropertySearchForm(props: {
  title: string,
  publicProperty: boolean,
  disabled: boolean,
  onSubmit: (x: any) => void
}) {
  const { title, publicProperty, disabled, onSubmit } = props
  return (
    <div className="property-search-form">
      <Formik
        initialValues={{
          title,
          publicProperty
        }}
        validate={values => {
          const errors: any = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            onSubmit({
              title: values.title,
              publicProperties: values.publicProperty
            })
          } catch (err) {
            // custom error handler
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <TextInputField
              fieldLabel="Property Title"
              placeholder="Property Title"
              fieldName="title" />

            <CheckBoxInputField
              fieldLabel={'Public'}
              fieldName={'publicProperty'} />

            <Button className="mb-3" type="submit" disabled={isSubmitting || disabled}>
              Search
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
