import loadImage from 'blueimp-load-image'

export function modImageName(filename: string, extra: string): string {
  const fileParts = filename.split('.');
  const extension = fileParts.pop();
  const modifiedName = `${fileParts.join('_')}${extra}.${extension}`;

  return modifiedName;
}

export async function processImage(imageInput: any, maxDimension: number = 2000) {
  const file: File = imageInput.files[0]
  // handle normal files
  const isImage = file.type.includes('image')
  if (!isImage) {
    const fileBlob = new Blob([file], { type: file.type })
    return {
      imageToUpload: fileBlob,
      filename: file.name
    }
  }
  // handle images
  const fixedImage = await rotateImages([file], maxDimension)

  return { imageToUpload: fixedImage[0], filename: file.name }
}

export async function processMultipleImages(imageInput: any, maxDimension: number = 2000) {
  const processedImages = []
  for (const file of imageInput.files) {
    // handle normal files
    const isImage = file.type.includes('image')
    if (!isImage) {
      const fileBlob = new Blob([file], { type: file.type })
      processedImages.push({ imageToUpload: fileBlob, filename: file.name })
      continue
    }
    // handle images
    const fixedImage = await rotateImages([file], maxDimension)
    processedImages.push({ imageToUpload: fixedImage[0], filename: file.name })
  }
  return processedImages
}

async function rotateImages(images: (File | string)[], maxDimension: number) {
  const fixedImages = await fixRotation(images, maxDimension)
  return fixedImages
}

// BLUE IMP STUFF
export async function fixRotation(arrayOfFiles: any[], maxDimension: number) {
  for (let i = 0; i < arrayOfFiles.length; i++) {
    arrayOfFiles[i] = await fixRotationOfFile(arrayOfFiles[i], maxDimension)
  }
  return arrayOfFiles
}

async function fixRotationOfFile(file: File | string, maxDimension: number) {

  // TODO: check the actual type of file and use it below for the mime type.

  return new Promise((resolve) => {
    loadImage(file, (img) => {
      const canvasImg = createCanvas(img, maxDimension)
      canvasImg.toBlob(
        (blob) => {
          resolve(blob)
        },
        '' // mimeType is not needed for jpg image/jpeg => defaults to PNGs
      )
    }, { orientation: true })
  })
}

function createCanvas(img: any, maxDimension: number) {
  const canvas = document.createElement('canvas');
  const ctx: any = canvas.getContext('2d');

  let width = img.width;
  let height = img.height;

  if (width > maxDimension || height > maxDimension) {
    const aspectRatio = width / height;
    if (width > height) {
      width = maxDimension;
      height = Math.floor(maxDimension / aspectRatio);
    } else {
      height = maxDimension;
      width = Math.floor(maxDimension * aspectRatio);
    }
  }

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(img, 0, 0, width, height);

  return canvas;
}
