import { useState } from 'react'
import { Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { useParams } from "react-router"
import { navigateTo } from '../../../redux/router/routerActions'
import { useAppDispatch } from "../../../redux/store"
import { useDeletePropertyMutation, useGetPropertyByIdQuery } from '../../../services/bClientApi'

export default function DeletePropertyPage() {

  const dispatch = useAppDispatch()
  const {
    propertyId = ''
  } = useParams()

  const {
    data: propertyData = {},
    error,
    isLoading
  } = useGetPropertyByIdQuery(propertyId, { skip: !propertyId })

  const { _id = '' } = propertyData

  const [deleteClicked, setDeleteClicked] = useState(false)

  const [
    deleteProperty,
    {
      isLoading: isDeleting,
      error: deleteError
    }
  ] = useDeletePropertyMutation()

  return (
    <Container>
      <Row>
        <Col>
          <h1>Delete Property Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}

          {propertyId && propertyId === _id && propertyData && (
            <Stack gap={3}>
              <div>Are you sure you want to delete property: {_id}</div>

              <div>{propertyData.title}</div>

              <div>
                <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Property</Button>
              </div>

              {deleteClicked && (
                <div>
                  Once you delete this property it will be gone forever. <br />
                  <Button
                    variant={'danger'}
                    disabled={isLoading || isDeleting}
                    onClick={async () => {
                      const deleteValue = await deleteProperty(propertyId).unwrap()
                      if (deleteValue) {
                        dispatch(navigateTo(`/my-properties/`))
                      }
                    }}> Confirm Delete Property </Button>
                </div>
              )}
            </Stack>
          )}
        </Col>
      </Row>
    </Container>
  )
}
