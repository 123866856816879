import { BuildingEntryType } from "../../../Types/buildingTypes"
import { useState } from "react"
import { Form, Formik } from "formik"
import { TextInputField } from "../../FormFields/TextInput/TextInputField"
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload"
import { modImageName } from "../../FormFields/ImageUpload/imageUploadHelpers"
import Collapsible from "../../Collapsible/Collapsible";
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput"
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField"
import { Button } from "react-bootstrap"
import { imageGalleryFieldValues, linkedServicesFieldValues } from "../commonFieldValues"

const generateBlankBuilding = () => ({
  created: new Date(),
  title: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  linkedServices: [],
  featuredImage: '',
  thumbnailImage: '',
  imageGallery: [],
  public: true,
})

export default function BuildingForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<BuildingEntryType>,
  uploadImageFn: (v: any) => Promise<string>,
  uploadImageMultiFn: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
  imageUploadProgress: string,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    uploadImageFn,
    uploadImageMultiFn,
    imageUploadProgress,
  } = props

  const [uploadingImage, setUploadingImage] = useState(false)


  return (
    <div className="building-form">
      <Formik
        initialValues={{
          ...generateBlankBuilding(),
          ...(initialValues ? initialValues : {})
        }}
        // validationSchema={buildingSchema}
        validate={values => { const errors: any = {}; return errors; }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionResponse = await onSubmit(values)
            if (!submissionResponse.error) {
              return
            }
          } catch (err) {
            // custom error handler
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form>

            <h3>General Info</h3>
            <TextInputField
              fieldLabel="Title"
              placeholder="title"
              required={true}
              fieldName="title" />

            <h3>Buiding Address</h3>
            <TextInputField
              fieldLabel="Address"
              required={true}
              placeholder="Address"
              fieldName={`address.address1`} />

            <TextInputField
              fieldLabel="Address 2"
              placeholder="Address 2"
              fieldName={`address.address2`} />

            <TextInputField
              fieldLabel="City"
              required={true}
              placeholder="City"
              fieldName={`address.city`} />

            <TextInputField
              fieldLabel="State"
              required={true}
              placeholder="State"
              fieldName={`address.state`} />

            <TextInputField
              fieldLabel="Zip"
              required={true}
              placeholder="Zip"
              fieldName={`address.zip`} />

            <TextInputField
              fieldLabel="Country"
              required={true}
              placeholder="Country"
              fieldName={`address.country`} />


            <h3>Featured Image</h3>
            {values.featuredImage && (
              <>
                <img alt={'featured preview'} src={values.featuredImage} className="image-preview" />
                <br />
              </>
            )}
            <TextInputField
              fieldLabel="Featured Image"
              placeholder="Featured Image"
              fieldName={`featuredImage`} />

            <TextInputField
              fieldLabel="Thumbnail Image"
              placeholder="Thumbnail Image"
              fieldName={`thumbnailImage`} />

            <ImageUploadField
              key={'featuredImageUpload'}
              uploadedImageCallback={async (imageInfo: any, thumbInfo: any) => {
                setUploadingImage(true)
                try {
                  const uploadedImageUrl = await uploadImageFn(imageInfo)
                  const uploadedThumbUrl = await uploadImageFn({
                    ...thumbInfo,
                    filename: modImageName(thumbInfo.filename, '_thumb')
                  })
                  if (uploadedImageUrl) {
                    setFieldValue('featuredImage', uploadedImageUrl)
                    setFieldValue('thumbnailImage', uploadedThumbUrl)
                  }
                } catch (err) {
                  // something went wrong
                }
                setUploadingImage(false)
              }}
            />
            <br />

            <h3>Building Images</h3>
            <Collapsible>
              <div>
                <label htmlFor="Image Gallery">Image Gallery</label><br />
                <ArrayFieldInput
                  fieldName={'imageGallery'}
                  customClass={'image-gallery-drag-container'}
                  values={values.imageGallery}
                  setFieldValue={setFieldValue}
                  blankEntryFn={() => ({ url: '', title: '', description: '', thumbUrl: '' })}
                  imgPreview={{
                    srcVal: 'url'
                  }}
                  fields={imageGalleryFieldValues} />
                {imageUploadProgress && (<>{imageUploadProgress} <br /></>)}
                <ImageUploadField
                  key={'imageGalleryUpload'}
                  multipleImages={true}
                  uploadedImageCallback={async (imagesInfo: any[], thumbsInfo: any[]) => {
                    setUploadingImage(true)
                    try {
                      const uploadedImageList = await uploadImageMultiFn(imagesInfo)
                      const uploadedThumbs = await uploadImageMultiFn(thumbsInfo.map(x => {
                        return {
                          ...x,
                          filename: modImageName(x.filename, '_thumb')
                        }
                      }))
                      if (uploadedImageList) {
                        const mappedUploadedImages = uploadedImageList.map((imgUrl: string, idx: number) => {
                          const thumbUrl = uploadedThumbs && uploadedThumbs[idx] ? uploadedThumbs[idx] : ''
                          return {
                            url: imgUrl,
                            title: '',
                            description: '',
                            thumbUrl
                          }
                        })
                        setFieldValue('imageGallery', [...values.imageGallery, ...mappedUploadedImages])
                      }
                    } catch (err) {
                      // something went wrong
                    }
                    setUploadingImage(false)
                  }}
                />
              </div>
            </Collapsible>

            <h3>Linked Services</h3>
            <Collapsible>
              <div>
                <label htmlFor="Authorized Users">Linked Services</label><br />
                <ArrayFieldInput
                  fieldName={'linkedServices'}
                  values={values.linkedServices}
                  setFieldValue={setFieldValue}
                  blankEntryFn={() => ({ linkedService: '', linkedServiceId: '' })}
                  fields={linkedServicesFieldValues} />
              </div>
            </Collapsible>

            <h3>Public Building</h3>
            <CheckBoxInputField
              fieldLabel={'Display this building to the public'}
              fieldName={'public'} />

            Errors: {JSON.stringify(errors)}
            <br />
            <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled || uploadingImage}>
              Submit
            </Button>


          </Form>
        )}
      </Formik>
    </div>
  )
}
