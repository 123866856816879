import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getActiveQueryFromUrl } from "../../Helpers/Query/queryHelpers"
import { routerSelector } from "../../redux/router/routerSelector"
import { ActiveQueryType } from "../../Types/queryTypes"

export default function useActiveQueryHook(defaultQuery?: any) {
    const { location } = useSelector(routerSelector)
    const activeQuery: ActiveQueryType = useMemo(() => {
        return getActiveQueryFromUrl(location?.search ? location.search : '', {
            ...(defaultQuery ? defaultQuery : {})
        })
    }, [location, defaultQuery])

    return { activeQuery }
}