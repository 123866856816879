import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BukibleForm from "../../../Components/Forms/BukibleForm/BukibleForm";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditBukibleMutation, useGetBukibleByIdQuery } from "../../../services/bClientApi";


export default function EditBukiblePage() {
  const [
    editBukible,
    {
      isLoading: editLoading,
      error: editError,
    }
  ] = useEditBukibleMutation()
  const { bukibleId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data,
    isLoading,
    error,
  } = useGetBukibleByIdQuery(bukibleId, { skip: !bukibleId })
  const bukibleData = data || {}

  const {
    uploadImageFn,
  } = useUploadImage()


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (bukibleId) {
      const editedBukible = await editBukible({ bukibleId, bukibleInfo: values }).unwrap()
      if (editedBukible._id) {
        dispatch(navigateTo(`/bukible/${editedBukible._id}`))
      }
      return editedBukible
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Bukible</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}

          {bukibleId && bukibleData && !isLoading && (
            <BukibleForm
              disabled={editLoading}
              initialValues={bukibleData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
