import QuickForm from "../QuickForm/QuickForm"
import { BookingRequestEntryType } from "../../../Types/bookingRequestTypes"

const generateBlankBookingRequest = () => ({
  name: '',
})

export default function BookingRequestForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<BookingRequestEntryType>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
  } = props

  return (
    <div className="booking-request-form">
      <QuickForm 
        initialData={{
          ...generateBlankBookingRequest(),
          ...(initialValues ? initialValues : {})
        }}
        disabled={disabled}
        onSubmit={onSubmit}
        fields={[
          // created, checkIn, checkOut, propertyId, propertyName, guestName, email, phone, message 
            {
                fieldName: 'created',
                fieldLabel: 'Created',
                fieldType: 'date',
                placeholder: 'Created',
            },
            {
                fieldName: 'checkIn',
                fieldLabel: 'Check In',
                fieldType: 'date',
                placeholder: 'Check In',
            },
            {
                fieldName: 'checkOut',
                fieldLabel: 'Check Out',
                fieldType: 'date',
                placeholder: 'Check Out',
            },
            {
                fieldName: 'propertyId',
                fieldLabel: 'Property Id',
                fieldType: 'text',
                placeholder: 'Property Id',
            },
            {
                fieldName: 'propertyName',
                fieldLabel: 'Property Name',
                fieldType: 'text',
                placeholder: 'Property Name',
            },
            {
                fieldName: 'guestName',
                fieldLabel: 'Name',
                fieldType: 'text',
                placeholder: 'Name',
            },
            {
                fieldName: 'email',
                fieldLabel: 'Email',
                fieldType: 'text',
                placeholder: 'Email',
            },
            {
                fieldName: 'phone',
                fieldLabel: 'Phone',
                fieldType: 'text',
                placeholder: 'Phone',
            },
            {
                fieldName: 'message',
                fieldLabel: 'Message',
                fieldType: 'textarea',
                placeholder: 'Message',
            },
        ]}
      />
    </div>
  )
}
