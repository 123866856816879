import { PropertyBookingEntry } from "../../Types/bookingTypes"
import { PropertyType } from "../../Types/propertyTypes";
import timeService, { DATE_FORMAT } from "../../services/time/timeService"


export const createBlockShiftLogic = (
    myProperties: { [propertyId: string]: PropertyType; },
    bookingQueue: { [key: string]: PropertyBookingEntry },
    blocksByProperties: { [key: string]: any[] },
    options: { disableNewBookings?: boolean; } = {}
) => {

    const {
        disableNewBookings = false
    } = options

    let copyText = ''
    const selectedBookingsByProperty: any = {}
    Object.keys(bookingQueue).forEach((bookingId) => {
        const booking = bookingQueue[bookingId]
        if (!selectedBookingsByProperty[booking.propertyId]) {
            selectedBookingsByProperty[booking.propertyId] = {
                bookings: [],
                blocks: blocksByProperties[booking.propertyId] ? blocksByProperties[booking.propertyId] : [],
                propertyData: myProperties[booking.propertyId]
            }
        }
        selectedBookingsByProperty[booking.propertyId].bookings.push(booking)
    })

    Object.keys(selectedBookingsByProperty).forEach((propertyId) => {
        // console.log(propertyId, '-=-=-=-=-=-=-=-=-')
        const property = selectedBookingsByProperty[propertyId]

        const {
            bookings: unsortedBookings,
            blocks: unsortedBlocks,
            propertyData
        } = property

        const unitNumber = propertyData?.address?.address2

        const bookings = unsortedBookings.sort((a: any, b: any) => {
            return a.checkIn - b.checkIn
        })
        const blocks = unsortedBlocks.sort((a: any, b: any) => {
            return a.checkIn - b.checkIn
        })

        let earliestDay = Infinity
        let latestDay = 0
        if (bookings.length > 0) {
            bookings.forEach((bookings: any) => {
                const checkInMillis = timeService.createLuxonDate(bookings.checkIn).toMillis()
                const checkOutMillis = timeService.createLuxonDate(bookings.checkOut).toMillis()
                if (checkInMillis < earliestDay) {
                    earliestDay = checkInMillis
                }
                if (checkOutMillis > latestDay) {
                    latestDay = checkOutMillis
                }
            })
        }
        if (blocks.length > 0) {
            blocks.forEach((block: any) => {
                const checkInMillis = timeService.createLuxonDate(block.checkIn).toMillis()
                const checkOutMillis = timeService.createLuxonDate(block.checkOut).toMillis()
                if (checkInMillis < earliestDay) {
                    earliestDay = checkInMillis
                }
                if (checkOutMillis > latestDay) {
                    latestDay = checkOutMillis
                }
            })
        }
        // console.log(earliestDay, latestDay)
        const dateHash = timeService.createDateHashObject(earliestDay, latestDay, {
            extraFields: {

                blockIn: '',
                block: '',
                blockOut: '',

                bookIn: '',
                book: '',
                bookOut: ''
            }
        })

        const blockRef: any = {}
        const bookingRef: any = {}

        blocks.forEach((block: any) => {
            if (!blockRef[block._id]) {
                blockRef[block._id] = {
                    ...block,
                }
            }
            const checkInString = timeService.createLuxonDate(block.checkIn).toFormat(DATE_FORMAT)
            const checkOutString = timeService.createLuxonDate(block.checkOut).toFormat(DATE_FORMAT)
            timeService.createDateHashObject(block.checkIn, block.checkOut, {
                callback: (date: string) => {
                    if (checkOutString === date) {
                        dateHash[date].blockOut = block._id
                        return
                    }
                    dateHash[date].block = block._id
                    if (checkInString === date) {
                        dateHash[date].blockIn = block._id
                    }
                }
            })
        })

        bookings.forEach((booking: any) => {
            if (!bookingRef[booking._id]) {
                bookingRef[booking._id] = {
                    ...booking,
                }
            }
            const checkInString = timeService.createLuxonDate(booking.checkIn).toFormat(DATE_FORMAT)
            const checkOutString = timeService.createLuxonDate(booking.checkOut).toFormat(DATE_FORMAT)
            timeService.createDateHashObject(booking.checkIn, booking.checkOut, {
                callback: (date: string) => {
                    if (checkOutString === date) {
                        dateHash[date].bookOut = booking._id
                        return
                    }
                    dateHash[date].book = booking._id
                    if (checkInString === date) {
                        dateHash[date].bookIn = booking._id
                    }
                }
            })
        })

        const finalBlocks: any = []

        let activeBlock: any = null
        let activeBooking: any = null

        Object.keys(dateHash).forEach((date: string) => {
            const dateData = dateHash[date]
            const {
                // blockIn,
                block,
                blockOut,
                // bookIn,
                book,
                bookOut
            } = dateData

            // BLOCKS
            if (activeBlock && blockOut) {
                finalBlocks.push({
                    ...activeBlock,
                    checkOut: date
                })
                activeBlock = null
            }
            if (block && !activeBlock && !activeBooking) {
                activeBlock = {
                    id: block,
                    type: 'block',
                    checkIn: date,
                    checkOut: date,
                }
            }
            if (block && activeBlock) {
                activeBlock.checkOut = date
            }

            // BOOKINGS
            if (activeBooking && bookOut) {
                finalBlocks.push({
                    ...activeBooking,
                    checkOut: date
                })
                activeBooking = null
            }
            if (book && !activeBooking) {
                activeBooking = {
                    id: book,
                    type: 'booking',
                    checkIn: date,
                    checkOut: date,
                    touchingBlocks: [],
                }
                if (activeBlock) {
                    finalBlocks.push({
                        ...activeBlock,
                        checkOut: date,
                    })
                    activeBlock = null
                }
            }
            if (book && activeBooking) {
                activeBooking.checkOut = date
            }
            if (block && activeBooking) {
                activeBooking.touchingBlocks.push(block)
            }

            // BLOCKS WRAP UP
            if (!activeBooking && block && !activeBlock) {
                activeBlock = {
                    id: block,
                    type: 'block',
                    checkIn: date,
                    checkOut: date,
                }
            }
        })

        const touchedBlocks: any = {}
        const modList = finalBlocks.filter((x: any) => {
            if (x.type === 'block') {
                const blockInfo = blockRef[x.id]
                const checkIn = timeService.createLuxonDate(blockInfo.checkIn).toFormat(DATE_FORMAT)
                const checkOut = timeService.createLuxonDate(blockInfo.checkOut).toFormat(DATE_FORMAT)
                if (checkIn === x.checkIn && checkOut === x.checkOut) {
                    return false
                }
                if (x.checkIn === x.checkOut) {
                    return false
                }
                if (!touchedBlocks[x.id]) {
                    touchedBlocks[x.id] = []
                }
                touchedBlocks[x.id].push(x)
                return true
            }
            if (x.touchingBlocks) {
                const usedBlocks: any = {}
                x.touchingBlocks.forEach((blockId: string) => {
                    if (!touchedBlocks[blockId]) {
                        touchedBlocks[blockId] = []
                    }
                    if (usedBlocks[blockId]) {
                        return
                    }
                    touchedBlocks[blockId].push(x)
                    usedBlocks[blockId] = true
                })
            }
            return true
        })
        // console.log(modList)
        // console.log(touchedBlocks)

        const cancelReservations: any[] = []
        const modifiedReservations: any[] = []
        const newReservations: any[] = []

        const handledBlocks: any = {}
        const handledBookings: any = {}

        Object.keys(touchedBlocks).forEach((blockId: string) => {
            const entries = touchedBlocks[blockId]
            const baseBlockInfo = blockRef[blockId]
            // const blockCheckInMillis = timeService.createLuxonDate(baseBlockInfo.checkIn).toMillis()
            const blockCheckInString = timeService.createLuxonDate(baseBlockInfo.checkIn).toFormat(DATE_FORMAT)
            // const blockCheckOutMillis = timeService.createLuxonDate(baseBlockInfo.checkOut).toMillis()
            const blockCheckOutString = timeService.createLuxonDate(baseBlockInfo.checkOut).toFormat(DATE_FORMAT)
            const outOfBounds: any = []
            const filteredEntries = entries.filter((x: any) => {
                if (x.type === 'booking') {
                    return false

                    // RESTORE THIS IF YOU WANT TO ADD PEOPLE TO BOOKINGS AGAIN
                    // const bookingInfo = bookingRef[x.id]
                    // if (handledBookings[x.id]) {
                    //     return false
                    // }
                    // const bookingCheckIn = timeService.createLuxonDate(bookingInfo.checkIn).toMillis()
                    // const bookingCheckOut = timeService.createLuxonDate(bookingInfo.checkOut).toMillis()
                    // const stayTime = bookingCheckOut - bookingCheckIn
                    // if (bookingCheckIn < blockCheckInMillis || bookingCheckOut > blockCheckOutMillis) {
                    //     outOfBounds.push({
                    //         ...x,
                    //         stayTime
                    //     })
                    //     return false
                    // }
                }
                return true
            }).map((x: any) => {
                const checkInMillis = timeService.createLuxonDate(x.checkIn).toMillis()
                const checkOutMillis = timeService.createLuxonDate(x.checkOut).toMillis()
                const stayTime = checkOutMillis - checkInMillis
                return {
                    ...x,
                    stayTime
                }
            }).sort((a: any, b: any) => {
                return b.stayTime - a.stayTime
            })

            if (filteredEntries.length === 0 && outOfBounds.length === 0) {
                cancelReservations.push({
                    type: 'block',
                    checkIn: blockCheckInString,
                    checkOut: blockCheckOutString,
                    confirmationCode: baseBlockInfo.confirmationCode,
                    name: `${baseBlockInfo.firstName} ${baseBlockInfo.lastName}`,
                })
                return
            }

            if (filteredEntries.length === 0 && outOfBounds.length > 0) {
                const sortedOutOfBounds = outOfBounds.sort((a: any, b: any) => {
                    return b.stayTime - a.stayTime
                })
                filteredEntries.push({
                    ...sortedOutOfBounds[0],
                    outOfBounds: true
                })
            }

            const longestStay = filteredEntries[0]
            const modifyReservation = {
                ...longestStay,
                confirmationCode: baseBlockInfo.confirmationCode,
                name: `${baseBlockInfo.firstName} ${baseBlockInfo.lastName}`,
                originalCheckIn: blockCheckInString,
                originalCheckOut: blockCheckOutString,
            }

            if (modifyReservation.type === 'booking') {
                const bookingInfo = bookingRef[modifyReservation.id]
                modifyReservation.addName = `${bookingInfo.contactInfo.firstName} ${bookingInfo.contactInfo.lastName}`
                handledBookings[modifyReservation.id] = true
            }
            if (modifyReservation.type === 'block') {
                handledBlocks[`${modifyReservation.id}-${modifyReservation.checkIn}-${modifyReservation.checkOut}`] = true
            }

            modifiedReservations.push(modifyReservation)

        })

        modList.forEach((x: any) => {
            if (x.type === 'block') {
                if (handledBlocks[`${x.id}-${x.checkIn}-${x.checkOut}`]) {
                    return
                }
                const blockInfo = blockRef[x.id]
                const newRSVP = {
                    checkIn: x.checkIn,
                    checkOut: x.checkOut,
                    name: `${blockInfo.firstName} ${blockInfo.lastName}`,
                }
                newReservations.push(newRSVP)
            }
            if (x.type === 'booking') {
                if (handledBookings[x.id]) {
                    return
                }
                const bookingInfo = bookingRef[x.id]
                const newRSVP = {
                    checkIn: x.checkIn,
                    checkOut: x.checkOut,
                    name: `${bookingInfo.contactInfo.firstName} ${bookingInfo.contactInfo.lastName}`,
                }
                newReservations.push(newRSVP)
            }
        })

        // console.log('cancel reservations')
        // console.log(cancelReservations)

        // console.log('modified reservations')
        // console.log(modifiedReservations)

        // console.log('new reservations')
        // console.log(newReservations)


        // console.log('BEGIN INSTRUCTIONS: ______________________________________________')

        cancelReservations.forEach((x: any) => {
            const [, checkInMonth, checkInDay] = x.checkIn.split('/')
            // const [_checkInYear, checkInMonth, checkInDay] = x.checkIn.split('/')
            const [, checkOutMonth, checkOutDay] = x.checkOut.split('/')
            // const [_checkOutYear, checkOutMonth, checkOutDay] = x.checkOut.split('/')
            const cancelString = `${unitNumber} - ${x.name} - ${x.confirmationCode} - Cancel reservation: ${checkInMonth}/${checkInDay} - ${checkOutMonth}/${checkOutDay}`
            // console.log(cancelString)
            copyText += `${cancelString}\n`
        })

        modifiedReservations.forEach((x: any, idx: number) => {
            const [, checkInMonth, checkInDay] = x.checkIn.split('/')
            // const [_checkInYear, checkInMonth, checkInDay] = x.checkIn.split('/')
            const [, checkOutMonth, checkOutDay] = x.checkOut.split('/')
            // const [_checkOutYear, checkOutMonth, checkOutDay] = x.checkOut.split('/')

            let modifyString = `${unitNumber} - ${x.name} - ${x.confirmationCode} - `

            if (x.addName) {
                // this should never happen but in case it does this will push cancel
                // restore the lines in the filter above to allow for adding names to bookings
                modifyString += `Cancel reservation: ${checkInMonth}/${checkInDay} - ${checkOutMonth}/${checkOutDay}`
                newReservations.push({
                    checkIn: x.checkIn,
                    checkOut: x.checkOut,
                    name: x.addName
                })
            } else {
                if (x.checkIn !== x.originalCheckIn && x.checkOut !== x.originalCheckOut) {
                    modifyString += `Update check in and check out to: `
                } else if (x.checkIn !== x.originalCheckIn) {
                    modifyString += `Update check in to: `
                } else if (x.checkOut !== x.originalCheckOut) {
                    modifyString += `Update check out to: `
                }
                modifyString += `${checkInMonth}/${checkInDay} - ${checkOutMonth}/${checkOutDay}`
            }

            // BEGIN ORIGINAL MOD LOGIC
            // let moddedDate = false
            // let modifyString = `${unitNumber} - ${x.name} - ${x.confirmationCode} - `
            // if (x.checkIn !== x.originalCheckIn && x.checkOut !== x.originalCheckOut) {
            //     modifyString += `Update check in and check out to: `
            //     moddedDate = true
            // } else if (x.checkIn !== x.originalCheckIn) {
            //     modifyString += `Update check in to: `
            //     moddedDate = true
            // } else if (x.checkOut !== x.originalCheckOut) {
            //     modifyString += `Update check out to: `
            //     moddedDate = true
            // }
            // if (moddedDate) {
            //     modifyString += `${checkInMonth}/${checkInDay} - ${checkOutMonth}/${checkOutDay}`
            // }
            // if (x.addName) {
            //     if (moddedDate) {
            //         modifyString += ' - '
            //     }
            //     modifyString += `Add name to reservation: ${x.addName}`
            // }
            // END ORIGINAL MOD LOGIC

            if (idx === modifiedReservations.length - 1 && newReservations.length > 0 && !disableNewBookings) {
                const newResCount = newReservations.length
                modifyString += `. ${newResCount} Reservation${newResCount > 1 ? 's' : ''} to follow.`
            }
            // console.log(modifyString)
            copyText += `${modifyString}\n`
        })

        if (disableNewBookings) {
            const reservationCount = modifiedReservations.length + cancelReservations.length
            if(reservationCount > 0) {
                copyText += '\n'
                copyText += '-------------------------------------------------------------\n\n'                
            }
            return
        }

        newReservations.sort((x, y) => {
            return timeService.dateToMillis(x.checkIn) - timeService.dateToMillis(y.checkIn)
        }).forEach((x: any) => {
            const [, checkInMonth, checkInDay] = x.checkIn.split('/')
            // const [_checkInYear, checkInMonth, checkInDay] = x.checkIn.split('/')
            const [, checkOutMonth, checkOutDay] = x.checkOut.split('/')
            // const [_checkOutYear, checkOutMonth, checkOutDay] = x.checkOut.split('/')
            const newResString = `${unitNumber} - ${x.name} - New booking - ${checkInMonth}/${checkInDay} - ${checkOutMonth}/${checkOutDay}`
            // console.log(newResString)
            copyText += `${newResString}\n`
        })



        copyText += '\n'
        copyText += '-------------------------------------------------------------\n\n'

    })

    return copyText
}