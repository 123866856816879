import { Col, Container, Row } from "react-bootstrap"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import { Link } from "react-router-dom"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import { useGetPropertyPricesQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"

export default function PropertyPricesPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
            query: {},
            limit: 10,
    })

    const {
        data,
        error,
        isLoading,
    } = useGetPropertyPricesQuery({
        propertyPricesQuery: {
            ...activeQuery.query,
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
        }
    })
    const propertyPricesList = data?.docs || []

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/property-prices?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>PropertyPrices</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            name: activeQuery.query.name,
                        }}
                        fields={[
                            {
                                fieldName: 'propertyId',
                                fieldLabel: 'Property ID',
                                fieldType: 'text',
                                placeholder: 'Propety ID',
                            }
                        ]}
                        disabled={isLoading}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: activeQuery.limit
                            })
                        }}
                    />

                    <div className="propertyPrice-list-container">
                        {propertyPricesList && propertyPricesList.length > 0 && propertyPricesList.map((propertyPriceEntry: any, idx: number) => (
                            <div key={idx}>
                                {JSON.stringify(propertyPriceEntry, null, 2)} <br />
                                <Link className="btn btn-primary btn-sm" to={`/property-price/${propertyPriceEntry._id}`}>View</Link>
                                <Link className="btn btn-secondary btn-sm" to={`/edit-property-price/${propertyPriceEntry._id}`}>Edit</Link>
                                <Link className="btn btn-danger btn-sm" to={`/delete-property-price/${propertyPriceEntry._id}`}>Delete</Link>
                            </div>
                        ))
                        }
                    </div>


                        <MongoPagination
                            data={data}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: activeQuery.query,
                                    limit: activeQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />

                </Col>
            </Row>
        </Container>
    )
}
