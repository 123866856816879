import * as yup from 'yup'
import canAccess from '../../../services/accessService/accessService'

export const editUserSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
  profilePic: yup.string().nullable(),
  password: yup.string().nullable(),
})

export const userFieldRoles = () => ([
  ...(canAccess('user', 'form.grantAdmin') ? [{ value: 'admin', text: 'Admin' }] : []),
  { value: 'association', text: 'Association' },
  { value: 'association-member', text: 'Association Member' },
  { value: 'casual', text: 'Casual' },
  { value: 'cleaning', text: 'Cleaning' },
  { value: 'front-desk', text: 'Front Desk' },
  { value: 'maintenance', text: 'Maintenance' },
  { value: 'maintenance-lead', text: 'Maintenance Lead' },
  { value: 'manager', text: 'Manager'},
  { value: 'owner', text: 'Owner' },
  { value: 'viewer', text: 'Viewer' },
])
