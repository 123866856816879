import { Card } from "react-bootstrap";
import { PropertyType } from "../../Types/propertyTypes";
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters"
import './propertyEntry.scss'

export default function FullPropertyEntry(props: {
    propertyInfo: PropertyType
}) {

    const {
        _id,
        title,
        address,
        description,
        aliases,
        owner,
        featuredImage,
        imageGallery,
        calendars,
        listed,
        about,
        multiUnit,
        pricing,
        virtualTour,
        services,
        authorizedUsers
    } = props.propertyInfo

    return (
        <Card className="property-entry">
            {/* <Card.Header>
        <b>{title}</b>
      </Card.Header> */}
            <Card.Body>
                <Card.Title>
                    <b>{title}</b>
                </Card.Title>
                <Card.Subtitle>
                    {_id ? _id : "No ID"}
                </Card.Subtitle>
                <Card.Link
                    className={`small text-decoration-none`}
                    href={`${googleAddress(address)}`}
                    target="_blank">
                    {formatAddress(address)}
                </Card.Link>
                <br />
                <b>Description: </b> {description} <br />
                <h4>Aliases:</h4> <br />
                <ul>
                    {aliases.map((alias: any, idx: number) => (
                        <li key={idx}>
                            <b>Alias: </b> {alias.alias} <br />
                            <b>Alias Source: </b> {alias.aliasSource} <br /><br />
                        </li>
                    ))}
                </ul>

                <h4>Owner</h4>
                <b>Name: </b> {owner.firstName} {owner.lastName} <br />
                <b>Email: </b> {owner.email} <br />
                <b>Phone: </b> {owner.phone} <br />

                <h4>Images</h4>
                <b>Featured Image: </b> <br />

                <img src={featuredImage} alt="" /> <br />
                <b>Image Gallery: </b>

                <div className="image-grid">
                    {imageGallery.map((imageEntry: any, idx: number) => (
                        <div key={idx} className="image-grid-item">
                            <img src={imageEntry.url} alt="" /> <br />
                            Title: {imageEntry.title ? imageEntry.title : '-'} <br />
                            Description: {imageEntry.description ? imageEntry.description : '-'} <br />
                        </div>
                    ))}
                </div>
                <br />

                <h4>Calendars</h4>
                <ul>
                    {calendars.map((calendarEntry: any, idx: number) => (
                        <li key={idx}>
                            <b>{calendarEntry.source}: </b> {calendarEntry.url} <br />
                        </li>
                    ))}
                </ul>
                <br />

                <h4>Listed</h4>
                <ul>
                    {listed.map((listedEntry: any, idx: number) => (
                        <li key={idx}>
                            <b>{listedEntry.source}: </b> {listedEntry.url} <br />
                            <b>Internal Name: </b> {listedEntry.internalName} <br />
                            <b>Internal Title: </b> {listedEntry.internalTitle} <br />
                        </li>
                    ))}
                </ul >
                <br />

                <h4>About</h4>
                <b>Summary:</b> {about.summary} <br />
                <ul>
                    {about.sections.map((section: any, idx: number) => (
                        <li key={idx}>
                            <b>{section.sectionTitle}</b> <br />
                            {section.content} <br />
                        </li>
                    ))}
                </ul >
                <b>Guests: </b> {about.guests} <br />

                <b>Adults: </b> {about.guestInfo.adults} <br />
                <b>Children: </b> {about.guestInfo.children} <br />
                <b>Infants: </b> {about.guestInfo.infants} <br />

                <b>Bedrooms/Beds: </b> {about.bedrooms} / {about.beds} <br />
                <b>Bathrooms/Baths: </b> {about.bathrooms} / {about.baths} <br />
                <br />

                <h4>Multi Unit</h4>
                <b>Is Multi Unit: </b> {multiUnit.isMultiUnit} <br />
                <ul>
                    {multiUnit.linkedUnits.map((linkedUnit: any, idx: number) => (
                        <li key={idx}>
                            <b>PropertyId: </b> {linkedUnit.propertyId} <br />
                            <b>Relationship: </b> {linkedUnit.relationship} <br />
                        </li>
                    ))}
                </ul >
                <br />

                <h4>Pricing</h4>
                <b>Base Rate: </b> {pricing.baseRate} <br />
                <b>Deposit: </b> {pricing.deposit} <br />
                <b>Cleaning: </b> {pricing.cleaning} <br />
                <b>Tax: </b> {pricing.tax}% <br />
                <b>Extra Fees: </b>
                <ul>
                    {pricing.extraFees.map((extraFee: any, idx: number) => (
                        <li key={idx}>
                            <b>{extraFee.name}: </b> {extraFee.amount} charged {extraFee.frequency}
                        </li>
                    ))}
                </ul >

                <b>Virtual Tour:</b> {virtualTour} <br /><br />

                <h4>Authorized Users</h4>
                <ul>
                    {authorizedUsers.map((authorizedUserEntry: any, idx: number) => (
                        <li key={idx}>
                            <b>{authorizedUserEntry.email}: </b> {authorizedUserEntry.role} <br />
                        </li>
                    ))}
                </ul >
                <br />

                <b>Public: </b> {props.propertyInfo.public}

                <br />
                <b>Services: </b> {JSON.stringify(services)}
                <br /><br /><br />



            </Card.Body >
            <Card.Footer>
                {/* <Link className="btn btn-primary btn-sm me-1 mb-1" to={`/property/${_id}`}>
                    View
                </Link>

                <Link className="btn btn-success btn-sm me-1 mb-1" to={`/property/ical-data/${_id}`}>
                    iCal
                </Link>

                <Link className="btn btn-success btn-sm me-1 mb-1" to={`/financials-property/${_id}`}>
                    Financials
                </Link>

                <Link className="btn btn-secondary btn-sm me-1 mb-1" to={`/inventory-check/${_id}`}>
                    Inventory
                </Link>

                <Link className="btn btn-secondary btn-sm me-1 mb-1" to={`/bookings?query={"propertyId":"${_id}","checkIn":${Date.now()}}`}>
                    Bookings
                </Link>

                <Link className="btn btn-secondary btn-sm me-1 mb-1" to={`/property-tasks?query={"propertyId":"${_id}"}`}>
                    Tasks
                </Link>

                <Link className="btn btn-dark btn-sm me-1 mb-1" to={`/add-booking?query={"propertyId":"${_id}"}`}>
                    Add Booking
                </Link>

                <Link className="btn btn-dark btn-sm me-1 mb-1" to={`/add-property-task?query={"propertyId":"${_id}"}`}>
                    Add Task
                </Link>

                <Link className="btn btn-secondary btn-sm me-1 mb-1" to={`/edit-property/${_id}`}>
                    Edit
                </Link>

                <Link className="btn btn-danger btn-sm me-1 mb-1" to={`/delete-property/${_id}`}>
                    Delete
                </Link> */}
            </Card.Footer>
        </Card >
    )

}