export const airBnBFile = () => {
  return {
    'Confirmation code': false,
    'Status': false,
    'Guest name': false,
    'Contact': false,
    '# of adults': false,
    '# of children': false,
    '# of infants': false,
    'Start date': false,
    'End date': false,
    '# of nights': false,
    'Booked': false,
    'Listing': false,
    'Earnings': false,
    // 'cleaning': false,
  }
}

export const homeAwayFile = () => {
  return {
    'Reservation ID': false,
    'Listing Number': false,
    'Property Name': false,
    'Created On': false,
    'Email': false,
    'Inquirer': false,
    'Phone': false,
    'Check-in': false,
    'Check-out': false,
    'Nights Stay': false,
    'Adults': false,
    'Children': false,
    'Status': false,
    'Source': false,
  }
}

export const homeAwayFinancial = () => {
  return {
    'Property ID': false,
    'Unit ID': false,
    'Address': false,
    'Reservation ID': false,
    'Traveler First Name': false,
    'Traveler Last Name': false,
    'Booking status': false,
    'Check-in': false,
    'Check-out': false,
    'Nights': false,
    'Payout date': false,
    'Gross booking amount': false,
    'Deductions': false,
    'Payout': false,
    'Lodging Tax Owner Remits': false,
    'Payout currency': false,
  }
}

export const fontaine = () => {
  return {
    'unit': false,
    'confirm': false,
    'status': false,
    'name': false,
    'check_in': false,
    'check_out': false,
    'nights': false,
    'ownerfname': false,
    'ownerlname': false,
    'cleaning': false,
    'people': false,
    'spa': false,
    'price': false,
    'nightly rate': false,
  }
}

export const internalFile = () => {
  return {
    '_id': false,
    'alias': false,
    'title': false,
    'nickName': false,
    'propertyId': false,
    'checkIn': false,
    'checkOut': false,
    'pricePerNight': false,
    'source': false,
    'sourceConfirmation': false,
    'firstName': false,
    'lastName': false,
    'guestTotal': false,
    'adults': false,
    'children': false,
    'infants': false,
    'email': false,
    'phone': false,
    'dateBooked': false,
    'dateInquired': false,
    'bookingStatus': false,
    'requiresConfirmation': false,
    'confirmationCode': false,
  }
}

export const determineFileType = (fields: any[]) => {
  if (checkFileSource(fields, airBnBFile())) return 'airbnb'
  if (checkFileSource(fields, homeAwayFile())) return 'homeawayReservation'
  if (checkFileSource(fields, homeAwayFinancial())) return 'homeawayFinancial'
  if (checkFileSource(fields, fontaine())) return 'fontaine'
  if (checkFileSource(fields, internalFile())) return 'internalFile'
  return 'custom'
}

const checkFileSource = (fields: string[], fieldsToCheck: any) => {
  fields.forEach((x) => { fieldsToCheck[x.trim()] = true })
  return Object.keys(fieldsToCheck).filter((x) => fieldsToCheck[x] === false).length === 0
}

export const identifyField: any = {
  'airbnb': 'Listing',
  'homeawayReservation': 'Listing Number',
  'homeawayFinancial': 'Property ID',
  'fontaine': 'unit',
  'internalFile': 'propertyId'
}