import { Col, Container, Row } from "react-bootstrap";
import BukinForm from "../../../Components/Forms/BukinForm/BukinForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useMemo } from "react";
import { useAddNewBukinMutation, useGetBukiblesQuery } from "../../../services/bClientApi";
import { BukibleEntryType } from "../../../Types/bukibleTypes";

export default function AddBukinPage() {

  const [
    addNewBukin,
    {
      error,
      isLoading,
    }
  ] = useAddNewBukinMutation()

  const dispatch = useAppDispatch()

  const {
    data: bukiblesData,
  } = useGetBukiblesQuery({
    bukiblesQuery: {},
    pagination: {
      limit: 500,
      page: 1,
    }
  })
  // const bukiblesList = bukiblesData?.docs || []

  const bukibleSelectOptions = useMemo(() => {
    const bukiblesList = bukiblesData?.docs || []
    if (!bukiblesList) { return [] }
    return bukiblesList.map((bukible: BukibleEntryType) => ({
      value: bukible._id,
      text: bukible.title
    }))
  }, [bukiblesData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newBukin = await addNewBukin(values).unwrap()
    if (newBukin._id) {
      dispatch(navigateTo(`/bukin/${newBukin._id}`))
    }
    return newBukin
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Bukin</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <BukinForm
            initialValues={{}}
            disabled={isLoading}
            onSubmit={handleSubmit}
            bukibleSelectOptions={bukibleSelectOptions}
          />

        </Col>
      </Row>
    </Container>
  )
}
