import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { useGetMediaFileByIdQuery } from "../../../services/bClientApi"

export default function MediaFilePage() {

  const { mediaFileId = '' } = useParams()

  const {
    data,
    isLoading,
    error
  } = useGetMediaFileByIdQuery(mediaFileId, { skip: !mediaFileId })
  const mediaFileData = data || {}

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single MediaFile Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {mediaFileId && mediaFileData && !isLoading && (
            <div className="mediaFileinfo">
              <pre>
                {JSON.stringify(mediaFileData, null, 2)}            
              </pre>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
