import { forwardRef } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import DatePicker from 'react-datepicker'
import timeService from "../../../services/time/timeService"

export const DateRangeInputField = (props: {
  fieldLabel: string,
  customClass?: string,
  required?: boolean,
  subText?: string,
  startDate?: any,
  placeholder?: string,
  endDate?: any,
  fieldName: string,
  onChange: (dates: [Date | null, Date | null]) => void
}) => {
  const {
    fieldName,
    fieldLabel,
    customClass,
    required,
    subText,
    startDate,
    endDate,
    placeholder,
    onChange,
  } = props

  const CustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <FloatingLabel
      controlId={fieldName}
      label={`${fieldLabel}${required ? '*' : ''}`}>
      <Form.Control
        type={'text'}
        placeholder={placeholder}
        name={fieldName}
        defaultValue={value}
        onClick={onClick}
        autoComplete={'off'}
        readOnly={true}
      />
    </FloatingLabel>
  ))

  return (
    <>
      <Form.Group className={`mb-3 date-picker-input ${customClass ? customClass : ''}`}>
        {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
        <DatePicker
          selected={null}
          className={'form-control'}
          placeholderText={placeholder}
          onChange={(dates: [Date | null, Date | null]) => {
            const [startDate, endDate] = dates
            const convertedStartDate = startDate ? timeService.getOffsetJSDate(startDate) : null
            const convertedEndDate = endDate ? timeService.getOffsetJSDate(endDate) : null
            onChange([convertedStartDate, convertedEndDate])
            // onChange(dates)
          }}
          // startDate={startDate ? new Date(startDate) : null}
          // endDate={endDate ? new Date(endDate) : null}
          startDate={startDate ? timeService.getReverseOffsetJSDate(startDate) : null}
          endDate={endDate ? timeService.getReverseOffsetJSDate(endDate) : null}
          customInput={<CustomInput />}
          monthsShown={document?.body?.clientWidth > 550 ? 2 : 1}
          selectsRange
          isClearable
          showYearDropdown
        />
        {subText && (
          <Form.Text className="text-muted">
            {subText}
          </Form.Text>
        )}
        {/*<Form.Control.Feedback type="invalid">
            {checkVal(form.errors, fieldNameEnd)}
            {checkVal(form.errors, fieldNameStart)}
          </Form.Control.Feedback>*/}
      </Form.Group>
    </>
  )
}
