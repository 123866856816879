import { Card, Stack } from "react-bootstrap";
import { BukibleEntryType } from "../../Types/bukibleTypes";
import './bukibleEntry.scss'
import AccessLinkButton from "../Buttons/AccessLinkButton";
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters";

export default function BukibleEntry(props: {
    bukibleInfo: BukibleEntryType
}) {

    const {
        bukibleInfo
    } = props

    const {
        _id,
        title,
        description,
        featuredImage,
        qrCode,
        address,
        bukibleType,
        groups,
        tags,
    } = bukibleInfo

    return (
        <Card className={'mb-3'}>
            <Card.Body>

                <Card.Title>
                    {title}
                </Card.Title>

                <Card.Subtitle>
                    {bukibleType}
                </Card.Subtitle>
                <hr />
                {address && address?.address1 && (
                    <div className="mb-4">
                        <b>Address:</b> <br />
                        <Card.Link
                            className={`small text-decoration-none`}
                            href={`${googleAddress(address)}`}
                            target="_blank">
                            {formatAddress(address)}
                        </Card.Link>
                    </div>
                )}

                <b>Description:</b> <br />
                <pre>{description}</pre>
                <br />
                {featuredImage && (
                    <>
                        <Card.Img className="bukible-featured-image" src={featuredImage} />
                        <br />
                    </>
                )}
                {qrCode && (
                    <>
                        <Card.Img className="bukible-qr-code" src={qrCode} />
                        <br />
                    </>
                )}

                {tags && tags.length > 0 && (
                    <div className="mb-4">
                        <b>Tags:</b> <br />
                        <ul>
                            {tags.map((tag, idx) => (
                                <li key={idx}>
                                    {tag.tag}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {groups && groups.length > 0 && (
                    <div className="mb-4">
                        <b>Groups:</b> <br />
                        <ul>
                            {groups.map((group, idx) => (
                                <li key={idx}>
                                    {group.group}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

            </Card.Body>
            <Card.Footer>
                <Stack direction={'horizontal'} gap={1} className="mt-2 mb-2">
                    <AccessLinkButton
                        action={'bukible'}
                        color={'primary'}
                        size={'sm'}
                        text={'View'}
                        to={`/bukible/${_id}`}
                    />
                    {/* <Link className="btn btn-primary btn-sm" to={`/bukible/${_id}`}>View</Link> */}

                    <AccessLinkButton
                        action={'edit-bukible'}
                        color={'secondary'}
                        size={'sm'}
                        text={'Edit'}
                        to={`/edit-bukible/${_id}`}
                    />
                    {/* <Link className="btn btn-secondary btn-sm" to={`/edit-bukible/${_id}`}>Edit</Link> */}
                    {/* <Link className="btn btn-danger btn-sm" to={`/delete-bukible/${_id}`}>Delete</Link> */}
                </Stack>
            </Card.Footer>
        </Card>
    )
}