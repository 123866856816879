import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BookingMessageEntry from "../../Components/BookingMessage/BookingMessageEntry";
import { FieldGeneratorFieldType } from "../../Components/FormFields/FieldGenerator/FieldGenerator";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import timeService from "../../services/time/timeService";
import { PropertyBookingMessageType } from "../../Types/bookingMessageType";
import { useGetBookingMessagesQuery } from "../../services/bClientApi";
import MongoPagination from "../../Components/Pagination/MongoPagination";

export default function BookingMessagesPage() {

    const { myProperties, publicPropertyIdKeys } = useMyProperties()

    const propertySelectOptions = useMyPropertiesSelectValues(myProperties)

    const [activeQuery, setActiveQuery] = useState<any>({
        query: {
            dateFrom: timeService.getCurrentTime().minus({ days: 2 }).startOf('day').toMillis(),
            dateTo: timeService.getCurrentTime().plus({ days: 2 }).endOf('day').toMillis(),
        },
        limit: 20,
        page: 1,
    })

    const {
        data,
        error,
        isLoading
    } = useGetBookingMessagesQuery({
        bookingMessagesQuery: {
            propertyId: publicPropertyIdKeys,
            ...activeQuery.query,
        },
        pagination: {
            limit: activeQuery.limit,
            page: activeQuery.page,
        }
    })
    const bookingMessagesList = data?.docs || []

    // const dispatch = useAppDispatch()

    const handleBookingMessageSubmit = (data: any) => {
        if (!data.dateFrom || !data.dateTo) {
            return
        }
        const newQuery = {
            ...activeQuery,
            page: 1,
            query: {
                ...activeQuery.query
            }
        }
        newQuery.query.dateFrom = timeService.dateToMillis(data.dateFrom)
        newQuery.query.dateTo = timeService.dateToMillis(data.dateTo)

        if (data.propertyId) {
            newQuery.query.propertyId = data.propertyId
        } else {
            delete newQuery.query.propertyId
        }
        setActiveQuery(newQuery)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Messages</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        fields={[
                            ...bookingMessagesField,
                            {
                                fieldName: 'propertyId',
                                fieldLabel: 'Property',
                                fieldType: 'select',
                                placeholder: 'Select Property',
                                options: propertySelectOptions,
                            }
                        ]}
                        initialData={activeQuery.query}
                        onSubmit={handleBookingMessageSubmit}
                        disabled={isLoading}
                    />

                    {bookingMessagesList && bookingMessagesList.map((b: PropertyBookingMessageType, index: number) => (
                        <BookingMessageEntry
                            key={index}
                            propertyInfo={myProperties && b.propertyId ? myProperties[b.propertyId] : undefined}
                            bookingMessage={b} 
                            full={true}
                            />
                    ))}

                        <MongoPagination
                            data={data}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                setActiveQuery({
                                    query: activeQuery.query,
                                    limit: activeQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />


                </Col>
            </Row>
        </Container>
    )
}

const bookingMessagesField: FieldGeneratorFieldType[] = [
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'dateFrom',
            endDate: 'dateTo',
        }
    },
    {
        fieldName: 'dateFrom',
        fieldLabel: 'Date From',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'dateTo',
        fieldLabel: 'Date To',
        fieldType: 'date',
        required: true,
        hidden: true,
    }
]