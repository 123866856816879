import { Button } from "react-bootstrap"
import canAccess from "../../services/accessService/accessService"

export default function AccessClickButton(props: {
    variant?: string,
    text: string,
    resource?: string,
    action?: string,
    size?: 'sm' | 'lg',
    disabled?: boolean,
    className?: string,
    accessOptions?: any
    onClick?: any
}) {
    const {
        variant = 'primary',
        text,
        resource = 'routes',
        action = 'mainLinks',
        size,
        className,
        disabled,
        accessOptions = {},
        onClick
    } = props

    if (!canAccess(resource, action, accessOptions)) {
        return null
    }

    return (
        <Button
            variant={variant}
            size={size}
            disabled={disabled}
            className={className}
            onClick={() => {
                if (onClick) {
                    onClick()
                }
            }}
        >
            {text}
        </Button>
    )
}