import Papa from "papaparse"
import { bookingsByDayObject } from "../../Helpers/Bookings/bookingHelpers"
import { PropertyBookingEntry } from "../../Types/bookingTypes"
import timeService from "../../services/time/timeService"
import { currencyFormatter } from "../../Helpers/Parsers/currencyFormatter"
import { FieldGeneratorFieldType } from "../../Components/FormFields/FieldGenerator/FieldGenerator"

export const dateHasher = (startDate: any, endDate: any, options: any = {}) => {
    return timeService.createDateHashObject(
        startDate,
        endDate,
        options
    )
}

export const generateCSVURL = (
    myProperties: any,
    propertyReportData: any,
    propertyTotalsReport: any,
) => {
    const csvData: any[] = []
    propertyReportData.forEach((p: any) => {
        const csvEntry: any = {
            name: p.shortTitle,
            beds: myProperties[p.propertyId].about.bedrooms,
            baths: myProperties[p.propertyId].about.bathrooms,
            ...myProperties[p.propertyId].address,
        }

        Object.keys(p.monthlySummary).forEach((d: string) => {
            const {
                earnings,
                adr,
                occupancy,
                leadTime,
                guests,
            } = p.monthlySummary[d]
            csvEntry[`${d} ADR`] = adr
            csvEntry[`${d} Occupancy`] = occupancy
            csvEntry[`${d} Lead`] = leadTime
            csvEntry[`${d} Earnings`] = earnings
            csvEntry[`${d} Payout`] = p.mappedData[d].payout
            csvEntry[`${d} Guests`] = guests
        })
        csvEntry['Total ADR'] = p.totals.adr
        csvEntry['Total Occupancy'] = p.totals.occupancy
        csvEntry['Total Lead'] = p.totals.leadTime
        csvEntry['Total Earnings'] = p.totals.earnings
        csvEntry['Total Payout'] = p.totals.payout
        csvEntry['Total Guests'] = p.totals.guests
        csvData.push(csvEntry)
    })
    const {
        totalsReport,
        totalsByMonth,
    } = propertyTotalsReport

    const csvTotalsEntry: any = {
        name: 'Totals',
    }

    Object.keys(totalsByMonth).forEach((d: string) => {
        const {
            earnings,
            adr,
            occupancy,
            leadTime,
            guests,
        } = totalsByMonth[d]
        csvTotalsEntry[`${d} ADR`] = adr
        csvTotalsEntry[`${d} Occupancy`] = occupancy
        csvTotalsEntry[`${d} Lead`] = leadTime
        csvTotalsEntry[`${d} Earnings`] = earnings
        csvTotalsEntry[`${d} Payout`] = totalsByMonth[d].payout
        csvTotalsEntry[`${d} Guests`] = guests
    })

    csvTotalsEntry['Total ADR'] = totalsReport.adr
    csvTotalsEntry['Total Occupancy'] = totalsReport.occupancy
    csvTotalsEntry['Total Lead'] = totalsReport.leadTime
    csvTotalsEntry['Total Earnings'] = totalsReport.earnings
    csvTotalsEntry['Total Payout'] = totalsReport.payout
    csvTotalsEntry['Total Guests'] = totalsReport.guests

    csvData.push(csvTotalsEntry)

    const csvString = Papa.unparse(csvData)
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    return url
}

export const generatePropertyReportData = (
    myProperties: any,
    selectedProperties: any,
    bookingsByProperty: any[],
    startDate: number,
    endDate: number,
) => {

    const dateRangeHash = dateHasher(startDate, endDate, { dateFormat: 'yyyy-MM' })
    // const dateRangeArray = Object.keys(dateRangeHash)
    const dayByDayRange = dateHasher(startDate, endDate)

    const propertyReport: any[] = []

    const foundProperties: any = {}
    const bookingByPropertyList = bookingsByProperty.map((b: any) => {
        const { propertyId } = b;
        foundProperties[propertyId] = true
        return b
    })
    if (selectedProperties) {
        Object.keys(selectedProperties).forEach((propertyId: string) => {
            if (!foundProperties[propertyId]) {
                bookingByPropertyList.push({
                    propertyId,
                    bookings: []
                })
            }
        })
    }

    bookingByPropertyList.forEach((b: { propertyId: string, bookings: PropertyBookingEntry[] }) => {
        const {
            propertyId,
            bookings,
        } = b;
        const mappedData: any = {}
        const uniqueBookings: any = {}
        const dateRef = dateHasher(startDate, endDate)
        const bookingsByDay = bookingsByDayObject(bookings)

        Object.keys(dateRef).forEach((dayKey: string) => {
            if (!dayByDayRange[dayKey]) { return }
            const { month, year } = dateRef[dayKey];
            const day = bookingsByDay[dayKey] ? bookingsByDay[dayKey] : {}
            const monthYear = `${year}-${month}`
            if (!mappedData[monthYear]) {
                mappedData[monthYear] = emptyReportNumbers()
            }
            mappedData[monthYear].days++
            mappedData[monthYear].nights += day.earnings ? 1 : 0
            mappedData[monthYear].earnings += day.earnings ? day.earnings : 0
            mappedData[monthYear].payout += day.payout ? day.payout : 0
            const bookingId = day.booking ? day.booking._id + monthYear : null
            if (bookingId && !uniqueBookings[bookingId] && day.booking.dateBooked && day.leadTime > -1) {
                uniqueBookings[bookingId] = true
                mappedData[monthYear].leadTime += day.leadTime ? day.leadTime : 0
                mappedData[monthYear].guests += +(day.booking?.guestInfo?.guestTotal ? day.booking.guestInfo.guestTotal : 0)
                mappedData[monthYear].uniqueBookings++
            }
        })

        Object.keys(mappedData).forEach(d => {
            const mData = mappedData[d]
            mData.adr = mData.earnings && mData.nights ? +(mData.earnings / mData.nights).toFixed(2) : 0
            mData.occupancy = mData.nights ? +((mData.nights / mData.days) * 100).toFixed(2) : 0
            mData.leadTime = mData.leadTime ? +((mData.leadTime / mData.uniqueBookings)).toFixed(2) : 0
            mData.guests = mData.guests ? +((mData.guests / mData.uniqueBookings)).toFixed(2) : 0

            mData.uniqueBookings = mData.uniqueBookings ? mData.uniqueBookings : 0
            mData.stayLength = mData.nights ? +(mData.nights / mData.uniqueBookings).toFixed(2) : 0
        })

        const monthlySummary: any = {}

        const totals = emptyReportNumbers()

        const mappedDataLength = Object.keys(mappedData).length

        Object.keys(mappedData).forEach(d => {
            if (!dateRangeHash[d]) {
                return
            }
            monthlySummary[d] = mappedData[d]

            totals.earnings += mappedData[d].earnings
            totals.days += mappedData[d].days
            totals.nights += mappedData[d].nights
            totals.leadTime += mappedData[d].leadTime / mappedDataLength
            totals.guests += mappedData[d].guests / mappedDataLength
            totals.payout += mappedData[d].payout
            totals.uniqueBookings += mappedData[d].uniqueBookings
        })

        totals.adr = totals.earnings && totals.nights ? +(totals.earnings / totals.nights).toFixed(2) : 0
        totals.occupancy = totals.nights ? +((totals.nights / totals.days) * 100).toFixed(2) : 0
        totals.leadTime = totals.leadTime ? +(totals.leadTime).toFixed(2) : 0
        totals.guests = totals.guests ? +(totals.guests).toFixed(2) : 0
        totals.stayLength = totals.nights ? +(totals.nights / totals.uniqueBookings).toFixed(2) : 0

        propertyReport.push({
            propertyId,
            mappedData,
            shortTitle: myProperties[propertyId].title.split(' - ')[0],
            monthlySummary,
            totals,
        })
    })

    // sort by short title
    return propertyReport.sort((a, b) => { return a.shortTitle > b.shortTitle ? 1 : -1 })
}

export const generatePropertyTotalsReport = (
    propertyReportData: any,
    startDate: number,
    endDate: number,
) => {

    const dateRangeHash = dateHasher(startDate, endDate, { dateFormat: 'yyyy-MM' })

    const totalsByMonth: any = {}
        const totalsReport: any = emptyReportNumbers()
        propertyReportData.forEach((p: any) => {
            totalsReport.earnings += p.totals.earnings
            totalsReport.payout += p.totals.payout
            totalsReport.days += p.totals.days
            totalsReport.nights += p.totals.nights
            totalsReport.leadTime += p.totals.leadTime / propertyReportData.length
            totalsReport.guests += p.totals.guests / propertyReportData.length
            totalsReport.uniqueBookings += p.totals.uniqueBookings

            Object.keys(p.monthlySummary).forEach((d: string) => {
                if (!dateRangeHash[d]) {
                    return
                }
                if (!totalsByMonth[d]) {
                    totalsByMonth[d] = emptyReportNumbers()
                }
                totalsByMonth[d].earnings += p.monthlySummary[d].earnings
                totalsByMonth[d].payout += p.monthlySummary[d].payout
                totalsByMonth[d].days += p.monthlySummary[d].days
                totalsByMonth[d].nights += p.monthlySummary[d].nights
                totalsByMonth[d].leadTime += p.monthlySummary[d].leadTime / propertyReportData.length
                totalsByMonth[d].guests += p.monthlySummary[d].guests / propertyReportData.length
                totalsByMonth[d].uniqueBookings += p.monthlySummary[d].uniqueBookings
                totalsByMonth[d].stayLength += p.monthlySummary[d].nights && totalsReport.uniqueBookings ? p.monthlySummary[d].nights / p.monthlySummary[d].uniqueBookings / propertyReportData.length : 0
            })
        })

        totalsReport.adr = totalsReport.earnings && totalsReport.nights ? +(totalsReport.earnings / totalsReport.nights).toFixed(2) : 0
        totalsReport.occupancy = totalsReport.nights ? +((totalsReport.nights / totalsReport.days) * 100).toFixed(2) : 0
        totalsReport.leadTime = totalsReport.leadTime ? +(totalsReport.leadTime).toFixed(2) : 0
        totalsReport.guests = totalsReport.guests ? +(totalsReport.guests).toFixed(2) : 0
        totalsReport.stayLength = totalsReport.nights ? +(totalsReport.nights / totalsReport.uniqueBookings).toFixed(2) : 0

        Object.keys(totalsByMonth).forEach((d: string) => {
            totalsByMonth[d].adr = totalsByMonth[d].earnings && totalsByMonth[d].nights ? +(totalsByMonth[d].earnings / totalsByMonth[d].nights).toFixed(2) : 0
            totalsByMonth[d].occupancy = totalsByMonth[d].nights && totalsReport.uniqueBookings ? +((totalsByMonth[d].nights / totalsByMonth[d].days) * 100).toFixed(2) : 0
        })

        return {
            totalsReport,
            totalsByMonth,
        }
}

const emptyReportNumbers = () => ({
    nights: 0,
    days: 0,
    earnings: 0,
    payout: 0,
    leadTime: 0,
    occupancy: 0,
    adr: 0,
    guests: 0,
    uniqueBookings: 0,
    stayLength: 0,
})

export const TABLE_MODE_BUTTONS = [
    { label: 'Earnings', value: 'earnings', },
    { label: 'Payout', value: 'payout', },
    { label: 'ADR', value: 'adr', },
    { label: 'Occupancy', value: 'occupancy', },
    { label: 'Lead Time', value: 'leadTime', },
    { label: 'Guests', value: 'guests', },
    { label: 'Stay Length', value: 'stayLength', },
    { label: 'Unique Bookings', value: 'uniqueBookings', },
]

export const DATE_FILTER_FORM: FieldGeneratorFieldType[] = [
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'checkIn',
            endDate: 'checkOut',
        }
    },
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check Out',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
]

export const numberFormatter = (value: number, type: string) => {
    const trimmedValue = +(value.toFixed(2))
    const formatTypes: any = {
        'adr': (v: number) => currencyFormatter(v),
        'occupancy': (v: number) => `${v}%`,
        'leadTime': (v: number) => `${v} days`,
        'earnings': (v: number) => currencyFormatter(v),
        'payout': (v: number) => currencyFormatter(v),
        'guests': (v: number) => `${v} guests`,
        'stayLength': (v: number) => `${v} nights`,
        'uniqueBookings': (v: number) => `${v} bookings`,
    }

    if (formatTypes[type]) {
        return formatTypes[type](trimmedValue)
    }

    return trimmedValue
}

export const saveSelectedPropertiesToLocal = (selectedProperties: any) => {
    localStorage.setItem('selectedProperties', JSON.stringify(selectedProperties))
}

export const getSelectedPropertiesFromLocal = () => {
    try {
        const selectedProperties = localStorage.getItem('selectedProperties')
        return selectedProperties ? JSON.parse(selectedProperties) : {}
    } catch (err) {
        return {}
    }
}