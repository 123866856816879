import { useMemo } from "react"

export default function useUsersSelectValues(
    userRef: { [email: string]: any }
) {
    const userSelectOptions = useMemo(() => {
        const options = []
        for (const user in userRef) {
            options.push({
                text: `${userRef[user].firstName} ${userRef[user].lastName}`,
                value: userRef[user].email,
            })
        }
        return options.sort((x, y) => x.text.localeCompare(y.text))
    }, [userRef])
    return userSelectOptions
}