import timeService from "../../services/time/timeService";
import { PropertyTaskType, WorkLogEntryType } from "../../Types/propertyTaskType";

export default function tasksByPersonHelper(
    propertyTasksList?: PropertyTaskType[] | undefined | null
) {
    const tasksByPersonList: any = {
        'TOTAL': {
            tasks: {},
            workLog: [],
            timeWorked: 0,
        }
    }
    if (propertyTasksList) {
        propertyTasksList.forEach((task: PropertyTaskType) => {
            const {
                workLog
            } = task
            workLog.forEach((logEntry: WorkLogEntryType) => {
                const {
                    start,
                    end,
                    // description,
                    person
                } = logEntry
                if (!tasksByPersonList[person]) {
                    tasksByPersonList[person] = {
                        tasks: {},
                        workLog: [],
                        timeWorked: 0,
                    }
                }
                if (task._id && !tasksByPersonList[person].tasks[task._id]) {
                    tasksByPersonList[person].tasks[task._id] = {
                        timeWorked: 0,
                    }
                }
                if (task._id && !tasksByPersonList['TOTAL'].tasks[task._id]) {
                    tasksByPersonList['TOTAL'].tasks[task._id] = {
                        timeWorked: 0,
                    }
                }
                tasksByPersonList[person].workLog.push(logEntry)

                const timeWorked = timeService.timeDiff(start, end, { diffDuration: 'minutes' })
                tasksByPersonList['TOTAL'].timeWorked += timeWorked
                tasksByPersonList[person].timeWorked += timeWorked
                if (task._id) {
                    tasksByPersonList[person].tasks[task._id].timeWorked += timeWorked
                }
            })
        })
    }

    return tasksByPersonList
}