import {  useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { navigateTo } from '../../../redux/router/routerActions';
import { accountSelector } from '../../../redux/account/accountSlice';
import RegisterForm from '../../../Components/Forms/RegisterForm/RegisterForm';
import { Col, Container, Row } from 'react-bootstrap';
import { useRegisterNewUserMutation } from '../../../services/bClientApi';
import { showSuccess } from '../../../redux/counter/counterSlice';

export default function RegisterUserPage() {

  const [
    registerUser,
    {
      error,
      isLoading: loading
    }
  ] = useRegisterNewUserMutation()

  // const { loading, error, message } = useSelector(registerUserSelector)
  const { token, username } = useSelector(accountSelector)
  const dispatch = useAppDispatch()

  return (
    <Container>
      <Row>
        <Col>
          <h1>Register Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <RegisterForm
            disabled={loading}
            onSubmit={async(formValues: {firstName: string, lastName: string, email: string, password: string}) => {
              try {
                const createdUser = await registerUser(formValues).unwrap()
                console.log(createdUser)
                if(createdUser && createdUser._id) {
                  dispatch(showSuccess('User created successfully.'))
                  if(token && username) {
                    dispatch(navigateTo('/users'))
                    return true
                  }
                  if(!token || !username) {
                    dispatch(navigateTo('/'))
                  }
                }
                return true
              } catch(err) {
                // error handler
              }
              return false
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}
