import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteMediaFileMutation, useGetMediaFileByIdQuery } from "../../../services/bClientApi"

export default function DeleteMediaFilePage() {

    const [deleteMediaFile, { isLoading: deleteMediaFileIsLoading, error: deleteMediaFileError }] = useDeleteMediaFileMutation()
    const { mediaFileId = '' } = useParams()
    const dispatch = useAppDispatch()
    const [deleteClicked, setDeleteClicked] = useState(false)

    const {
        data,
        isLoading,
        error
      } = useGetMediaFileByIdQuery(mediaFileId, { skip: !mediaFileId || deleteClicked })
      const mediaFileData = data || {}

    const mediaFileDeleteFn = async () => {
        if (mediaFileId) {
            const deleteValue = await deleteMediaFile(mediaFileId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/mediaFiles/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete MediaFile Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deleteMediaFileError && (<>{JSON.stringify(deleteMediaFileError)}<br /></>)}

                    {mediaFileId && mediaFileData && !isLoading && (
                        <div className="mediaFile-info">
                            {JSON.stringify(mediaFileData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete MediaFile: {mediaFileId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete MediaFile</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this mediaFile it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={deleteMediaFileIsLoading} onClick={() => {
                                            mediaFileDeleteFn()
                                        }}> Confirm Delete MediaFile </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
