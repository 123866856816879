import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";

export default function LoginForm(props: {
  disabled: boolean,
  onSubmit: (values: any) => Promise<boolean>
}) {

  const {onSubmit, disabled} = props

  return (
    <div>
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        validate={values => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          if(!values.password) {
            errors.password = 'Please enter a password'
          }
          return errors;
        }}

        onSubmit={async (values, { setSubmitting }) => {
            const submitVal = await onSubmit(values)
            if(submitVal){ return }
            setSubmitting(false)
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <TextInputField
              fieldLabel="Email"
              placeholder="Email"
              fieldType="email"
              required={true}
              fieldName="email" />

            <TextInputField
              fieldLabel="Password"
              placeholder="Password"
              fieldType="password"
              required={true}
              fieldName="password" />

            <CheckBoxInputField
              fieldLabel={'Remember me'}
              errors={errors}
              touched={touched}
              fieldName={'rememberMe'} />

             <Button variant="primary" type="submit" disabled={isSubmitting || disabled}>
               Login
             </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
