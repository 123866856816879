import { useState } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { accountSelector } from "../../../redux/account/accountSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useDeleteUserMutation, useGetUserInfoQuery } from "../../../services/bClientApi";


export default function DeleteUserPage() {

  const [
    deleteUser,
    {
      error: deleteError,
      isLoading: deleteLoading,
    }
  ] = useDeleteUserMutation()

  const accountInfo = useSelector(accountSelector)

  const { userId = '' } = useParams()
  const dispatch = useAppDispatch()
  const [deleteClicked, setDeleteClicked] = useState(false)

  const {
    data,
    error,
    isLoading
  } = useGetUserInfoQuery(userId, { skip: !userId || deleteClicked })
  const { _id, email, firstName, lastName } = data || {}


  return (
    <Container>
      <Row>
        <Col>
          <h1>Delete User Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}


          {!isLoading && userId && userId === _id && accountInfo.role && accountInfo.role === 'admin' && accountInfo._id !== _id && (
            <div className="user-info">

              <Stack gap={3}>
                <div>
                  Are you sure you want to delete User: {_id}
                  <br />
                  {(firstName || lastName) && (<>{firstName} {lastName}</>)}
                  <br />
                  {email && (<>{email}</>)}
                </div>
                <div>
                  <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete User</Button>
                </div>
                {deleteClicked && (
                  <div>
                    Once you delete this user it will be gone forever. <br />
                    <Button variant={'danger'} disabled={deleteLoading} onClick={async () => {
                      const deleteValue = await deleteUser(userId).unwrap()
                      if (deleteValue) {
                      }
                      dispatch(navigateTo(`/users/`))
                    }}> Confirm Delete User </Button>
                  </div>
                )}
              </Stack>

            </div>
          )}

        </Col>
      </Row>
    </Container>
  )
}
