import { Col, Container, Row } from "react-bootstrap";
import MediaFileForm from "../../../Components/Forms/MediaFileForm/MediaFileForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { useAddNewMediaFileMutation } from "../../../services/bClientApi";

export default function AddMediaFilePage() {

  const [addNewMediaFile, { isLoading, error }] = useAddNewMediaFileMutation()

  const {
    uploadImageFn,
  } = useUploadImage()

  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newMediaFile = await addNewMediaFile(values).unwrap()
    if (newMediaFile._id) {
      dispatch(navigateTo(`/mediaFile/${newMediaFile._id}`))
    }
    return newMediaFile
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add MediaFile</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <MediaFileForm
            initialValues={{}}
            disabled={isLoading}
            onSubmit={handleSubmit}
            uploadImageFn={uploadImageFn}
          />

        </Col>
      </Row>
    </Container>
  )
}
