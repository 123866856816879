import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { DateInputField } from "../../FormFields/DateInput/DateInputField";
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import QuickTag from "../../FormFields/QuickTag/QuickTag";
import { bookingSourceFieldValues } from "../BookingForm/BookingFormConstants";
import canAccess from "../../../services/accessService/accessService";

export default function BookingSearchForm(props: {
  checkIn: Date,
  checkOut: Date,
  firstName: string,
  lastName: string,
  sourceConfirmation: string,
  confirmationCode: string,
  propertyId: string,
  disabled: boolean,
  propertyOptionList: { value: string, text: string }[],
  source: string,
  bookingStatus: string[],
  onSubmit: (X: any) => void,
  dateBookedStart: Date,
  dateBookedEnd: Date,
}) {
  const {
    checkIn,
    checkOut,
    firstName,
    lastName,
    sourceConfirmation,
    confirmationCode,
    disabled,
    onSubmit,
    propertyOptionList,
    propertyId,
    source,
    bookingStatus = [],
    dateBookedStart,
    dateBookedEnd,
  } = props
  return (
    <div className="booking-search-form">
      <Formik
        initialValues={{
          checkIn,
          checkOut,
          firstName,
          lastName,
          sourceConfirmation,
          confirmationCode,
          propertyId,
          source: source,
          bookingStatus: bookingStatus ? bookingStatus.map(x => ({ tag: x })) : [],
          tempBookingStatus: '',
          dateBookedStart,
          dateBookedEnd,
        }}
        validate={values => {
          const errors: any = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submitValue: any = {}
            if (values.checkIn && !values.sourceConfirmation) {
              submitValue['checkIn'] = (new Date(values.checkIn)).getTime()
            }
            if (values.checkOut && !values.sourceConfirmation) {
              submitValue['checkOut'] = (new Date(values.checkOut)).getTime()
            }
            if (values.firstName) {
              submitValue['firstName'] = values.firstName.trim()
            }
            if (values.lastName) {
              submitValue['lastName'] = values.lastName.trim()
            }
            if (values.sourceConfirmation) {
              submitValue['sourceConfirmation'] = values.sourceConfirmation.trim()
            }
            if (values.confirmationCode) {
              submitValue['confirmationCode'] = values.confirmationCode.trim()
            }
            if (values.source) {
              submitValue['source'] = values.source
            }
            if (values.propertyId) {
              submitValue['propertyId'] = values.propertyId
            }
            if (values.bookingStatus && values.bookingStatus.length > 0) {
              submitValue['bookingStatus'] = values.bookingStatus.map(x => x.tag)
            }

            if (values.dateBookedStart) {
              submitValue['dateBookedStart'] = (new Date(values.dateBookedStart)).getTime()
            }

            if (values.dateBookedEnd) {
              submitValue['dateBookedEnd'] = (new Date(values.dateBookedEnd)).getTime()
            }

            onSubmit(submitValue)

            // onSubmit({
            //   checkIn: values.checkIn && !values.sourceConfirmation ? (new Date(values.checkIn)).getTime() : null,
            //   checkOut: values.checkOut && !values.sourceConfirmation ? (new Date(values.checkOut)).getTime() : null,
            //   firstName: values.firstName,//?.trim(),
            //   lastName: values.lastName,//?.trim(),
            //   confirmationCode: values.confirmationCode,//?.trim(),
            //   sourceConfirmation: values.sourceConfirmation,//?.trim(),
            //   propertyId: values.propertyId,
            //   source: values.bookingSource,//?.trim(),
            //   bookingStatus: values.bookingStatus && values.bookingStatus.length > 0 ? values.bookingStatus.map(x => x.tag) : null,
            // })
          } catch (err) {
            // custom error handler
            console.log(err)
          }
          setSubmitting(false)
        }}
      >
        {({ values, isSubmitting, errors, touched, setFieldValue }) => (
          <Form>

            <Row xs={2}>
              <Col>
                <DateInputField
                  fieldLabel="Check In"
                  placeholder="Check In"
                  maxDate={values.checkOut}
                  fieldName="checkIn" />
              </Col>
              <Col>
                <DateInputField
                  fieldLabel="Check Out"
                  placeholder="Check Out"
                  minDate={values.checkIn}
                  fieldName="checkOut" />
              </Col>
              <Col>
                <TextInputField
                  fieldLabel={"First Name"}
                  placeholder={"First Name"}
                  fieldName="firstName" />
              </Col>
              <Col>
                <TextInputField
                  fieldLabel={"Last Name"}
                  placeholder={"Last Name"}
                  fieldName="lastName" />
              </Col>
              <Col xs={12} md={6} xl={3}>
                <TextInputField
                  fieldLabel={"Source Confirmation"}
                  placeholder={"Source Confirmation"}
                  fieldName="sourceConfirmation" />
              </Col>
              <Col xs={12} md={6} xl={3}>
                <TextInputField
                  fieldLabel={"Internal Confirmation"}
                  placeholder={"Internal Confirmation"}
                  fieldName="confirmationCode" />
              </Col>

              {/* <Col xs={12} md={6} xl={3}>
                <TextInputField
                  fieldLabel={"Booking Source"}
                  placeholder={"Booking Source"}
                  fieldName="source" />
              </Col> */}
              <Col xs={12} md={6} xl={3}>
                <SelectInputField
                  fieldLabel={"Booking Source"}
                  placeholder={"Booking Source"}
                  fieldName="source"
                  options={bookingSourceFieldValues} />
              </Col>
              <Col xs={12} md={6} xl={3}>
                <SelectInputField
                  fieldLabel={'Property Id'}
                  placeholder={'- Select Property -'}
                  options={propertyOptionList}
                  fieldName={'propertyId'} />
              </Col>

              <Col>
                <DateInputField
                  fieldLabel="Date Booked Start"
                  placeholder="Date Booked Start"
                  maxDate={values.dateBookedEnd}
                  fieldName="dateBookedStart" />
              </Col>
              <Col>
                <DateInputField
                  fieldLabel="Date Booked End"
                  placeholder="Date Booked End"
                  minDate={values.dateBookedStart}
                  fieldName="dateBookedEnd" />
              </Col>


            </Row>




            <QuickTag
              className={'mb-3'}
              tags={values.bookingStatus}
              tempTag={values.tempBookingStatus}
              tagFieldName={'bookingStatus'}
              tempTagFieldName={'tempBookingStatus'}
              tempTagFieldLabel={'Booking Status'}
              setFieldValue={setFieldValue}
              buttons={searchStatuses()}
              options={{
                hideInput: true,
                hideBadges: true,
              }}
            />

            <Button className="mb-3" type="submit" disabled={isSubmitting || disabled}>
              Search
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const searchStatuses = () => canAccess('bookings', 'form.allStatus') ? [
  { tag: 'new', text: 'New' },
  { tag: 'pending', text: 'Pending' },
  { tag: 'confirmed', text: 'Confirmed' },
  { tag: 'canceled', text: 'Canceled' },
  { tag: 'declined', text: 'Declined' },
  { tag: 'expired', text: 'Expired' },
  { tag: 'request', text: 'Request' },
  { tag: 'ignore', text: 'Ignore' },
] : [
  { tag: 'new', text: 'New' },
  { tag: 'pending', text: 'Pending' },
  { tag: 'confirmed', text: 'Confirmed' },
  { tag: 'canceled', text: 'Canceled' },
  // { tag: 'declined', text: 'Declined' },
  // { tag: 'expired', text: 'Expired' },
  { tag: 'request', text: 'Request' },
  // { tag: 'ignore', text: 'Ignore' },
]
