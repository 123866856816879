import { Col, Container, Row } from "react-bootstrap";
import BookingRequestForm from "../../../Components/Forms/BookingRequestForm/BookingRequestForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewBookingRequestMutation } from "../../../services/bClientApi";

export default function AddBookingRequestPage() {

  const [
    addNewBookingRequest,
    {
      error,
    }
  ] = useAddNewBookingRequestMutation()
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (!values.created) {
      values.created = Date.now()
    }
    const newBookingRequest = await addNewBookingRequest(values).unwrap()
    if (newBookingRequest._id) {
      dispatch(navigateTo(`/booking-request/${newBookingRequest._id}`))
    }
    return newBookingRequest
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Booking Request</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <BookingRequestForm
            initialValues={{
              guestName: '',
            }}
            disabled={false}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
