import { ReactElement } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const DragDropVertical = (props: {
  onReorder: (x: any[]) => void,
  valuesArray: any[],
  childRenderer: (item: {entry: any, idx: number}) => ReactElement,
  idBase: string,
  customClass?: string,
}) => {

  const { onReorder, valuesArray, childRenderer, idBase, customClass } = props

  const getListStyle = (dragging: any) => {
    if(dragging) {
      return { backgroundColor: 'salmon' }
    }
    return {}
  }

  const getItemStyle = (dragging: any, draggableStyle: any) => {
    const baseStyle: any = {}
    if(dragging) {
      baseStyle.backgroundColor = '#eee'
    }
    return {
      ...draggableStyle,
      ...baseStyle
    }
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <DragDropContext onDragEnd={(result: any) => {
      // dropped outside the list
      if (!result.destination) { return }
      const items = reorder(
        valuesArray,
        result.source.index,
        result.destination.index
      );
      onReorder(items)
    }}>
      <Droppable droppableId={`${idBase}-drop`}>
      {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className={`${customClass}`}
          >
            {valuesArray.map((entry: any, idx: number) => (
              <Draggable key={`alias-${idx}`} draggableId={`${idBase}-${idx}`} index={idx}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className='drag-wrapper'
                  >
                    {childRenderer({entry, idx})}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
