import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";

export default function ForgotPasswordForm(props: any) {
  const { onSubmit } = props
  return (
    <div>
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors: any = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}

      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values.email)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <TextInputField
            fieldLabel="Email"
            placeholder="Email"
            fieldType="email"
            required={true}
            errors={errors}
            touched={touched}
            fieldName="email" />

          <Button className="mb-3" type="submit" disabled={isSubmitting}>
            Check
          </Button>
        </Form>
      )}
    </Formik>
    </div>
  )
}
