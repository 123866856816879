import { useState } from "react"
import { useUploadImageMutation } from "../../services/bClientApi"

export default function useUploadImage() {

    const [
        uploadImage,
        {
            error,
            isLoading
        }
    ] = useUploadImageMutation()    
    const [imageUploadProgress, setImageUploadProgress] = useState('')
    
    const uploadImageFn = async (imageInfo: { imageToUpload: any, filename: string }) => {
        const uploadedImageUrl = await uploadImage(imageInfo).unwrap()
        return uploadedImageUrl.imageUrl
    }

    const uploadImageMultiFn = async (imageInfo: { imageToUpload: any, filename: string }[]) => {
        const uploadedImageUrls = []
        for (let i = 0; i < imageInfo.length; i++) {
            setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
            const uploadedImageUrl = await uploadImage(imageInfo[i]).unwrap()
            uploadedImageUrls.push(uploadedImageUrl.imageUrl)
        }
        setImageUploadProgress(``)
        return uploadedImageUrls
    }


    return { 
        uploadImageFn, 
        uploadImageMultiFn, 
        imageUploadProgress,
        error,
        isLoading
    }

}