import { useMemo } from "react";
import { Table } from "react-bootstrap";
import tasksByPersonHelper from "../../Helpers/PropertyTasks/TasksByPersonHelper";
import { PropertyTaskType } from "../../Types/propertyTaskType";

export default function PropertyTaskByPersonTable(
    props: {
        propertyTasksList?: PropertyTaskType[] | null | undefined,
        userRef?: any,
        tableProps?: any,
    },
) {
    const { 
        propertyTasksList,
        userRef,
        tableProps,
     } = props

    const parseUserName = (userId: string) => {
        if (userRef && userRef[userId]) {
            return userRef[userId].firstName + ' ' + userRef[userId].lastName
        }
        return userId
    }

    const tasksByPerson = useMemo(() => {
        return tasksByPersonHelper(propertyTasksList)
    }, [propertyTasksList])

    return (
        <Table hover responsive {...tableProps}>
            <thead>
                <tr>
                    <th>Person</th>
                    <th>Tasks</th>
                    <th>Hours</th>
                </tr>
            </thead>
            <tbody>
                {tasksByPerson && Object.keys(tasksByPerson).map((person: string, idx: number) => {
                    const {
                        tasks,
                        // workLog,
                        timeWorked,
                    } = tasksByPerson[person]
                    const personName = parseUserName(person)
                    const taskCount = Object.keys(tasks).length
                    // const workLogEntries = workLog.length
                    const hoursWorked = (timeWorked / 60).toFixed(2)
                    return (
                        <tr key={idx}>
                            <td>
                                {personName}
                            </td>
                            <td>
                                {taskCount}
                            </td>
                            <td>
                                {hoursWorked}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}