import './stickyBar.scss'

export default function StickyBar(props: {
    children: React.ReactNode;
}) {
    return (
        <div className="sticky-bar">
            {props.children}
        </div>
    )
}