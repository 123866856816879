import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteBookingGuestMutation, useGetBookingGuestByIdQuery } from "../../../services/bClientApi"

export default function DeleteBookingGuestPage() {
    const [deleteClicked, setDeleteClicked] = useState(false)
    const { bookingGuestId = '' } = useParams()
    const dispatch = useAppDispatch()

    const [
        deleteBookingGuest,
        {
            isLoading: isDeleteLoading,
        }
    ] = useDeleteBookingGuestMutation()

    const {
        data,
        error,
        isLoading,
    } = useGetBookingGuestByIdQuery(bookingGuestId, { skip: !bookingGuestId || isDeleteLoading || deleteClicked })

    const bookingGuestData = data || {}

    const bookingGuestDeleteFn = async () => {
        if (bookingGuestId) {
            const deleteValue = await deleteBookingGuest(bookingGuestId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/booking-guests/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete BookingGuest Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    {bookingGuestId && bookingGuestData && !isLoading && (
                        <div className="bookingGuest-info">
                            {JSON.stringify(bookingGuestData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete BookingGuest: {bookingGuestId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete BookingGuest</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this bookingGuest it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={isLoading} onClick={() => {
                                            bookingGuestDeleteFn()
                                        }}> Confirm Delete BookingGuest </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
