import { Form } from "react-bootstrap";
import { processImage, processMultipleImages } from "./imageUploadHelpers";

export default function ImageUploadField(props: any) {

  const {
    uploadedImageCallback,
    multipleImages,
    // placeholder,
    fieldName,
    disabled,
    thumbSize = 400
  } = props

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>File</Form.Label>
        <Form.Control
          type="file"
          name={fieldName}
          multiple={multipleImages}
          disabled={disabled}
          onChange={async (event: any) => {
            if (event.currentTarget?.files) {
              const thumbFiles = {
                files: [...event.currentTarget.files]
              }
              const processedImages = multipleImages ? await processMultipleImages(event.currentTarget) : await processImage(event.currentTarget)
              const thumbSizeProcessedImages = multipleImages ? await processMultipleImages(thumbFiles, thumbSize) : await processImage(thumbFiles, thumbSize)
              uploadedImageCallback(processedImages, thumbSizeProcessedImages);
            }
          }}
        // isInvalid={!!errors.file}
        />
        {/*<Form.Control.Feedback type="invalid" tooltip>
              {errors.file}
            </Form.Control.Feedback>*/}
      </Form.Group>
      {/*<input type="file" placeholder={placeholder} multiple name={fieldName} onChange={async (event) => {
      if (event.currentTarget?.files) {
        const processedImages = await processMultipleImages(event.currentTarget)
        uploadedImageCallback(processedImages);
      }
    }} />*/}
    </>
  )
}
