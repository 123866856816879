import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setRole } from "../../services/accessService/accessService";
import { clearToken, setToken } from "../../services/authentication/authenticationService";

interface AccountState {
  _id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role: string;
  profilePic?: string
  token?: string,
  initialized: boolean,
  lastLogin: string | number,
}

const generateEmptyUserState = (): AccountState => {
  return {
    initialized: false,
    _id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: 'guest',
    profilePic: '',
    lastLogin: new Date().getTime(),
    token: '',
  }
}

const initialState: AccountState = generateEmptyUserState()

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<any>) => {
      if(action.payload.role){
        setRole(action.payload.role)
      }
      if (action.payload.token) {
        setToken(action.payload.token)
      }
      return {
        ...state,
        ...action.payload,
      }
    },
    logoutUser: () => {
      clearToken()
      setRole('guest')
      return generateEmptyUserState()
    }
  },
  // extraReducers: (builder) => {}
})

// Action creators are generated for each case reducer function
export const { setUserInfo, logoutUser } = accountSlice.actions

export const accountSelector = createSelector((state: RootState) => state, (state) => state.account)

export default accountSlice.reducer
