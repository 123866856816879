import { Button, Col, Container, Modal, Row, Toast, ToastContainer } from "react-bootstrap"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import timeService from "../../services/time/timeService"
import { useMemo, useState } from "react"
import CalendarBookings from "../../Components/Bookings/CalendarBookings/CalendarBookings"
import { FieldGeneratorFieldType } from "../../Components/FormFields/FieldGenerator/FieldGenerator"
import { textBooleanHandler } from "../../Helpers/ValueHandlers/boolHandler"
import BukibleEntry from "../../Components/Bukibles/BukibleEntry"
import BukinForm from "../../Components/Forms/BukinForm/BukinForm"
import { Link } from "react-router-dom"
import { currencyFormatter } from "../../Helpers/Parsers/currencyFormatter"
import canAccess from "../../services/accessService/accessService"
import AccessClickButton from "../../Components/Buttons/AccessClickButton"
import { useAddNewBukinMutation, useGetBukiblesQuery, useGetBukinsQuery } from "../../services/bClientApi"
import { BukibleEntryType } from "../../Types/bukibleTypes";

export default function BukiblesPage() {

    const [addNewBukin] = useAddNewBukinMutation()

    const [bukinPreview, setBukinPreview] = useState<any>({})

    const [bukibleFilters, setBukibleFilters] = useState<any>({
        quickFilter: '',
    })

    const [modalSettings, setModalSettings] = useState<any>({
        show: false,
        quickView: '',
        quickViewInfo: null,
        quickBukin: null
    })

    const dispatch = useAppDispatch()

    const startOfToday = timeService.getCurrentTime().set({ hour: 15, minute: 0, second: 0, millisecond: 0 }).toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ days: 2 }).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toMillis()

    const { activeQuery } = useActiveQueryHook({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            status: ['new', 'pending', 'confirmed', 'request'],
        },
        limit: 100,
        page: 1,
    })

    const {
        data,
        // isLoading,
        error,
    } = useGetBukinsQuery({
        bukinsQuery: {
            ...activeQuery.query,
        },
        pagination: {
            limit: activeQuery.limit,
            page: activeQuery.page,
        }
    })

    const {
        data: bukiblesData,
    } = useGetBukiblesQuery({
        bukiblesQuery: {},
        pagination: {
            limit: 500,
            page: 1,
        }
    })

    const bukibleSelectOptions = useMemo(() => {
        const bukiblesList = bukiblesData?.docs || []
        if (!bukiblesList) { return [] }
        return bukiblesList.map((bukible: BukibleEntryType) => ({
            value: bukible._id,
            text: bukible.title
        }))
    }, [bukiblesData])

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/bukibles?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }

    const clearModal = () => {
        setModalSettings({
            show: false,
            quickView: '',
            quickViewInfo: null,
            quickBukin: null
        })
    }

    const createQuickBukin = (bukibleId: any) => {
        const newBukin = {
            bukibleId: bukibleId,
            checkIn: activeQuery.query.checkIn,
            checkOut: activeQuery.query.checkOut,
            status: 'new',
        }
        setModalSettings({
            show: true,
            quickView: 'bukin',
            quickViewInfo: null,
            quickBukin: newBukin
        })
    }

    const bukinsByBukible = useMemo(() => {
        const bukiblesList = bukiblesData?.docs || []
        const bukinsList = data?.docs || []
        if (!bukiblesList || !bukinsList) { return [] }

        const bukibleRef: any = {}
        bukiblesList.forEach((bukible: BukibleEntryType) => {
            if(!bukible._id) { return }
            bukibleRef[bukible._id] = { ...bukible }
        })

        bukinsList.forEach((bukin: any) => {
            if (bukin.bukibleId && bukibleRef[bukin.bukibleId]) {
                bukibleRef[bukin.bukibleId].bukins = bukibleRef[bukin.bukibleId].bukins || []
                bukibleRef[bukin.bukibleId].bukins.push(bukin)
            }
        })

        return Object.keys(bukibleRef).map((bukibleKey: any) => {
            return bukibleRef[bukibleKey]
        })

    }, [data, bukiblesData])


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Bukibles</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            dateRange: null,
                            checkIn: activeQuery.query.checkIn,
                            checkOut: activeQuery.query.checkOut,
                            quickFilter: '',
                            // hideUnavailable: false,
                            ...activeQuery.query
                        }}
                        fields={bukilbleFormFields}
                        onChange={(data: any) => {
                            const fieldName = data.target.name
                            const fieldValue = data.target.value
                            if (quickFilterFields[fieldName]) {
                                const newFilters = {
                                    ...bukibleFilters,
                                    [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                                }
                                setBukibleFilters(newFilters)
                            }

                        }}
                        onSubmit={(data: any) => {
                            const newQuery = {
                                ...activeQuery,
                                query: {
                                    ...activeQuery.query,
                                }
                            }
                            if (data.checkIn) {
                                newQuery.query.checkIn = timeService.setDateTime(data.checkIn, { hour: 15 })?.getTime()
                            } else {
                                newQuery.query.checkIn = null
                            }
                            if (data.checkOut) {
                                newQuery.query.checkOut = timeService.setDateTime(data.checkOut, { hour: 12 })?.getTime()
                            } else {
                                newQuery.query.checkOut = null
                            }
                            loadPage(newQuery)
                        }} />

                    <div className="bukible-list-container">
                        {bukinsByBukible && bukinsByBukible.length > 0 && bukinsByBukible.filter((x) => {
                            if (bukibleFilters.quickFilter && x.title.toLowerCase().indexOf(bukibleFilters.quickFilter.toLowerCase()) === -1) {
                                return false
                            }
                            return true
                        }).map((bukibleEntry: any, idx) => (
                            <div key={idx}>
                                {/* <BukibleEntry bukibleInfo={bukibleEntry} /> */}
                                <h3 className={`bukible-title ${bukibleEntry.bukins && bukibleEntry.bukins.length > 0 ? 'text-danger' : 'text-success'}`}>
                                    {bukibleEntry.title}
                                </h3>
                                {canAccess('routes', 'add-bukin') && (!bukibleEntry.bukins || bukibleEntry.bukins.length === 0) && (
                                    <>
                                        <AccessClickButton
                                            text={'Quick Bukin'}
                                            resource={'routes'}
                                            action={'add-bukin'}
                                            className={'me-2 mb-2'}
                                            size={'sm'}
                                            onClick={() => {
                                                createQuickBukin(bukibleEntry._id)
                                            }}
                                        />
                                    </>
                                )}

                                {canAccess('routes', 'bukin') && (
                                    <>
                                        <AccessClickButton
                                            text={'View'}
                                            resource={'routes'}
                                            action={'bukin'}
                                            className={'me-2 mb-2'}
                                            size={'sm'}
                                            onClick={() => {
                                                setModalSettings({
                                                    show: true,
                                                    quickView: 'bukin',
                                                    quickViewInfo: bukibleEntry
                                                })
                                            }}
                                        />
                                    </>
                                )}
                                <CalendarBookings
                                    startDate={activeQuery.query.checkIn}
                                    endDate={activeQuery.query.checkOut}
                                    onView={canAccess('routes', 'bukin') ? (bukin: any) => {
                                        setBukinPreview(bukin.raw)
                                    } : undefined}
                                    bookings={bukibleEntry.bukins ? bukibleEntry.bukins : []} />
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>

            <Modal
                size={'lg'}
                show={modalSettings.show}
                onHide={clearModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Bukible Bukin Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalSettings && modalSettings.quickView === 'bukin' && modalSettings.quickBukin && (
                        <BukinForm
                            initialValues={modalSettings.quickBukin}
                            onSubmit={async (data: any) => {
                                const newBukin = await addNewBukin(data).unwrap()
                                clearModal()
                                return newBukin
                            }}
                            disabled={false}
                            bukibleSelectOptions={bukibleSelectOptions}
                        />
                    )}
                    {modalSettings && modalSettings.quickViewInfo && (
                        <BukibleEntry bukibleInfo={modalSettings.quickViewInfo} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={clearModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className="toast-fix">
                <ToastContainer>
                    <Toast show={bukinPreview && bukinPreview._id ? true : false} onClose={() => {
                        setBukinPreview({})
                    }}>
                        <Toast.Header>
                            <strong className="me-auto">
                                {bukinPreview.contactInfo?.firstName} {bukinPreview.contactInfo?.lastName}
                            </strong>
                            <small>{bukinPreview._id}</small>
                        </Toast.Header>
                        <Toast.Body>
                            {bukinPreview.checkIn && bukinPreview.checkOut && (
                                <>
                                    <b>Check In:</b> {timeService.createLuxonDate(bukinPreview.checkIn).toFormat('MM/dd/yyyy')}<br />
                                    <b>Check Out:</b> {timeService.createLuxonDate(bukinPreview.checkOut).toFormat('MM/dd/yyyy')}<br />
                                    <b>Status:</b> {bukinPreview.status}<br />
                                    {bukinPreview.bookingId && (
                                        <>
                                            <b>Booking ID:</b> {bukinPreview.bookingId}&nbsp;
                                            <Link
                                                className="text-decoration-none"
                                                to={`/booking/${bukinPreview.bookingId}`}>
                                                👀
                                            </Link>
                                            <br />
                                        </>
                                    )}
                                    {bukinPreview.referenceNumber && (
                                        <>
                                            <b>Reference Number:</b> {bukinPreview.referenceNumber}<br />
                                        </>
                                    )}
                                </>
                            )}
                            <b>Earnings: </b> {currencyFormatter(bukinPreview.earnings)}<br />
                            <Link to={`/bukin/${bukinPreview._id}`}>
                                View
                            </Link>

                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>





        </Container>
    )
}


const quickFilterFields: any = {
    quickFilter: 1,
    hideUnavailable: {
        handler: textBooleanHandler
    },
}

const bukilbleFormFields: FieldGeneratorFieldType[] = [
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'checkIn',
            endDate: 'checkOut',
        }
    },
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check Out',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'quickFilter',
        fieldLabel: 'Quick Filter',
        fieldType: 'text',
    },
]