import { useMemo, useState } from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { getChildProperties } from "../../Helpers/Property/multiUnit";
import { textBooleanHandler } from "../../Helpers/ValueHandlers/boolHandler";
import { PropertyType } from "../../Types/propertyTypes";
import { FieldGeneratorFieldType } from "../FormFields/FieldGenerator/FieldGenerator";
import QuickForm from "../Forms/QuickForm/QuickForm";
import './propertyMultiSelect.scss';

export default function PropertyMultiSelect(props: {
    propertyData: { [key: string]: PropertyType },
    selectedProperties: any,
    setSelectedProperties: (selectedProperties: any) => void,
}) {

    const {
        propertyData,
        selectedProperties,
        setSelectedProperties,
    } = props;

    const [propertyFilters, setPropertyFilters] = useState<any>({
        propertyFilter: '',
        publicProperties: true,
        multiProperties: false,
    });

    const [gridMode, setGridMode] = useState(true);

    const toggleSelectedProperty = (propertyId: string) => {
        if (selectedProperties[propertyId]) {
            const newSelectedProperties = { ...selectedProperties };
            delete newSelectedProperties[propertyId];
            setSelectedProperties(newSelectedProperties);
        } else {
            setSelectedProperties({ ...selectedProperties, [propertyId]: true });
        }
    }

    const processedMyProperties = useMemo(() => {
        const propertyList: any[] = []
        if (propertyData) {
            Object.keys(propertyData).forEach((propertyId: string) => {
                const property = propertyData[propertyId];
                if (propertyFilters.publicProperties && !property.public) { return }
                if (!propertyFilters.multiProperties) {
                    const childProperties = getChildProperties(propertyData, propertyId);
                    if (childProperties.length > 0) {
                        return
                    }
                }
                if (propertyFilters.propertyFilter &&
                    JSON.stringify(property).toLowerCase().indexOf(propertyFilters.propertyFilter.toLowerCase()) === -1) {
                    return
                }
                const shortTitle = property.title.split(' - ')[0];
                propertyList.push({
                    propertyId,
                    shortTitle,
                })
            })
        }
        return propertyList
    }, [propertyData, propertyFilters])

    return (
        <Row>
            <Col>
                <QuickForm
                    initialData={propertyFilters}
                    fields={PROPERTY_FILTER_FORM}
                    onChange={(data) => {
                        const fieldName = data.target.name
                        const fieldValue = data.target.value
                        if (quickFilterFields[fieldName]) {
                            const newFilters = {
                                ...propertyFilters,
                                [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                            }
                            setPropertyFilters(newFilters)
                        }
                    }}
                />

                <h3>Select Properties</h3>
                <div className="property-selection-button-container">
                    <Button
                        className="mb-2 me-2"
                        onClick={() => {
                            const newSelectedProperties = { ...selectedProperties }
                            processedMyProperties.forEach((propertyEntry: any) => {
                                const { propertyId } = propertyEntry;
                                newSelectedProperties[propertyId] = true;
                            })
                            setSelectedProperties(newSelectedProperties);
                        }}
                        variant={"secondary"}>
                        Select All
                    </Button>

                    {propertyFilters.multiProperties && (
                        <Button
                            className="mb-2 me-2"
                            onClick={() => {
                                const newSelectedProperties = { ...selectedProperties }
                                processedMyProperties.forEach((propertyEntry: any) => {
                                    const { propertyId } = propertyEntry;
                                    const childProperties = getChildProperties(propertyData, propertyId);
                                    if (childProperties.length > 0) {
                                        delete newSelectedProperties[propertyId];
                                    }
                                })
                                setSelectedProperties(newSelectedProperties);
                            }}
                            variant={"secondary"}>
                            Remove Double
                        </Button>
                    )}

                    <Button
                        className="mb-2 me-2"
                        onClick={() => { setSelectedProperties({}) }}
                        variant={"secondary"}>
                        Clear All
                    </Button>

                    {/* grid mode button */}
                    <Button
                        className="mb-2"
                        onClick={() => { setGridMode(!gridMode) }}
                        variant={"secondary"}>
                        {gridMode ? 'List Mode' : 'Grid Mode'}
                    </Button>

                </div>
                <div className={`property-select-container ${gridMode ? 'grid-mode' : ''}`}>
                    {processedMyProperties.map((propertyEntry: any) => {
                        const { propertyId, shortTitle } = propertyEntry;
                        return (
                            <div className="property-select-entry" key={propertyId} onClick={() => {
                                toggleSelectedProperty(propertyId);
                            }}>
                                <div>
                                    {selectedProperties[propertyId] ? (
                                        <Badge bg="success">✓</Badge>
                                    ) : (
                                        <Badge bg="secondary">▢</Badge>
                                    )}
                                </div>
                                <div>
                                    {shortTitle}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Col>
        </Row>
    )
}

const PROPERTY_FILTER_FORM: FieldGeneratorFieldType[] = [
    {
        fieldName: 'propertyFilter',
        fieldLabel: 'Property Filter',
        fieldType: 'text',
        placeholder: 'Property Filter',
    },
    {
        fieldName: 'publicProperties',
        fieldLabel: 'Public Properties',
        fieldType: 'checkbox',
        placeholder: 'Public Properties',
    },
    {
        fieldName: 'multiProperties',
        fieldLabel: 'Multi Properties',
        fieldType: 'checkbox',
        placeholder: 'Multi Properties',
    },
]

const boolHandler = {
    handler: textBooleanHandler
}

const quickFilterFields: any = {
    publicProperties: boolHandler,
    multiProperties: boolHandler,
    propertyFilter: true,
}