import { useEffect, useMemo, useRef, useState } from 'react';
import './balendar.scss';
import timeService from '../../services/time/timeService';
import { DateTime } from 'luxon';

const DATE_FORMAT = 'yyyy/MM/dd'

export default function Balendar(props: {
    selectedStartDate: string | Date | null,
    selectedEndDate: string | Date | null,
    onSelectDate: (date: string | Date | null) => void,
    hoveredDate?: string | Date | null,
    onHoverDate?: (date: string | Date | null) => void,
    activeDay?: Date,
    monthOffset?: number,
    dayRender?: (dayInfo: {
        year: string,
        month: string,
        day: string,
        weekDay: string,
        activeMonth: boolean,
        dayKey: string,
        selectedStart: boolean,
        selectedEnd: boolean,
        inBetween: boolean,
        hoverBetween: boolean,
        rowEnd: boolean,
        rowStart: boolean,
    }) => any,
    onDateChange?: (newDate: any) => void,
    onCancel?: () => void,
    weekStart?: 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat',
}) {

    const {
        selectedStartDate,
        selectedEndDate,
        onSelectDate,
        activeDay = new Date(),
        dayRender,
        onDateChange,
        onCancel,
        weekStart = 'Sun',
        monthOffset = 0,
        hoveredDate,
        onHoverDate
    } = props

    // const [hoveredDate, setHoveredDate] = useState<string | null>(null)

    const balendarContainer = useRef(null)
    const [containerWidth, setContainerWidth] = useState(0)
    const windowWidth = useMemo(() => {
        return window.innerWidth
    }, [])

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [balendarContainer])

    const handleResize = () => {
        if (!balendarContainer.current) { return }
        const width = (balendarContainer.current as any).offsetWidth
        if(width === containerWidth) { return }
        setContainerWidth(width)
    }

    const activeDayObj = timeService.createLuxonDate(activeDay).plus({ months: monthOffset })

    const activeMonth = activeDayObj.toFormat('MM')

    const startOfMonth = activeDayObj.startOf('month').startOf('day')
    const endOfMonth = activeDayObj.endOf('month').endOf('day')

    const dayOfStartOfMonth = startOfMonth.toFormat('ccc')
    const dayOfEndOfMonth = endOfMonth.toFormat('ccc')

    const weekDayKey = useMemo(() => {
        return generateWeekDayKey(weekStart)
    }, [weekStart])

    const startDate = startOfMonth.minus({ days: weekDayKey[dayOfStartOfMonth] }).toJSDate()
    const endDate = endOfMonth.plus({ days: 6 - weekDayKey[dayOfEndOfMonth] }).toJSDate()

    const processDay = (from: DateTime) => {
        const formattedFrom = from.toFormat(DATE_FORMAT)
        const [year, month, day] = formattedFrom.split('/')
        // tempDateArray.push(from.toFormat(DATE_FORMAT))
        const possibleBetween = selectedStartDate && selectedEndDate
        const possibleHover = hoveredDate && selectedStartDate && !selectedEndDate
        const fromMillis = from.toMillis()
        const startMillis = selectedStartDate ? timeService.dateToMillis(selectedStartDate) : 0
        const inBetween = possibleBetween && fromMillis > startMillis && fromMillis < timeService.dateToMillis(selectedEndDate)
        const hoverBetween = possibleHover && fromMillis > startMillis && fromMillis < timeService.dateToMillis(hoveredDate)
        const weekDayAbbr = from.toFormat('ccc')
        const processedDay = {
            year,
            month,
            day,
            weekDay: weekDayAbbr,
            activeMonth: activeMonth === month,
            dayKey: formattedFrom,
            selectedStart: formattedFrom === selectedStartDate,
            selectedEnd: formattedFrom === selectedEndDate,
            inBetween,
            hoverBetween,
            rowEnd: weekDayKey[weekDayAbbr] === 6,
            rowStart: weekDayKey[weekDayAbbr] === 0,
        }
        return processedDay
    }

    const dateArray = useMemo(() => {
        const tempDateArray: any[] = []
        let from = timeService.createLuxonDate(startDate);
        const to = timeService.getFormattedTime(endDate, DATE_FORMAT);
        while (from.toFormat(DATE_FORMAT) !== to) {
            tempDateArray.push(processDay(from))
            from = from.plus({ days: 1 })
        }
        tempDateArray.push(processDay(from))
        return tempDateArray
    }, [startDate, endDate])


    const handleDateSelection = (date: string) => {
        if (!onSelectDate) { return }
        onSelectDate(date)
    }

    const handleDateChange = (date: string | Date) => {
        if (!onDateChange) { return }
        onDateChange(date)
    }

    let fontSize = 100

    if(containerWidth && windowWidth) {
        fontSize = Math.max(80, Math.ceil((containerWidth / windowWidth)*100))
    }

    if(containerWidth < 400 ) {
        fontSize = 60
    }

    if( containerWidth > 800) {
        fontSize = 120
    }

    return (
        <div ref={balendarContainer} className="balendar" style={containerWidth && windowWidth ? {
            fontSize: `${fontSize}%`
        } : {}}>
            <div className="balendar-controls">

                {/* prev year button */}
                <div className="prev-year-btn control-btn" onClick={() => { handleDateChange(activeDayObj.minus({ years: 1 }).toJSDate()) }}>
                    &lt;&lt;
                </div>

                {/* prev month button */}
                <div className="prev-month-btn control-btn" onClick={() => { handleDateChange(activeDayObj.minus({ months: 1 }).toJSDate()) }}>
                    &lt;
                </div>

                {/* month info */}
                <div className="month-info">
                    {startOfMonth.toFormat('MMMM yyyy')}
                </div>

                {/* next month button */}
                <div className="next-month-btn control-btn" onClick={() => { handleDateChange(activeDayObj.plus({ months: 1 }).toJSDate()) }}>
                    &gt;
                </div>

                {/* next year button */}
                <div className="next-year-btn control-btn" onClick={() => { handleDateChange(activeDayObj.plus({ years: 1 }).toJSDate()) }}>
                    &gt;&gt;
                </div>
            </div>
            <div className="date-container" onMouseOut={() => {
                // setHoveredDate(null)
                onHoverDate && onHoverDate(null)
            }}>
                {dateArray.slice(0, 7).map((x: any, idx: number) => {
                    return (
                        <div key={idx} className="date-entry day-head">
                            <div className="date">
                                {x.weekDay}
                            </div>
                        </div>
                    )
                })}
                {dateArray.map((x: any, idx: number) => {
                    return (
                        <div
                            key={`${idx}`}
                            className={`date-entry ${x.activeMonth ? 'active' : 'inactive'} ${x.selectedStart ? 'selected-start' : ''} ${x.selectedEnd ? 'selected-end' : ''} ${x.inBetween || x.hoverBetween ? 'in-between' : ''}`}
                            onMouseOver={() => {
                                if (!x.activeMonth) { return }
                                // setHoveredDate(x.dayKey)
                                onHoverDate && onHoverDate(x.dayKey)
                            }}
                            onClick={() => {
                                if (!x.activeMonth) { return }
                                handleDateSelection(x.dayKey)
                            }}
                        >
                            <div className="date-content">
                                <div className="date">
                                    {x.day}
                                </div>

                                {dayRender && dayRender(x)}
                            </div>
                        </div>
                    )

                })}
            </div>

            <div className="balendar-bottom-controls">
                {selectedStartDate || selectedEndDate ? (
                    <div
                        onClick={() => { onCancel && onCancel() }}
                        className="clear-selection-btn control-btn">
                        clear
                    </div>

                ) : null}
            </div>

        </div>
    )
}


const generateWeekDayKey = (startingDay: 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' = 'Sun') => {
    const weekDayKey: any = {
        'Sun': 0,
        'Mon': 1,
        'Tue': 2,
        'Wed': 3,
        'Thu': 4,
        'Fri': 5,
        'Sat': 6,
    }
    const weekDayArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const startingDayIndex = weekDayArray.indexOf(startingDay)
    const newWeekDayArray = weekDayArray.slice(startingDayIndex).concat(weekDayArray.slice(0, startingDayIndex))
    newWeekDayArray.forEach((x, idx) => {
        weekDayKey[x] = idx
    })
    return weekDayKey
}