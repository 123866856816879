import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteBukinMutation, useGetBukinByIdQuery } from "../../../services/bClientApi"

export default function DeleteBukinPage() {
    const [
        deleteBukin,
        {
            error: deleteError,
            isLoading: deleteIsLoading,
        }
    ] = useDeleteBukinMutation()
    const [deleteClicked, setDeleteClicked] = useState(false)

    const { bukinId = '' } = useParams()
    const dispatch = useAppDispatch()

    const {
        data,
        isLoading,
        error,
    } = useGetBukinByIdQuery(bukinId, { skip: !bukinId || deleteClicked })

    const bukinData = data || {}

    const bukinDeleteFn = async () => {
        if (bukinId) {
            const deleteValue = await deleteBukin(bukinId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/bukins/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Bukin Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}

                    {bukinId && bukinData && !isLoading && (
                        <div className="bukin-info">
                            {JSON.stringify(bukinData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Bukin: {bukinId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Bukin</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this bukin it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={deleteIsLoading} onClick={() => {
                                            bukinDeleteFn()
                                        }}> Confirm Delete Bukin </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
