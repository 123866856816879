import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import InquiryForm from "../../../Components/Forms/InquiryForm/InquiryForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditInquiryMutation, useGetInquiryByIdQuery } from "../../../services/bClientApi";

export default function EditInquiryPage() {
  const { inquiryId = '' } = useParams()
  const dispatch = useAppDispatch()
  const [editInquiry, { error: editInquiryError, isLoading: editInquiryLoading }] = useEditInquiryMutation()
  const {
    data,
    error,
    isLoading
  } = useGetInquiryByIdQuery(inquiryId, { skip: !inquiryId })
  const inquiryData = data || {}

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (inquiryId) {
      const editedInquiry = await editInquiry({ inquiryId, inquiryInfo: values }).unwrap()
      if (editedInquiry._id) {
        dispatch(navigateTo(`/inquiry/${editedInquiry._id}`))
      }
      return editedInquiry
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Inquiry</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editInquiryError && (<>{JSON.stringify(editInquiryError)}<br /></>)}

          {inquiryId && inquiryData && !isLoading && (
            <InquiryForm
              disabled={editInquiryLoading}
              initialValues={inquiryData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
