import { Col, Container, Row } from "react-bootstrap"
import Pagination from "../../Components/Pagination/Pagination"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import BukinEntry from "../../Components/Bukins/BukinEntry"
import { useGetBukinsQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"

export default function BukinsPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        data,
        isLoading,
        error,
    } = useGetBukinsQuery({
        bukinsQuery: {
            ...activeQuery.query,
        },
        pagination: {
            limit: activeQuery.limit,
            page: activeQuery.page,
        }
    })

    const bukinsList = data?.docs || []


    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/bukins?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Bukins</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            firstName: activeQuery.query.firstName,
                            lastName: activeQuery.query.lastName,
                            checkIn: activeQuery.query.checkIn,
                            checkOut: activeQuery.query.checkOut,
                        }}
                        fields={[
                            {
                                fieldName: 'firstName',
                                fieldLabel: 'First Name',
                                fieldType: 'text',
                                placeholder: 'First Name',
                            },
                            {
                                fieldName: 'lastName',
                                fieldLabel: 'Last Name',
                                fieldType: 'text',
                                placeholder: 'Last Name',
                            },
                            {
                                fieldName: 'checkIn',
                                fieldLabel: 'Check In',
                                fieldType: 'date',
                                placeholder: 'Check In',
                            },
                            {
                                fieldName: 'checkOut',
                                fieldLabel: 'Check Out',
                                fieldType: 'date',
                                placeholder: 'Check Out',
                            },
                        ]}
                        disabled={isLoading}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: activeQuery.limit
                            })
                        }}
                    />

                    <div className="bukin-list-container">
                        {bukinsList && bukinsList.length > 0 && bukinsList.map((bukinEntry: any, idx: number) => (
                            <BukinEntry
                                key={idx}
                                bukinInfo={bukinEntry}
                            />
                        ))
                        }
                    </div>


                        <MongoPagination
                            data={data}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: activeQuery.query,
                                    limit: activeQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />

                </Col>
            </Row>
        </Container>
    )
}
