import { Col, Container, Row } from "react-bootstrap"
import PropertySearchForm from "../../Components/Forms/PropertySearchForm/PropertySearchForm"
import { PropertyEntry } from "../../Components/PropertyEntry/PropertyEntry"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers"
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import './propertiesPage.scss'
import { useGetPropertiesListQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"

export default function PropertiesPage() {

  const dispatch = useAppDispatch()

  const { activeQuery } = useActiveQueryHook({
    query: {
      publicProperties: true,
    },
    limit: 10
  })

  const {
    data,
    error,
    // isLoading,
    isFetching,
    requestId,
  } = useGetPropertiesListQuery({
    propertiesQuery: activeQuery.query,
    pagination: {
      page: activeQuery.page,
      limit: activeQuery.limit,
    }
  })

  const propertyList: any[] = data?.docs || []

  const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
    const destination = `/properties?${convertActiveQueryToUrl(pageParams)}`
    dispatch(navigateTo(destination))
  }
  return (
    <Container>
      <Row>
        <Col>
          <h1>Properties</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          <PropertySearchForm
            key={`search-form-${requestId}`}
            disabled={isFetching}
            onSubmit={(values: any) => {
              loadPage({
                query: values,
                page: 1,
                limit: activeQuery.limit
              })
            }}
            title={activeQuery.query.title}
            publicProperty={activeQuery.query.publicProperties}
          />

          {propertyList && propertyList.length > 0 && (
            <div className="property-list-container">
              {propertyList.map((property, idx) => (
                <PropertyEntry
                  key={`property-${property._id}`}
                  propertyInfo={property}
                />
              ))}
            </div>
          )}

          <MongoPagination
            data={data}
            loadPage={(q: { page: number, limit: number }) => {
              loadPage({ ...activeQuery, ...q, })
            }}
          />

        </Col>
      </Row>
    </Container>
  )
}
