import { Field } from "formik"
import { forwardRef } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import DatePicker from 'react-datepicker'
import { checkVal } from "../../../Helpers/Parsers/checkVal"
import timeService from "../../../services/time/timeService"

export const DateInputField = (props: {
  fieldName: string,
  fieldLabel: string,
  customClass?: string,
  placeholder?: string,
  required?: boolean,
  subText?: string,
  onChange?: (e: any) => void,
  minDate?: any,
  maxDate?: any,
  extraOptions?: any
}) => {
  const {
    fieldLabel,
    customClass,
    required,
    placeholder,
    subText,
    fieldName,
    onChange,
    minDate,
    maxDate,
    extraOptions
  } = props

  return (
    <>
      <Field name={fieldName} >
        {({
          field: { value },
          form
        }: any) => {
          const errorMessage = checkVal(form.errors, fieldName)
          const touched = checkVal(form.touched, fieldName)

          const CustomInput = forwardRef(({ value, onClick }: any, ref) => {
            return (
              <FloatingLabel
                controlId={fieldName}
                label={`${fieldLabel}${required ? '*' : ''}`}>
                <Form.Control
                  type={'text'}
                  placeholder={placeholder}
                  name={fieldName}
                  defaultValue={value}
                  isInvalid={checkVal(form.touched, fieldName) && checkVal(form.errors, fieldName)}
                  isValid={!checkVal(form.errors, fieldName) && checkVal(form.touched, fieldName)}
                  onClick={onClick}
                  autoComplete={'off'}
                  readOnly={true}
                />
              </FloatingLabel>
            )
          })

          return (
            <Form.Group className={`mb-3 date-picker-input ${customClass ? customClass : ''}`}>
              {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
              <DatePicker
                className={`form-control ${touched && errorMessage ? 'is-invalid' : ''} ${errorMessage === false && touched ? 'is-valid' : ''}`}
                selected={value ? timeService.getReverseOffsetJSDate(value) : null}
                // selected={value ? new Date(value) : null}
                placeholderText={placeholder}
                onChange={(date: Date | null) => {
                  const convertedDate = date ? timeService.getOffsetJSDate(date) : null
                  if (onChange) {
                    onChange(convertedDate)
                    // onChange(date)
                  }
                  form.setFieldTouched(fieldName, true, true)
                  form.setFieldValue(fieldName, convertedDate)
                  // form.setFieldValue(fieldName, date)
                }}
                customInput={<CustomInput />}
                minDate={minDate ? timeService.getReverseOffsetJSDate(minDate) : null}
                // minDate={minDate ? new Date(minDate) : null}
                maxDate={maxDate ? timeService.getReverseOffsetJSDate(maxDate) : null}
                // maxDate={maxDate ? new Date(maxDate) : null}
                showTimeSelect
                isClearable
                showYearDropdown
                {...(extraOptions ? extraOptions : {})}
              />
              {subText && (
                <Form.Text className="text-muted">
                  {subText}
                </Form.Text>
              )}
              {/*<Form.Control.Feedback type="invalid">
            {checkVal(form.errors, fieldName)}
          </Form.Control.Feedback>*/}
              {errorMessage && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {errorMessage}
                </div>
              )}
            </Form.Group>
          )
        }}
      </Field>
    </>
  )
}
