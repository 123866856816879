import { Badge } from "react-bootstrap";
import { WorkLogEntryType } from "../../Types/propertyTaskType";

export default function PropertyTaskBadges(props: {
    priority: number,
    status: string,
    occupancyInfo?: any,
    tags?: { tag: string }[],
    workLog?: WorkLogEntryType[]
}) {

    const { priority, status, occupancyInfo, tags, workLog } = props;

    const occupied = occupancyInfo && occupancyInfo.occupied;

    const hasWorkLog = workLog && workLog.length > 0 && workLog.filter((w: WorkLogEntryType) => {
        return !w.end
    }).length > 0

    return (
        <div className="property-task-badges">
            <Badge
                bg={TASK_PRIORITY_OPTIONS[priority - 1] ? TASK_PRIORITY_OPTIONS[priority - 1].color : 'primary'}
                text={TASK_PRIORITY_OPTIONS[priority - 1] ? TASK_PRIORITY_OPTIONS[priority - 1].textColor : 'primary'}
                className="me-2 mb-2">
                priority: {priority}
            </Badge>

            <Badge bg="dark" className="me-2 mb-2">
                {status}
            </Badge>

            {occupied && (
                <Badge bg="danger" className="me-2 mb-2">
                    occupied
                </Badge>
            )}

            {occupancyInfo && !occupied && (
                <Badge bg="success" className="me-2 mb-2">
                    vacant
                </Badge>
            )}

            {hasWorkLog && (
                <Badge bg="success" className="me-2 mb-2">
                    work in progress
                </Badge>
            )}

            || &nbsp;

            {tags && tags.map((t: { tag: string }, idx: number) => (
                <Badge bg="secondary" className="me-2 mb-2" key={idx}>
                    {t.tag}
                </Badge>
            ))}
        </div>
    )
}

export const TASK_PRIORITY_OPTIONS: any[] = [
    { priority: 1, text: '1', color: 'danger', },
    { priority: 2, text: '2', color: 'warning', textColor: 'dark' },
    { priority: 3, text: '3', color: 'info' },
    { priority: 4, text: '4', color: 'secondary' },
]