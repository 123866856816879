import { Card, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { currencyFormatter } from '../../Helpers/Parsers/currencyFormatter';
import timeService from '../../services/time/timeService';
import { BukinEntryType } from '../../Types/bukinTypes';
import AccessLinkButton from '../Buttons/AccessLinkButton';


export default function BukinEntry(props: {
    bukinInfo: BukinEntryType
}) {

    const {
        _id,
        contactInfo,
        checkIn,
        checkOut,
        status,
        earnings,
        bukibleId,
        bookingId,
        referenceNumber,
    } = props.bukinInfo

    const bukibleLink = `/bukible/${bukibleId}`

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Title>
                    {contactInfo.firstName} {contactInfo.lastName}
                </Card.Title>
                <Card.Subtitle>
                    {bukibleId}&nbsp;
                    <Link
                        className="text-decoration-none"
                        to={bukibleLink}>
                        👀
                    </Link>
                </Card.Subtitle>
                <Card.Text>
                    <b>Check In: </b> {timeService.getFormattedTime(checkIn)} <br />
                    <b>Check Out: </b> {timeService.getFormattedTime(checkOut)} <br />
                    <b>Status: </b> {status} <br />
                    <b>Earnings: </b> {currencyFormatter(earnings)} <br />
                    {bookingId && (
                        <>
                            <b>Booking ID:</b> {bookingId}&nbsp;
                            <Link
                                className="text-decoration-none"
                                to={`/booking/${bookingId}`}>
                                👀
                            </Link>
                            <br />
                        </>
                    )}
                    {referenceNumber && (
                        <>
                            <b>Reference Number:</b> {referenceNumber}<br />
                        </>
                    )}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Stack direction={'horizontal'} gap={1} className="mt-2 mb-2">
                    <AccessLinkButton
                        action={'bukin'}
                        color={'primary'}
                        size={'sm'}
                        text={'View'}
                        to={`/bukin/${_id}`}
                    />
                    {/* <Link className="btn btn-primary btn-sm" to={`/bukin/${_id}`}>View</Link> */}

                    <AccessLinkButton
                        action={'edit-bukin'}
                        color={'secondary'}
                        size={'sm'}
                        text={'Edit'}
                        to={`/edit-bukin/${_id}`}
                    />

                    {/* <Link className="btn btn-secondary btn-sm" to={`/edit-bukin/${_id}`}>Edit</Link> */}
                    {/* <Link className="btn btn-danger btn-sm" to={`/delete-bukin/${_id}`}>Delete</Link> */}
                </Stack>
            </Card.Footer>
        </Card>
    )
}