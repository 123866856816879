import { useMemo, useState } from "react";
import { Button, Col, Container, ProgressBar, Row, Spinner, Table } from "react-bootstrap";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import timeService from "../../services/time/timeService";
import { useAppDispatch } from "../../redux/store";
import useBookingsByPropertyMemo from "../../Memos/Bookings/UseBookingsByPropertyMemo";
import PropertyMultiSelect from "../../Components/PropertyMultiSelect/PropertyMultiSelect";
import './adrOccupancyReporter.scss'
import { Link } from "react-router-dom";
import {
    DATE_FILTER_FORM,
    TABLE_MODE_BUTTONS,
    dateHasher,
    generateCSVURL,
    generatePropertyReportData,
    generatePropertyTotalsReport,
    getSelectedPropertiesFromLocal,
    numberFormatter,
    saveSelectedPropertiesToLocal
} from "./adrOccupancyHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import { navigateTo } from "../../redux/router/routerActions";
import { useGetBookingsQuery } from "../../services/bClientApi";

export default function AdrOccupancyReporterPage() {

    // earnings, adr, occupancy, leadTime
    const [tableMode, setTableMode] = useState<string>('earnings')

    const dispatch = useAppDispatch()
    const { myProperties, isLoading: myPropertiesLoading } = useMyProperties();

    const [selectedProperties, setSelectedProperties] = useState<any>(getSelectedPropertiesFromLocal());

    const [percentageMode, setPercentageMode] = useState<boolean>(false)

    const [fileUrl, setFileUrl] = useState<string>('')

    const { activeQuery } = useActiveQueryHook({
        query: {
            checkIn: timeService.getCurrentTime().startOf('month').startOf('day').toMillis(),
            checkOut: timeService.getCurrentTime().endOf('month').endOf('day').toMillis(),
            bookingStatus: ['confirmed', 'new', 'pending'],
        },
        page: 1,
        limit: 300,
        all: true
    })

    const shouldQueryData = !activeQuery.query.checkIn || !activeQuery.query.checkOut || Object.keys(selectedProperties).length === 0

    const {
        data,
        isLoading: bookingListLoading,
        isFetching: bookingListFetching,
    } = useGetBookingsQuery({
        bookingsQuery: {
            ...activeQuery.query,
            propertyId: Object.keys(selectedProperties),
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
            all: true
        },
    }, {skip: shouldQueryData})
    const bookingsList = data?.docs || []

    const { data: directBookingsData } = useGetBookingsQuery({
        bookingsQuery: {
            source: ['direct', 'website'],
            bookingStatus: ['confirmed', 'new', 'pending'],
            dateBookedStart: activeQuery.query.checkIn,
            dateBookedEnd: activeQuery.query.checkOut,
            propertyId: Object.keys(selectedProperties),
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
            all: true
        },
    }, {skip: shouldQueryData})
    const directBookings = directBookingsData?.docs || []

    const comboBookings = useMemo(() => {
        let bookings: any[] = []

        if (bookingsList) {
            bookings = [...bookingsList]
        }

        if (directBookings) {
            bookings = [...bookings, ...directBookings]
        }

        return bookings
    }, [directBookings, bookingsList])

    const bookingsByProperty = useBookingsByPropertyMemo(comboBookings ? comboBookings : [], { propertyData: myProperties });

    const startDate = activeQuery.query.checkIn
    const endDate = activeQuery.query.checkOut

    const {
        dateRangeHash,
        dateRangeArray,
    }: any = useMemo(() => {

        const dateRangeHash = dateHasher(
            startDate,
            endDate,
            { dateFormat: 'yyyy-MM' }
        )
        return {
            dateRangeHash,
            dateRangeArray: Object.keys(dateRangeHash),
        }
    }, [startDate, endDate])

    const propertyReportData = useMemo(() => {
        const propertyReport: any[] = generatePropertyReportData(
            myProperties,
            selectedProperties,
            bookingsByProperty,
            startDate,
            endDate,
        )
        return propertyReport
    }, [bookingsByProperty, myProperties, selectedProperties, startDate, endDate])

    const propertyTotalsReport = useMemo(() => {
        return generatePropertyTotalsReport(
            propertyReportData,
            startDate,
            endDate,
        )
    }, [propertyReportData, startDate, endDate])

    const submitDateFilterForm = (data: any) => {
        const newQuery: any = {
            ...activeQuery,
            query: {
                checkIn: timeService.createLuxonDate(new Date(data.checkIn)).startOf('day').toMillis(),
                checkOut: timeService.createLuxonDate(new Date(data.checkOut)).endOf('day').toMillis(),
                bookingStatus: ['confirmed', 'new', 'pending'],
            }
        }
        if (selectedProperties && Object.keys(selectedProperties).length > 0) {
            const propertyIds = Object.keys(selectedProperties);
            newQuery.query['propertyId'] = propertyIds;
        }

        const pageParams = JSON.parse(JSON.stringify(newQuery))
        delete pageParams.query.propertyId
        const destination = `/adr-occupancy-reporter?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }

    const generateCSV = () => {
        const csvUrl = generateCSVURL(myProperties, propertyReportData, propertyTotalsReport)
        setFileUrl(csvUrl)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>ADR Occupancy Reporter Page</h1>
                    <hr />

                    <div className="text-center">
                        {/* back one year button */}
                        <Button
                            className="me-2 mb-2"
                            variant="outline-secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const currentSelectedStartDate = activeQuery.query.checkIn
                                const currentSelectedEndDate = activeQuery.query.checkOut
                                const startDate = timeService.createLuxonDate(new Date(currentSelectedStartDate)).minus({ years: 1 }).startOf('month').startOf('day').toMillis()
                                const endDate = timeService.createLuxonDate(new Date(currentSelectedEndDate)).minus({ years: 1 }).endOf('month').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            &larr; 1 Yr
                        </Button>

                        {/* back 1 month button */}
                        <Button
                            className="me-2 mb-2"
                            variant="outline-secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const currentSelectedStartDate = activeQuery.query.checkIn
                                const currentSelectedEndDate = activeQuery.query.checkOut
                                const startDate = timeService.createLuxonDate(new Date(currentSelectedStartDate)).minus({ months: 1 }).startOf('month').startOf('day').toMillis()
                                const endDate = timeService.createLuxonDate(new Date(currentSelectedEndDate)).minus({ months: 1 }).endOf('month').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            &larr; 1 Mo
                        </Button>


                        {/* this month button */}
                        <Button
                            className="me-2 mb-2"
                            variant="secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const startDate = timeService.getCurrentTime().startOf('month').startOf('day').toMillis()
                                const endDate = timeService.getCurrentTime().endOf('month').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            This Month
                        </Button>

                        {/* this year button */}
                        <Button
                            className="me-2 mb-2"
                            variant="secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const startDate = timeService.getCurrentTime().startOf('year').startOf('day').toMillis()
                                const endDate = timeService.getCurrentTime().endOf('year').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            This Year
                        </Button>

                        {/* Year to date button */}
                        <Button
                            className="me-2 mb-2"
                            variant="secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const startDate = timeService.getCurrentTime().startOf('year').startOf('day').toMillis()
                                const endDate = timeService.getCurrentTime().endOf('month').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            YTD
                        </Button>

                        {/* forward 1 month */}
                        <Button
                            className="me-2 mb-2"
                            variant="outline-secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const currentSelectedStartDate = activeQuery.query.checkIn
                                const currentSelectedEndDate = activeQuery.query.checkOut
                                const startDate = timeService.createLuxonDate(new Date(currentSelectedStartDate)).plus({ months: 1 }).startOf('month').startOf('day').toMillis()
                                const endDate = timeService.createLuxonDate(new Date(currentSelectedEndDate)).plus({ months: 1 }).endOf('month').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            1 Mo &rarr;
                        </Button>

                        {/* forward 1 year */}
                        <Button
                            className="me-2 mb-2"
                            variant="outline-secondary"
                            size="sm"
                            disabled={bookingListLoading || bookingListFetching}
                            onClick={() => {
                                const currentSelectedStartDate = activeQuery.query.checkIn
                                const currentSelectedEndDate = activeQuery.query.checkOut
                                const startDate = timeService.createLuxonDate(new Date(currentSelectedStartDate)).plus({ years: 1 }).startOf('month').startOf('day').toMillis()
                                const endDate = timeService.createLuxonDate(new Date(currentSelectedEndDate)).plus({ years: 1 }).endOf('month').endOf('day').toMillis()
                                submitDateFilterForm({
                                    checkIn: startDate,
                                    checkOut: endDate,
                                })
                            }}>
                            1 Yr &rarr;
                        </Button>
                    </div>



                    <hr />
                    <QuickForm
                        key={`${startDate}-${endDate}`}
                        initialData={{
                            checkIn: startDate,
                            checkOut: endDate,
                        }}
                        fields={DATE_FILTER_FORM}
                        onSubmit={submitDateFilterForm}
                        disabled={bookingListLoading || bookingListFetching}
                        submitText={'Generate Report'}
                        onCancel={Object.keys(selectedProperties).length > 0 ? () => { 
                            setSelectedProperties({})
                        } : undefined}
                        cancelText={Object.keys(selectedProperties).length > 0 ? "Clear Properties": undefined}
                    />

                    {bookingListLoading || bookingListFetching && (
                        <Row>
                            <Col>
                                Loading Bookings <Spinner size="sm" animation="border" />
                            </Col>
                        </Row>
                    )}

                    {Object.keys(selectedProperties).length > 0 && !(bookingListLoading) && JSON.stringify({
                        checkIn: activeQuery.query.checkIn,
                        checkOut: activeQuery.query.checkOut,
                    }) === JSON.stringify({
                        checkIn: startDate,
                        checkOut: endDate,
                    }) && bookingsList && (
                            <div className="report-table-container">
                                <div className="table-button-container">
                                    <Button
                                        className="me-4 mb-2"
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => setPercentageMode(!percentageMode)}
                                        active={percentageMode}>
                                        % Mode
                                    </Button>

                                    {TABLE_MODE_BUTTONS.map((b: any, idx) => (
                                        <Button
                                            className="me-2 mb-2"
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => setTableMode(b.value)}
                                            active={tableMode === b.value}
                                            key={idx}>
                                            {b.label}
                                        </Button>
                                    ))}

                                    <Button
                                        className="me-2 mb-2"
                                        variant="primary"
                                        size="sm"
                                        onClick={() => {
                                            generateCSV()
                                        }}>
                                        Download Report
                                    </Button>

                                    {fileUrl && (
                                        <a className="btn btn-sm btn-success me-2 mb-2" href={fileUrl} download="exported-data.csv">Download File</a>
                                    )}

                                    {tableMode === 'payout' && (
                                        <div className="w-100 mb-2">
                                            <small>
                                                Payouts are added in on the day after check in day for each booking(direct are added in on date booked)
                                            </small>
                                        </div>
                                    )}
                                </div>
                                {propertyTotalsReport?.totalsByMonth && Object.keys(propertyTotalsReport.totalsByMonth).length > 0 && (
                                <div className="table-wrapper">
                                    <Table size="sm" bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Property</th>
                                                {dateRangeArray.map((d: string) => (
                                                    <th key={d}>{d}</th>
                                                ))}
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Totals</td>
                                                {dateRangeArray.map((d: string) => {
                                                    const formattedNumber = numberFormatter(propertyTotalsReport.totalsByMonth[d][tableMode], tableMode)
                                                    const percentageValue = (propertyTotalsReport.totalsByMonth[d][tableMode] / propertyTotalsReport.totalsReport[tableMode]) * 100
                                                    const percentageNumber = `${+((percentageValue ? percentageValue : 0).toFixed(2))}%`
                                                    return (
                                                        <td key={d}>
                                                            <div className="number-wrapper me-3" title={percentageMode ? formattedNumber : percentageNumber}>
                                                                {percentageMode ? percentageNumber : formattedNumber}
                                                            </div>
                                                            <ProgressBar now={propertyTotalsReport.totalsByMonth[d].occupancy} />
                                                        </td>
                                                    )
                                                })}
                                                <td>
                                                    <div className="number-wrapper me-3">
                                                        {numberFormatter(propertyTotalsReport.totalsReport[tableMode], tableMode)}
                                                    </div>
                                                    <ProgressBar now={propertyTotalsReport.totalsReport.occupancy} />
                                                </td>

                                            </tr>
                                            {propertyReportData.map((propertyReport: any) => (
                                                <tr key={propertyReport.propertyId}>
                                                    <td>{propertyReport.shortTitle}</td>
                                                    {dateRangeArray.map((d: string) => {
                                                        const checkInTimeStamp = timeService.createLuxonDate(new Date(dateRangeHash[d].timeStamp)).startOf('month').toMillis()
                                                        const formattedNumber = numberFormatter(propertyReport.mappedData[d][tableMode], tableMode)
                                                        const percentageValue = (propertyReport.mappedData[d][tableMode] / propertyReport.totals[tableMode]) * 100
                                                        const percentageNumber = `${+((percentageValue ? percentageValue : 0).toFixed(2))}%`
                                                        return (
                                                            <td key={d}>
                                                                <div className="number-wrapper me-3" title={percentageMode ? formattedNumber : percentageNumber}>
                                                                    {percentageMode ? percentageNumber : formattedNumber}
                                                                </div>
                                                                <Link className="corner-link text-decoration-none" to={`/financials-property/${propertyReport.propertyId}?checkIn=${checkInTimeStamp}`}>
                                                                    ◥
                                                                </Link>
                                                                <ProgressBar now={propertyReport.mappedData[d].occupancy} />
                                                            </td>
                                                        )
                                                    })}
                                                    <td>
                                                        <div className="number-wrapper me-3">
                                                            {numberFormatter(propertyReport.totals[tableMode], tableMode)}
                                                        </div>
                                                        <ProgressBar now={propertyReport.totals.occupancy} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                )}
                            </div>
                        )}

                    <hr />

                </Col>
            </Row>

            {myPropertiesLoading === true && (
                <Row>
                    <Col>
                        Loading Properties <Spinner size="sm" animation="border" />
                    </Col>
                </Row>
            )}

            { !myPropertiesLoading && (
                <PropertyMultiSelect
                    selectedProperties={selectedProperties}
                    setSelectedProperties={(sp: any) => {
                        saveSelectedPropertiesToLocal(sp)
                        setSelectedProperties(sp)
                    }}
                    propertyData={myProperties}
                />
            )}

        </Container>
    )
}
