import QuickForm from "../QuickForm/QuickForm"
import { InquiryEntryType } from "../../../Types/inquiryTypes"

const generateBlankInquiry = () => ({
  created: new Date(),
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  extra: '',
})

export default function InquiryForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<InquiryEntryType>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
  } = props

  return (
    <div className="inquiry-form">
      <QuickForm 
        initialData={{
          ...generateBlankInquiry(),
          ...(initialValues ? initialValues : {})
        }}
        disabled={disabled}
        onSubmit={onSubmit}
        fields={[
            {
                fieldName: 'created',
                fieldLabel: 'Created',
                fieldType: 'date',
                placeholder: 'Created',
            },
            {
                fieldName: 'name',
                fieldLabel: 'Name',
                fieldType: 'text',
                placeholder: 'Name',
            },
            {
                fieldName: 'email',
                fieldLabel: 'Email',
                fieldType: 'text',
                placeholder: 'Email',
            },
            {
                fieldName: 'phone',
                fieldLabel: 'Phone',
                fieldType: 'text',
                placeholder: 'Phone',
            },
            {
                fieldName: 'subject',
                fieldLabel: 'Subject',
                fieldType: 'text',
                placeholder: 'Subject',
            },
            {
                fieldName: 'message',
                fieldLabel: 'Message',
                fieldType: 'textarea',
                placeholder: 'Message',
            },
            {
                fieldName: 'extra',
                fieldLabel: 'Extra',
                fieldType: 'textarea',
                placeholder: 'Extra',
            },
        ]}
      />
    </div>
  )
}
