import { PropertyAddressType } from "../../Types/propertyTypes"

export const googleAddress = (address: PropertyAddressType) => {
    return `https://www.google.com/maps/place/${address.address1}+${address.city}+${address.state}`
}

export const formatAddress = (address: PropertyAddressType) => {
    let addressString = ''
    addressString += `${address.address1}`
    addressString += address.address2 ? ` #${address.address2}, ` : ', '
    addressString += `${address.city}, ${address.state} ${address.zip}`
    return addressString
}
