import timeService from "../../../services/time/timeService"

export const nameSplitter = (name: string) => {
    const splitName = name.trim().split(' ')
    const firstName = splitName.shift()
    const lastName = splitName.length > 0 ? splitName.join(' ').trim() : ''
    return {
        firstName,
        lastName
    }
}

export const fixHomeAwayFinancialDate = (dateString: string) => {
    const splitDate = dateString.split('-')
    return `${splitDate[1]} ${splitDate[0]}, 20${splitDate[2]}`
}

export const bookingStatusDefinitions: any = {
    // new: 'new',
    new: 'confirmed',
    pending: 'pending',
    // reserve: 'pending',

    confirmed: 'confirmed',
    booked: 'confirmed',
    reserved: 'confirmed',
    reserve: 'confirmed',

    expired: 'expired',
    declined: 'declined',
    request: 'request',
    tentative: 'request',

    cancel: 'canceled',
    canceled: 'canceled',
    cancelled: 'canceled',
}

export const bookingMapDefinitions = (): any => {
    return {
        'airbnb': (file: any) => {
            return {
                checkIn: timeService.setDateTime(file['Start date'], { hour: 16 }),
                checkOut: timeService.setDateTime(file['End date'], { hour: 11 }),
                propertyId: file._propertyID,
                pricePerNight: +((+file['Earnings'].replace(/[^0-9.-]+/g, "")) / (+file['# of nights'])).toFixed(2),
                source: 'airbnb',
                sourceConfirmation: file['Confirmation code'],
                contactInfo: {
                    firstName: file['Guest name'] ? nameSplitter(file['Guest name']).firstName : null,
                    lastName: file['Guest name'] ? nameSplitter(file['Guest name']).lastName : null,
                    email: '',
                    phone: file['Contact']
                },
                dateBooked: file['Booked'] ? timeService.createLuxonDate(file['Booked']).toJSDate() : null,
                dateInquired: null,
                bookingStatus: bookingStatusDefinitions[file['Status'].toLowerCase().trim()],
                requiresConfirmation: null,
                confirmationCode: null,
                requiresBookingGuestInfo: null,
                bookingGuestInfoId: null,
                guestInfo: {
                    guestTotal: (+file['# of adults']) + (+file['# of children']) + (+file['# of infants']),
                    adults: +file['# of adults'],
                    children: +file['# of children'],
                    infants: +file['# of infants']
                }
            }
        },
        'homeawayReservation': (file: any) => {
            return {
                checkIn: timeService.setDateTime(file['Check-in'], { hour: 16 }),
                checkOut: timeService.setDateTime(file['Check-out'], { hour: 11 }),
                propertyId: file._propertyID,
                pricePerNight: null,
                source: 'homeaway',
                sourceConfirmation: file['Reservation ID'],
                contactInfo: {
                    firstName: file['Inquirer'] ? nameSplitter(file['Inquirer']).firstName : null,
                    lastName: file['Inquirer'] ? nameSplitter(file['Inquirer']).lastName : null,
                    email: file['Email'],
                    phone: file['Phone']
                },
                dateBooked: file['Created on'] ? timeService.createLuxonDate(file['Created on']).toJSDate() : null,
                dateInquired: null,
                bookingStatus: bookingStatusDefinitions[file['Status'].toLowerCase().trim()],
                requiresConfirmation: null,
                confirmationCode: null,
                requiresBookingGuestInfo: null,
                bookingGuestInfoId: null,
                guestInfo: {
                    guestTotal: (+file['Adults']) + (+file['Children']),
                    adults: +file['Adults'],
                    children: +file['Children'],
                    infants: null
                }
            }
        },
        'homeawayFinancial': (file: any) => {
            return {
                checkIn: timeService.setDateTime(file['Check-in'], { hour: 16 }),
                checkOut: timeService.setDateTime(file['Check-out'], { hour: 11 }),
                propertyId: file._propertyID,
                pricePerNight: +((+file['Payout'].replace(/[^0-9.-]+/g, "")) / (+file['Nights'])).toFixed(2),
                source: 'homeaway',
                sourceConfirmation: file['Reservation ID'],
                contactInfo: {
                    firstName: file['Traveler First Name'],
                    lastName: file['Traveler Last Name'],
                    email: '',
                    phone: ''
                },
                dateBooked: file['Created on'] ? timeService.createLuxonDate(file['Created on']).toJSDate() : null,
                dateInquired: null,
                bookingStatus: bookingStatusDefinitions[file['Booking status'].toLowerCase().trim()],
                requiresConfirmation: null,
                confirmationCode: null,
                requiresBookingGuestInfo: null,
                bookingGuestInfoId: null,
                guestInfo: {
                    guestTotal: null,
                    adults: null,
                    children: null,
                    infants: null
                }
            }
        },
        'fontaine': (file: any) => {
            return {
                checkIn: timeService.setDateTime(file['check_in'], { hour: 16 }),
                checkOut: timeService.setDateTime(file['check_out'], { hour: 11 }),
                propertyId: file._propertyID,
                pricePerNight: +(+file['nightly rate'].replace(/[^0-9.-]+/g, "")).toFixed(2),
                source: file['name'].toLowerCase().trim() === 'hotel' ? 'hotel' : null,
                sourceConfirmation: file['confirm'],
                contactInfo: {
                    firstName: file['name'] ? nameSplitter(file['name']).firstName : null,
                    lastName: file['name'] ? nameSplitter(file['name']).lastName : null,
                    email: null,
                    phone: null
                },
                dateBooked: null,
                dateInquired: null,
                bookingStatus: bookingStatusDefinitions[file['status'].toLowerCase().trim()] ? bookingStatusDefinitions[file['status'].toLowerCase().trim()] : 'confirmed',
                requiresConfirmation: true,
                confirmationCode: file['confirm'],
                requiresBookingGuestInfo: null,
                bookingGuestInfoId: null,
                guestInfo: {
                    guestTotal: file['people'],
                    adults: null,
                    children: null,
                    infants: null
                }
            }
        },
        'internalFile': (file: any) => {
            return {
                _id: file._id,
                checkIn: timeService.setDateTime(file.checkIn, { hour: 16 }),
                checkOut: timeService.setDateTime(file.checkOut, { hour: 11 }),
                propertyId: file.propertyId,
                pricePerNight: file.pricePerNight ? Number(file.pricePerNight) : null,
                // TODO: some kind of airbnb homeaway parser - if contains either default to lowercase value and other booking listed places
                source: file.source ? file.source.toLowerCase() : null,
                sourceConfirmation: file.sourceConfirmation,
                contactInfo: {
                    firstName: file.firstName,
                    lastName: file.lastName,
                    email: file.email,
                    phone: file.phone
                },
                dateBooked: file.dateBooked ? timeService.createLuxonDate(file.dateBooked).toJSDate() : null,
                dateInquired: file.dateInquired ? timeService.createLuxonDate(file.dateInquired).toJSDate() : null,
                bookingStatus: bookingStatusDefinitions[file.bookingStatus.trim().toLowerCase()] != null ? bookingStatusDefinitions[file.bookingStatus.trim().toLowerCase()] : file.bookingStatus,
                requiresConfirmation: file.requiresConfirmation ? file.requiresConfirmation.toLowerCase() === 'true' ? true : false : false,
                confirmationCode: file.confirmationCode,
                requiresBookingGuestInfo: file.requiresBookingGuestInfo ? file.requiresBookingGuestInfo.toLowerCase() === 'true' ? true : false : false,
                bookingGuestInfoId: file.bookingGuestInfoId,
                guestInfo: {
                    guestTotal: file.guestTotal ? Number(file.guestTotal) : null,
                    adults: file.adults ? Number(file.adults) : null,
                    children: file.children ? Number(file.children) : null,
                    infants: file.infants ? Number(file.infants) : null
                }
            }
        }
    }
}
