import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import MediaFileForm from "../../../Components/Forms/MediaFileForm/MediaFileForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { useEditMediaFileMutation, useGetMediaFileByIdQuery } from "../../../services/bClientApi";

export default function EditMediaFilePage() {

  const [
    editMediaFile,
    { 
      isLoading: editMediaFileIsLoading, 
      error: editMediaFileError 
    }
  ] = useEditMediaFileMutation()

  const {
    uploadImageFn,
  } = useUploadImage()

  const { mediaFileId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data,
    isLoading,
    error
  } = useGetMediaFileByIdQuery(mediaFileId, { skip: !mediaFileId })
  const mediaFileData = data || {}

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (mediaFileId) {
      const editedMediaFile = await editMediaFile({ mediaFileId, mediaFileInfo: values }).unwrap()
      if (editedMediaFile._id) {
        dispatch(navigateTo(`/mediaFile/${editedMediaFile._id}`))
      }
      return editedMediaFile
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit MediaFile</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editMediaFileError && (<>{JSON.stringify(editMediaFileError)}<br /></>)}

          {mediaFileId && mediaFileData && !isLoading && (
            <MediaFileForm
              disabled={editMediaFileIsLoading}
              initialValues={mediaFileData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
