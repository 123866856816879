import { Button, Col, Container, Row } from "react-bootstrap"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import { Link } from "react-router-dom"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import './mediaFiles.scss'
import { copyToClipboard } from "../../Helpers/ValueHandlers/copyText"
import { useGetMediaFilesQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"
import { MediaFileEntryType } from "../../Types/mediaFileTypes"

const FILE_TYPE: any = {
    'jpg': 'image',
    'jpeg': 'image',
    'png': 'image',
    'gif': 'image',
    'mp4': 'video',
    'mov': 'video',
    'avi': 'video',
    'mp3': 'audio',
    'wav': 'audio',
    'pdf': 'document',
}

export default function MediaFilesPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        data,
        error,
        isLoading
    } = useGetMediaFilesQuery({
        mediaFilesQuery: activeQuery.query,
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit
        }
    })
    const mediaFilesList = data?.docs || []

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/mediaFiles?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>MediaFiles</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            title: activeQuery.query.title,
                            description: activeQuery.query.description,
                            url: activeQuery.query.url,
                        }}
                        fields={[
                            {
                                fieldName: 'title',
                                fieldLabel: 'Title',
                                fieldType: 'text',
                                placeholder: 'Title',
                            },
                            {
                                fieldName: 'description',
                                fieldLabel: 'Description',
                                fieldType: 'text',
                                placeholder: 'Description',
                            },
                            {
                                fieldName: 'url',
                                fieldLabel: 'Url',
                                fieldType: 'text',
                                placeholder: 'Url',
                            },
                        ]}
                        disabled={isLoading}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: activeQuery.limit
                            })
                        }}
                    />

                    <div className="mediaFile-list-container">
                        {mediaFilesList && mediaFilesList.length > 0 && mediaFilesList.map((mediaFileEntry: MediaFileEntryType, idx: number) => {
                            const fileExtension = mediaFileEntry.url.split('.').pop()
                            const fileName = mediaFileEntry.url.split('/').pop()
                            const fileType = fileExtension ? FILE_TYPE[fileExtension] : 'unknown'
                            return (
                                <div className="media-file-entry" key={idx}>
                                    <div className="media-entry-image-wrapper">
                                        {fileType === 'image' && (
                                            <img className="" src={mediaFileEntry.url} alt={mediaFileEntry.title} />
                                        )}
                                        {fileType !== 'image' && (
                                            <div className="media-entry-file-type">
                                                {fileType}: {fileName} <br />
                                                <a href={mediaFileEntry.url} target="_blank" rel="noreferrer">View File</a> <br />
                                            </div>
                                        )}
                                        <br />
                                    </div>
                                    <div className="button-wrapper">
                                        {/* {JSON.stringify(mediaFileEntry, null, 2)} <br /> */}
                                        <Button
                                            className={'me-2 mb-2'}
                                            size={'sm'}
                                            variant={'success'}
                                            onClick={() => {
                                                copyToClipboard(mediaFileEntry.url)
                                            }}
                                        >
                                            Copy URL
                                        </Button>
                                        <Link className="btn btn-primary btn-sm me-2 mb-2" to={`/mediaFile/${mediaFileEntry._id}`}>View</Link>
                                        <Link className="btn btn-secondary btn-sm me-2 mb-2" to={`/edit-mediaFile/${mediaFileEntry._id}`}>Edit</Link>
                                        <Link className="btn btn-danger btn-sm me-2 mb-2" to={`/delete-mediaFile/${mediaFileEntry._id}`}>Delete</Link>


                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>

                        <MongoPagination
                            data={data}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: activeQuery.query,
                                    limit: activeQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                </Col>
            </Row>
        </Container>
    )
}
