import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";

export default function RegisterForm(props: {
  disabled: boolean,
  onSubmit: (formValues: {firstName: string, lastName: string, email: string, password: string}) => Promise<boolean>
}) {
  const {disabled, onSubmit} = props
  return (
    <div>
      <Formik
        initialValues={{ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' }}
        validate={values => {
          const errors: any = {};

          if (!values.firstName) {
            errors.firstName = 'Required';
          }

          if (!values.lastName) {
            errors.lastName = 'Required';
          }

          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          if (!values.password) {
            errors.password = 'Required';
          } else if (values.password.length < 5) {
            errors.password = 'password too short';
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'passwords do no match';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>

            <TextInputField
              fieldLabel="First Name"
              placeholder="First Name"
              required={true}
              fieldName="firstName" />

            <TextInputField
              fieldLabel="Last Name"
              placeholder="Last Name"
              required={true}
              fieldName="lastName" />

            <TextInputField
              fieldLabel="Email"
              placeholder="Email"
              fieldType="email"
              required={true}
              fieldName="email" />

            <TextInputField
              fieldLabel="Password"
              placeholder="Password"
              fieldType="password"
              required={true}
              fieldName="password" />

            <TextInputField
              fieldLabel="Confirm Password"
              placeholder="Confirm Password"
              fieldType="password"
              required={true}
              fieldName="confirmPassword" />

           <Button variant="primary" type="submit" disabled={isSubmitting || disabled}>
             Register
           </Button>
          </Form>
        )}
      </Formik>

    </div>
  )
}
