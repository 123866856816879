import jwt_decode from "jwt-decode";


interface TokenType {
  email: string
  role: string
  exp?: number
  iat: number
}

////////////////////////////////////////////////////

export function setToken(token: string) {
  localStorage.setItem('token', token)
  localStorage.setItem('selectedProperties', '')
}

export function clearToken() {
  localStorage.removeItem('token')
  localStorage.removeItem('selectedProperties')
}

export async function validateToken(token: string) {
  // async validateToken(token: string) {
  try {
      const decodedToken: TokenType = jwt_decode(token)
      // check contents
      if (!decodedToken || !decodedToken.email || !decodedToken.role || !decodedToken.iat) {
          throw new Error('invalid token')
      }
      // check expiration
      if (decodedToken.exp) {
          const expiry = decodedToken.exp * 1000
          if (expiry <= Date.now()) {
              throw new Error('expired token')
              // attempt to refresh token
          }
      }
      return true
  } catch (err) {
      // clear Token
      clearToken()
      // log out user
  }
  return false
}

export async function getTokenFlow() {
  const token = localStorage.getItem('token')
  if (token && await validateToken(token)) {
      return token
  }
  clearToken()
  return ''
}



////////////////////////
