import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import BukinEntry from "../../../Components/Bukins/BukinEntry"
import { useGetBukinByIdQuery } from "../../../services/bClientApi"

export default function BukinPage() {

  const { bukinId = '' } = useParams()

  const {
    data,
    isLoading,
    error,
  } = useGetBukinByIdQuery(bukinId, { skip: !bukinId })

  const bukinData = data || {}

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Bukin Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {bukinId && bukinData && !isLoading && (
            <div className="bukininfo">
              <BukinEntry bukinInfo={bukinData} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
