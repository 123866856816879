import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";

export function ChangePasswordForm(props: any) {
  const { disabled, onSubmit } = props
  return (
    <div>
      <h2>Change Password</h2>
      <Formik
        initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
        validate={values => {
          const errors: any = {};
          if (!values.oldPassword) {
            errors.oldPassword = 'Required';
          }
          if (!values.newPassword) {
            errors.newPassword = 'Required';
          } else if (values.newPassword.length < 5) {
            errors.newPassword = 'Password too short';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
          } else if (values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = 'Passwords do not match';
          }
          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          try {
            onSubmit({
              oldPassword: values.oldPassword,
              newPassword: values.newPassword
            })
            setSubmitting(false)
          } catch (err) {
            // do something
          }
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched, values }) => (
          <Form>
            <TextInputField
              fieldLabel="Current Password"
              placeholder="Current Password"
              fieldType="password"
              required={true}
              fieldName="oldPassword" />

            <TextInputField
              fieldLabel="New Password"
              placeholder="New Password"
              fieldType="password"
              required={true}
              fieldName="newPassword" />

            <TextInputField
              fieldLabel="Confirm New Password"
              placeholder="Confirm New Password"
              fieldType="password"
              required={true}
              fieldName="confirmPassword" />

            <Button className="mb-3" type="submit" disabled={disabled || isSubmitting}>
              Change Password
            </Button>
          </Form>
        )}
      </Formik>

    </div>
  )
}
