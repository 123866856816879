import { PropertyLinkedPropertyType } from "../../Types/propertyTypes"

export const getChildProperties = (
    myProperties: any,
    propertyId: string
) => {

    if (myProperties && propertyId && myProperties[propertyId]) {
        const property = myProperties[propertyId]
        return property.multiUnit.linkedUnits.filter((linkedProperty: PropertyLinkedPropertyType) => {
            return linkedProperty.relationship === 'child'
        })
    }

    return []
}