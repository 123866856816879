import timeService, { DATE_FORMAT } from "../../services/time/timeService"

export const bookingsByDayObject = (bookingsList: any[] | null) => {
    const hashedData: any = {}
    if (!bookingsList || bookingsList === null) {
        return hashedData
    }
    [...bookingsList].sort((x, y) => {
        return timeService.dateToMillis(x.checkIn) - timeService.dateToMillis(y.checkIn)
    }).forEach((x: any) => {
        const nights = timeService.calculateNights(x.checkIn, x.checkOut)
        const leadTime = Math.floor(timeService.timeDiff(x.dateBooked, x.checkIn))

        let bookingStartDate = timeService.createLuxonDate(x.checkIn).minus({ days: 1 })
        const bookingEndDateString = timeService.getFormattedTime(x.checkOut, DATE_FORMAT)
        const bookingEndUnix = timeService.dateToMillis(x.checkOut)
        while (bookingStartDate.toFormat(DATE_FORMAT) !== bookingEndDateString && bookingStartDate.toMillis() < bookingEndUnix) {
            bookingStartDate = bookingStartDate.plus({ days: 1 })
            const currentDayString = bookingStartDate.toFormat(DATE_FORMAT)
            if (!hashedData[currentDayString]) {
                hashedData[currentDayString] = {
                    date: currentDayString,
                    booking: {},
                    paid: {},
                    payout: 0,
                }
            }
            if (currentDayString === bookingEndDateString) {
                hashedData[currentDayString].checkingOut = x
            } else {
                hashedData[currentDayString].booking = x
                hashedData[currentDayString].nights = nights
                hashedData[currentDayString].earnings = x.pricePerNight
                hashedData[currentDayString].totalEarning = x.pricePerNight * nights
                hashedData[currentDayString].leadTime = leadTime

                if (currentDayString === timeService.getFormattedTime(x.checkIn, DATE_FORMAT) && !dayOfPayouts[x.source]) {
                    const nextDayString = timeService.createLuxonDate(x.checkIn).plus({ days: 1 }).toFormat(DATE_FORMAT)
                    if (!hashedData[nextDayString]) {
                        hashedData[nextDayString] = {
                            date: nextDayString,
                            booking: {},
                            paid: {},
                            payout: 0,
                        }
                    }
                    hashedData[nextDayString].payout += x.pricePerNight * nights
                    hashedData[nextDayString].paid[x._id] = 1


                    // if (!hashedData[currentDayString].payout) {
                    //     hashedData[currentDayString].payout = 0
                    //     hashedData[currentDayString].paid[x._id] = 1
                    // }
                    // hashedData[currentDayString].payout += x.pricePerNight * nights
                }
            }

            if (dayOfPayouts[x.source] && currentDayString === timeService.getFormattedTime(x.checkIn, DATE_FORMAT)) {
                const hashedDate = timeService.createLuxonDate(x.dateBooked).toFormat(DATE_FORMAT)
                if (!hashedData[hashedDate]) {
                    hashedData[hashedDate] = {
                        date: hashedDate,
                        booking: {},
                        paid: {},
                        payout: 0,
                    }
                }
                if (!hashedData[hashedDate].payout) {
                    hashedData[hashedDate].payout = 0
                }
                if (!hashedData[hashedDate].paid[x._id]) {
                    hashedData[hashedDate].payout += x.pricePerNight * nights
                    hashedData[hashedDate].paid[x._id] = 1
                }
            }

        }

        // add some logic here for the last date
    })
    return hashedData
}

const dayOfPayouts: { [key: string]: number } = {
    'website': 1,
    'direct': 1,
}