import { Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PersonCircle } from 'react-bootstrap-icons';
import './header.scss';
import timeService from "../../services/time/timeService";
import canAccess from "../../services/accessService/accessService";
import { BENICHAY_RESERVES_BASE_URL } from "../../Config/config";

export default function Header(
  props: {
    loggedIn: boolean;
    logOutFn: () => void;
  }
) {
  const {
    loggedIn,
    logOutFn,
  } = props;


  return (
    <Navbar expand={'lg'} collapseOnSelect>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img className="logo-image" src="/images/benichay-brother-logo.png" alt="" />
        </Navbar.Brand>
        {/* <Navbar.Brand as={Link} to="/">Benichay Brothers</Navbar.Brand> */}
        <Navbar.Toggle aria-controls={`main-menu-expand`} />
        <Navbar.Offcanvas
          id={`main-menu-expand`}
          aria-labelledby={`main-menu-expand`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>
              Benichay Brothers
            </Offcanvas.Title> */}
            <img className="logo-image" src="/images/benichay-brother-logo.png" alt="" />
          </Offcanvas.Header>

          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">

              <Nav.Link eventKey="home" as={Link} to="/">Home</Nav.Link>

              {canAccess('menu', 'bookings') && (
                <NavDropdown title="Bookings" id="bookings-dropdown">
                  <NavLinkList links={bookingLinks} />

                  {canAccess('routes', 'booking-guests') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={bookingGuestLinks} />
                    </>
                  )}

                  {canAccess('routes', 'booking-requests') && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item eventKey="booking-requests" as={Link} to="/booking-requests">View Booking Requests</NavDropdown.Item>
                      {/* <NavDropdown.Item eventKey="add-booking-request" as={Link} to="/add-booking-request">Add Booking Request</NavDropdown.Item> */}
                    </>
                  )}
                </NavDropdown>
              )}

              {canAccess('menu', 'properties') && (
                <NavDropdown title="Properties" id="properties-dropdown">

                  <NavLinkList links={propertyLinks} />

                  {canAccess('menu', 'buildings') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={buildingLinks} />
                    </>
                  )}

                  {canAccess('menu', 'property-prices') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={propertyPriceLinks} />
                    </>
                  )}

                </NavDropdown>
              )}

              {canAccess('route', 'cleaning') && (
                <Nav.Link eventKey="cleaning" as={Link} to="/cleaning">Cleaning</Nav.Link>
              )}

              {canAccess('route', 'property-task-list', { ignoreAdmin: true }) && (
                <Nav.Link eventKey="property-task-list" as={Link} to="/property-task-list">Tasks</Nav.Link>
              )}

              {canAccess('route', 'confirm-booking-guests', { ignoreAdmin: true }) && (
                <Nav.Link eventKey="confirm-booking-guests" as={Link} to="/confirm-booking-guests">Guests</Nav.Link>
              )}

              {canAccess('menu', 'property-tasks') && (
                <NavDropdown title="Tasks" id="property-tasks-dropdown">
                  <NavLinkList links={propertyTaskLinks} />
                </NavDropdown>
              )}

              {canAccess('menu', 'tools') && (
                <NavDropdown title="Tools" id="tools-dropdown">

                  <NavDropdown.Item eventKey="force-mail" as={'a'} target="_blank" href={`${BENICHAY_RESERVES_BASE_URL}/force-mail`}>Mail Force 1</NavDropdown.Item>

                  <NavLinkList links={mainToolsLinks} />

                  {/* <NavDropdown.Divider />
                  <a className="dropdown-item" rel="noreferrer" href="https://box-spider.herokuapp.com/api/puppet/stats" target="_blank">Check Collinaire Lock Sync</a>
                  <a className="dropdown-item" rel="noreferrer" href="https://box-spider.herokuapp.com/api/puppet/main" target="_blank">Force Collinaire Lock Sync</a> */}

                  {canAccess('menu', 'date-blocks') && (
                    <>
                      <NavDropdown.Item eventKey="date-blocks" as={Link} to={`/date-blocks?query={"checkIn":${getStartOfTodayInMillis()},"status":["new","pending","confirmed"]}`}>View Date Blocks</NavDropdown.Item>
                      <NavDropdown.Item eventKey="add-date-block" as={Link} to="/add-date-block">Add Date Block</NavDropdown.Item>
                    </>
                  )}

                  {canAccess('menu', 'purchases') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={purchaseLinks} />
                    </>
                  )}

                  {canAccess('menu', 'media-files') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={mediaFileLinks} />
                    </>
                  )}
                  {canAccess('menu', 'inquiries') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={inquiryLinks} />
                    </>
                  )}
                </NavDropdown>
              )}

              {canAccess('menu', 'bukibles') && (
                <NavDropdown title="Bukibles" id="bukibles-dropdown">

                  <NavLinkList links={bukibleLinks} />

                  {canAccess('menu', 'bukins') && (
                    <>
                      <NavDropdown.Divider />
                      <NavLinkList links={bukinLinks} />
                    </>
                  )}

                </NavDropdown>
              )}

              {canAccess('menu', 'users') && (
                <NavDropdown title="Users" id="users-dropdown">
                  <NavLinkList links={userLinks} />
                </NavDropdown>
              )}

              {loggedIn ? (
                <NavDropdown align="end" title={
                  <>
                    <div className="header-icon">
                      <PersonCircle className="header-icon" />
                    </div> Account
                  </>
                } id="account-dropdown">
                  <NavDropdown.Item eventKey="account" as={Link} to="/account">
                    Account
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="logout" onClick={() => {
                    logOutFn();
                  }}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <Nav.Link eventKey="login" as={Link} to="/login">Login</Nav.Link>
                  <Nav.Link eventKey="register" as={Link} to="/register">Register</Nav.Link>
                </>
              )}

            </Nav>
          </Offcanvas.Body>

        </Navbar.Offcanvas>
      </Container>
    </Navbar >
  )
}

const NavLinkList = (props: {
  links: {
    eventKey: string,
    title: string,
    to: string,
    options?: {
      onlyAdmin?: boolean,
      ignoreAdmin?: boolean
    }
  }[]
}) => {
  const {
    links,
  } = props;
  return (
    <>
      {links.map((link: any, idx: number) => {
        if (canAccess('routes', link.eventKey, link.options)) {
          return (
            <NavDropdown.Item
              key={`${link.eventKey}-${idx}`}
              eventKey={link.eventKey}
              as={Link}
              to={link.customLink ? link.customLink() : link.to}>
              {link.title}
            </NavDropdown.Item>
          )
        }
        return null
      })}
    </>
  )
}

const getStartOfTodayInMillis = () => {
  const startOfToday = timeService.createLuxonDate(new Date()).startOf('day');
  return startOfToday.toMillis();
}

const bookingLinks = [
  { eventKey: 'bookings', title: 'View Bookings', to: '/bookings', customLink: () => { return `/bookings?query={"checkIn":${getStartOfTodayInMillis()},"bookingStatus":["new","pending","confirmed"]}` } },
  { eventKey: 'unconfirmed-booking', title: 'Unconfirmed Bookings', to: '/unconfirmed-bookings' },
  { eventKey: 'upcoming-booking', title: 'Upcoming Bookings', to: '/upcoming-bookings' },
  { eventKey: 'all-icals', title: 'View All Icals', to: '/all-icals' },
  { eventKey: 'add-booking-quick', title: 'Add Booking⚡', to: '/add-booking?query={"quickBooking":1}' },
  { eventKey: 'add-booking', title: 'Add Booking', to: '/add-booking', options: { onlyAdmin: true } },
  { eventKey: 'export-bookings', title: 'Export Bookings', to: '/export-bookings' },
  { eventKey: 'booking-export-combiner', title: 'Booking Export Combiner', to: '/booking-export-combiner' },
  { eventKey: 'import-bookings', title: 'Import Bookings', to: '/import-bookings' },
  { eventKey: 'booking-messages', title: 'Booking Messages', to: '/booking-messages' },
]

const bookingGuestLinks = [
  { eventKey: 'confirm-booking-guests', title: 'Confirm Booking Guests', to: '/confirm-booking-guests' },
  { eventKey: 'booking-guests', title: 'View Booking Guests', to: '/booking-guests', customLink: () => { return `/booking-guests?query={"arrivalTimeStart":${getStartOfTodayInMillis()}}` } },
  { eventKey: 'add-booking-guest', title: 'Add Booking Guest', to: '/add-booking-guest' },
]

const propertyLinks = [
  { eventKey: 'my-properties', title: 'My Properties', to: '/my-properties' },
  { eventKey: 'properties', title: 'Search Properties', to: '/properties' },
  { eventKey: 'add-property', title: 'Add Property', to: '/add-property' },
  { eventKey: 'export-properties', title: 'Export Properties', to: '/export-properties' },
  { eventKey: 'import-properties', title: 'Import Properties', to: '/import-properties' },
]

const buildingLinks = [
  { eventKey: 'buildings', title: 'View Buildings', to: '/buildings' },
  { eventKey: 'add-building', title: 'Add Building', to: '/add-building' },
]

const propertyTaskLinks = [
  { eventKey: 'property-task-list', title: 'Property Task List', to: '/property-task-list' },
  { eventKey: 'property-task-reporter', title: 'Property Task Reporter', to: '/property-task-reporter' },
  { eventKey: 'property-tasks', title: 'View Property Tasks', to: '/property-tasks' },
  { eventKey: 'add-property-task', title: 'Add Property Task', to: '/add-property-task' },
]

const mainToolsLinks = [
  { eventKey: 'adr-occupancy-reporter', title: 'ADR Occupancy Reporter', to: '/adr-occupancy-reporter' },
  // { eventKey: 'fontaine-rates', title: 'Fontaine Rates', to: '/fontaine-rates' },
  { eventKey: 'deal-tool', title: 'Deal Tool', to: '/deal-tool' },
  { eventKey: 'rankuu', title: 'Rankuu', to: '/rankuu' },
]

const mediaFileLinks = [
  { eventKey: 'mediaFiles', title: 'View Media Files', to: '/mediaFiles' },
  { eventKey: 'add-mediaFile', title: 'Add Media File', to: '/add-mediaFile' },
]

const inquiryLinks = [
  { eventKey: 'inquiries', title: 'View Inquiries', to: '/inquiries' },
  { eventKey: 'add-inquiry', title: 'Add Inquiry', to: '/add-inquiry' },
]

const bukibleLinks = [
  { eventKey: 'bukibles', title: 'View Bukibles', to: '/bukibles' },
  { eventKey: 'add-bukible', title: 'Add Bukible', to: '/add-bukible' },
]

const bukinLinks = [
  { eventKey: 'bukins', title: 'View Bukins', to: '/bukins' },
  { eventKey: 'add-bukin', title: 'Add Bukin', to: '/add-bukin' },
]

const userLinks = [
  { eventKey: 'users', title: 'View Users', to: '/users' },
  { eventKey: 'add-user', title: 'Add User', to: '/register' },
  // { eventKey: 'add-user', title: 'Add User', to: '/register' },
]

const purchaseLinks = [
  { eventKey: 'purchases', title: 'View Purchases', to: '/purchases' },
  { eventKey: 'add-purchase', title: 'Add Purchase', to: '/add-purchase' },
]

const propertyPriceLinks = [
  // { eventKey: 'handle-property-prices', title: 'Handle Property Prices', to: '/handle-property-prices' },
  { eventKey: 'property-prices', title: 'View Property Prices', to: '/property-prices' },
  { eventKey: 'add-property-price', title: 'Add Property Price', to: '/add-property-price' },
  // { eventKey: 'import-property-prices', title: 'Import Property Prices', to: '/import-property-prices' },
]