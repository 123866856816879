import { FieldGeneratorFieldType } from "../FormFields/FieldGenerator/FieldGenerator"

export const imageGalleryFieldValues: FieldGeneratorFieldType[] = [
    {
      fieldType: 'text',
      fieldLabel: 'Image Url',
      placeholder: 'Image Url',
      fieldName: 'url'
    },
    {
      fieldType: 'text',
      fieldLabel: 'Image Title',
      placeholder: 'Image Title',
      fieldName: 'title'
    },
    {
      fieldType: 'text',
      fieldLabel: 'Image Description',
      placeholder: 'Image Description',
      fieldName: 'description'
    },
    {
      fieldType: 'text',
      fieldLabel: 'Thumb Url',
      placeholder: 'Thumb Url',
      fieldName: 'thumbUrl'
    }
  ]

  export const linkedServicesFieldValues: FieldGeneratorFieldType[] = [
    {
      fieldType: 'text',
      fieldLabel: 'Linked Service Name',
      placeholder: 'Linked Service Name',
      fieldName: 'linkedService'
    },
    {
      fieldType: 'textarea',
      fieldLabel: 'Linked Service Id',
      placeholder: 'Linked Service Id',
      fieldName: 'linkedServiceId'
    }
  ]
  