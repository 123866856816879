import { FieldGeneratorFieldType } from "../../FormFields/FieldGenerator/FieldGenerator"
import { userFieldRoles } from "../EditUserForm/EditUserFormHelpers"


export const aliasFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Alias',
    placeholder: 'Alias',
    fieldName: 'alias'
  },
  {
    fieldType: 'text',
    fieldLabel: 'Alias Source',
    placeholder: 'Alias Source',
    fieldName: 'aliasSource'
  },
]

export const calendarFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Calendar Url',
    placeholder: 'Calendar Url',
    fieldName: 'url'
  },
  {
    fieldType: 'select',
    fieldLabel: 'Calendar Source',
    placeholder: '- Select Calendar Source -',
    fieldName: 'source',
    options: [
      { value: 'airbnb', text: 'AirBnB' },
      { value: 'homeaway', text: 'Homeaway' },
      { value: 'booking', text: 'Booking.com' },
      { value: 'rentalsUnited', text: 'Rentals United' },
      { value: 'internal', text: 'BenichayBrothers.com' },
    ]
  },
]

export const listedFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Listed Url',
    placeholder: 'Listed Url',
    fieldName: 'url'
  },
  {
    fieldType: 'select',
    fieldLabel: 'Listed Source',
    placeholder: '- Select Listed Source -',
    fieldName: 'source',
    options: [
      { value: 'airbnb', text: 'AirBnB' },
      { value: 'homeaway', text: 'Homeaway' },
      { value: 'booking', text: 'Booking.com' },
    ]
  },
  {
    fieldType: 'text',
    fieldLabel: 'Internal Name',
    placeholder: 'Internal Name',
    fieldName: 'internalName'
  },
  {
    fieldType: 'text',
    fieldLabel: 'Internal Title',
    placeholder: 'Internal Title',
    fieldName: 'internalTitle'
  },
]

export const aboutSectionsFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Section Title',
    placeholder: 'Section Title',
    fieldName: 'sectionTitle'
  },
  {
    fieldType: 'textarea',
    fieldLabel: 'Section Content',
    placeholder: 'Section Content',
    fieldName: 'content'
  },
]

export const extraFeesFieldValues: FieldGeneratorFieldType[] = [
  {
    fieldType: 'text',
    fieldLabel: 'Fee Name',
    placeholder: 'Fee Name',
    fieldName: 'name'
  },
  {
    fieldType: 'number',
    fieldLabel: 'Extra Fee Amount',
    placeholder: 'Extra Fee Amount',
    fieldName: 'amount'
  },
  {
    fieldType: 'select',
    fieldLabel: '- Select Fee Frequency -',
    placeholder: 'Fee Frequency',
    fieldName: 'frequency',
    options: [
      { value: 'once', text: 'Once' },
      { value: 'nightly', text: 'Nightly' },
    ]
  },
]

export const authorizedUsersFieldValues = (): FieldGeneratorFieldType[] => ([
  {
    fieldType: 'text',
    fieldLabel: 'Email',
    required: true,
    placeholder: 'Email',
    fieldName: 'email'
  },
  {
    fieldType: 'select',
    fieldLabel: 'Role',
    placeholder: '- Select Role -',
    required: true,
    fieldName: 'role',
    options: userFieldRoles()
  },
])
