import { Col, Container, Row } from "react-bootstrap";
import ForgotPasswordForm from "../../../Components/Forms/ForgotPasswordForm/ForgotPasswordForm";
import { useForgotPasswordMutation } from "../../../services/bClientApi";
import { useState } from "react";

export default function ForgotPasswordPage() {

  const [message, setMessage] = useState<string | null>(null)
  const [
    forgotPassword,
    {
      error,
      isLoading,
    }
  ] = useForgotPasswordMutation()

  return (
    <Container>
      <Row>
        <Col>
          <h1>Forgot Password</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {message && (<>{message} <br /></>)}
          <ForgotPasswordForm
            disabled={isLoading}
            onSubmit={async (emailAddress: string) => {
              setMessage('Making request...')
              const forgotPasswordResponse = await forgotPassword(emailAddress).unwrap()
              console.log('forgotPassword', forgotPasswordResponse)
              if(forgotPasswordResponse.message) {
                setMessage(forgotPasswordResponse.message)
                return
              }
              setMessage('Something went wrong')
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}
