import { DateTime, Settings } from 'luxon';

// https://github.com/moment/luxon/blob/master/docs/formatting.md

export const DATE_FORMAT = 'yyyy/MM/dd'
// Settings.defaultZone = 'America/New_York'

class TimeService {
    defaultTimeZone: string = 'America/New_York';
    liveZone: string = ''
    hoursOffset: number = 0
    lastOffsetUpdate: number = 0

    constructor() {
        this.updateActiveTimeZone()
        console.log('TimeService', {
            defaultTimeZone: this.defaultTimeZone,
            liveZone: this.liveZone,
            hoursOffset: this.hoursOffset
        })
    }

    updateActiveTimeZone(force: boolean = false) {

        // check last update time
        const currentTime = Date.now()
        if (!force && currentTime - this.lastOffsetUpdate < 1000 * 60 * 60) {
            return
        }

        // Here we are snapping the current time to the local time zone
        const currentDate = new Date()
        const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        // const currentTimeZone = DateTime.now().zoneName
        this.liveZone = currentTimeZone
        const liveZoneTime = DateTime.fromJSDate(currentDate, { zone: currentTimeZone }).toObject()

        Settings.defaultLocale = 'en-US';
        Settings.defaultZone = 'America/New_York'

        // Here we are calculating the offset between the local time zone and the default time zone
        const defaultZoneTime = DateTime.fromJSDate(currentDate)
        const fakeCurrentTime = DateTime.fromObject(liveZoneTime)
        const timeZoneDiffHours = fakeCurrentTime.diff(defaultZoneTime, 'hours').hours
        this.hoursOffset = timeZoneDiffHours
        this.lastOffsetUpdate = new Date().getTime()
    }

    getCurrentTime() {
        return DateTime.local();
    }

    isToday(date: Date) {
        return this.getFormattedTime(date, DATE_FORMAT) === this.getFormattedTime(new Date(), DATE_FORMAT)
    }

    sameDay(date1: Date, date2: Date) {
        return this.getFormattedTime(date1, DATE_FORMAT) === this.getFormattedTime(date2, DATE_FORMAT)
    }

    getFormattedTime(time: Date, format: string = 'f') {
        const jsDate = new Date(time)
        return DateTime.fromJSDate(jsDate).toFormat(format);
    }

    createDateRangeHash(time1: Date, time2: Date, options: any = {}) {
        const {
            format = 'LLL dd YYYY',
        } = options;
        const time1Formatted = this.getFormattedTime(time1, format);
        const time2Formatted = this.getFormattedTime(time2, format);
        return `${time1Formatted}-${time2Formatted}`;
    }

    createDateHashObject = (startDate: any, endDate: any, options: any = {}) => {
        const {
            extraFields = {},
            dateFormat = DATE_FORMAT,
            callback
        } = options;
        const tempDateHash: { [d: string]: any } = {}

        if (!startDate || !endDate) { return tempDateHash }

        let from = this.createLuxonDate((new Date(startDate)));
        const to = this.getFormattedTime((new Date(endDate)), dateFormat);
        while (from.toFormat(dateFormat) !== to) {
            tempDateHash[from.toFormat(dateFormat)] = {
                date: from.toFormat(dateFormat),
                dayOfWeek: from.toFormat('ccc'),
                dayOfMonth: from.toFormat('d'),
                monthName: from.toFormat('LLL'),
                month: from.toFormat('MM'),
                year: from.toFormat('yyyy'),
                timeStamp: from.toMillis(),
                ...extraFields
            }
            if (callback) {
                callback(from.toFormat(dateFormat))
            }
            from = from.plus({ days: 1 })
        }
        tempDateHash[from.toFormat(dateFormat)] = {
            date: from.toFormat(dateFormat),
            dayOfWeek: from.toFormat('ccc'),
            dayOfMonth: from.toFormat('d'),
            monthName: from.toFormat('LLL'),
            month: from.toFormat('MM'),
            year: from.toFormat('yyyy'),
            timeStamp: from.toMillis(),
            ...extraFields
        }
        if (callback) {
            callback(from.toFormat(dateFormat))
        }
        return tempDateHash
    }

    createLuxonDate(date: Date | string) {
        return DateTime.fromMillis(this.dateToMillis(date))
        // return DateTime.fromMillis(this.dateToMillis(date), { zone: 'America/New_York' })
    }

    createLuxonDateFromObject(dateObject: any) {
        return DateTime.fromObject(dateObject)
    }

    dateToMillis(date: Date | string) {
        // return DateTime.fromJSDate(new Date(date)).toMillis()
        return (new Date(date)).getTime()
    }

    setDateTime(date: Date | string | null, time: { hour?: number, minute?: number, second?: number, millisecond?: number }) {
        if (date === null) {
            return null
        }
        const newDate = DateTime.fromMillis(this.dateToMillis(date)).set({
            // const newDate = DateTime.fromMillis(this.dateToMillis(date), { zone: 'America/New_York' }).set({
            ...{ minute: 0, second: 0, millisecond: 0 },
            ...time
        })
        return newDate.toJSDate()
    }

    calculateNights(startDate: Date | string | null, endDate: Date | string | null) {
        if (startDate && endDate) {
            const start = DateTime.fromMillis(this.dateToMillis(startDate)).startOf('day')
            const end = DateTime.fromMillis(this.dateToMillis(endDate)).startOf('day')
            const diff = end.diff(start, 'days').toObject()
            // console.log(diff)
            if (diff && diff.days) {
                return Math.ceil(diff.days)
            }
        }
        return 1
    }

    timeDiff(startDate?: Date | string | null, endDate?: Date | string | null, options: any = {}) {
        const {
            diffDuration = 'days'
        } = options;
        if (!startDate || !endDate) {
            return 0
        }
        const start = DateTime.fromMillis(this.dateToMillis(startDate))
        const end = DateTime.fromMillis(this.dateToMillis(endDate))
        const diff: any = end.diff(start, diffDuration).toObject()
        if (diff && diff[diffDuration]) {
            return diff[diffDuration]
        }
    }

    calculatePricePerNight(bookingTotal: number | string, startDate: Date | string | null, endDate: Date | string | null) {
        const totalVal = Number(bookingTotal)
        const nights = this.calculateNights(startDate, endDate)
        return Number((totalVal / nights).toFixed(2))
    }

    calculateBookingTotal(pricePerNight: number | string, startDate: Date | string | null, endDate: Date | string | null) {
        const nightVal = Number(pricePerNight)
        const nights = this.calculateNights(startDate, endDate)
        return Number((nightVal * nights).toFixed(2))
    }

    // TIMEZONE CONVERSIONS

    // USE THIS WHEN THE YOU ARE USING A TIME GENERATED BY "New Date()" from a string
    getOffsetFormattedTime(time: Date, format: string = 'f') {
        return DateTime.fromJSDate(new Date(time)).plus({ hours: this.hoursOffset }).toFormat(format);
    }

    // Used to convert our default time zone to the local time zone for inputs and stuff
    getOffsetJSDate(date: Date) {
        this.updateActiveTimeZone()
        return DateTime.fromJSDate(new Date(date)).plus({ hours: this.hoursOffset }).toJSDate()
    }

    // Used to convert the local time zone to our default time zone for storage
    getReverseOffsetJSDate(date: Date) {
        this.updateActiveTimeZone()
        return DateTime.fromJSDate(new Date(date)).minus({ hours: this.hoursOffset }).toJSDate()
    }

}

const timeService = new TimeService();

export default timeService