import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BookingForm, { PropertyBookingFormEntry } from "../../../Components/Forms/BookingForm/BookingForm";
import useActiveQueryHook from "../../../Hooks/UseActiveQuery/useActiveQueryHook";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { PropertyLinkedPropertyType } from "../../../Types/propertyTypes";
import { getChildProperties } from "../../../Helpers/Property/multiUnit";
import { useAddNewBookingMutation, useImportBookingsMutation, useLazyGetBookingsQuery } from "../../../services/bClientApi";

export default function AddBookingPage() {

  const [
    addNewBooking,
    {
      error,
      isLoading,
    }
  ] = useAddNewBookingMutation()

  const [
    importBookings,
    {
      error: importError,
      isLoading: importLoading,
    }
  ] = useImportBookingsMutation()

  const {
    myProperties,
  } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)
  const dispatch = useAppDispatch()
  const [dateValidationStatus, setDateValidationStatus] = useState({
    available: true,
    status: '',
    message: ''
  })

  const [checkBookingDates] = useLazyGetBookingsQuery()

  const { activeQuery } = useActiveQueryHook({})
  const initialData = activeQuery.query

  const validateDate = async (selectedPropertyId: string, checkIn: Date, checkOut: Date) => {
    setDateValidationStatus({
      available: false,
      status: 'warning',
      message: 'Checking date range for property'
    })
    const propertyId = [selectedPropertyId]
    if (myProperties[selectedPropertyId]) {
      const {
        multiUnit: {
          isMultiUnit,
          linkedUnits,
        }
      } = myProperties[selectedPropertyId]
      if (isMultiUnit) {
        linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
          propertyId.push(unit.propertyId)
        })
      }
    }
    const bookingData = await checkBookingDates({
      bookingsQuery: {
        propertyId,
        checkIn,
        checkOut,
        bookingStatus: ['new', 'confirmed', 'pending']
      },
      pagination: {
        page: 1,
        limit: 10
      }
    }).unwrap()
    if (bookingData && bookingData.docs && bookingData.docs.length > 0) {
      setDateValidationStatus({
        available: false,
        status: 'danger',
        message: 'WARNING: selected date range is unavailable for this property'
      })
      return
    }
    setDateValidationStatus({
      available: true,
      status: 'success',
      message: 'This property is available for  the date range'
    })
    return
  }

  const handleSubmit = async (data: PropertyBookingFormEntry) => {
    const values: any = {
      ...data
    }
    if (!values.dateBooked) {
      values.dateBooked = new Date()
    }
    delete values.dateRange
    delete values.bookingTotal

    if (values.source === 'direct' && !values.sourceConfirmation) {
      values.sourceConfirmation = `BCD${Date.now()}`
    }

    const {
      multiUnit: {
        isMultiUnit,
        linkedUnits,
      }
    } = myProperties[values.propertyId]
    if (isMultiUnit && linkedUnits) {
      const childProperties = linkedUnits.filter((unit: PropertyLinkedPropertyType) => unit.relationship === 'child')
      if (childProperties.length > 0) {
        const bookingsToAdd = [values]
        childProperties.forEach((unit: PropertyLinkedPropertyType) => {
          const propertyInfo = myProperties[unit.propertyId]
          // check if requires confirmation
          const isFontaine = propertyInfo.title.toLowerCase().indexOf('fontaine') > -1
          const isParentProperty = getChildProperties(myProperties, propertyInfo._id).length > 0
          const requiresConfirmation = isFontaine && !isParentProperty

          bookingsToAdd.push({
            ...values,
            propertyId: unit.propertyId,
            pricePerNight: values.pricePerNight ? values.pricePerNight / childProperties.length : 0,
            requiresConfirmation: requiresConfirmation,
          })
        })
        const newBookings = await importBookings(bookingsToAdd).unwrap()
        if (newBookings && newBookings.acknowledged) {
          dispatch(navigateTo(`/bookings?query={"firstName":"${values.contactInfo?.firstName}","lastName":"${values.contactInfo?.lastName}"}`))
        }
        return newBookings
      }
    }

    const newBooking = await addNewBooking(values).unwrap()
    if (newBooking._id) {
      dispatch(navigateTo(`/booking/${newBooking._id}`))
    }
    return newBooking
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Booking</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {importError && (<>{JSON.stringify(importError)}<br /></>)}

          {myProperties && Object.keys(myProperties).length > 0 && (
            <BookingForm
              disabled={isLoading || importLoading}
              initialValues={initialData ? initialData : {}}
              onSubmit={handleSubmit}
              propertyOptionList={propertyOptions}
              propertyData={myProperties}
              dateValidationStatus={dateValidationStatus}
              dateValidationFn={validateDate}
              quickBooking={initialData && initialData.quickBooking ? true : false}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
