import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BookingRequestForm from "../../../Components/Forms/BookingRequestForm/BookingRequestForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditBookingRequestMutation, useGetBookingRequestByIdQuery } from "../../../services/bClientApi";

export default function EditBookingRequestPage() {

  const [editBookingRequest] = useEditBookingRequestMutation()
  const { bookingRequestId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data: bookingRequestData,
    error,
    isLoading,
  } = useGetBookingRequestByIdQuery(bookingRequestId, { skip: !bookingRequestId })

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (bookingRequestId) {
      const editedBookingRequest = await editBookingRequest({ bookingRequestId, bookingRequestInfo: values }).unwrap()
      if (editedBookingRequest._id) {
        dispatch(navigateTo(`/booking-request/${editedBookingRequest._id}`))
      }
      return editedBookingRequest
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Booking Request</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {bookingRequestId && bookingRequestData && (
            <BookingRequestForm
              disabled={isLoading}
              initialValues={bookingRequestData}
              onSubmit={handleSubmit}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
