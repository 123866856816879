import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import PurchaseEntry from "../../../Components/Purchases/PurchaseEntry"
import { useGetPurchaseByIdQuery } from "../../../services/bClientApi"

export default function PurchasePage() {
  const { purchaseId = '' } = useParams()

  const {
    data,
    error,
    isLoading
  } = useGetPurchaseByIdQuery(purchaseId, { skip: !purchaseId })
  const purchaseData = data || {}

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Purchase Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {purchaseId && purchaseData && !isLoading && (
            <div className="purchaseinfo">
              {/* <pre>
                {JSON.stringify(purchaseData, null, 2)}            
              </pre> */}
              <PurchaseEntry
                purchaseData={purchaseData}
                fullView={true}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
