import { useMemo } from "react"
import { decorateIcalEntry } from "../../Helpers/Icals/IcalHelpers"
import timeService from "../../services/time/timeService"
import { PropertyBookingEntry } from "../../Types/bookingTypes"

export function UseIcalsDecorated(
    props: {
        icalData: any
        bookingsList?: PropertyBookingEntry[] | null
        myProperties: any
        propertyId?: string
    }
) {
    const {
        icalData,
        bookingsList,
        myProperties,
        propertyId
    } = props

    const decoratedIcalBookings = useMemo(() => {
        const decorated: any = []
        const existingCheck: any = {}

        if (bookingsList) {
            bookingsList.forEach((booking: PropertyBookingEntry) => {
                const bookingDateHash = timeService.createDateRangeHash(booking.checkIn, booking.checkOut)
                existingCheck[bookingDateHash] = booking._id
                if (booking.sourceConfirmation) {
                    existingCheck[booking.sourceConfirmation] = booking._id
                }
            })
        }

        if (icalData && propertyId && icalData[propertyId]) {
            icalData[propertyId].forEach((ical: any) => {
                const decoratedIcal = decorateIcalEntry(
                    ical,
                    myProperties,
                    propertyId,
                    existingCheck,
                )
                decorated.push(decoratedIcal)
            })
        }
        return decorated
    }, [bookingsList, myProperties, propertyId, icalData])

    return decoratedIcalBookings
}

export function UseAllIcalsDecorated(
    props: {
        icalData: any
        bookingsList?: PropertyBookingEntry[] | null
        myProperties: any
    }
) {
    const {
        icalData,
        bookingsList,
        myProperties,
    } = props

    const decoratedIcalBookings = useMemo(() => {
        const decorated: any = []
        const existingCheck: any = {}

        if (bookingsList) {
            bookingsList.forEach((booking: PropertyBookingEntry) => {
                if(!existingCheck[booking.propertyId]) {
                    existingCheck[booking.propertyId] = {}
                }
                const bookingDateHash = timeService.createDateRangeHash(booking.checkIn, booking.checkOut)
                existingCheck[booking.propertyId][bookingDateHash] = booking._id
                if (booking.sourceConfirmation) {
                    existingCheck[booking.propertyId][booking.sourceConfirmation] = booking._id
                }
            })
        }

        if (icalData) {
            Object.keys(icalData).forEach((propertyId: string) => {
                icalData[propertyId].forEach((ical: any) => {
                    const decoratedIcal = decorateIcalEntry(
                        ical,
                        myProperties,
                        propertyId,
                        existingCheck[ical.propertyId] ? existingCheck[ical.propertyId] : {},
                    )
                    decorated.push(decoratedIcal)
                })
            })
        }
        return decorated
    }, [bookingsList, myProperties, icalData])

    return decoratedIcalBookings

}