import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeletePurchaseMutation, useGetPurchaseByIdQuery } from "../../../services/bClientApi"

export default function DeletePurchasePage() {

    const [
        deletePurchase,
        {
            isLoading: deleteLoading,
            error: deleteError
        }
    ] = useDeletePurchaseMutation()

    const { purchaseId = '' } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    const {
        data,
        error,
        isLoading
      } = useGetPurchaseByIdQuery(purchaseId, { skip: !purchaseId || deleteClicked })
      const purchaseData = data || {}


    const purchaseDeleteFn = async () => {
        if (purchaseId) {
            const deleteValue = await deletePurchase(purchaseId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/purchases/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Purchase Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deleteError && (<>{JSON.stringify(deleteError)}<br /></>)}

                    {purchaseId && purchaseData && !isLoading && (
                        <div className="purchase-info">
                            {JSON.stringify(purchaseData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Purchase: {purchaseId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Purchase</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this purchase it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={deleteLoading} onClick={() => {
                                            purchaseDeleteFn()
                                        }}> Confirm Delete Purchase </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
