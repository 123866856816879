import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import './property.scss'
import { PropertyEntry } from "../../../Components/PropertyEntry/PropertyEntry"
import { useGetPropertyByIdQuery } from "../../../services/bClientApi"

export default function PropertyPage() {

  const { propertyId } = useParams()

  const {
    data: propertyData,
    error,
  } = useGetPropertyByIdQuery(propertyId ? propertyId : '')

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Property Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {propertyId && propertyData && (
            <div className="property-info">
              <PropertyEntry
                propertyInfo={propertyData}
                details={true}
                disableLinks={{
                  view: 1,
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}