import { useMemo } from "react";
import timeService from "../../../services/time/timeService";
import './CalendarBookings.scss'
import { DateBlockEntryType } from "../../../Types/dateBlockTypes";

const DATE_FORMAT = 'yyyy/MM/dd'

export interface SimpleBookingEntry {
    checkIn: Date,
    checkOut: Date,
    contactInfo: {
        firstName: string,
        lastName: string,
    }
}

export default function CalendarBookings(props: {
    bookings: SimpleBookingEntry[],
    blocks?: DateBlockEntryType[],
    startDate: Date,
    endDate: Date,
    onView?: (info: any) => void,
}) {
    const {
        bookings,
        blocks = [],
        startDate,
        endDate,
        onView
    } = props

    const dateArray = useMemo(() => {
        // const dateFormat = DATE_FORMAT
        const tempDateArray: string[] = []
        let from = timeService.createLuxonDate(startDate);
        const to = timeService.getFormattedTime(endDate, DATE_FORMAT);
        while (from.toFormat(DATE_FORMAT) !== to) {
            tempDateArray.push(from.toFormat(DATE_FORMAT))
            from = from.plus({ days: 1 })
        }
        tempDateArray.push(to)
        return tempDateArray
    }, [startDate, endDate])

    const hashedBookingDateInfo = useMemo(() => {
        const hashedData: any = {}
        bookings?.sort((j, k) => {
            return timeService.dateToMillis(j.checkIn) - timeService.dateToMillis(k.checkIn)
        }).forEach(x => {
            let bookingStartDate = timeService.createLuxonDate(x.checkIn).minus({ days: 1 })
            const bookingStartString = timeService.getFormattedTime(x.checkIn, DATE_FORMAT)
            const bookingEndDateString = timeService.getFormattedTime(x.checkOut, DATE_FORMAT)
            const bookingEndUnix = timeService.dateToMillis(x.checkOut)
            while (bookingStartDate.toFormat(DATE_FORMAT) !== bookingEndDateString && bookingStartDate.toMillis() < bookingEndUnix) {
                bookingStartDate = bookingStartDate.plus({ days: 1 })
                const currentDayString = bookingStartDate.toFormat(DATE_FORMAT)
                if (!hashedData[currentDayString]) {
                    hashedData[currentDayString] = {
                        bookingInfo: {},
                        checkingIn: '',
                        checkingOut: '',
                    }
                }
                hashedData[currentDayString].bookingInfo = {
                    checkIn: bookingStartString,
                    checkOut: bookingEndDateString,
                    name: `${x.contactInfo?.firstName} ${x.contactInfo?.lastName}`,
                    raw: x
                }
                if (currentDayString === bookingStartString) {
                    hashedData[currentDayString].checkingIn = `${x.contactInfo?.firstName} ${x.contactInfo?.lastName}`
                }
                if (currentDayString === bookingEndDateString) {
                    hashedData[currentDayString].checkingOut = `${x.contactInfo?.firstName} ${x.contactInfo?.lastName}`
                }
            }
            // add some logic here for the last date
        })
        return hashedData

    }, [bookings])

    const hashedBlockDateInfo = useMemo(() => {
        const hashedData: any = {}
        blocks?.sort((j, k) => {
            return timeService.dateToMillis(j.checkIn) - timeService.dateToMillis(k.checkIn)
        }).forEach(x => {
            let bookingStartDate = timeService.createLuxonDate(x.checkIn).minus({ days: 1 })
            const bookingStartString = timeService.getFormattedTime(x.checkIn, DATE_FORMAT)
            const bookingEndDateString = timeService.getFormattedTime(x.checkOut, DATE_FORMAT)
            const bookingEndUnix = timeService.dateToMillis(x.checkOut)
            while (bookingStartDate.toFormat(DATE_FORMAT) !== bookingEndDateString && bookingStartDate.toMillis() < bookingEndUnix) {
                bookingStartDate = bookingStartDate.plus({ days: 1 })
                const currentDayString = bookingStartDate.toFormat(DATE_FORMAT)
                if (!hashedData[currentDayString]) {
                    hashedData[currentDayString] = {
                        blockInfo: {},
                        checkingIn: '',
                        checkingOut: '',
                    }
                }
                hashedData[currentDayString].blockInfo = {
                    checkIn: bookingStartString,
                    checkOut: bookingEndDateString,
                    name: `${x.firstName} ${x.lastName}`,
                    raw: x
                }
                if (currentDayString === bookingStartString) {
                    hashedData[currentDayString].checkingIn = `${x.firstName} ${x.lastName}`
                }
                if (currentDayString === bookingEndDateString) {
                    hashedData[currentDayString].checkingOut = `${x.firstName} ${x.lastName}`
                }
            }
            // add some logic here for the last date
        })
        return hashedData
    }, [blocks])

    let blockedDays = 0

    dateArray.forEach((x: string, i: number) => {
        if (!hashedBlockDateInfo[x]) {
            return
        }
        if (i < dateArray.length - 1) {
            if (hashedBlockDateInfo[x].checkingOut && !hashedBlockDateInfo[x].checkingIn) {
                return
            }
        }
        if (hashedBlockDateInfo[x]) {
            blockedDays++
        }
    })

    const datesBlocked = blockedDays >= dateArray.length

    return (
        <div>
            {/* {bookings?.length} <br /> */}
            {/* {JSON.stringify(dateArray)} */}
            {/* {JSON.stringify(Object.keys(hashedBookingDateInfo))} */}

            {datesBlocked && (
                <div className="blocked-message mb-2">
                    <b>
                        All dates are blocked
                    </b>
                </div>
            )}
            <div className="quick-calendar mb-5">
                <div className="date-row">
                    {dateArray.slice(0, 7).map((x, idx: number) => (
                        <div key={`${x}-day-entry-${idx}`} className="day-entry">
                            {timeService.getOffsetFormattedTime(new Date(x), 'ccc')}
                        </div>
                    ))}
                </div>
                <div className="date-row">
                    {dateArray.map((x: any, idx: number) => {
                        const hbd = hashedBookingDateInfo[x]
                        const bookingInfo = hbd?.bookingInfo
                        let dateClass = bookingInfo ? 'booked' : ''
                        if (hbd?.checkingIn) {
                            dateClass += ' checkIn'
                        }
                        if (hbd?.checkingOut) {
                            dateClass += ' checkOut'
                        }
                        return (
                            <div key={`${x}-date-entry-${idx}`} className={`date-entry ${dateClass}`}>
                                {timeService.getOffsetFormattedTime(x, 'LL/dd')}
                                <div className="booking-info">
                                    {bookingInfo && (bookingInfo.checkIn || bookingInfo.checkOut) ? (
                                        <>

                                            {onView && (
                                                <div
                                                    onClick={() => onView(bookingInfo)}
                                                    role="button"
                                                    className="view-box">
                                                    👀
                                                </div>
                                            )}
                                            {!hbd.checkingIn && !hbd.checkingOut && (
                                                <div className="full-day">
                                                    {bookingInfo.name}
                                                </div>
                                            )}
                                            {hbd.checkingOut && (
                                                <div className="check-out-day">
                                                    {hbd.checkingOut}
                                                </div>
                                            )}
                                            {hbd.checkingIn && (
                                                <div className="check-in-day">
                                                    {hbd.checkingIn}
                                                </div>
                                            )}
                                        </>
                                    ) : '-'}

                                    {hashedBlockDateInfo[x] && (
                                        <div className="blocked-day" style={!bookingInfo ? {right: 0} : {}}>
                                            🧱
                                        </div>
                                    )}
                                </div>
                            </div >
                        )
                    })}
                </div>

            </div >
        </div >
    )
}