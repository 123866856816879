import { Link } from "react-router-dom"
import canAccess from "../../services/accessService/accessService"

export default function AccessLinkButton(props: {
    to: string,
    color?: string,
    text: string,
    action?: string,
    size?: string,
    accessOptions?: any
}) {
    const {
        to,
        color = 'primary',
        text,
        action = 'mainLinks',
        size,
        accessOptions = {}
    } = props

    if (!canAccess('routes', action, accessOptions)) {
        return null
    }

    return (
        <Link className={`btn ${size ? `btn-${size}` : ''} btn-${color} me-1 mb-1`} to={to}>
            {text}
        </Link>
    )
}