import { createAsyncThunk } from "@reduxjs/toolkit"
import { push } from "redux-first-history"

export const navigateTo = createAsyncThunk(
  'router/navigateToCall',
  async (endpoint: string, thunkApi: any) => {
    thunkApi.dispatch(push(`${endpoint}`))
    return true
  }
)
