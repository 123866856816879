import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { checkVal } from "../../../Helpers/Parsers/checkVal";
import timeService from "../../../services/time/timeService";
import { DateRangeInputField } from "../../FormFields/DateInput/DateRangeInputField";
import FieldGenerator, { FieldGeneratorFieldType } from "../../FormFields/FieldGenerator/FieldGenerator";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import "./quickForm.scss";
import { writeVal } from "../../../Helpers/Parsers/writeVal";
import QuickTag from "../../FormFields/QuickTag/QuickTag";
import AddressInputField from "../../FormFields/AddressInput/AddressInputField";

export default function QuickForm(
    props: {
        fields: FieldGeneratorFieldType[] | ((v: any) => FieldGeneratorFieldType[]),
        layout?: {
            xs?: number,
            sm?: number,
            md?: number,
            lg?: number,
            xl?: number,
            xxl?: number,
        },
        initialData: any
        onSubmit?: (values: any) => void
        onChange?: (values: any, api?: any) => void
        onCancel?: () => void
        disabled?: boolean
        submitText?: string
        cancelText?: string
        showErrors?: boolean
        validationSchema?: any
        onReset?: () => any
        uploadImageFn?: (v: any) => Promise<string>,
        uploadImageMultiFn?: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
    }
) {
    const {
        fields,
        layout = { xs: 1 },
        initialData,
        onSubmit,
        disabled,
        submitText,
        cancelText,
        onCancel,
        onChange,
        showErrors,
        onReset,
        uploadImageFn,
        uploadImageMultiFn,
        validationSchema,
    } = props

    return (
        <Formik
            initialValues={{
                ...initialData,
            }}
            validationSchema={validationSchema ? validationSchema : undefined}
            validate={!validationSchema ? values => {
                const errors: any = {};
                const fieldList = typeof fields === 'function' ? fields(values) : fields
                fieldList.forEach(field => {
                    const fieldVal = checkVal(values, field.fieldName)
                    if (field.required && (!fieldVal || fieldVal === '')) {
                        writeVal(errors, field.fieldName, 'Required')
                    }
                })
                return errors;
            } : undefined}
            onSubmit={async (values, { setSubmitting }) => {
                if (onSubmit) {
                    try {
                        onSubmit({ ...values })
                    } catch (err) {
                        // custom error handler
                    }
                }
                setSubmitting(false)
            }}
        >
            {({ values, isSubmitting, errors, touched, setFieldValue, handleChange, setValues }) => {
                const fieldList = typeof fields === 'function' ? fields(values) : fields
                return (
                    <Form
                        onChange={(d: any) => {
                            if (onChange) {
                                onChange(d, {
                                    values,
                                    setValues,
                                    setFieldValue,
                                })
                            }
                        }}>
                        <Row {...layout}>

                            {fieldList.map((field: FieldGeneratorFieldType, idx: number) => {
                                if (field.hidden) {
                                    return null
                                }
                                if (field.condition && !field.condition(values)) {
                                    return null
                                }
                                const fieldLayout: any = field?.layout || {}
                                if (field.fieldType === 'imageUpload') {
                                    const { extra } = field
                                    const multipleImages = extra?.multipleImages ? extra.multipleImages : false
                                    return (
                                        <Col {...fieldLayout} key={`quick-form-${idx}-${field.fieldName}`}>
                                            {field.extra?.title && (
                                                <h3>{field.extra.title}</h3>
                                            )}
                                            {/* <Col {...layout} > */}
                                            <ImageUploadField
                                                multipleImages={multipleImages}
                                                uploadedImageCallback={async (imageInfo: any) => {
                                                    try {
                                                        if (!multipleImages && uploadImageFn) {
                                                            const uploadedImageUrl = await uploadImageFn(imageInfo)
                                                            if (uploadedImageUrl) {
                                                                setFieldValue(field.fieldName, uploadedImageUrl)
                                                            }
                                                        }
                                                        if (multipleImages && uploadImageMultiFn && extra.mapUploadedImages) {
                                                            const uploadedImageList = await uploadImageMultiFn(imageInfo)
                                                            if (uploadedImageList) {
                                                                const mappedUploadedImages = extra.mapUploadedImages(uploadedImageList)
                                                                const currentImages = checkVal(values, field.fieldName)
                                                                setFieldValue(field.fieldName, [...(currentImages ? currentImages : []), ...mappedUploadedImages])
                                                            }
                                                        }
                                                    } catch (err) { }
                                                }}
                                            />
                                            {field.extra?.breakAfter && <hr />}
                                        </Col>
                                        // </div>
                                    )
                                }
                                if (field.fieldType === 'dateRange') {
                                    return (
                                        <Col {...fieldLayout} key={`quick-form-${idx}-${field.fieldName}`}>
                                            {field.extra?.title && (
                                                <h3>{field.extra.title}</h3>
                                            )}
                                            {/* <Col {...layout}> */}
                                            <DateRangeInputField
                                                fieldName={field.fieldName}
                                                fieldLabel={field.fieldLabel}
                                                startDate={values[field.extra.startDate]}
                                                placeholder={field.placeholder ? field.placeholder : 'mm/dd/yyyy - mm/dd/yyyy'}
                                                endDate={values[field.extra.endDate]}
                                                onChange={(dates: [Date | null, Date | null]) => {
                                                    const startVal = timeService.setDateTime(dates[0], field.extra.startTime ? field.extra.startTime : { hour: 16 })
                                                    const endVal = timeService.setDateTime(dates[1], field.extra.endTime ? field.extra.endTime : { hour: 11 })
                                                    const newValues: any = {
                                                        [field.fieldName]: [startVal, endVal]
                                                    }
                                                    if (field.extra.startDate) {
                                                        newValues[field.extra.startDate] = startVal
                                                    }
                                                    if (field.extra.endDate) {
                                                        newValues[field.extra.endDate] = endVal
                                                    }
                                                    setValues({ ...values, ...newValues }, true)
                                                }} />
                                            {field.extra?.breakAfter && <hr />}
                                        </Col>
                                        // </div>
                                    )
                                }
                                if (field.fieldType === 'quickTag') {
                                    const fieldName = field.fieldName
                                    const tempFieldName = field.extra?.tempFieldName ? field.extra.tempFieldName : 'tempTag'
                                    const tagEntryName = field.extra?.tagEntryName ? field.extra.tagEntryName : 'tag'
                                    return (
                                        <Col {...fieldLayout} key={`quick-form-${idx}-${field.fieldName}`}>
                                            {field.extra?.title && (
                                                <h3>{field.extra.title}</h3>
                                            )}
                                            <QuickTag
                                                className={'mb-3'}
                                                tags={values[fieldName] ? values[fieldName] : []}
                                                tempTag={values[tempFieldName]}
                                                tagFieldName={fieldName}
                                                tempTagFieldName={tempFieldName}
                                                tagEntryName={tagEntryName}
                                                tempTagFieldLabel={field.fieldLabel}
                                                setFieldValue={setFieldValue}
                                                buttons={field.extra?.buttons ? field.extra.buttons : []}
                                                options={field.extra?.options ? field.extra.options : {
                                                    hideInput: true,
                                                    hideBadges: true,
                                                }}
                                            />
                                            {field.extra?.breakAfter && <hr />}
                                        </Col>
                                    )
                                }
                                if (field.fieldType === 'address') {
                                    return (
                                        <Col {...fieldLayout} key={`quick-form-${idx}-${field.fieldName}`}>
                                            {field.extra?.title && (
                                                <h3>{field.extra.title}</h3>
                                            )}

                                            <AddressInputField
                                                fieldName={field.fieldName}
                                                required={field.required}
                                            />

                                            {field.extra?.breakAfter && <hr />}
                                        </Col>
                                    )
                                }

                                const fieldVal = field.extra?.imagePreview ? checkVal(values, field.fieldName) : ''
                                return (
                                    <Col {...fieldLayout} key={`quick-form-${idx}-${field.fieldName}`}>
                                        {/* <Col {...layout}> */}
                                        {field.extra?.title && (
                                            <h3>{field.extra.title}</h3>
                                        )}
                                        {field.extra?.imagePreview && fieldVal && (
                                            <img className="quick-form-image-preview" alt="" src={fieldVal} />
                                        )}
                                        <FieldGenerator {...field} />
                                        {field.extra?.breakAfter && <hr />}
                                    </Col>
                                    // </div>
                                )
                            })}
                        </Row>

                        {showErrors && Object.keys(errors).length > 0 && (
                            <pre>
                                Errors: {JSON.stringify(errors)}
                            </pre>
                        )}

                        {onSubmit && (
                            <Button className="mb-3 me-3" type="submit" disabled={isSubmitting || disabled || Object.keys(errors).length > 0}>
                                {submitText ? submitText : 'Submit'}
                            </Button>
                        )}

                        {onCancel && (
                            <Button className="mb-3 me-3" type="button" variant="danger" onClick={onCancel}>
                                {cancelText ? cancelText : 'Cancel'}
                            </Button>
                        )}

                        {onReset && (
                            <Button
                                className="mb-3 me-3"
                                type="button"
                                variant="secondary"
                                onClick={() => {
                                    setValues(onReset(), true)
                                }}>
                                Reset
                            </Button>
                        )}

                    </Form>
                )
            }
            }
        </Formik>
    )

}