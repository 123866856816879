import { FieldGeneratorFieldType } from "../FormFields/FieldGenerator/FieldGenerator"

export const nightRateMultiplier: any = {
    nightly: 1,
    monthly: 30,
    yearly: 365
}

export const expenseMultiplier: any = {
    nightly: 1 / 30,
    monthly: 1,
    yearly: 12
}

export const defaultDealInfo: any = {
    name: '',
    location: '',
    nightlyRate: 0,
    occupancy: 0,
    rent: 0,
    electricity: 0,
    maintenance: 0,
    garbage: 0,
    water: 0,
    internet: 0,
    repairs: 0,
    association: 0,
    propertyTax: 0,
    insurance: 0,
    purchasePrice: 0,
    downPayment: 0,
    loanAmount: 0,
    interestRate: 0,
    splitRatePercentage: 0,
}

export const dealFormFields: FieldGeneratorFieldType[] = [
    {
        fieldName: 'name',
        fieldLabel: 'Deal Name',
        fieldType: 'text',
        placeholder: 'Deal Name',
        required: false,
        extra: {
            title: 'Deal Info'
        }
    },
    {
        fieldName: 'location',
        fieldLabel: 'Location',
        fieldType: 'text',
        placeholder: 'Location',
        required: false,
    },
    // NIGHTLY RATE & OCCUPANCY
    {
        fieldName: 'nightlyRate',
        fieldLabel: 'Nightly Rate',
        fieldType: 'number',
        placeholder: 'Nightly Rate',
        required: false,
        extra: {
            title: 'Nightly Rate & Occupancy'
        }
    },
    {
        fieldName: 'occupancy',
        fieldLabel: 'Occupancy',
        fieldType: 'number',
        placeholder: 'Occupancy %',
        required: false,
    },
    // VARIABLE COSTS
    {
        fieldName: 'rent',
        fieldLabel: 'Rent',
        fieldType: 'number',
        placeholder: 'Rent',
        required: false,
        extra: {
            title: 'Variable Costs'
        }
    },
    {
        fieldName: 'electricity',
        fieldLabel: 'Electricity',
        fieldType: 'number',
        placeholder: 'Electricity',
        required: false,
    },
    {
        fieldName: 'maintenance',
        fieldLabel: 'Maintenance',
        fieldType: 'number',
        placeholder: 'Maintenance',
        required: false,
    },
    {
        fieldName: 'garbage',
        fieldLabel: 'Garbage',
        fieldType: 'number',
        placeholder: 'Garbage',
        required: false,
    },
    {
        fieldName: 'water',
        fieldLabel: 'Water & Sewer',
        fieldType: 'number',
        placeholder: 'Water & Sewer',
        required: false,
    },
    {
        fieldName: 'internet',
        fieldLabel: 'Internet & Cable',
        fieldType: 'number',
        placeholder: 'Internet & Cable',
        required: false,
    },
    {
        fieldName: 'repairs',
        fieldLabel: 'Repairs & Incidentals',
        fieldType: 'number',
        placeholder: 'Repairs & Incidentals',
        required: false,
    },
    {
        fieldName: 'association',
        fieldLabel: 'Association Fees',
        fieldType: 'number',
        placeholder: 'Association Fees',
        required: false,
    },
    // FIXED COSTS
    {
        fieldName: 'propertyTax',
        fieldLabel: 'Property Tax',
        fieldType: 'number',
        placeholder: 'Property Tax',
        required: false,
        extra: {
            title: 'Fixed Costs'
        }
    },
    {
        fieldName: 'insurance',
        fieldLabel: 'Insurance',
        fieldType: 'number',
        placeholder: 'Insurance',
        required: false,
    },
    // FINANCE
    {
        fieldName: 'purchasePrice',
        fieldLabel: 'Purchase Price',
        fieldType: 'number',
        placeholder: 'Purchase Price',
        required: false,
        extra: {
            title: 'Finance'
        }
    },
    {
        fieldName: 'downPayment',
        fieldLabel: 'Down Payment',
        fieldType: 'number',
        placeholder: 'Down Payment',
        required: false,
    },
    {
        fieldName: 'loanAmount',
        fieldLabel: 'Loan Amount',
        fieldType: 'number',
        placeholder: 'Loan Amount',
        required: false,
    },
    {
        fieldName: 'interestRate',
        fieldLabel: 'Interest Rate',
        fieldType: 'number',
        placeholder: 'Interest Rate',
        required: false,
    },
    {
        fieldName: 'splitRatePercentage',
        fieldLabel: 'Split Rate Percentage (Lenders Ownership)',
        fieldType: 'number',
        placeholder: 'Split Rate Percentage (Lenders Ownership)',
        required: false,
    },
]