import { useState } from 'react'
import { Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { useParams } from "react-router"
import { navigateTo } from '../../../redux/router/routerActions'
import { useAppDispatch } from "../../../redux/store"
import { useDeletePropertyTaskMutation, useGetPropertyTaskByIdQuery } from '../../../services/bClientApi'

export default function DeletePropertyTaskPage() {

    const [
        deletePropertyTask,
        { isLoading: deletePropertyTaskLoading, error: deletePropertyTaskError }
    ] = useDeletePropertyTaskMutation()

    const { propertyTaskId = '' } = useParams()
    const dispatch = useAppDispatch()

    const [deleteClicked, setDeleteClicked] = useState(false)

    const {
        data,
        error,
        isLoading
    } = useGetPropertyTaskByIdQuery(propertyTaskId, { skip: !propertyTaskId || deleteClicked })
    const propertyTaskData = data || {}


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Property Task Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deletePropertyTaskError && (<>{JSON.stringify(deletePropertyTaskError)}<br /></>)}

                    {propertyTaskId && propertyTaskData && !isLoading && (
                        <Stack gap={3}>
                            <div>Are you sure you want to delete property task: {propertyTaskId}</div>

                            <pre className="propertyTask-info">
                                {JSON.stringify(propertyTaskData, null, 2)}
                            </pre>

                            <div>
                                <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Property Task</Button>
                            </div>

                            {deleteClicked && (
                                <div>
                                    Once you delete this property it will be gone forever. <br />
                                    <Button variant={'danger'} disabled={deletePropertyTaskLoading} onClick={async () => {
                                        await deletePropertyTask(propertyTaskId).unwrap()
                                        dispatch(navigateTo(`/property-tasks/`))
                                    }}> Confirm Delete Property </Button>
                                </div>
                            )}
                        </Stack>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
