import { useSelector } from "react-redux"
import { useAppDispatch } from '../../redux/store'
import './account.scss'
import { accountSelector, setUserInfo } from '../../redux/account/accountSlice'
import { EditProfileForm } from '../../Components/Forms/EditProfileForm/EditProfileForm'
import { ChangePasswordForm } from '../../Components/Forms/ChangePasswordForm/ChangePasswordForm'
import { useState } from "react"
import { Badge, Button, Col, Container, Row, Stack } from "react-bootstrap"
import timeService from "../../services/time/timeService"
import useUploadImage from "../../Hooks/UseUploadImage/useUploadImageHook"
import { useChangePasswordMutation, useUpdateProfileMutation } from "../../services/bClientApi"

export default function AccountPage() {


  const [
    updateProfile,
    {
      error: updateProfileError,
      isLoading: updateProfileLoading
    }
  ] = useUpdateProfileMutation()

  const [
    changePassword,
    {
      error: changePasswordError,
      isLoading: changePasswordLoading
    }
  ] = useChangePasswordMutation()

  const { 
    username, 
    firstName, 
    lastName, 
    profilePic, 
    role,
    lastLogin,
  } = useSelector(accountSelector)
  const dispatch = useAppDispatch()

  const {
    uploadImageFn,
    isLoading: uploadImageLoading,
    // error: uploadImageError,
  } = useUploadImage()

  const [editProfileMode, setEditProfileMode] = useState(false)
  const [editPasswordMode, setEditPasswordMode] = useState(false)
  const [updateCount, setUpdateCount] = useState(0)

  return (
    <Container>
      <Row>
        <Col>
          <h1>Profile Page</h1>
          <hr />
          {updateProfileError && (<>{JSON.stringify(updateProfileError)}<br /></>)}
          {changePasswordError && (<>{JSON.stringify(changePasswordError)}<br /></>)}

          {profilePic && (
            <img alt={'profile pic'} className="profile-pic mb-2" src={profilePic} />
          )}
          <ul>
            <li>Username: {username}</li>
            <li>Name: {firstName} {lastName}</li>
            <li>Role: {role}</li>
            {lastLogin && (
              <li>Last Login: {timeService.getFormattedTime(new Date(lastLogin))}</li>
            )}
            <li>&nbsp;</li>
            <li>TZ: {timeService.liveZone} <small>({timeService.hoursOffset} hours)</small> &nbsp;
              <Badge role="button" bg="secondary" onClick={() => {
                timeService.updateActiveTimeZone(true)
                setUpdateCount(updateCount + 1)
              }}>
                Refresh
              </Badge>
            </li>
          </ul>
          <hr />

          {editProfileMode && (
            <EditProfileForm
              firstName={firstName}
              lastName={lastName}
              profilePic={profilePic}
              disabled={updateProfileLoading || uploadImageLoading}
              onSubmit={async (updatedProfile: { firstName?: string, lastName?: string, profilePic?: string }) => {
                await updateProfile({
                  firstName: updatedProfile.firstName,
                  lastName: updatedProfile.lastName,
                  profilePic: updatedProfile.profilePic
                }).unwrap()
                dispatch(setUserInfo({
                  firstName: updatedProfile.firstName,
                  lastName: updatedProfile.lastName,
                  profilePic: updatedProfile.profilePic
                }))
                setEditProfileMode(false)
                return
              }}
              uploadImageFn={uploadImageFn}
            />
          )}

          {editPasswordMode && (
            <ChangePasswordForm
              onSubmit={async (passwordInfo: { oldPassword: string, newPassword: string }) => {
                await changePassword(passwordInfo).unwrap()
                setEditPasswordMode(false)
              }}
              diabled={changePasswordLoading}
            />
          )}
          <Stack direction={"horizontal"} gap={3}>
            {!editPasswordMode && (
              <Button variant={editProfileMode ? 'danger' : 'primary'} onClick={() => setEditProfileMode(!editProfileMode)}>
                {editProfileMode ? 'Cancel' : 'Edit Profile'}
              </Button>
            )}
            {!editProfileMode && (
              <Button variant={editPasswordMode ? 'danger' : 'primary'} className="change-password-btn" onClick={() => setEditPasswordMode(!editPasswordMode)}>
                {editPasswordMode ? 'Cancel' : 'Change Password'}
              </Button>
            )}
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}
