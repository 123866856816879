import QuickForm from "../QuickForm/QuickForm"
import { PropertyPriceEntryType } from "../../../Types/propertyPriceTypes"

const generateBlankPropertyPrice = () => ({
  propertyId: '',
  price: 0,
  date: null
})

export default function PropertyPriceForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<PropertyPriceEntryType>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
  } = props

  return (
    <div className="property-price-form">
      <QuickForm
        initialData={{
          ...generateBlankPropertyPrice(),
          ...(initialValues ? initialValues : {})
        }}
        disabled={disabled}
        onSubmit={onSubmit}
        fields={[
          {
            fieldName: 'propertyId',
            fieldLabel: 'Property ID',
            fieldType: 'text',
            placeholder: 'Property ID',
          },
          {
            fieldName: 'price',
            fieldLabel: 'Price',
            fieldType: 'number',
            placeholder: 'Price',
          },
          {
            fieldName: 'date',
            fieldLabel: 'Date',
            fieldType: 'date',
            placeholder: 'Date',
          }
        ]}
      />
    </div>
  )
}
