import { useMemo } from "react"
import useBookingsByPropertyMemo from "../../Memos/Bookings/UseBookingsByPropertyMemo"
import timeService, { DATE_FORMAT } from "../../services/time/timeService"
import { PropertyBookingEntry } from "../../Types/bookingTypes"
import { PropertyLinkedPropertyType, PropertyType } from "../../Types/propertyTypes"
import { useGetBookingsQuery } from "../../services/bClientApi"

const generateBlankOccupancyRef = (propertyId: string) => ({
    id: propertyId,
    propertyId: propertyId,
    nextCheckIn: null,
    nextCheckInToday: false,
    checkOutToday: false,
    occupied: false,
})

export default function useOccupancyRef(props: {
    myProperties: any,
}) {

    const { myProperties } = props

    const startOfToday = timeService.getCurrentTime().startOf('day').toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ weeks: 2 }).startOf('day').toMillis()

    const {
        data,
    } = useGetBookingsQuery({
        bookingsQuery: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            propertyId: Object.keys(myProperties || {}),
        },
        pagination: {
            page: 1,
            limit: 500,
            // all: true,
        }
    }, { skip: !myProperties })

    const bookingsList = data?.docs || []

    const bookingsByProperty = useBookingsByPropertyMemo(bookingsList, {
        propertyData: myProperties ? myProperties : {},
        bookingProcessor: (bookingList: any[]) => {
            return bookingList.sort((x: any, y: any) => timeService.dateToMillis(x.checkOut) - timeService.dateToMillis(y.checkOut)).map(
                (x: any, idx: number, a: any[]) => {
                    const b = { ...x, }
                    if (idx < a.length - 1) { b.nextCheckIn = a[idx + 1].checkIn }
                    return b
                }
            )
        }
    })

    const propertyOccupancyRef = useMemo(() => {
        const tempEmptyRightNow: any[] = []
        const tempOccupancyRef: any = {}

        const currentDay = timeService.getFormattedTime(new Date(), DATE_FORMAT)
        const day = timeService.getCurrentTime().toMillis()
        const todayTime = timeService.getCurrentTime().toMillis()

        bookingsByProperty.forEach((b: { propertyId: string, bookings: PropertyBookingEntry[] }) => {
            const { propertyId, bookings } = b
            const pData = generateBlankOccupancyRef(propertyId)
            bookings.some((bData: any) => {
                const checkIn = timeService.dateToMillis(bData.checkIn)
                const checkOut = timeService.dateToMillis(bData.checkOut)
                // checks for active reservation for today
                if (checkIn <= day && day <= checkOut) {
                    // sees if there is a checkout today and mark is as potentially empty today
                    if (timeService.getFormattedTime(bData.checkOut, DATE_FORMAT) === currentDay) {
                        pData.checkOutToday = true
                    }
                }
                // check right now right now
                if (checkIn <= todayTime && todayTime <= checkOut) {
                    pData.occupied = true
                }
                if (day < timeService.dateToMillis(bData.checkIn)) {
                    pData.nextCheckIn = bData.checkIn
                    if (timeService.getFormattedTime(bData.checkIn, DATE_FORMAT) === currentDay) {
                        pData.nextCheckInToday = true
                    }
                    return true
                }
                return false
            })
            tempEmptyRightNow.push(pData)
        })

        tempEmptyRightNow.forEach((p: any) => {
            tempOccupancyRef[p.id] = p
        })

        if (myProperties) {
            // check all the properties that are not in the list
            Object.keys(myProperties).forEach((pId: string) => {
                if (tempOccupancyRef[pId]) { return }
                tempOccupancyRef[pId] = generateBlankOccupancyRef(pId)
                const propertyInfo: PropertyType = myProperties[pId]
                if (propertyInfo && propertyInfo.multiUnit && propertyInfo.multiUnit.isMultiUnit) {
                    propertyInfo.multiUnit.linkedUnits.forEach((linkedPropertyEntry: PropertyLinkedPropertyType) => {
                        const {
                            propertyId,
                            relationship
                        } = linkedPropertyEntry
                        if (relationship === 'child' && tempOccupancyRef[propertyId]) {
                            const {
                                nextCheckIn,
                                nextCheckInToday,
                                occupied,
                            } = tempOccupancyRef[propertyId]
                            if (occupied) {
                                tempOccupancyRef[pId].occupied = true
                            }
                            if (nextCheckInToday) {
                                tempOccupancyRef[pId].nextCheckInToday = true
                            }
                            if (nextCheckIn && (
                                !tempOccupancyRef[pId].nextCheckIn ||
                                timeService.dateToMillis(nextCheckIn) < timeService.dateToMillis(tempOccupancyRef[pId].nextCheckIn)
                            )) {
                                tempOccupancyRef[pId].nextCheckIn = nextCheckIn
                            }
                        }
                    })
                }
            })
        }

        return tempOccupancyRef

    }, [bookingsByProperty, myProperties])

    return propertyOccupancyRef
}