import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteBookingRequestMutation, useGetBookingRequestByIdQuery } from "../../../services/bClientApi"

export default function DeleteBookingRequestPage() {
    const { bookingRequestId = '' } = useParams()
    const dispatch = useAppDispatch()

    const {
        data: bookingRequestData,
        error,
        isLoading,
      } = useGetBookingRequestByIdQuery(bookingRequestId, { skip: !bookingRequestId })

    const [deleteBookingRequest] = useDeleteBookingRequestMutation()

    const [deleteClicked, setDeleteClicked] = useState(false)

    const bookingRequestDeleteFn = async () => {
        if (bookingRequestId) {
            const deleteValue = await deleteBookingRequest(bookingRequestId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/booking-requests/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Booking Request Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    {bookingRequestId && bookingRequestData && (
                        <div className="bookingRequest-info">
                            {JSON.stringify(bookingRequestData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete BookingRequest: {bookingRequestId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete BookingRequest</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this bookingRequest it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={isLoading} onClick={() => {
                                            bookingRequestDeleteFn()
                                        }}> Confirm Delete BookingRequest </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
