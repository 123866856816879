import { PropertyTaskType } from "../../Types/propertyTaskType"

export default function tasksByPropertyHelper(
    propertyTasksList: PropertyTaskType[] | undefined | null,
    myProperties: any
) {
    const tasksByPropertyList: any = {}
    if (propertyTasksList) {
        propertyTasksList.forEach((task: PropertyTaskType) => {
            if (tasksByPropertyList[task.propertyId]) {
                tasksByPropertyList[task.propertyId].push(task)
            } else {
                tasksByPropertyList[task.propertyId] = [task]
            }
        })
    }
    // tasks by property array sorted by property title 
    const tasksByPropertyArray = Object.keys(tasksByPropertyList).map((key: string) => {
        return {
            propertyId: key,
            shortTitle: myProperties[key] ? myProperties[key].title.split(' - ')[0] : key,
            tasks: tasksByPropertyList[key],
        }
    })

    return tasksByPropertyArray.sort((a: any, b: any) => {
        if (!myProperties[a.propertyId] || !myProperties[b.propertyId]) {
            return 0
        }
        return myProperties[a.propertyId].title.localeCompare(myProperties[b.propertyId].title)
    })
}