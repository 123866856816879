import { Badge, Button, Card } from "react-bootstrap";
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters";
import timeService from "../../services/time/timeService";
import { PropertyBookingEntry } from "../../Types/bookingTypes";
import { PropertyType } from "../../Types/propertyTypes";
import { Link } from "react-router-dom";

export default function CleaningEntry(
    props: {
        propertyInfo: PropertyType,
        bookingInfo?: PropertyBookingEntry,
        nextCheckIn?: PropertyBookingEntry,
        // nextCheckIn?: Date,
        nextCheckInToday?: boolean,
        checkedOutToday?: boolean,
        dayRef?: Date | string,
        onEdit?: (b: any) => void
        onCheckedOut?: (b: any) => void
        colorBg?: boolean
        emptyRef?: boolean
    }
) {

    const {
        propertyInfo,
        bookingInfo,
        nextCheckIn,
        nextCheckInToday,
        checkedOutToday,
        dayRef,
        onEdit,
        onCheckedOut,
        colorBg,
        emptyRef
    } = props;

    const occupied = () => {
        const now = timeService.dateToMillis(new Date())
        if (bookingInfo) {
            const checkIn = timeService.dateToMillis(bookingInfo.checkIn)
            const checkOut = timeService.dateToMillis(bookingInfo.checkOut)
            if (now >= checkIn && now <= checkOut) {
                return true
            }
        }
        if (nextCheckIn) {
            const checkIn = timeService.dateToMillis(nextCheckIn.checkIn)
            const checkOut = timeService.createLuxonDate(nextCheckIn.checkIn).endOf('day').toMillis()
            if (now >= checkIn && now <= checkOut) {
                return true
            }
        }

        return !emptyRef //false
    }

    const isOccupied = occupied()

    let nights = 0
    if (bookingInfo) {
        nights = timeService.calculateNights(bookingInfo.checkIn, bookingInfo.checkOut)
    }

    let nextCheckInNights = 0
    if (nextCheckIn) {
        nextCheckInNights = timeService.calculateNights(nextCheckIn.checkIn, nextCheckIn.checkOut)
    }

    return (
        <Card
            className={`cleaning-entry mb-3`}
            style={{
                backgroundColor: colorBg ? isOccupied ? '#ffe9ec' : '#e8fbe8' : 'white',
            }}
        // bg={colorBg ? isOccupied ? 'warning' : 'success' : ''}
        // text={colorBg && !isOccupied ? 'light' : 'dark'}
        >
            <Card.Body>
                <Card.Subtitle className="fw-bold">{propertyInfo.title}</Card.Subtitle>
                {/* <Card.Title>{propertyInfo.title}</Card.Title> */}

                <Card.Link
                    className={`small text-decoration-none`}
                    // className={`small text-decoration-none ${colorBg && !isOccupied ? 'text-light' : 'text-dark'}`}
                    href={`${googleAddress(propertyInfo.address)}`}
                    target="_blank">
                    {formatAddress(propertyInfo.address)}
                </Card.Link>

                <Card.Text>

                    {/* <Badge bg={isOccupied ? 'danger' : 'success'}>
                        {isOccupied ? 'Occupied' : 'Vacant'}
                    </Badge>
                    <br /> */}

                    {dayRef && (
                        <>
                            {checkedOutToday && (
                                <>
                                    <Badge bg='danger'>Checked Out Today</Badge> <br />
                                </>
                            )}

                            {nextCheckInToday && (
                                <>
                                    <Badge bg='danger'>Next Check In Today</Badge> <br />
                                </>
                            )}

                            {nextCheckIn ? (
                                <>
                                    <b>Empty Until:</b> {timeService.getFormattedTime(nextCheckIn.checkIn)} for {Math.floor(timeService.timeDiff(dayRef, nextCheckIn.checkIn))} days <br />
                                </>
                            ) : (
                                <>
                                    <b>Empty Until:</b> Unavailable <br />
                                </>
                            )}
                        </>
                    )}

                    {bookingInfo && (
                        <>
                            <b>Check In: </b> {timeService.getFormattedTime(bookingInfo.checkIn)} &nbsp; {nights > 0 ? (<small>(🌙{nights})&nbsp;</small>) : ''}
                            {timeService.isToday(bookingInfo.checkIn) ? (
                                <Badge bg='danger'>TODAY</Badge>
                            ) : ''} <br />

                            <b>Check Out:</b> {timeService.getFormattedTime(bookingInfo.checkOut)} &nbsp;
                            {timeService.isToday(bookingInfo.checkOut) ? (
                                <Badge bg='danger'>TODAY</Badge>
                            ) : ''} <br />

                        </>
                    )}

                    {nextCheckIn && (
                        <>
                            <b>Next Check In:</b> {nextCheckIn ? (<>
                                {timeService.getFormattedTime(nextCheckIn.checkIn)} &nbsp;
                                {nextCheckInNights > 0 ? (<small>(🌙{nextCheckInNights})</small>) : ''}
                            </>) : 'UNAVAILABLE'} &nbsp;
                            {bookingInfo && timeService.isToday(bookingInfo.checkOut) && (
                                <>
                                    {timeService.sameDay(bookingInfo.checkOut, nextCheckIn.checkIn) ? (
                                        <Badge bg='danger'>SAME DAY</Badge>
                                    ) : ''}
                                </>
                            )} <br />
                        </>
                    )}


                </Card.Text>
            </Card.Body>

            <Card.Footer className="button-box">
                {bookingInfo && (
                    <>
                        <div className="mt-2">
                            {onEdit && (
                                <Button
                                    className="me-2 mb-2"
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                        onEdit(bookingInfo)
                                    }}
                                >Edit</Button>
                            )}

                            {onCheckedOut && (
                                <Button
                                    className="me-2 mb-2"
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => {
                                        onCheckedOut(bookingInfo)
                                    }}
                                >Checked Out</Button>
                            )}
                        </div>
                    </>
                )}
                {!bookingInfo && (
                    <Link className="btn btn-sm btn-warning" to={`/inventory-check/${propertyInfo._id}`}>
                        Inventory
                    </Link>
                )}
            </Card.Footer>
        </Card>
    )
}