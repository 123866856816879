import { useState } from "react"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import { useParams } from "react-router"
import { navigateTo } from "../../../redux/router/routerActions"
import { useAppDispatch } from "../../../redux/store"
import { useDeleteInquiryMutation, useGetInquiryByIdQuery } from "../../../services/bClientApi"

export default function DeleteInquiryPage() {

    const [deleteInquiry,
        {
            isLoading: deleteInquiryLoading,
            error: deleteInquiryError,
        }
    ] = useDeleteInquiryMutation()
    const { inquiryId = '' } = useParams()
    const dispatch = useAppDispatch()
    const [deleteClicked, setDeleteClicked] = useState(false)

    const {
        data,
        error,
        isLoading
      } = useGetInquiryByIdQuery(inquiryId, { skip: !inquiryId || deleteClicked })
      const inquiryData = data || {}


    const inquiryDeleteFn = async () => {
        if (inquiryId) {
            const deleteValue = await deleteInquiry(inquiryId).unwrap()
            if (deleteValue) {
                dispatch(navigateTo(`/inquiries/`))
            }
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Delete Inquiry Page</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {deleteInquiryError && (<>{JSON.stringify(deleteInquiryError)}<br /></>)}

                    {inquiryId && inquiryData && !isLoading && (
                        <div className="inquiry-info">
                            {JSON.stringify(inquiryData)}

                            <Stack gap={3}>
                                <div>Are you sure you want to delete Inquiry: {inquiryId}</div>
                                <div>
                                    <Button variant={'warning'} onClick={() => setDeleteClicked(true)}>Delete Inquiry</Button>
                                </div>
                                {deleteClicked && (
                                    <div>
                                        Once you delete this inquiry it will be gone forever. <br />
                                        <Button variant={'danger'} disabled={deleteInquiryLoading} onClick={() => {
                                            inquiryDeleteFn()
                                        }}> Confirm Delete Inquiry </Button>
                                    </div>
                                )}
                            </Stack>

                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
