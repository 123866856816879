import { Col, Container, Row } from "react-bootstrap"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import PurchaseEntry from "../../Components/Purchases/PurchaseEntry"
import { useGetPurchasesQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"
import { PurchaseEntryType } from "../../Types/purchaseTypes"

export default function PurchasesPage() {

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        data,
        error,
        isLoading,
    } = useGetPurchasesQuery({
        purchasesQuery: activeQuery.query,
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
        }
    })
    const purchasesList = data?.docs || []

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/purchases?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Purchases</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        layout={{xs: 2}}
                        initialData={{
                            firstName: activeQuery.query.firstName,
                            lastName: activeQuery.query.lastName,
                            email: activeQuery.query.email,
                            phoneNumber: activeQuery.query.phoneNumber,
                            referenceNumber: activeQuery.query.referenceNumber,
                            transactionId: activeQuery.query.transactionId,
                            paymentIntent: activeQuery.query.paymentIntent,
                            paymentStatus: activeQuery.query.paymentStatus,
                            sessionStatus: activeQuery.query.sessionStatus,
                            dateCreatedStart: activeQuery.query.dateCreatedStart,
                            dateCreatedEnd: activeQuery.query.dateCreatedEnd,
                        }}
                        fields={[
                            {
                                fieldName: 'firstName',
                                fieldLabel: 'First Name',
                                fieldType: 'text',
                                placeholder: 'firstName',
                            },
                            {
                                fieldName: 'lastName',
                                fieldLabel: 'Last Name',
                                fieldType: 'text',
                                placeholder: 'lastName',
                            },
                            {
                                fieldName: 'email',
                                fieldLabel: 'Email',
                                fieldType: 'text',
                                placeholder: 'email',
                            },
                            {
                                fieldName: 'phoneNumber',
                                fieldLabel: 'Phone Number',
                                fieldType: 'text',
                                placeholder: 'phoneNumber',
                            },
                            {
                                fieldName: 'referenceNumber',
                                fieldLabel: 'Reference Number',
                                fieldType: 'text',
                                placeholder: 'referenceNumber',
                            },
                            {
                                fieldName: 'transactionId',
                                fieldLabel: 'Transaction Id',
                                fieldType: 'text',
                                placeholder: 'transactionId',
                            },
                            {
                                fieldName: 'paymentIntent',
                                fieldLabel: 'Payment Intent',
                                fieldType: 'text',
                                placeholder: 'paymentIntent',
                                layout: {
                                    xs: 12,
                                    md: 4,
                                }
                            },
                            {
                                fieldName: 'paymentStatus',
                                fieldLabel: 'Payment Status',
                                fieldType: 'text',
                                placeholder: 'paymentStatus',
                                layout: {
                                    xs: 12,
                                    md: 4,
                                }
                            },
                            {
                                fieldName: 'sessionStatus',
                                fieldLabel: 'Session Status',
                                fieldType: 'text',
                                placeholder: 'sessionStatus',
                                layout: {
                                    xs: 12,
                                    md: 4,
                                }
                            },
                            {
                                fieldName: 'dateCreatedStart',
                                fieldLabel: 'Date Created Start',
                                fieldType: 'date',
                                placeholder: 'dateCreatedStart',
                                layout: {
                                    xs: 12,
                                    md: 6,
                                }
                            },
                            {
                                fieldName: 'dateCreatedEnd',
                                fieldLabel: 'Date Created End',
                                fieldType: 'date',
                                placeholder: 'dateCreatedEnd',
                                layout: {
                                    xs: 12,
                                    md: 6,
                                }
                            },
                        ]}
                        disabled={isLoading}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: activeQuery.limit
                            })
                        }}
                    />

                    <div className="purchase-list-container">
                        {purchasesList && purchasesList.length > 0 && purchasesList.map((purchaseEntry: PurchaseEntryType, idx: number) => (
                            <PurchaseEntry
                                key={idx}
                                purchaseData={purchaseEntry}
                            />
                        ))
                        }
                    </div>

                        <MongoPagination
                            data={data}
                            loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                                loadPage({
                                    query: activeQuery.query,
                                    limit: activeQuery.limit,
                                    ...pageLoadInfo
                                })
                            }}
                        />
                </Col>
            </Row>
        </Container>
    )
}
