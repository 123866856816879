import { useState } from "react"
import { BookingGuestEntryType } from "../../../Types/bookingGuestTypes"
import { Form, Formik } from "formik"
import { TextInputField } from "../../FormFields/TextInput/TextInputField"
import { DateInputField } from "../../FormFields/DateInput/DateInputField"
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField"
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput"
import { Button } from "react-bootstrap"
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload"
import * as Yup from 'yup'

const bookingGuestSchema = Yup.object().shape({
  created: Yup.date().required('Required'),
  propertyId: Yup.string().required('Required'),
  bookingId: Yup.string().required('Required'),
  main: Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().required('Required'),
    idImage: Yup.string().required('Required'),
  }),
  guests: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      idImage: Yup.string().required('Required'),
    })
  ),
  documents: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      url: Yup.string().required('Required'),
    }),
  ),
  arrivalTime: Yup.date().required('Required'),
  status: Yup.string().required('Required'),
})

const generateBlankBookingGuest = () => ({
  created: new Date(),
  bookingId: '',
  propertyId: '',
  main: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    idImage: '',
  },
  guests: [],
  documents: [],
  arrivalTime: null,
  status: 'new',
  mailSent: null
})

export default function BookingGuestForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  uploadImageFn: (v: any) => Promise<string>,
  uploadImageMultiFn: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
  propertyOptionList: { value: string, text: string }[],
  imageUploadProgress: string,
  initialValues?: Partial<BookingGuestEntryType>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    uploadImageFn,
    uploadImageMultiFn,
    imageUploadProgress,
    propertyOptionList,
  } = props

  const [uploadingImage, setUploadingImage] = useState(false)

  return (
    <div className="booking-guest-form">
      <Formik
        initialValues={{
          ...generateBlankBookingGuest(),
          ...(initialValues ? initialValues : {})
        }}
        validationSchema={bookingGuestSchema}
        // validate={(values) => { const errors: any = {}; return errors; }}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values)
          // try {
          //   const submissionValues: any = { ...values }
          //   delete submissionValues.tempTag
          //   const submissionResponse = await onSubmit(submissionValues)
          //   if (!submissionResponse.error) {
          //     return
          //   }
          // } catch (err) {
          //   // custom error handler
          // }
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            {/* <TextInputField
              fieldLabel={'Property ID'}
              fieldName={'propertyId'}
              placeholder={'Property ID'}
              required={true}
            /> */}
            <SelectInputField
              fieldLabel={'Property Id'}
              placeholder={'- Select Property -'}
              required={true}
              fieldName={`propertyId`}
              options={propertyOptionList}
              disabled
            />
            <TextInputField
              fieldLabel={'Booking ID'}
              fieldName={'bookingId'}
              placeholder={'Booking ID'}
              required={true}
              disabled
            />
            <DateInputField
              fieldLabel={'Arrival Time'}
              fieldName={'arrivalTime'}
              placeholder={'Arrival Time'}
              required={true}
            />
            <DateInputField
              fieldLabel={'Created'}
              fieldName={'created'}
              placeholder={'Created'}
              required={true}
            />
            <SelectInputField
              fieldLabel={'Status'}
              fieldName={'status'}
              placeholder={'Status'}
              required={true}
              options={[
                { value: 'new', text: 'New' },
                { value: 'pending', text: 'Pending' },
                { value: 'confirmed', text: 'Confirmed' },
              ]}
            />

            <DateInputField
              fieldLabel={'Mail Sent'}
              fieldName={'mailSent'}
              placeholder={'Mail Sent'}
            />

            <hr />

            <h3>Main Guest</h3>

            <TextInputField
              fieldLabel={'First Name'}
              fieldName={'main.firstName'}
              placeholder={'First Name'}
              required={true}
            />
            <TextInputField
              fieldLabel={'Last Name'}
              fieldName={'main.lastName'}
              placeholder={'Last Name'}
              required={true}
            />
            <TextInputField
              fieldLabel={'Email'}
              fieldName={'main.email'}
              placeholder={'Email'}
              required={true}
            />
            <TextInputField
              fieldLabel={'Phone'}
              fieldName={'main.phone'}
              placeholder={'Phone'}
              required={true}
            />

            {values?.main?.idImage && (
              <div className="image-preview">
                <img src={values.main.idImage} alt="preview" />
              </div>
            )}

            <ImageUploadField
              key={'taskPictureUpload'}
              uploadedImageCallback={async (imageInfo: any) => {
                setUploadingImage(true)
                try {
                  const uploadedImageUrl = await uploadImageFn(imageInfo)
                  if (uploadedImageUrl) {
                    setFieldValue('main.idImage', uploadedImageUrl)
                  }
                } catch (err) {
                  // something went wrong
                }
                setUploadingImage(false)
              }}
            />

            <TextInputField
              fieldLabel={'idImage'}
              fieldName={'main.idImage'}
              placeholder={'idImage'}
              required={true}
            />

            <hr />

            <h3>Guests</h3>

            {imageUploadProgress && (<>{imageUploadProgress} <br /></>)}
            <ImageUploadField
              key={'otherIdUpload'}
              multipleImages={true}
              uploadedImageCallback={async (imagesInfo: any[]) => {
                setUploadingImage(true)
                try {
                  const uploadedImageList = await uploadImageMultiFn(imagesInfo)
                  if (uploadedImageList) {
                    const mappedUploadedImages = uploadedImageList.map((imgUrl: string) => {
                      return {
                        idImage: imgUrl,
                        firstName: '',
                        lastName: '',
                      }
                    })
                    setFieldValue('guests', [...values.guests, ...mappedUploadedImages])
                  }
                } catch (err) {
                  // something went wrong
                }
                setUploadingImage(false)
              }}
            />

            <ArrayFieldInput
              fieldName={'guests'}
              values={values.guests}
              setFieldValue={setFieldValue}
              errors={errors}
              customClass={'image-gallery-drag-container'}
              blankEntryFn={() => ({
                firstName: '',
                lastName: '',
                idImage: '',
              })}
              imgPreview={{
                srcVal: 'idImage'
              }}
              fields={[
                {
                  fieldLabel: 'First Name',
                  fieldName: 'firstName',
                  placeholder: 'First Name',
                  fieldType: 'text',
                  required: true,
                },
                {
                  fieldLabel: 'Last Name',
                  fieldName: 'lastName',
                  placeholder: 'Last Name',
                  fieldType: 'text',
                  required: true,
                },
                {
                  fieldLabel: 'idImage',
                  fieldName: 'idImage',
                  placeholder: 'idImage',
                  fieldType: 'text',
                  required: true,
                }
              ]}
            />

            <hr />

            <h3>Documents</h3>

            <ArrayFieldInput
              fieldName={'documents'}
              values={values.documents}
              setFieldValue={setFieldValue}
              errors={errors}
              customClass={''}
              blankEntryFn={() => ({
                name: '',
                url: '',
              })}
              fields={[
                {
                  fieldLabel: 'Document Name',
                  fieldName: 'name',
                  placeholder: 'Document Name',
                  fieldType: 'text',
                  required: true,
                },
                {
                  fieldLabel: 'Url',
                  fieldName: 'url',
                  placeholder: 'Document URL',
                  fieldType: 'text',
                  required: true,
                },
              ]}
            />

            <hr />

            Errors: {JSON.stringify(errors)}
            <br />
            {uploadingImage && (<><br />Uploading image please wait...</>)}

            <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled || uploadingImage}>
              Submit
            </Button>

          </Form>
        )}
      </Formik>
    </div>
  )
}
