import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropertyTaskForm from "../../../Components/Forms/PropertyTaskForm/PropertyTaskForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import useUsersSelectValues from "../../../Hooks/UseUsersQuery/UseUsersSelectValues";
import { accountSelector } from "../../../redux/account/accountSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditPropertyTaskMutation, useGetPropertyTaskByIdQuery } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function EditPropertyTaskPage() {

    const [
        editPropertyTask,
        {
            isLoading: editPropertyTaskLoading,
            error: editPropertyTaskError
        }
    ] = useEditPropertyTaskMutation()

    const userRef = useUserRefs()
    const userSelectOptions = useUsersSelectValues(userRef)

    const { propertyTaskId = '' } = useParams()

    const {
        data,
        error,
        isLoading
    } = useGetPropertyTaskByIdQuery(propertyTaskId, { skip: !propertyTaskId })
    const propertyTaskData = data || {}

    const { myProperties } = useMyProperties()
    const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)
    const { email, role } = useSelector(accountSelector)

    const {
        uploadImageFn,
        imageUploadProgress,
        uploadImageMultiFn,
        // isLoading: uploadImageLoading,
        error: uploadImageError,
      } = useUploadImage()

    const dispatch = useAppDispatch()


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Edit Property Task</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {editPropertyTaskError && (<>{JSON.stringify(editPropertyTaskError)}<br /></>)}
                    {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}

                    {email && role && propertyTaskData && !isLoading && (
                        <PropertyTaskForm
                            initialValues={propertyTaskData}
                            disabled={editPropertyTaskLoading}
                            userSelectOptions={userSelectOptions}
                            onSubmit={async (values) => {
                                const editedTask = await editPropertyTask({ propertyTaskId, propertyTaskInfo: values }).unwrap()
                                if (editedTask._id) {
                                    dispatch(navigateTo(`/property-task/${editedTask._id}`))
                                }
                                return editedTask

                            }}
                            propertyOptionList={myPropertySelectOptions}
                            uploadImageFn={uploadImageFn}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={uploadImageMultiFn}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}