import { Col, Container, Row } from "react-bootstrap";
import BuildingForm from "../../../Components/Forms/BuildingForm/BuildingForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewBuildingMutation } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function AddBuildingPage() {

  const dispatch = useAppDispatch();

  const {
    uploadImageFn,
    imageUploadProgress,
    uploadImageMultiFn,
    // isLoading: uploadImageLoading,
    error: uploadImageError,
  } = useUploadImage()

  const [
    addNewBuilding,
    {
      error,
      isLoading,
    }
  ] = useAddNewBuildingMutation();

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    };
    const newBuilding = await addNewBuilding(values).unwrap();
    if (newBuilding._id) {
      dispatch(navigateTo(`/building/${newBuilding._id}`));
    }
    return newBuilding;
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Building</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}

          <BuildingForm
            initialValues={{}}
            disabled={isLoading}
            onSubmit={handleSubmit}
            uploadImageFn={uploadImageFn}
            imageUploadProgress={imageUploadProgress}
            uploadImageMultiFn={uploadImageMultiFn}
          />

        </Col>
      </Row>
    </Container>
  )
}
