import { Card } from "react-bootstrap"
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters"
import './propertyEntry.scss'
import timeService from "../../services/time/timeService"
import AccessLinkButton from "../Buttons/AccessLinkButton"
import { PropertyType } from "../../Types/propertyTypes"
import { API_URL, FRONTEND_URL } from "../../Config/config"
import canAccess from "../../services/accessService/accessService"

export const PropertyEntry = (props: any) => {
  const {
    propertyInfo,
    details = false,
    disableLinks = {}
  } = props

  const {
    _id,
    title,
    address,
  } = propertyInfo

  const startOfToday = timeService.createLuxonDate(new Date()).startOf('day').toMillis()

  return (
    <Card className="property-entry">
      {/* <Card.Header>
        <b>{title}</b>
      </Card.Header> */}
      <Card.Body>
        <Card.Title>
          <b>{title}</b>
        </Card.Title>
        {address && (
          <Card.Link
            className={`small text-decoration-none`}
            href={`${googleAddress(address)}`}
            target="_blank">
            {formatAddress(address)}
          </Card.Link>
        )}

        {details && (
          <PropertyInfo {...propertyInfo} />
        )}


      </Card.Body>
      <Card.Footer>
        {!disableLinks['view'] && (
          <AccessLinkButton
            action={'property'}
            text={'View'}
            size={'sm'}
            to={`/property/${_id}`}
          />
        )}

        <AccessLinkButton
          action={'ical-data'}
          color={'success'}
          size={'sm'}
          text={'Ical'}
          to={`/property/ical-data/${_id}`}
        />

        <AccessLinkButton
          action={'financials-property'}
          color={'success'}
          size={'sm'}
          text={'Financials'}
          to={`/financials-property/${_id}`}
        />

        {/* handle property prices link */}
        <AccessLinkButton
          action={'handle-property-prices'}
          color={'success'}
          size={'sm'}
          text={'Pricing'}
          to={`/handle-property-prices/${_id}`}
        />

        <AccessLinkButton
          action={'property-inventory'}
          color={'secondary'}
          size={'sm'}
          text={'Inventory'}
          to={`/inventory-check/${_id}`}
        />

        <AccessLinkButton
          action={'property-bookings'}
          color={'secondary'}
          size={'sm'}
          text={'Bookings'}
          to={`/bookings?query={"propertyId":"${_id}","checkIn":${startOfToday},"bookingStatus":["new","pending","confirmed"]}`}
        />

        <AccessLinkButton
          action={'property-tasks'}
          color={'secondary'}
          size={'sm'}
          text={'Tasks'}
          to={`/property-tasks?query={"propertyId":"${_id}"}`}
        />

        {/* add booking link */}
        <AccessLinkButton
          action={'add-booking'}
          color={'dark'}
          size={'sm'}
          text={'Booking⚡'}
          to={`/add-booking?query={"propertyId":"${_id}", "quickBooking":1}`}
        />

        <AccessLinkButton
          action={'full-add-booking'}
          color={'dark'}
          size={'sm'}
          text={'+ Booking'}
          to={`/add-booking?query={"propertyId":"${_id}"}`}
        />

        {/* add task link */}
        <AccessLinkButton
          action={'add-task'}
          color={'dark'}
          size={'sm'}
          text={'+ Task'}
          to={`/add-property-task?query={"propertyId":"${_id}"}`}
        />

        <AccessLinkButton
          action={'date-blocks'}
          color={'warning'}
          size={'sm'}
          text={'Blocks'}
          to={`/date-blocks?query={"propertyId":"${_id}", "checkIn":${startOfToday}}`}
        />

        <AccessLinkButton
          action={'edit-property'}
          color={'secondary'}
          size={'sm'}
          text={'Edit'}
          to={`/edit-property/${_id}`}
        />

        {/* <AccessLinkButton
          action={'delete-property'}
          color={'danger'}
          size={'sm'}
          text={'Delete'}
          to={`/delete-property/${_id}`}
        /> */}

      </Card.Footer>
    </Card>
  )
}


export const PropertyInfo = (props: PropertyType) => {
  const { _id, created, title, description, featuredImage, multiUnit, listed, linkedServices } = props

  let remoteLockPayload: {
    deviceId?: string,
    deviceName?: string,
    deviceType?: string,
  } = {}

  if (linkedServices && linkedServices.length > 0) {
    const remoteLock = linkedServices.find((service: any) => service.linkedService === 'remoteLock')
    if (remoteLock) {
      remoteLockPayload = JSON.parse(remoteLock.linkedServiceId)
      if (!remoteLockPayload.deviceType) {
        remoteLockPayload.deviceType = 'locks'
      }
    }
  }

  return (
    <div>
      <hr />

      {/* <h2>{title}</h2> */}

      <p className="fst-italic text-break">
        {description}
      </p>


      Property ID: {_id}
      {created && (<>
        <br />
        Created: {timeService.getFormattedTime(new Date(created))}
      </>)}
      <br />
      Web Link: &nbsp;
      <a target="_blank" href={`${FRONTEND_URL}/property/${_id}`} rel="noreferrer">
        {`${FRONTEND_URL}/property/${_id}`}
      </a>

      <br /><br />

      {listed && listed.length > 0 && (
        <div>
          <b>Online Listings</b>
          <ul>
            {listed.map((listing, i) => {

              let editLink = ''

              if (listing.source === 'airbnb' && canAccess('property', 'edit-airbnb')) {
                const airbnbId = listing.url.split('/').pop()
                editLink = `https://www.airbnb.com/hosting/listings/editor/${airbnbId}`
              }

              return (
                <li key={i}>
                  {listing.source}:  <a target="_blank" href={listing.url} rel="noreferrer">
                    {listing.url}
                  </a>&nbsp;
                  {editLink && (
                    <a className="text-decoration-none" target="_blank" href={editLink} rel="noreferrer" title="Edit Listing">
                      ✏️
                    </a>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )}

      {remoteLockPayload.deviceId && (
        <div>
          <b>Remote Lock</b> <br />
          <ul>
            <li>
              Device ID: <a target="_blank" href={`https://connect.remotelock.com/devices/${remoteLockPayload.deviceType}/${remoteLockPayload.deviceId}`} rel="noreferrer">
                {remoteLockPayload.deviceId}
              </a>
            </li>
            <li>
              Device Name: {remoteLockPayload.deviceName}
            </li>
            <li>
              Device Type: {remoteLockPayload.deviceType}
            </li>
          </ul>
        </div>
      )}

      <b>Icalendars</b>
      <br />
      Ical Link: &nbsp;
      <a target="_blank" href={`${API_URL}/icalendar/property/${_id}.ics`} rel="noreferrer">
        {`${API_URL}/icalendar/property/${_id}.ics`}
      </a>
      <br />

      {multiUnit?.isMultiUnit && (
        <>
          MULTI Ical Link: &nbsp;
          <a target="_blank" href={`${API_URL}/icalendar/property-multi/${_id}.ics`} rel="noreferrer">
            {`${API_URL}/icalendar/property-multi/${_id}.ics`}
          </a>
          <br />
        </>
      )}

      <br />
      <img className="property-img" src={featuredImage} alt={title} />
      <br />
    </div>
  )
}
