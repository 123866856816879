import { DateBlockEntryType } from "../../../Types/dateBlockTypes"
import { Form, Formik } from "formik"
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField"
import timeService from "../../../services/time/timeService"
import { DateRangeInputField } from "../../FormFields/DateInput/DateRangeInputField"
import { DateInputField } from "../../FormFields/DateInput/DateInputField"
import { TextInputField } from "../../FormFields/TextInput/TextInputField"
import QuickTag from "../../FormFields/QuickTag/QuickTag"
import { Alert, Button, Col, Row } from "react-bootstrap"

const generateBlankDateBlock = () => ({
  created: new Date(),
  checkIn: null,
  checkOut: null,
  firstName: '',
  lastName: '',
  propertyId: '',
  confirmationCode: '',
  source: '',
  status: '',
  tags: [],

  // temp tag
  tempTag: '',
})

export default function DateBlockForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<DateBlockEntryType>,
  propertyOptionList: { value: string, text: string }[],
  propertyData: { [pId: string]: any },
  showFields?: { [key: string]: boolean },
  forceTimes?: boolean,
  dateValidationFn?: (propertyId: string, checkIn: Date, checkOut: Date) => void,
  dateValidationStatus?: { available: boolean, message: string, status: string },
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
    propertyOptionList,
    // propertyData,
    showFields,
    forceTimes = true,
    dateValidationFn,
    dateValidationStatus,
  } = props

  const handleInitialValues = (blockData: Partial<DateBlockEntryType>) => {
    return blockData
  }

  const checkDateRange = async (propertyId: string, checkIn: Date, checkOut: Date) => {
    if (dateValidationFn && propertyId && checkIn && checkOut) {
      dateValidationFn(propertyId, checkIn, checkOut)
    }
  }

  return (
    <div className="date-block-form">
      <Formik
        initialValues={{
          ...generateBlankDateBlock(),
          ...(initialValues ? handleInitialValues(initialValues) : {})
        }}
        // validationSchema={bookingSchema}
        // validate={values => { const errors: any = {}; return errors; }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionValues: any = { ...values }
            delete submissionValues.tempTag

            if (forceTimes) {
              submissionValues.checkIn = timeService.setDateTime(submissionValues.checkIn, { hour: 16 })
              submissionValues.checkOut = timeService.setDateTime(submissionValues.checkOut, { hour: 11 })
            }

            const submissionResponse = await onSubmit(submissionValues)
            if (!submissionResponse.error) {
              return
            }
          } catch (err) {
            // custom error handler
            setSubmitting(false)
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              {(!showFields || showFields.dateRange) && (
                <DateRangeInputField
                  fieldName={'dateRange'}
                  fieldLabel={'Date Range'}
                  startDate={values.checkIn}
                  placeholder={'mm/dd/yyyy - mm/dd/yyyy'}
                  endDate={values.checkOut}
                  onChange={(dates: [Date | null, Date | null]) => {
                    const checkInVal = timeService.setDateTime(dates[0], { hour: 16 })
                    const checkOutVal = timeService.setDateTime(dates[1], { hour: 11 })
                    setFieldValue('checkIn', checkInVal)
                    setFieldValue('checkOut', checkOutVal)
                    if (checkInVal && checkOutVal) {
                      checkDateRange(values.propertyId, checkInVal, checkOutVal)
                    }
                  }} />
              )}

              {(!showFields || showFields.checkIn || showFields.checkOut) && (
                <Row>
                  <Col xs={12} md={6}>
                    <DateInputField
                      fieldLabel={'Check In'}
                      placeholder={'mm/dd/yyyy'}
                      maxDate={values.checkOut}
                      fieldName={'checkIn'}
                      onChange={(date: Date) => {
                        if (values.checkIn && values.checkOut) {
                          checkDateRange(values.propertyId, values.checkIn, values.checkOut)
                        }
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <DateInputField
                      fieldLabel={'Check Out'}
                      placeholder={'mm/dd/yyyy'}
                      minDate={values.checkIn}
                      fieldName={'checkOut'}
                      onChange={(date: Date) => {
                        if (values.checkIn && values.checkOut) {
                          checkDateRange(values.propertyId, values.checkIn, values.checkOut)
                        }
                      }}
                    />
                  </Col>
                </Row>
              )}

              {dateValidationStatus && dateValidationStatus.status && values.checkIn && values.checkOut && values.propertyId && (
                <Alert variant={dateValidationStatus.status}>
                  {dateValidationStatus.message}
                </Alert>
              )}

              {(!showFields || showFields.propertyId) && (
                <SelectInputField
                  fieldLabel={'Property Id'}
                  placeholder={'- Select Property -'}
                  required={true}
                  options={propertyOptionList}
                  fieldName={'propertyId'} />
              )}

              {(!showFields || showFields.firstName) && (
                <TextInputField
                  fieldLabel={'First Name'}
                  placeholder={'First Name'}
                  fieldName={'firstName'} />
              )}

              {(!showFields || showFields.lastName) && (
                <TextInputField
                  fieldLabel={'Last Name'}
                  placeholder={'Last Name'}
                  fieldName={'lastName'} />
              )}

              {(!showFields || showFields.source) && (
                <SelectInputField
                  fieldLabel={'Source'}
                  placeholder={'- Select -'}
                  options={[
                    { text: 'Owner', value: 'owner' },
                    { text: 'Hotel', value: 'hotel' }
                  ]}
                  fieldName={'source'} />
              )}


              {(!showFields || showFields.status) && (
                <SelectInputField
                  fieldLabel={'Status'}
                  placeholder={'- Select -'}
                  options={[
                    { text: 'New', value: 'new' },
                    { text: 'Pending', value: 'pending' },
                    { text: 'Confirmed', value: 'confirmed' },
                    { text: 'Canceled', value: 'canceled' },
                  ]}
                  fieldName={'status'} />
              )}

              {(!showFields || showFields.confirmationCode) && (
                <TextInputField
                  fieldLabel={'Confirmation Code'}
                  placeholder={'confirmationCode'}
                  fieldName={'confirmationCode'} />
              )}

              {(!showFields || showFields.tags) && (
                <QuickTag
                  tags={values.tags}
                  tempTag={values.tempTag}
                  tagFieldName={'tags'}
                  tempTagFieldName={'tempTag'}
                  tempTagFieldLabel={'Quick Tag'}
                  setFieldValue={setFieldValue}
                  buttons={[
                    { tag: 'blackout', text: 'Blackout' },
                    { tag: 'maintenance', text: 'Maintenance' },
                    { tag: 'ownerStay', text: 'Owner Stay' },
                  ]}
                />
              )}

              <hr />

              {Object.keys(errors).length > 0 && (
                <>
                  Errors: {JSON.stringify(errors)}
                  <br />
                </>
              )}
              <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled}>
                Submit
              </Button>


            </Form>
          )
        }}
      </Formik>



    </div>
  )
}
