import { Col, Container, Row } from "react-bootstrap";
import DateBlockForm from "../../../Components/Forms/DateBlockForm/DateBlockForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useActiveQueryHook from "../../../Hooks/UseActiveQuery/useActiveQueryHook";
import { useState } from "react";
import timeService from "../../../services/time/timeService";
import { useAddNewDateBlockMutation, useLazyGetDateBlocksQuery } from "../../../services/bClientApi";

export default function AddDateBlockPage() {

  const [checkDateBlockDates] = useLazyGetDateBlocksQuery()

  const {
    myProperties,
  } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { activeQuery } = useActiveQueryHook({})
  const initialData = activeQuery.query

  const [
    addNewDateBlock,
    {
      error,
      isLoading,
    }
  ] = useAddNewDateBlockMutation()

  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newDateBlock = await addNewDateBlock(values).unwrap()
    console.log('newDateBlock', newDateBlock)
    if (newDateBlock._id && newDateBlock.propertyId) {
      console.log('navigating')
      const newBlockCheckIn = timeService.dateToMillis(newDateBlock.checkIn)
      dispatch(navigateTo(`/date-blocks?query={"status":["new","pending","confirmed"], "propertyId": "${newDateBlock.propertyId}", "checkIn": ${newBlockCheckIn}}`))
      // dispatch(navigateTo(`/date-block/${newDateBlock._id}`))
    }
    return newDateBlock
  }

  const [dateValidationStatus, setDateValidationStatus] = useState({
    available: true,
    status: '',
    message: ''
  })

  const validateDate = async (selectedPropertyId: string, checkIn: Date, checkOut: Date) => {
    setDateValidationStatus({
      available: false,
      status: 'warning',
      message: 'Checking date range for property'
    })
    const propertyId = [selectedPropertyId]
    // if (myProperties[selectedPropertyId]) {
    //   const {
    //     multiUnit: {
    //       isMultiUnit,
    //       linkedUnits,
    //     }
    //   } = myProperties[selectedPropertyId]
    //   if (isMultiUnit) {
    //     linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
    //       propertyId.push(unit.propertyId)
    //     })
    //   }
    // }

    const blockData = await checkDateBlockDates({
      dateBlocksQuery: {
        propertyId,
        checkIn: timeService.dateToMillis(checkIn),
        checkOut: timeService.dateToMillis(checkOut),
        status: ['new', 'confirmed', 'pending']
      },
      pagination: {
        limit: 1,
        page: 1
      }
    }).unwrap()

    if (blockData && blockData.docs && blockData.docs.length > 0) {
      setDateValidationStatus({
        available: false,
        status: 'danger',
        message: 'WARNING: selected date range is unavailable for this property'
      })
      return
    }
    setDateValidationStatus({
      available: true,
      status: 'success',
      message: 'This property is available for  the date range'
    })
    return
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add DateBlock</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <DateBlockForm
            initialValues={{
              propertyId: '',
              ...initialData
            }}
            disabled={isLoading}
            onSubmit={handleSubmit}
            propertyOptionList={propertyOptions}
            propertyData={myProperties}
            dateValidationStatus={dateValidationStatus}
            dateValidationFn={validateDate}
          />

        </Col>
      </Row>
    </Container>
  )
}
