import { ActiveQueryType } from "../../Types/queryTypes"

export const getActiveQueryFromUrl = (locationSearch: string, defaultActiveQuery: any = {}) => {
  const baseQuery = {
    query: {},
    limit: 10,
    page: 1,
    ...defaultActiveQuery
  }
  const searchParams = new URLSearchParams(locationSearch)
  const page = searchParams.get('page')
  const limit = searchParams.get('limit')
  const query = searchParams.get('query')

  if (page) {
    baseQuery.page = parseInt(page)
  }
  if (limit) {
    baseQuery.limit = parseInt(limit)
  }
  if (query) {
    baseQuery.query = JSON.parse(decodeURIComponent(query))
  }
  return baseQuery
}

export const convertActiveQueryToUrl = (activeQuery: ActiveQueryType) => {
  return `page=${activeQuery.page}&limit=${activeQuery.limit}&query=${encodeURIComponent(JSON.stringify(activeQuery.query))}`
}
