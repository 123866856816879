import { PropertyLinkedPropertyType, PropertyType } from "../../Types/propertyTypes"

export const hasChildren = (
    mainPropertyData: PropertyType | null
) => {
    if (mainPropertyData) {
        const {
            multiUnit: {
                isMultiUnit,
                linkedUnits,
            }
        } = mainPropertyData
        if (isMultiUnit) {
            const parentProperty = linkedUnits.find((unit: PropertyLinkedPropertyType) => {
                return unit.relationship === 'child'
            })
            if (parentProperty) {
                return true
            }
        }
    }
    return false
}