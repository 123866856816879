import { useMemo } from "react"
import { currencyFormatter } from "../../Helpers/Parsers/currencyFormatter"
import { expenseMultiplier, nightRateMultiplier } from "./DealConstants"

export const DealFinancials = (props: { dealInfo: any }) => {
    const { dealInfo } = props
    const {
        // name,
        // location,
        nightlyRate,
        occupancy,
        rent,
        electricity,
        maintenance,
        garbage,
        water,
        internet,
        repairs,
        association,
        propertyTax,
        insurance,
        purchasePrice,
        downPayment,
        loanAmount,
        interestRate,
        splitRatePercentage,
    } = dealInfo

    const downPaymentAmount = purchasePrice * (downPayment / 100)
    const loanAmountAmount = purchasePrice - downPaymentAmount

    const loanPaybackTable = useMemo(() => {
        const loanTable: any = []

        const totalIncome = (nightlyRate * nightRateMultiplier['monthly']) * occupancy / 100
        const totalVariableCosts = (rent + electricity + maintenance + garbage + water + internet + repairs + association) * expenseMultiplier['monthly']
        const totalFixedCosts = (propertyTax + insurance) * expenseMultiplier['monthly']
        const totalExpenses = totalVariableCosts + totalFixedCosts
        const netProfit = totalIncome - totalExpenses
        const splitRateAmount = netProfit * (splitRatePercentage / 100)
        const profitAfterSplit = netProfit - splitRateAmount

        const monthlyProfit = profitAfterSplit
        const yearlyProfit = monthlyProfit * 12

        let loanAmountTotal = loanAmountAmount
        const interestRatePercent = interestRate / 100

        const deferFirstYear = false

        loanTable.push({
            loanAmount: loanAmountTotal,
            interestPaid: deferFirstYear ? 0 : loanAmount * interestRatePercent,
            monthlyEquity: monthlyProfit,
            yearlyEquity: yearlyProfit
        })

        if (yearlyProfit - (loanAmountTotal * interestRatePercent) > 0) {
            while (loanAmountTotal > 0) {
                let lastYear = loanTable[loanTable.length - 1]

                let newLoanAmount = lastYear.loanAmount - yearlyProfit + lastYear.interestPaid
                let newInterestPaid = newLoanAmount * interestRatePercent
                loanTable.push({
                    loanAmount: newLoanAmount,
                    interestPaid: newInterestPaid,
                    monthlyEquity: monthlyProfit - (lastYear.interestPaid / 12),
                    yearlyEquity: yearlyProfit - lastYear.interestPaid
                })
                loanAmountTotal = newLoanAmount
            }
        }

        return loanTable
    }, [
        nightlyRate,
        occupancy,
        rent,
        electricity,
        maintenance,
        garbage,
        water,
        internet,
        repairs,
        association,
        propertyTax,
        insurance,
        // purchasePrice,
        // downPayment,
        loanAmount,
        interestRate,
        splitRatePercentage,
        loanAmountAmount
    ])

    return (
        <div className="deal-financial-wrapper">
            <h3>Financing</h3>
            <div className="deal-financial">
                <h5>Details</h5>
                <ul>
                    <li>
                        <b>Purchase Price: </b> {currencyFormatter(purchasePrice)}
                    </li>
                    <li>
                        <b>Down Payment ({downPayment}%): </b> {currencyFormatter(downPaymentAmount)}
                    </li>
                    <li>
                        <b>Loan Amount ({loanAmount}%): </b> {currencyFormatter(loanAmountAmount)}
                    </li>
                    <li>
                        <b>Interest Rate: </b> {interestRate}%
                    </li>
                </ul>
            </div>
            <div className="financial-loan-table">
                <h5>Loan Payback</h5>
                <div className="loan-payback-table-row">
                    <div className="loan-payback-table-col">
                        Year
                    </div>
                    <div className="loan-payback-table-col">
                        Loan Amount
                    </div>
                    <div className="loan-payback-table-col">
                        Interest Paid
                    </div>
                    <div className="loan-payback-table-col">
                        Monthly Equity
                    </div>
                    <div className="loan-payback-table-col">
                        Yearly Equity
                    </div>
                </div>
                {loanPaybackTable.map((entry: any, idx: number) => (
                    <div key={`payback-table-${idx}`} className="loan-payback-table-row">
                        <div className="loan-payback-table-col">
                            {idx}
                        </div>
                        <div className="loan-payback-table-col">
                            {currencyFormatter(entry.loanAmount)}
                        </div>
                        <div className="loan-payback-table-col">
                            {currencyFormatter(entry.interestPaid)}
                        </div>
                        <div className="loan-payback-table-col">
                            {currencyFormatter(entry.monthlyEquity)}
                        </div>
                        <div className="loan-payback-table-col">
                            {currencyFormatter(entry.yearlyEquity)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}