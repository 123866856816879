import Papa from "papaparse"
import { useMemo, useState } from "react"
import { Button, ProgressBar } from "react-bootstrap"
import { textBooleanHandler } from "../../../Helpers/ValueHandlers/boolHandler"
import FileUploadField from "../../FormFields/FileUpload/FileUploadField"
import QuickForm from "../../Forms/QuickForm/QuickForm"

export default function DataImporter(props: {
    processEntryFn: (entry: any) => any,
    validationFn: (entry: any) => any[],
    entryRenderFn: (entry: any) => JSX.Element,
    bulkEditFn:  (entries: any[]) => Promise<any>,
    importFn:  (entries: any[]) => Promise<any>,
    complexSplitFields: string[],
    step?: number,
}) {

    const {
        processEntryFn,
        complexSplitFields,
        validationFn,
        entryRenderFn,
        bulkEditFn,
        importFn,
        step = 100,
    } = props

    const [importSettings, setImportSettings] = useState<any>({
        idIncluded: false,
        freshImport: false,
    })

    const [importStatus, setImportStatus] = useState<any>({
        importing: false,
        importComplete: false,
        importError: false,
        importErrorMessage: '',
        importProgressMessage: '',
        importProgress: 0,
        importTotal: 0,
    })

    const [parsedData, setParsedData] = useState<any[]>([])
    const [entityPreviewIndex, setEntityPreviewIndex] = useState<number>(0)
    const [entityPreviewEnabled, setEntityPreviewEnabled] = useState<boolean>(false)

    const handleFileAdd = (files: FileList) => {
        const fileData: any = files[0]
        Papa.parse(fileData, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                setEntityPreviewEnabled(false)
                setEntityPreviewIndex(0)
                setParsedData(results.data)
            }
        })
    }

    const processedEntities = useMemo(() => {
        const expandedData = parsedData.map((e: any) => {
            const x: any = { ...e }
            complexSplitFields.forEach((v: string) => {
                x[v] = splitFieldData(x[v])
            })
            return x
        })
        const mappedExpandedData = expandedData.map((x: any) => {
            const dataEntry: any = processEntryFn(x)
            if ((importSettings.idIncluded || importSettings.freshImport) && x._id != null) {
                dataEntry._id = x._id
            }
            return dataEntry
        })
        return mappedExpandedData
    }, [parsedData, importSettings, complexSplitFields, processEntryFn])

    const {
        validEntries,
        validationErrors
    }: {
        validEntries: any[],
        validationErrors: any[]
    } = useMemo(() => {
        const validEntries: any[] = []
        const validationErrors: any[] = []

        processedEntities.forEach((x: any, i: number) => {
            const errors = validationFn(x)
            if (importSettings.idIncluded && x._id == null) {
                errors.push('You are missing _id for this entity')
            }
            if (errors.length > 0) {
                validationErrors.push({
                    entry: i,
                    errors: errors
                })
            } else {
                validEntries.push(x)
            }
        })
        return {
            validEntries,
            validationErrors
        }
    }, [processedEntities, importSettings, validationFn])

    const handleImport = async () => {
        // const step = 100 // entities
        const entitiesToImport = [...validEntries]

        const currentImport = {
            importing: true,
            importComplete: false,
            importError: false,
            importErrorMessage: '',
            importProgressMessage: `Importing entities 0 - ${step} of ${entitiesToImport.length}`,
            importProgress: 0,
            importTotal: entitiesToImport.length,
        }

        setImportStatus(currentImport)

        while (entitiesToImport.length > 0 && !currentImport.importError) {
            const batch = entitiesToImport.splice(0, step)

            const batchResult = await (importSettings.idIncluded ? bulkEditFn(batch) : importFn(batch))

            if (batchResult && batchResult.error) {
                currentImport.importError = true
                currentImport.importErrorMessage = batchResult.message
                setImportStatus({ ...currentImport })
                break
            }

            // await new Promise((resolve) => setTimeout(resolve, 1000))
            currentImport.importProgress += step
            currentImport.importProgressMessage = `Importing entities ${currentImport.importProgress} - ${currentImport.importProgress + step} of ${currentImport.importTotal}`
            setImportStatus({ ...currentImport })
        }
        currentImport.importing = false
        currentImport.importComplete = true
        setImportStatus({ ...currentImport })
    }

    return (
        <div>
            <QuickForm
                initialData={{
                    ...importSettings
                }}
                fields={[
                    {
                        fieldName: 'idIncluded',
                        fieldLabel: 'Bulk Edit (entries require _id)',
                        fieldType: 'checkbox',
                        required: false,
                    },
                    {
                        fieldName: 'freshImport',
                        fieldLabel: 'Fresh Import',
                        fieldType: 'checkbox',
                        required: false,
                    }
                ]}
                onChange={(data: any) => {
                    const fieldName = data.target.name
                    const fieldValue = data.target.value
                    const newSettings = { ...importSettings }
                    newSettings[fieldName] = textBooleanHandler(fieldValue)
                    setImportSettings(newSettings)
                }}
            />

            <FileUploadField
                onFileAdd={handleFileAdd}
                multiple={false}
                fieldName="file"
                disabled={false}
            />


            <hr />

            {processedEntities.length > 0 && (
                <>
                    You have {processedEntities.length} entities processed of them {validEntries.length} are valid and {validationErrors.length} are invalid. <br />
                    {validEntries.length > 0 && (
                        <div className={'entity-import-preview'}>
                            <h4>Entity Entry - {entityPreviewIndex}</h4>

                            <Button
                                className={'me-2 mb-2'}
                                onClick={() => {
                                    if (entityPreviewIndex > 0) {
                                        setEntityPreviewIndex(entityPreviewIndex - 1)
                                    }
                                }}
                                disabled={entityPreviewIndex === 0}
                            >
                                Prev
                            </Button>

                            <Button
                                className={'me-2 mb-2'}
                                onClick={() => {
                                    if (entityPreviewIndex < validEntries.length - 1) {
                                        setEntityPreviewIndex(entityPreviewIndex + 1)
                                    }
                                }}
                                disabled={entityPreviewIndex === validEntries.length - 1}
                            >
                                Next
                            </Button>

                            <Button
                                className={'me-2 mb-2'}
                                variant={'secondary'}
                                onClick={() => {
                                    setEntityPreviewEnabled(!entityPreviewEnabled)
                                }}
                            >
                                {entityPreviewEnabled ? 'Hide' : 'Show'} Preview
                            </Button>

                            <Button
                                className={'me-2 mb-2'}
                                variant={'success'}
                                onClick={() => {
                                    handleImport()
                                }}
                            >
                                Import Valid Entities
                            </Button>

                            <hr />

                            {importStatus.importing && (
                                <div className={'import-status'}>
                                    <h4>Importing...</h4>
                                    <ProgressBar
                                        now={importStatus.importProgress}
                                        max={importStatus.importTotal}
                                    />
                                    {importStatus.importProgressMessage}
                                </div>
                            )}

                            {entityPreviewEnabled && validEntries[entityPreviewIndex] && (
                                <>
                                    {entryRenderFn(validEntries[entityPreviewIndex])}
                                </>
                            )}
                        </div>
                    )}
                </>
            )}

        </div>
    )
}

const splitFieldData = (entryToSplit: string): Array<any> => {
    if (entryToSplit === '') {
        return []
    }
    return entryToSplit.split('||').map((y) => {
        const entry: any = {}
        y.split('%%').forEach((z) => {
            let [fieldTitle, fieldData] = z.split('::')
            entry[fieldTitle.trim()] = fieldData
        })
        return entry
    })
}
