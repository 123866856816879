import { Card, Stack } from "react-bootstrap";
import { DateBlockEntryType } from "../../../Types/dateBlockTypes";
import { Link } from "react-router-dom";
import timeService from "../../../services/time/timeService";

export default function DateBlockEntry(
    props: {
        dateBlockInfo: DateBlockEntryType,
        propertyName: string,
        full?: boolean
    }
) {

    const {
        dateBlockInfo,
        propertyName,
        full = false
    } = props

    const {
        created,
        _id,
        checkIn,
        checkOut,
        // propertyId,
        source,
        status,
        firstName,
        lastName,
        confirmationCode,
        tags,
    } = dateBlockInfo

    return (
        <Card className="mb-3">

            <Card.Body>
                <Card.Title>
                    {propertyName}
                </Card.Title>
                <Card.Text>
                    <b>Check In:</b> {timeService.getFormattedTime(checkIn)} <br />
                    <b>Check Out:</b> {timeService.getFormattedTime(checkOut)} <br />
                    <b>Guest:</b> {firstName} {lastName}<br />
                    <b>Source:</b> {source}<br />
                    <b>Status:</b> {status}<br />
                    <b>Confirmation Code:</b> {confirmationCode ? confirmationCode : '-----'}<br />
                    {/* <b>Tags:</b> {tags}<br /> */}

                    {full && (
                        <>
                            <b>Created:</b> {timeService.getFormattedTime(created)}<br />
                            <b>Tags:</b> {tags && tags.length > 0 ? tags.map((tag, index) => {
                                return (
                                    <span key={index}>{tag.tag} </span>
                                )
                            }) : '----'}<br />
                        </>
                    )}
                </Card.Text>

            </Card.Body>

            <Card.Footer>
                <Stack direction={'horizontal'} gap={1} className="mt-2 mb-2">
                    <Link className="btn btn-primary btn-sm" to={`/date-block/${_id}`}>View</Link>
                    <Link className="btn btn-secondary btn-sm" to={`/edit-date-block/${_id}`}>Edit</Link>
                    <Link className="btn btn-danger btn-sm" to={`/delete-date-block/${_id}`}>Delete</Link>
                </Stack>

            </Card.Footer>
        </Card>
    )
}