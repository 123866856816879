import { Col, Container, Row } from "react-bootstrap"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import QuickForm from "../../Components/Forms/QuickForm/QuickForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import BookingRequestEntry from "../../Components/BookingRequest/BookingRequestEntry"
import { useGetBookingRequestsQuery } from "../../services/bClientApi"
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook"
import MongoPagination from "../../Components/Pagination/MongoPagination"
import { BookingRequestEntryType } from "../../Types/bookingRequestTypes"

export default function BookingRequestsPage() {

    const dispatch = useAppDispatch()

    const {
        publicPropertyIdKeys
    } = useMyProperties()

    const { activeQuery } = useActiveQueryHook({
        query: {},
        limit: 10,
    })

    const {
        data,
        error,
        isLoading,
        isFetching,
    } = useGetBookingRequestsQuery({
        bookingRequestsQuery: {
            propertyId: publicPropertyIdKeys,
            ...activeQuery.query,
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
            sort: {
                created: -1
            }
        }
    }, { skip: publicPropertyIdKeys.length === 0 })

    const bookingRequestsList = data?.docs || []

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/booking-requests?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Requests</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            guestName: activeQuery.query.guestName,
                        }}
                        fields={[
                            {
                                fieldName: 'guestName',
                                fieldLabel: 'Name',
                                fieldType: 'text',
                                placeholder: 'Name',
                            }
                        ]}
                        disabled={isLoading || isFetching}
                        onSubmit={(values: any) => {
                            loadPage({
                                query: values,
                                page: 1,
                                limit: activeQuery.limit
                            })
                        }}
                    />

                    <div className="bookingRequest-list-container">
                        {bookingRequestsList && bookingRequestsList.length > 0 && bookingRequestsList.map((bookingRequestEntry: BookingRequestEntryType, idx: number) => (
                            <BookingRequestEntry
                                key={idx}
                                bookingRequest={bookingRequestEntry}
                            />
                        ))
                        }
                    </div>

                    <MongoPagination
                        data={data}
                        loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                            loadPage({
                                query: activeQuery.query,
                                limit: activeQuery.limit,
                                ...pageLoadInfo
                            })
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}
