import { Button, Modal } from "react-bootstrap"

export function ConfirmModal(props: {
    active: boolean,
    onClose: () => void,
    onConfirm: () => void,
    message: string
    title: string
}) {

    const {
        active,
        onClose,
        onConfirm,
        message,
        title
    } = props

    return (
        <Modal
            show={active}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-lg font-semibold">{message}</p>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>


                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal >
    )
}