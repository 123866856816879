import { Field } from "formik"
import { FloatingLabel, Form } from "react-bootstrap"
import { checkVal } from "../../../Helpers/Parsers/checkVal"

export const SelectInputField = (props: {
  fieldLabel: string,
  fieldName: string,
  placeholder: string,
  customClass?: string,
  required?: boolean,
  touched?: any,
  errors?: any,
  subText?: any,
  onChange?: (e: any) => void
  options: { value: string | number, text?: string }[],
  disabled?: boolean,
}) => {
  const {
    fieldLabel,
    fieldName,
    placeholder,
    customClass,
    options,
    required,
    subText,
    onChange,
    disabled
  } = props

  return (
    <>
      <Field type="text" name={fieldName}>
        {({
          field,
          form
        }: any) => (
            <Form.Group className={`mb-3 ${customClass ? customClass : ''}`}>
              {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
              <FloatingLabel
                controlId={fieldName}
                label={`${fieldLabel}${required ? '*' : ''}`}>
                <Form.Select
                  name={fieldName}
                  disabled={disabled}
                  required={required}
                  onChange={(e: any) => {
                    if (onChange) {
                      onChange(e)
                    }
                    form.handleChange(e)
                  }}
                  onBlur={form.handleBlur}
                  value={field.value}
                  isInvalid={checkVal(form.touched, fieldName) && checkVal(form.errors, fieldName)}
                  isValid={!checkVal(form.errors, fieldName) && checkVal(form.touched, fieldName)}
                  aria-label={fieldLabel}>
                  <option value={''}>{placeholder ? placeholder : '- Select -'}</option>
                  {options.map((val: { value: string | number, text?: string }, idx: number) => (
                    <option key={`options-${idx}`} value={val.value}>{val.text ? val.text : val.value}</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
              {subText && (
                <Form.Text className="text-muted">
                  {subText}
                </Form.Text>
              )}
              <Form.Control.Feedback type="invalid">
                {checkVal(form.errors, fieldName)}
              </Form.Control.Feedback>
            </Form.Group>
          )}
      </Field>
      {/*<div className={`field-container select-input-container ${customClass ? customClass : ''}`}>
        <label htmlFor={fieldLabel}>{fieldLabel}{required ? '*' : ''}</label><br />
        <Field type="text" as={'select'} placeholder={placeholder} name={fieldName}>
            <option value={''}>{placeholder ? placeholder : '- Select -'}</option>
          {options.map((val: {value: string, text?: string}, idx: number) => (
            <option key={`options-${idx}`} value={val.value}>{val.text ? val.text : val.value}</option>
          ))}
        </Field><br />
        <ErrorMessage name={fieldName} render={ErrorComponent} />
      </div>*/}
    </>
  )
}
