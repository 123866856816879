import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import BukibleEntry from "../../../Components/Bukibles/BukibleEntry"
import { useGetBukibleByIdQuery } from "../../../services/bClientApi"

export default function BukiblePage() {
  const { bukibleId = '' } = useParams()

  const {
    data,
    isLoading,
    error,
  } = useGetBukibleByIdQuery(bukibleId, { skip: !bukibleId })
  const bukibleData = data || {}


  return (
    <Container>
      <Row>
        <Col>
          <h1>Single Bukible Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {bukibleId && bukibleData && !isLoading && (
            <BukibleEntry bukibleInfo={bukibleData} />
          )}
        </Col>
      </Row>
    </Container>
  )
}
