import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DealComponent } from "../../Components/Deals/DealComponent";
import { dealFormFields, defaultDealInfo } from "../../Components/Deals/DealConstants";
import { DealFinancials } from "../../Components/Deals/DealFinancials";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import { routerSelector } from "../../redux/router/routerSelector";
import './DealsPage.scss'

export default function DealsPage() {

    const { location } = useSelector(routerSelector)

    const [dealInfo, setDealInfo] = useState(() => {

        const baseDealInfo: any = defaultDealInfo

        if (location?.search) {
            const urlParams = new URLSearchParams(location.search);
            urlParams.forEach((value, key) => {
                baseDealInfo[key] = value
            })
        }

        return baseDealInfo
    })

    const [dealFormToggle, setDealFormToggle] = useState(true)
    const [showDeal, setShowDeal] = useState(false)

    const shareDealUrl = new URLSearchParams(dealInfo)

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Deals</h1>
                    <hr />
                    {/* {error && (<>{JSON.stringify(error)}<br /></>)} */}
                    {/* {message && (<>{message}<br /></>)} */}
                    {dealFormToggle ? (
                        <QuickForm
                            initialData={dealInfo}
                            onSubmit={async (data: any) => {
                                setDealInfo(data)
                                setShowDeal(true)
                                setDealFormToggle(false)
                            }}
                            onChange={(data: any) => { }}
                            fields={dealFormFields}
                        />
                    ) : (
                        <>
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => {
                                    setShowDeal(false)
                                    setDealFormToggle(true)
                                }}>
                                Edit Deal
                            </Button>
                            <br />
                            <small>
                               <Link to={`/deal-tool?${shareDealUrl.toString()}`}>Share Deal</Link>
                            </small>
                        </>
                    )}
                </Col>
            </Row>

            {showDeal && (
                <>
                    <Row>
                        {/* <pre>{JSON.stringify(dealInfo,null,2)}</pre> */}
                        <Col>
                            <DealComponent dealInfo={dealInfo} timeFrame={'nightly'} />
                        </Col>
                        <Col>
                            <DealComponent dealInfo={dealInfo} timeFrame={'monthly'} />
                        </Col>
                        <Col>
                            <DealComponent dealInfo={dealInfo} timeFrame={'yearly'} />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <DealFinancials dealInfo={dealInfo} />
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    )
}