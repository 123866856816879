import { FieldArray, FieldArrayRenderProps } from "formik"
import { Button, Card } from "react-bootstrap"
import { DragDropVertical } from "../../DragDrop/DragDropVertical"
import FieldGenerator, { FieldGeneratorFieldType } from "../FieldGenerator/FieldGenerator"
// import { SelectInputField } from "../SelectInput/SelectInputField"
// import { TextInputField } from "../TextInput/TextInputField"

// export interface ArrayEntryFieldType {
//   fieldType: 'text' | 'number' | 'password' | 'select' | 'email',
//   fieldLabel: string,
//   placeholder?: string,
//   fieldName: string,
//   options?: any[],
//   required?: boolean
// }

// const textFieldTypes: any = {
//   text: 1,
//   number: 1,
//   password: 1,
//   email: 1
// }

export const ArrayFieldInput = (props: {
  fieldName: string,
  values: any[],
  touched?: any,
  errors?: any,
  setFieldValue: (f: string, v: any[]) => void,
  fields: FieldGeneratorFieldType[],
  blankEntryFn: () => any,
  customClass?: string,
  imgPreview?: {srcVal: string}
}) => {
  const {
    fieldName,
    values,
    setFieldValue,
    fields,
    blankEntryFn,
    customClass,
    imgPreview,
    // touched,
    // errors,
  } = props
  return (
    <div className="field-container">
    <FieldArray
      key={fieldName}
      name={fieldName}
      render={(arrayHelpers: FieldArrayRenderProps) => (
        <div className="field-array-container">
          <DragDropVertical
            idBase={fieldName}
            valuesArray={values}
            customClass={customClass}
            onReorder={(newValues: any[]) => {
              setFieldValue(fieldName, [...newValues])
            }}
            childRenderer={(item: {entry: any, idx: number}) => (
              <Card>
                <Card.Body>
                  <div className="field-array-entry">
                    <div className="drag-entry">
                      {imgPreview && item.entry[imgPreview.srcVal] && (
                        <div className="drag-image-preview">
                          <img className="image-preview" src={item.entry[imgPreview.srcVal]} alt={`${fieldName} preview ${item.idx}`} />
                        </div>
                      )}
                      {imgPreview && !item.entry[imgPreview.srcVal] &&(
                        <div className="drag-image-preview">
                          <p>Image Source Missing</p>
                        </div>
                      )}
                      <div className="drag-fields-container">
                        {fields.map((fieldEntry: any, idx: number) => {
                            return (
                              <div key={`${fieldName}-array-entry-${idx}`} className="field-wrapper">
                                <FieldGenerator 
                                {...fieldEntry} 
                                fieldName={`${fieldName}[${item.idx}].${fieldEntry.fieldName}`} />
                                {/* <TextInputField
                                  fieldType={fieldEntry.fieldType}
                                  fieldLabel={fieldEntry.fieldLabel}
                                  required={fieldEntry.required}
                                  placeholder={fieldEntry.placeholder}
                                  fieldName={`${fieldName}[${item.idx}].${fieldEntry.fieldName}`} /> */}
                              </div>
                            )
                        })}
                      </div>
                    </div>
                    <Button
                      className="remove-btn array-btn mb-3"
                      type="button"
                      size="sm"
                      variant="danger"
                      onClick={() => arrayHelpers.remove(item.idx)}>
                       - Remove
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            )}
           />
           <br />
          <Button
            className="add-btn array-btn mb-3"
            type="button"
            size="sm"
            onClick={() => arrayHelpers.push(blankEntryFn())}>
            + Add
          </Button>
        </div>
      )}
    />
    </div>
  )
}
