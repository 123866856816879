import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";

export const UserSearchForm = (props: {
  firstName: string,
  lastName: string,
  email: string,
  disabled: boolean,
  onSubmit: (x: any) => void
}) => {
  const { firstName, lastName, email, disabled, onSubmit } = props
  return (
    <div className="user-search-form">
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
        }}
        validate={values => {
          const errors: any = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            onSubmit(values)
          } catch (err) {
            // custom error handler
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <TextInputField
              fieldLabel="First Name"
              placeholder="First Name"
              errors={errors}
              touched={touched}
              fieldName="firstName" />

            <TextInputField
              fieldLabel="Last Name"
              placeholder="Last Name"
              errors={errors}
              touched={touched}
              fieldName="lastName" />

            <TextInputField
              fieldLabel="Email"
              placeholder="Email"
              errors={errors}
              touched={touched}
              fieldName="email" />

            <Button className="mb-3" type="submit" disabled={isSubmitting || disabled}>
              Search
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
