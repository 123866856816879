import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { createReduxHistoryContext } from "redux-first-history";
import { sleeper } from "../services/sleeper/sleeper";
import accountSlice from "./account/accountSlice";
import counterSlice from "./counter/counterSlice";
import { bClientApi } from "../services/bClientApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

// redux-first-history thing
const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory(), reduxTravelling: true, });

export const store = configureStore({
  devTools: true,
  reducer: {
    router: routerReducer,
    counter: counterSlice,
    account: accountSlice,
    [bClientApi.reducerPath]: bClientApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        sleep: sleeper,
      }
    }
  }).concat(routerMiddleware).concat(bClientApi.middleware)
})

setupListeners(store.dispatch)

// redux-first-history thing
export const history = createReduxHistory(store)

// export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
