import { Button, Card, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"
import timeService from "../../../services/time/timeService"
import { IcalEntryType } from "../../../Types/icalEntryType"
import IcalConversionForm from "../../Forms/IcalConversionForm/IcalConversionForm"

export const IcalEntry = (props: {
    icalData: IcalEntryType,
    convertIcalBooking?: (x: any) => void,
}) => {
    const {
        icalData,
        convertIcalBooking
    } = props
    const {
        checkIn,
        checkOut,
        unit,
        source,
        propertyId,
        summary,
        cleaning,
        viewInfoLink,
        existing,
        missing,
        blocked,
        past,
        // rawData
    } = icalData
    return (
        <Card>
            <Card.Body>
                <Card.Title>{summary}</Card.Title>
                <Card.Text>

                    <b>Property</b> {unit} <br />
                    <b>Source</b> {source} <br />
                    <b>Check In</b> {timeService.getFormattedTime(checkIn)} <br />
                    <b>Check Out</b> {timeService.getFormattedTime(checkOut)} <br />
                    <b>Property Id</b> {propertyId} <br />
                    {/* {cleaning ? (<><b>Cleaning Fee</b> {cleaning} <br /></>) : null} */}
                    {/* {viewInfoLink && (<><b>View Info Link</b> {viewInfoLink} <br /></>)} */}
                    {/* {existing && (<><b>Existing</b> {existing} <br /></>)} */}
                    {/* {JSON.stringify(rawData, null, 2)} */}
                </Card.Text>

                {convertIcalBooking && missing && !blocked && !past && (
                    <div className="mb-3">
                        <IcalConversionForm
                            icalData={icalData}
                            disabled={false}
                            onSubmit={(e: any) => {
                                const updatedIcalInfo = {
                                    ...icalData,
                                    ...e
                                }
                                if (cleaning && cleaning > 0) {
                                    updatedIcalInfo.earnings = updatedIcalInfo.earnings - cleaning
                                }
                                convertIcalBooking(updatedIcalInfo)
                            }}
                        />
                        {cleaning ? (
                            <div>
                                <small>${cleaning} cleaning will be deducted from earnings</small>
                            </div>
                        ) : null}
                    </div>
                )}

                <Stack direction={'horizontal'} gap={1}>
                    {/* convert ical booking button */}
                    {existing && (
                        <Link className="btn btn-secondary btn-sm" to={`/booking/${existing}`}>
                            View Existing Booking
                        </Link>
                    )}
                    {/* view info link */}
                    {viewInfoLink && (
                        <Button variant="secondary" size={'sm'} target={'_blank'} href={viewInfoLink}>
                            View Info Link
                        </Button>
                    )}
                </Stack>
            </Card.Body>
        </Card>
    )
}

export default IcalEntry