import { Toast, ToastContainer } from "react-bootstrap"
import timeService from "../../../services/time/timeService"
import { Link } from "react-router-dom"
import { currencyFormatter } from "../../../Helpers/Parsers/currencyFormatter"

export default function BookingPreview(props: {
    booking: any,
    onClose: () => void
}) {
    const { 
        booking,
        onClose = () => {}
    } = props

    if(!booking) {
        return null
    }

    const nights = timeService.calculateNights(booking.checkIn, booking.checkOut)
    const earnings = currencyFormatter(booking.pricePerNight * nights)

    return (
        <div className="toast-fix">
            <ToastContainer>
                <Toast show={booking && booking._id ? true : false} onClose={() => {
                    onClose()
                }}>
                    <Toast.Header>
                        <strong className="me-auto">
                            {booking.contactInfo?.firstName} {booking.contactInfo?.lastName}
                        </strong>
                        <small>{booking._id}</small>
                    </Toast.Header>
                    <Toast.Body>
                        {booking.checkIn && booking.checkOut && (
                            <>
                                <b>Check In:</b> {timeService.createLuxonDate(booking.checkIn).toFormat('MM/dd/yyyy')}<br />
                                <b>Check Out:</b> {timeService.createLuxonDate(booking.checkOut).toFormat('MM/dd/yyyy')}<br />
                            </>
                        )}
                        {booking.dateBooked && (
                            <>
                                <b>Date Booked:</b> {timeService.createLuxonDate(booking.dateBooked).toFormat('MM/dd/yyyy')}<br />
                            </>
                        )}
                        <b>Nights: </b> {nights}<br />
                        <b>Earnings: </b> {earnings}<br />
                        <b>Source:</b> {booking.source}<br />
                        <b>Confirmation:</b> {booking.sourceConfirmation}<br />

                        <Link to={`/booking/${booking._id}`}>
                            View Booking
                        </Link>

                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}