import { Col, Container, Row } from "react-bootstrap"
import { BookingEntry } from "../../Components/Bookings/BookingEntry/BookingEntry"
import BookingSearchForm from "../../Components/Forms/BookingSearchForm/BookingSearchForm"
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers"
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook"
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook"
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook"
import { navigateTo } from "../../redux/router/routerActions"
import { useAppDispatch } from "../../redux/store"
import './bookingsPage.scss'
import { useGetBookingsQuery } from "../../services/bClientApi"
import MongoPagination from "../../Components/Pagination/MongoPagination"
import { PropertyBookingEntry } from "../../Types/bookingTypes"

export default function BookingsPage() {

  const dispatch = useAppDispatch()
  const {
    myProperties,
    publicPropertyIdKeys
  } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { activeQuery } = useActiveQueryHook({
    limit: 10
  })

  const {
    data,
    error,
    isLoading,
    isFetching,
    requestId,
  } = useGetBookingsQuery({
    bookingsQuery: {
      ...activeQuery.query,
      ...(!activeQuery.query.propertyId ? { propertyId: publicPropertyIdKeys } : {})
    },
    pagination: {
      limit: activeQuery.limit,
      page: activeQuery.page,
    }
  }, {
    skip: !myProperties
  })

  const bookingsList = data?.docs || []

  const paginationInfo = data ? {
    totalDocs: data.totalDocs,
    // page: data.page,
    // limit: data.limit,
    // nextPage: data.nextPage,
    // prevPage: data.prevPage,
    // totalPages: data.totalPages
  } : {}

  const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
    const destination = `/bookings?${convertActiveQueryToUrl(pageParams)}`
    dispatch(navigateTo(destination))
  }


  return (
    <Container>
      <Row>
        <Col>
          <h1>Bookings</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <div className="w-full my-2 small fst-italic">
            NOTE: Bot runs every 5 minutes. If you don't see your booking, it may not have been processed yet.
          </div>

          <BookingSearchForm
            key={`search-form-${requestId}`}
            disabled={isLoading || isFetching}
            onSubmit={(values: any) => {
              loadPage({
                query: values,
                page: 1,
                limit: activeQuery.limit
              })
            }}
            checkIn={activeQuery.query.checkIn}
            checkOut={activeQuery.query.checkOut}
            firstName={activeQuery.query.firstName}
            lastName={activeQuery.query.lastName}
            propertyId={activeQuery.query.propertyId}
            propertyOptionList={propertyOptions}
            sourceConfirmation={activeQuery.query.sourceConfirmation}
            confirmationCode={activeQuery.query.confirmationCode}
            source={activeQuery.query.source}
            bookingStatus={activeQuery.query.bookingStatus}
            dateBookedStart={activeQuery.query.dateBookedStart}
            dateBookedEnd={activeQuery.query.dateBookedEnd}
          />

          {paginationInfo && (
            <div className="p-2">
              {paginationInfo.totalDocs} entries
            </div>
          )}

          <div className="booking-list-container">
            {bookingsList && bookingsList.length > 0 && bookingsList.map((bookingEntry: PropertyBookingEntry, idx: number) => (
              <BookingEntry
                key={`${bookingEntry._id}-${idx}`}
                bookingInfo={bookingEntry}
                propertyName={myProperties[bookingEntry.propertyId]?.title}
              />
            ))
            }
          </div>

          <MongoPagination
            data={data}
            loadPage={(q: { page: number, limit: number }) => {
              loadPage({ ...activeQuery, ...q, })
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}
