import { Col, Container, Row } from "react-bootstrap";
import { FieldGeneratorFieldType } from "../../Components/FormFields/FieldGenerator/FieldGenerator";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import PropertyTaskEntry from "../../Components/PropertyTasks/PropertyTaskEntry"
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useOccupancyRef from "../../Hooks/UseOccupancyRef/UseOccupancyRef";
import useUserRefs from "../../Hooks/UseUsersQuery/UseUserRefs";
import { PropertyTaskType } from "../../Types/propertyTaskType";
import { navigateTo } from "../../redux/router/routerActions";
import { useAppDispatch } from "../../redux/store";
import { convertActiveQueryToUrl } from "../../Helpers/Query/queryHelpers";
import useActiveQueryHook from "../../Hooks/UseActiveQuery/useActiveQueryHook";
import { useGetPropertyTasksQuery } from "../../services/bClientApi";
import MongoPagination from "../../Components/Pagination/MongoPagination";

export default function PropertyTasksPage() {

    const userRef = useUserRefs()
    const { myProperties, publicPropertyIdKeys } = useMyProperties()
    const propertySelectOptions = useMyPropertiesSelectValues(myProperties)
    const propertyOccupancyRef = useOccupancyRef({ myProperties })

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({
        query: {
            status: ['new', 'approved', 'in progress', 'complete', 'confirmed'],
        },
        limit: 10,
        page: 1,
    })

    const activeCheckboxes: any = {}
    if (activeQuery.query.status) {
        activeQuery.query.status.forEach((status: string) => {
            activeCheckboxes[checkBoxField[status]] = true
        })
    }

    const {
        data,
        error,
        isLoading,
    } = useGetPropertyTasksQuery({
        propertyTasksQuery: {
            propertyId: publicPropertyIdKeys,
            ...activeQuery.query,
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
        }
    }, { skip: publicPropertyIdKeys.length === 0 })
    const propertyTasksList = data?.docs || []


    const handleFormSubmit = (data: any) => {
        const newQuery: any = {
            ...activeQuery,
            query: {},
            page: 1,
            limit: activeQuery.limit,
        }
        Object.keys(data).forEach((key: string) => {
            if (checkBoxField[key] && data[key]) {
                if (!newQuery.query.status) {
                    newQuery.query.status = []
                }
                newQuery.query.status.push(checkBoxField[key])
            }
        })
        if (data.propertyId) {
            newQuery.query.propertyId = data.propertyId
        }
        if (data.title) {
            newQuery.query.title = data.title
        }
        if (data.createdStart) {
            newQuery.query.createdStart = data.createdStart
        }
        if (data.createdEnd) {
            newQuery.query.createdEnd = data.createdEnd
        }
        if (data.completedStart) {
            newQuery.query.completedStart = data.completedStart
        }
        if (data.completedEnd) {
            newQuery.query.completedEnd = data.completedEnd
        }
        loadPage(newQuery)
    }

    const loadPage = (pageParams: { query: any, page: number, limit: number }) => {
        const destination = `/property-tasks?${convertActiveQueryToUrl(pageParams)}`
        dispatch(navigateTo(destination))
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Property Tasks</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            propertyId: activeQuery.query.propertyId || '',
                            title: activeQuery.query.title || '',
                            createdStart: activeQuery.query.createdStart || '',
                            createdEnd: activeQuery.query.createdEnd || '',
                            completedStart: activeQuery.query.completedStart || '',
                            completedEnd: activeQuery.query.completedEnd || '',
                            new: activeCheckboxes.new || false,
                            confirmed: activeCheckboxes.confirmed || false,
                            complete: activeCheckboxes.complete || false,
                            approved: activeCheckboxes.approved || false,
                            inProgress: activeCheckboxes.inProgress || false,
                            blocked: activeCheckboxes.blocked || false,
                        }}
                        disabled={isLoading}
                        fields={[
                            {
                                fieldName: 'propertyId',
                                fieldLabel: 'Property',
                                fieldType: 'select',
                                placeholder: 'Select Property',
                                options: propertySelectOptions,
                            },
                            ...taskFormFields
                        ]}
                        onSubmit={handleFormSubmit}
                        onReset={() => {
                            const resetValues = {
                                propertyId: '',
                                new: true,
                                confirmed: true,
                                complete: true,
                                approved: true,
                                inProgress: true,
                                blocked: false
                            }
                            handleFormSubmit(resetValues)
                            return resetValues
                        }}
                    />

                    <hr />

                    {propertyTasksList && propertyTasksList.map((task: PropertyTaskType, idx: number) => (
                        <PropertyTaskEntry
                            key={idx}
                            taskInfo={task}
                            propertyInfo={myProperties[task.propertyId]}
                            occupancyInfo={propertyOccupancyRef[task.propertyId]}
                            userRef={userRef}
                            hideButtons={{
                                delete: true
                            }}
                        />
                    ))}

                    <MongoPagination
                        data={data}
                        loadPage={(pageLoadInfo: { page: number, limit?: number }) => {
                            loadPage({
                                query: activeQuery.query,
                                page: pageLoadInfo.page,
                                limit: pageLoadInfo.limit || activeQuery.limit
                            })
                            // const aQ = { ...activeQuery }
                            // if (pageLoadInfo.limit) {
                            //     aQ.limit = pageLoadInfo.limit
                            // }
                            // if (pageLoadInfo.page) {
                            //     aQ.page = pageLoadInfo.page
                            // }
                            // dispatch(navigateTo(`/property-tasks?page=${aQ.page}&limit=${aQ.limit}&query=${encodeURI(JSON.stringify(activeQuery.query))}`))
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const taskFormFields: FieldGeneratorFieldType[] = [
    {
        fieldName: 'title',
        fieldLabel: 'Task Title',
        fieldType: 'text',
        required: false,
    },

    {
        fieldName: 'createdStart',
        fieldLabel: 'Created Start',
        fieldType: 'date',
        required: false,
    },
    {
        fieldName: 'createdEnd',
        fieldLabel: 'Created End',
        fieldType: 'date',
        required: false,
    },

    {
        fieldName: 'completedStart',
        fieldLabel: 'Completed Start',
        fieldType: 'date',
        required: false,
    },
    {
        fieldName: 'completedEnd',
        fieldLabel: 'Completed End',
        fieldType: 'date',
        required: false,
    },

    {
        fieldName: 'new',
        fieldLabel: 'New',
        fieldType: 'checkbox',
        required: false,
    },
    {
        fieldName: 'confirmed',
        fieldLabel: 'Confirmed',
        fieldType: 'checkbox',
        required: false,
    },
    {
        fieldName: 'complete',
        fieldLabel: 'Complete',
        fieldType: 'checkbox',
        required: false,
    },
    {
        fieldName: 'approved',
        fieldLabel: 'Approved',
        fieldType: 'checkbox',
        required: false,
    },
    {
        fieldName: 'inProgress',
        fieldLabel: 'In Progress',
        fieldType: 'checkbox',
        required: false,
    },
    {
        fieldName: 'blocked',
        fieldLabel: 'Blocked',
        fieldType: 'checkbox',
        required: false,
    },

]

const checkBoxField: any = {
    new: 'new',
    confirmed: 'confirmed',
    complete: 'complete',
    approved: 'approved',
    inProgress: 'in progress',
    blocked: 'blocked',
}
