import { useMemo } from "react"

export default function UseIcalsFilter(
    icalData: any,
    icalFilter: {
        checkIn?: Date,
        checkOut?: Date,
        hideExisting?: boolean,
        hideBlocked?: boolean,
        source?: string
    }
) {
    const filteredIcalData = useMemo(() => {
        const fromDate = icalFilter.checkIn ? (new Date(icalFilter.checkIn)).getTime() : null
        const toDate = icalFilter.checkOut ? (new Date(icalFilter.checkOut)).getTime() : null
        return icalData.filter((ical: any) => {

            // hide existing
            if (icalFilter.hideExisting && ical.existing) {
                return false
            }

            // hide blocked
            if (icalFilter.hideBlocked && ical.blocked) {
                return false
            }

            // source filter
            if (icalFilter.source && ical.source.toLowerCase().indexOf(icalFilter.source) === -1) {
                return false
            }
            
            // date collision
            const checkIn = (new Date(ical.checkIn)).getTime()
            const checkOut = (new Date(ical.checkOut)).getTime()
            if (fromDate && toDate && !(
                (checkIn <= fromDate && checkOut >= fromDate) ||
                (checkIn <= toDate && checkOut >= toDate) ||
                (fromDate <= checkIn && toDate >= checkIn) ||
                (fromDate <= checkOut && toDate >= checkOut)
            )) {
                return false
            } else {
                if (fromDate && !(checkOut >= fromDate)) {
                    return false
                }
                if (toDate && !(checkIn <= toDate)) {
                    return false
                }
            }

            return true
        })
    }, [icalData, icalFilter])

    return filteredIcalData
}