import { useMemo } from "react"

export default function useListToRefMemo(list: any[] = [], field: string = '_id') {
    const listRef = useMemo(() => {
        const ref: any = {}
        list?.forEach(x => {
            if(x[field]) {
                ref[x[field]] = x
            }
        })
        return ref
    }, [list, field])

    return listRef
}