import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Define a type for the slice state
interface CounterState {
  value: number,
  loading: boolean,
  properties: any[],

  title: string
  message: string
  active: boolean
  variant: string
}

// Define the initial state using that type
const initialState: CounterState = {
  value: 0,
  loading: false,
  properties: [],

  title: '',
  message: '',
  active: false,
  variant: ''
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    setCounterLoading: (state) => {
      state.loading = true
    },

    showMessage: (state, action: PayloadAction<string>) => {
      state.active = true
      state.message = action.payload
      state.variant = ''
    },
    showSuccess: (state, action: PayloadAction<string>) => {
      state.active = true
      state.message = action.payload
      state.variant = 'success'
    },
    showError: (state, action: PayloadAction<string>) => {
      state.active = true
      state.message = action.payload
      state.variant = 'danger'
    },

    hideMessage: (state) => {
      state.active = false
      state.title = ''
      state.message = ''
      state.variant = ''
    }
  },
  // extraReducers: (builder) => {}
})

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  setCounterLoading,

  showMessage,
  showSuccess,
  showError,
  hideMessage
} = counterSlice.actions

export const counterSelector = createSelector((state: RootState) => state, (state) => state.counter)

export default counterSlice.reducer
