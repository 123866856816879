import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import { accountSelector } from "../../../redux/account/accountSlice";
import FullPropertyEntry from "../../../Components/PropertyEntry/FullPropertyEntry";
import DataImporter from "../../../Components/Data/DataImporter/DataImporter";
import { useBulkEditPropertiesMutation, useImportPropertiesMutation } from "../../../services/bClientApi";

export default function ImportPropertiesPagse() {

    const { email } = useSelector(accountSelector)

    const [showExpandedInstructions, setShowExpandedInstructions] = useState<boolean>(false)

    const [importProperties,
        // { isLoading: importPropertiesLoading, error: importPropertiesError }
    ] = useImportPropertiesMutation()

    const [bulkEditProperties,
        // { isLoading: bulkEditPropertiesLoading, error: bulkEditPropertiesError }
    ] = useBulkEditPropertiesMutation()

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Import Properties</h1>
                    <hr />
                    You can use this tool to import properties.
                    <br /><br />
                    <a href={'/assets/sample-property-import.csv'} download={'sample-property-import.csv'}>Click here to download a sample template</a>
                    <br /><br />
                    <b>Instructions:</b>
                    <ol>
                        <li>Download the sample template above.</li>
                        <li>Fill in the data. <br />
                            <Button
                                className={'my-2'}
                                size={'sm'}
                                variant={'secondary'}
                                onClick={() => setShowExpandedInstructions(!showExpandedInstructions)}
                            >
                                {showExpandedInstructions ? 'Hide' : 'Show'} Expanded Instructions
                            </Button>
                            {showExpandedInstructions && (
                                <>
                                    <ul>
                                        <li>Each entry requires a your email to be included in the authorizedUsers field</li>
                                        <li>Make sure in the isMultiUnit and public fields you use either TRUE of FALSE values, otherwise it will be marked false.</li>
                                        <li>
                                            Some fields allow multple entries, use the following template for adding data:
                                            <ul>
                                                <li>
                                                    field name and field data are separated with double colon ::
                                                </li>
                                                <li>
                                                    fields are separated by double percentage %%
                                                </li>
                                                <li>
                                                    entries are separated by double pipe ||
                                                </li>
                                                <li>
                                                    example: <br />
                                                    fieldName::field data%%secondFieldName::field data||fieldName::field data%%secondFieldName::field data
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Below are the fields that require the special formatting:
                                            <ul>
                                                <li>
                                                    <b>alises:</b> <br />
                                                    alias::Street House%%aliasSource::default||alias::132456%%aliasSource::homeaway
                                                </li>
                                                <li>
                                                    <b>imageGallery:</b> <br />
                                                    url::http://www.imagelocation.com/image.jpg%%title::Image title goes here%%description::place the image description here||url::http://www.imagelocation.com/image2.jpg%%title::Second image title%%description::Description for second image
                                                </li>
                                                <li>
                                                    <b>calendars:</b> <br />
                                                    source::airbnb%%url::https://airbnb.com/something/propertycalendar.ics||source::homeaway%%url::https://homeaway.com/something/propertycalendar.ics
                                                </li>
                                                <li>
                                                    <b>listed:</b> <br />
                                                    source::airbnb%%url::https://airbnb.com/some-listing-url%%internalName::Internal property name%%internalTitle::Title used for property||source::homeaway%%url::https://homeaway.com/some-listing-url%%internalName::Internal property name%%internalTitle::Title used for property
                                                </li>
                                                <li>
                                                    <b>sections:</b> <br />
                                                    sectionTitle::Title of the section%%content::Content for the section you are adding||sectionTitle::Title of another section%%content::Content for this second section
                                                </li>
                                                <li>
                                                    <b>linkedUnits:</b> <br />
                                                    propertyId::12345678%%relationship::child||propertyId::098768766%%relationship::parent
                                                </li>
                                                <li>
                                                    <b>extraFees:</b> <br />
                                                    name::Towel Service%%amount::20%%frequency::nightly||name::Chauffer Service%%amount::50%%frequency::once
                                                </li>
                                                <li>
                                                    <b>authorizedUsers:</b> <br />
                                                    email::person@gmail.com%%role::admin||email::otherperson@hotmail.com%%role::casual
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Each entry in the amenities field is to be separated by a pipe |
                                        </li>
                                    </ul>
                                </>
                            )}
                        </li>
                        <li>Upload the file below.</li>
                        <li>If there are any errors, they will be listed below. Fix them and upload the file again.</li>
                        <li>Review your entries below to confirm all the data was parsed correctly.</li>
                    </ol>

                    <b>Troubleshooting:</b>
                    <ul>
                        <li>
                            If you are having issues with your csv file, try pasting your data into a new&nbsp;
                            <a href="https://sheets.google.com" rel="noreferrer" target="_blank">google sheet</a> and then clicking
                            File &rarr; Download as &rarr; comma separated values (csv).
                        </li>
                    </ul>

                    <hr />

                    <DataImporter
                        importFn={async (x: any) => {
                            return await importProperties(x)
                        }}
                        bulkEditFn={async (x: any) => {
                            return await bulkEditProperties(x)
                        }}
                        complexSplitFields={complexSplitFields}
                        entryRenderFn={entryRenderer}
                        validationFn={(x: any) => {
                            const validEmail = email ? email.toLowerCase() : 'nothing'
                            let foundEmail = false;
                            const errors = []
                            if (x.authorizedUsers) {
                                x.authorizedUsers.forEach((y: any) => {
                                    if (y.email === validEmail) {
                                        foundEmail = true
                                    }
                                })
                            }
                            if (!foundEmail) {
                                errors.push('your email was not found for in this property')
                            }
                            return errors
                        }}
                        processEntryFn={(x: any) => {
                            const dataEntry: any = {
                                title: x.title,
                                description: x.description,
                                aliases: x.aliases,
                                owner: {
                                    firstName: x.ownerFirstName,
                                    lastName: x.ownerLastName,
                                    email: x.ownerEmail,
                                    phone: x.ownerPhone
                                },
                                address: {
                                    address1: x.address1,
                                    address2: x.address2,
                                    city: x.city,
                                    state: x.state,
                                    zip: x.zip,
                                    country: x.country
                                },
                                featuredImage: x.featuredImage,
                                imageGallery: x.imageGallery,
                                calendars: x.calendars,
                                listed: x.listed,
                                about: {
                                    summary: x.summary,
                                    sections: x.sections,
                                    amenities: x.amenities ? x.amenities.split('|') : [],
                                    guests: Number(x.guests),
                                    guestInfo: {
                                        adults: Number(x.adults),
                                        children: Number(x.children),
                                        infants: Number(x.infants)
                                    },
                                    bedrooms: Number(x.bedrooms),
                                    beds: Number(x.beds),
                                    bathrooms: Number(x.bathrooms),
                                    baths: Number(x.baths),
                                },
                                multiUnit: {
                                    isMultiUnit: x.isMultiUnit.toLowerCase() === 'true' ? true : false,
                                    // TODO: add linked unit validation
                                    linkedUnits: x.linkedUnits
                                },
                                pricing: {
                                    baseRate: Number(x.baseRate),
                                    deposit: Number(x.deposit),
                                    cleaning: Number(x.cleaning),
                                    tax: Number(x.tax),
                                    extraFees: x.extraFees.map((y: any) => {
                                        y.number = Number(y.number)
                                        return y
                                    }),
                                    // rangedPricing: x.rangedPricing
                                },
                                virtualTour: x.virtualTour,
                                authorizedUsers: x.authorizedUsers.map((y: any) => {
                                    y.email = y.email.toLowerCase()
                                    return y
                                }),
                                public: x.public.toLowerCase() === 'true' ? true : false,
                                services: x.services ? stringToObject(x.services) : {}
                            }
                            return dataEntry
                        }}
                    />
                </Col>
            </Row>
        </Container>
    )
}

const entryRenderer = (entry: any) => {
    return (
        <FullPropertyEntry propertyInfo={entry} />
    )
}

const complexSplitFields: string[] = [
    'authorizedUsers',
    'aliases',
    'calendars',
    'extraFees',
    'imageGallery',
    'linkedUnits',
    'listed',
    'sections',
]

const stringToObject = (stringEntry: string): { [key: string]: boolean } => {
    const stringObject: any = {}
    stringEntry.split(',').forEach(x => {
        stringObject[x.trim()] = true
    })
    return stringObject
}