import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BuildingForm from "../../../Components/Forms/BuildingForm/BuildingForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditBuildingMutation, useGetBuildingByIdQuery } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function EditBuildingPage() {
  const [
    editBuilding,
    {
      error,
    }
  ] = useEditBuildingMutation()

  const { buildingId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    uploadImageFn,
    imageUploadProgress,
    uploadImageMultiFn,
    // isLoading: uploadImageLoading,
    error: uploadImageError,
  } = useUploadImage()

  const {
    data,
    isLoading,
    error: buildingError,
  } = useGetBuildingByIdQuery(buildingId, { skip: !buildingId })

  const buildingData = data || {}

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (buildingId) {
      const editedBuilding = await editBuilding({ buildingId, buildingInfo: values }).unwrap()
      if (editedBuilding._id) {
        dispatch(navigateTo(`/building/${editedBuilding._id}`))
      }
      return editedBuilding
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Building</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {buildingError && (<>{JSON.stringify(buildingError)}<br /></>)}
          {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}

          {buildingId && buildingData && (
            <BuildingForm
              disabled={isLoading}
              initialValues={buildingData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
              imageUploadProgress={imageUploadProgress}
              uploadImageMultiFn={uploadImageMultiFn}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
