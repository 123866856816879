import { useSelector } from "react-redux";
import { accountSelector } from "../../redux/account/accountSlice";

export default function useCheckRole(
    role: string,
    options: any = {}
) {
    const accountInfo = useSelector(accountSelector)

    return accountInfo.role === role
}