import * as yup from 'yup'

export const blankBooking = () => {
  return {
    checkIn: '',
    checkOut: '',
    propertyId: '',
    dateRange: null, // delete this
    pricePerNight: '',
    bookingTotal: '', // work this in
    cleaning: '', // fill this in with the property cleaning
    source: '',
    sourceConfirmation: '',
    contactInfo: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    dateBooked: '',
    dateInquired: '',
    bookingStatus: 'new',
    requiresConfirmation: false,
    confirmationCode: '',
    requiresBookingGuestInfo: false,
    bookingGuestInfoId: '',
    guestInfo: {
      guestTotal: '',
      adults: '',
      children: '',
      infants: '',
    },
    cancellation: {
      amount: '',
      amountType: 'percentage',
    },
    linkedServices: [],
    notes: '',
  }
}

export const bookingSchema = yup.object().shape({
  checkIn: yup.date().required(),
  checkOut: yup.date().required(),
  propertyId: yup.string().required(),
  pricePerNight: yup.number().nullable(),
  bookingTotal: yup.number().nullable(),
  cleaning: yup.number().nullable(),
  source: yup.string().nullable(),
  sourceConfirmation: yup.string(),
  contactInfo: yup.object().shape({
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    email: yup.string().email().nullable(),
    phone: yup.string().nullable(),
  }),
  dateBooked: yup.date().nullable(),
  dateInquired: yup.date().nullable(),
  bookingStatus: yup.string().required(),
  requiresConfirmation: yup.boolean(),
  confirmationCode: yup.string().nullable(),
  requiresBookingGuestInfo: yup.boolean(),
  bookingGuestInfoId: yup.string().nullable(),
  guestInfo: yup.object().shape({
    guestTotal: yup.number().nullable(),
    adults: yup.number().nullable(),
    children: yup.number().nullable(),
    infants: yup.number().nullable(),
  }),
  cancellation: yup.object().shape({
    amount: yup.number().nullable(),
    amountType: yup.string().nullable(),
  }),
  notes: yup.string().nullable(),
})
