import { Col, Container, Row } from "react-bootstrap";
import InquiryForm from "../../../Components/Forms/InquiryForm/InquiryForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewInquiryMutation } from "../../../services/bClientApi";

export default function AddInquiryPage() {
  const [addNewInquiry, { error, isLoading }] = useAddNewInquiryMutation()
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newInquiry = await addNewInquiry(values).unwrap()
    if (newInquiry._id) {
      dispatch(navigateTo(`/inquiry/${newInquiry._id}`))
    }
    return newInquiry
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add Inquiry</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <InquiryForm
            initialValues={{
                name: '',
            }}
            disabled={isLoading}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
