import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropertyTaskForm from "../../../Components/Forms/PropertyTaskForm/PropertyTaskForm";
import useActiveQueryHook from "../../../Hooks/UseActiveQuery/useActiveQueryHook";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import useUsersSelectValues from "../../../Hooks/UseUsersQuery/UseUsersSelectValues";
import { accountSelector } from "../../../redux/account/accountSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewPropertyTaskMutation } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function AddPropertyTaskPage() {

    const [
        addNewPropertyTask,
        {
            isLoading: addNewPropertyTaskLoading,
            error: addNewPropertyTaskError
        }
    ] = useAddNewPropertyTaskMutation()

    const userRef = useUserRefs()
    const userSelectOptions = useUsersSelectValues(userRef)

    const { myProperties } = useMyProperties()
    const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)
    const { email, role } = useSelector(accountSelector)

    const {
        uploadImageFn,
        imageUploadProgress,
        uploadImageMultiFn,
        // isLoading: uploadImageLoading,
        error: uploadImageError,
      } = useUploadImage()

    const dispatch = useAppDispatch()

    const { activeQuery } = useActiveQueryHook({})
    const initialData = activeQuery.query

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Add Property Task</h1>
                    <hr />

                    {addNewPropertyTaskError && (<>{JSON.stringify(addNewPropertyTaskError)}<br /></>)}
                    {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}

                    {email && role && (
                        <PropertyTaskForm
                            initialValues={{
                                createdBy: email,
                                status: role === 'admin' || role.indexOf('lead') > -1 ? 'approved' : 'new',
                                ...initialData
                            }}
                            disabled={addNewPropertyTaskLoading}
                            userSelectOptions={userSelectOptions}
                            onSubmit={async (values) => {
                                const newTask = await addNewPropertyTask(values).unwrap()
                                if (newTask._id) {
                                    dispatch(navigateTo(`/property-task/${newTask._id}`))
                                }
                                return newTask

                            }}
                            propertyOptionList={myPropertySelectOptions}
                            uploadImageFn={uploadImageFn}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={uploadImageMultiFn}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}