import { Col, Container, Row } from 'react-bootstrap';
import './footer.scss'
import { BCLIENT_APP_VERSION } from '../../Config/config';

export default function Footer() {
  const currentYear  =  new Date().getFullYear();
  return (
    <Container>
      <Row>
        <Col>
          Copyright {currentYear} Benichay Brothers <br />
          v.{BCLIENT_APP_VERSION}
        </Col>
      </Row>
    </Container>
  )
}