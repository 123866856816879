import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BookingGuestForm from "../../../Components/Forms/BookingGuestForm/BookingGuestForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { useEditBookingGuestMutation, useGetBookingGuestByIdQuery } from "../../../services/bClientApi";

export default function EditBookingGuestPage() {

  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)

  const { bookingGuestId = '' } = useParams()
  const dispatch = useAppDispatch()

  const [editBookingGuest] = useEditBookingGuestMutation()

  const {
    data,
    error,
    isLoading,
  } = useGetBookingGuestByIdQuery(bookingGuestId, { skip: !bookingGuestId })

  const bookingGuestData = data || {}

  const { uploadImageFn, uploadImageMultiFn, imageUploadProgress } = useUploadImage()

  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (bookingGuestId) {
      const editedBookingGuest = await editBookingGuest({ bookingGuestId, bookingGuestInfo: values }).unwrap()
      if (editedBookingGuest._id) {
        dispatch(navigateTo(`/booking-guest/${editedBookingGuest._id}`))
      }
      return editedBookingGuest
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit BookingGuest</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {bookingGuestId && bookingGuestData && !isLoading && (
            <BookingGuestForm
              disabled={isLoading}
              initialValues={bookingGuestData}
              onSubmit={handleSubmit}
              uploadImageFn={uploadImageFn}
              uploadImageMultiFn={uploadImageMultiFn}
              imageUploadProgress={imageUploadProgress}
              propertyOptionList={propertyOptions}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
