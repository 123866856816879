import { useMemo } from "react"
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BukinForm from "../../../Components/Forms/BukinForm/BukinForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useEditBukinMutation, useGetBukiblesQuery, useGetBukinByIdQuery } from "../../../services/bClientApi";
import { BukibleEntryType } from "../../../Types/bukibleTypes";

export default function EditBukinPage() {
  const [
    editBukin,
    {
      error: editError,
      isLoading: editIsLoading,
    }
  ] = useEditBukinMutation()
  const { bukinId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data: bukiblesData,
  } = useGetBukiblesQuery({
    bukiblesQuery: {},
    pagination: {
      limit: 500,
      page: 1,
    }
  })

  const {
    data,
    isLoading,
    error,
  } = useGetBukinByIdQuery(bukinId, { skip: !bukinId })

  const bukinData = data || {}

  const bukibleSelectOptions = useMemo(() => {
    const bukiblesList = bukiblesData?.docs || []
    if (!bukiblesList) { return [] }
    return bukiblesList.map((bukible: BukibleEntryType) => ({
      value: bukible._id,
      text: bukible.title
    }))
  }, [bukiblesData])


  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    if (bukinId) {
      const editedBukin = await editBukin({ bukinId, bukinInfo: values }).unwrap()
      if (editedBukin._id) {
        dispatch(navigateTo(`/bukin/${editedBukin._id}`))
      }
      return editedBukin
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Bukin</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}

          {bukinId && bukinData && !isLoading && (
            <BukinForm
              disabled={isLoading || editIsLoading}
              initialValues={bukinData}
              onSubmit={handleSubmit}
              bukibleSelectOptions={bukibleSelectOptions}
            />
          )}

        </Col>
      </Row>
    </Container>
  )
}
