import { useMemo } from "react"

export default function useMyPropertiesSelectValues(
  myProperties: { [propertyId: string]: any }
) {
  const propertyOptions = useMemo(() => {
    const propertyOptionsList: { value: string, text: string }[] = []
    if (Object.keys(myProperties).length > 0) {
      Object.keys(myProperties).forEach((pId) => {
        if (!myProperties[pId].public) { return }
        const propertyOptionsListEntry = {
          value: pId,
          text: myProperties[pId].title.split(' - ')[0].trim()
        }
        propertyOptionsList.push(propertyOptionsListEntry)
      })
    }
    return propertyOptionsList.sort((x, y) => {
      return x.text < y.text ? -1 : 1
    })
  }, [myProperties])

  return propertyOptions
}
