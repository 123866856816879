export interface InventoryType {
  places: {
    id: string,
    name: string,
    description: string,
    inventoryGroups: {
      id: string,
      name: string,
      inventory: InventoryEntry[],
    }[]
  }[]
}

export interface InventoryEntry {
  itemId: string,
  name: string,
  count: number,
  actualCount: number,
  img: string,
  checked: boolean,
}

export interface InventoryCreationOptions {
  bedrooms: number,
  beds: number,
  baths: number,
  bathrooms: number
}

export const createGenericInventory = (options: InventoryCreationOptions): InventoryType => {
	const {
    bedrooms,
		beds,
    baths,
		bathrooms
	} = options
  return {
    places: [
      {
        id: 'kitchen',
        name: 'Kitchen',
        description: 'This is the damn kitchen',
        inventoryGroups: [
          {
            id: ' cookwareTools',
            name: 'Cookware & Tools',
            inventory: [
              {
                itemId: 'fryingPan',
                name: 'Frying Pan',
                count: 1,
                actualCount: 1,
                img: 'Frying-Pan.jpg',
                checked: false,
              },
              {
                itemId: 'pastaPot',
                name: 'Pasta Pot',
                count: 1,
                actualCount: 1,
                img: 'Pasta-Pot.jpg',
                checked: false,
              },
              {
                itemId: 'smallSaucePot',
                name: 'Small Sauce Pot',
                count: 1,
                actualCount: 1,
                img: 'Small-Sauce-Pot.jpg',
                checked: false,
              },
              {
                itemId: 'strainer',
                name: 'Strainer',
                count: 2,
                actualCount: 2,
                img: 'Strainer.jpg',
                checked: false,
              },
              {
                itemId: 'bakingPans',
                name: 'Baking Pans',
                count: 1,
                actualCount: 1,
                img: 'Baking-Pans.jpg',
                checked: false,
              },
              {
                itemId: 'cookingUtensils',
                name: 'Cooking Utensils',
                count: 1,
                actualCount: 1,
                img: 'Cooking-Utensils.jpg',
                checked: false,
              },
              {
                itemId: 'measuringCup',
                name: 'Measuring Cup',
                count: 1,
                actualCount: 1,
                img: 'Measuring-Cup.jpg',
                checked: false,
              },
              {
                itemId: 'wineOpener',
                name: 'Wine Opener',
                count: 1,
                actualCount: 1,
                img: 'Wine-Opener.jpg',
                checked: false,
              },
              {
                itemId: 'canOpener',
                name: 'Can Opener',
                count: 1,
                actualCount: 1,
                img: 'Can-Opener.jpg',
                checked: false,
              },
              // {
              //   itemId: 'knifeSet',
              //   name: 'Knife Set',
              //   count: 1,
              //   actualCount: 1,
              //   img: 'Knife-Set.jpg',
              //   checked: false,
              // },
              {
                itemId: 'steakKnives',
                name: 'Steak Knives',
                count: 6,
                actualCount: 6,
                img: 'Steak-Knives.jpg',
                checked: false,
              },
              {
                itemId: 'kitchenShears',
                name: 'Kitchen Shears',
                count: 1,
                actualCount: 1,
                img: 'Kitchen-Shears.jpg',
                checked: false,
              },
              {
                itemId: 'cuttingBoard',
                name: 'Cutting Board',
                count: 1,
                actualCount: 1,
                img: 'Cutting-Board.jpg',
                checked: false,
              },
              {
                itemId: 'blender',
                name: 'Blender',
                count: 1,
                actualCount: 1,
                img: 'Blender.jpg',
                checked: false,
              },
              {
                itemId: 'toaster',
                name: 'Toaster',
                count: 1,
                actualCount: 1,
                img: 'Toaster.jpg',
                checked: false,
              },
              {
                itemId: 'keurigCoffeeMaker',
                name: 'Keurig Coffee Maker',
                count: 1,
                actualCount: 1,
                img: 'Keurig-Coffee-Maker.jpg',
                checked: false,
              },
            ]
          },
          {
            id: 'dinnerwareUtensils',
            name: 'Dinnerware & Utensils',
            inventory: [
              {
                itemId: 'dinnerPlates',
                name: 'Dinner Plates',
                count: 6,
                actualCount: 6,
                img: 'Large-Plates.jpg',
                checked: false,
              },
              {
                itemId: 'saladPlates',
                name: 'Salad Plates',
                count: 6,
                actualCount: 6,
                img: 'Small-Plates.jpg',
                checked: false,
              },
              {
                itemId: 'bowls',
                name: 'Bowls',
                count: 6,
                actualCount: 6,
                img: 'Bowls.jpg',
                checked: false,
              },
              {
                itemId: 'coffeeMugs',
                name: 'Coffee Mugs',
                count: 6,
                actualCount: 6,
                img: 'Coffee-Mugs.jpg',
                checked: false,
              },
              {
                itemId: 'drinkingGlasses',
                name: 'Drinking Glasses',
                count: 6,
                actualCount: 6,
                img: 'Drinking-Glasses.jpg',
                checked: false,
              },
              {
                itemId: 'wineGlasses',
                name: 'Wine Glasses',
                count: 6,
                actualCount: 6,
                img: 'Wine-Glasses.jpg',
                checked: false,
              },
              {
                itemId: 'saladBowl',
                name: 'Salad Bowl',
                count: 1,
                actualCount: 1,
                img: 'Salad-Bowl.jpg',
                checked: false,
              },
              {
                itemId: 'butterKnives',
                name: 'Butter Knives',
                count: 6,
                actualCount: 6,
                img: 'Butter-Knives.jpg',
                checked: false,
              },
              {
                itemId: 'saladForks',
                name: 'Salad Forks',
                count: 6,
                actualCount: 6,
                img: 'Salad-Forks.jpg',
                checked: false,
              },
              {
                itemId: 'dinnerForks',
                name: 'Dinner Forks',
                count: 6,
                actualCount: 6,
                img: 'Dinner-Forks.jpg',
                checked: false,
              },
              {
                itemId: 'teaSpoons',
                name: 'Tea Spoons',
                count: 6,
                actualCount: 6,
                img: 'Tea-Spoons.jpg',
                checked: false,
              },
              {
                itemId: 'tableSpoons',
                name: 'Table Spoons',
                count: 6,
                actualCount: 6,
                img: 'Table-Spoons.jpg',
                checked: false,
              },
              // {
              // 	itemId: 'silverwareSets',
              // 	name: 'Silverware Sets',
              // 	count: 6,
              // 	actualCount: 6,
              // 	img: 'Silverware-Set.jpg',
              // 	checked: false,
              // },
            ]
          },
          {
            id: 'cleaningSupplies',
            name: 'Cleaning Supplies',
            inventory: [
              {
                itemId: 'smallDishwashingLiquid',
                name: 'Small Dishwashing Liquid',
                count: 2,
                actualCount: 2,
                img: 'Dishwashing-Liquid-Small.jpg',
                checked: false,
              },
              {
                itemId: 'allPurposeCleaner',
                name: 'All Purpose Cleaner',
                count: 1,
                actualCount: 1,
                img: 'All-Purpose-Cleaner.jpg',
                checked: false,
              },
              {
                itemId: 'kitchenSponge',
                name: 'Kitchen Sponge',
                count: 1,
                actualCount: 1,
                img: 'Kitchen-Sponge.jpg',
                checked: false,
              },
              {
                itemId: 'kitchenWashCloth',
                name: 'Kitchen Wash Cloth',
                count: 2,
                actualCount: 2,
                img: 'Kitchen-Wash-Cloth.jpg',
                checked: false,
              },
              {
                itemId: 'paperTowels',
                name: 'Paper Towels',
                count: 2,
                actualCount: 2,
                img: 'Paper-Towels.jpg',
                checked: false,
              },
              {
                itemId: 'garbageBags',
                name: 'Garbage Bags',
                count: 1,
                actualCount: 1,
                img: 'Garbage-Bag-Box.jpg',
                checked: false,
              },
              {
                itemId: 'dishwasherDetergent',
                name: 'Dishwasher Detergent',
                count: 1,
                actualCount: 1,
                img: 'Dishwasher-Detergent.jpg',
                checked: false,
              },
              {
                itemId: 'laundryDetergent',
                name: 'Laundry Detergent',
                count: 1,
                actualCount: 1,
                img: 'Laundry-Detergent.jpg',
                checked: false,
              },
            ]
          },
          {
            id: 'essentials',
            name: 'The Essentials',
            inventory: [
              {
                itemId: 'coffeeAndTeaPods',
                name: 'Coffee & Tea Pods',
                count: 1,
                actualCount: 1,
                img: 'Coffe-and-Tea-Pods.jpg',
                checked: false,
              },
              {
                itemId: 'coffeePods',
                name: 'Coffee Pods',
                count: 25,
                actualCount: 25,
                img: 'Coffee-Pods.jpg',
                checked: false,
              },
              {
                itemId: 'teaPods',
                name: 'Tea Pods',
                count: 10,
                actualCount: 10,
                img: 'Tea-Pods.jpg',
                checked: false,
              },
              {
                itemId: 'sugar',
                name: 'Sugar',
                count: 1,
                actualCount: 1,
                img: 'Sugar.jpg',
                checked: false,
              },
              {
                itemId: 'salt',
                name: 'Salt',
                count: 1,
                actualCount: 1,
                img: 'Salt.jpg',
                checked: false,
              },
              {
                itemId: 'blackPepper',
                name: 'Black Pepper',
                count: 1,
                actualCount: 1,
                img: 'Black-Pepper.jpg',
                checked: false,
              },
              {
                itemId: 'oliveOil',
                name: 'Olive Oil',
                count: 1,
                actualCount: 1,
                img: 'Olive-Oil.jpg',
                checked: false,
              },
              {
                itemId: 'bottledWater',
                name: 'Bottled Water',
                count: 6,
                actualCount: 6,
                img: 'Bottled-Water.jpg',
                checked: false,
              },
            ]
          }
        ]
      },
      {
        id: 'bedroom',
        name: 'Bedroom',
        description: 'The damn bedroom',
        inventoryGroups: [
          {
            id: 'bedroomSupplies',
            name: 'Bedroom Supplies',
            inventory: [
              {
                itemId: 'bathTowels',
                name: 'Bath Towels',
                count: 2 * beds,
                actualCount: 2 * beds,
                img: 'Bath-Towels.jpg',
                checked: false,
              },
              {
                itemId: 'clothesHangers',
                name: 'Clothes Hangers',
                count: 4 * bedrooms,
                actualCount: 4 * bedrooms,
                img: 'Clothes-Hangers.jpg',
                checked: false,
              },
            ]
          }
        ]
      },
      {
        id: 'bathroom',
        name: 'Bathroom',
        description: 'The damn bathroom',
        inventoryGroups: [
          {
            id: 'bathroomSupplies',
            name: 'Bathroom Supplies',
            inventory: [
              {
                itemId: 'handTowels',
                name: 'Hand Towels',
                count: 3 * bathrooms,
                actualCount: 3 * bathrooms,
                img: 'Hand-Towels.jpg',
                checked: false,
              },
              {
                itemId: 'washCloths',
                name: 'Wash Cloths',
                count: 6,
                actualCount: 6,
                img: 'Wash-Cloths.jpg',
                checked: false,
              },
              {
                itemId: 'handSoap',
                name: 'Hand Soap',
                count: 1 * bathrooms,
                actualCount: 1 * bathrooms,
                img: 'Hand-Soap.jpg',
                checked: false,
              },
              {
                itemId: 'shampooConditionerAndBodyWash',
                name: 'Shampoo, Conditioner and Body Wash',
								count: 1 * baths,
                actualCount: 1 * baths,
                img: 'Shampoo-Conditioner-and-Body-Wash.jpg',
                checked: false,
              },
              {
                itemId: 'toiletPaper',
                name: 'Toilet Paper',
                count: 3 * bathrooms,
                actualCount: 3 * bathrooms,
                img: 'Toilet-Paper.jpg',
                checked: false,
              },
              // {
              // 	itemId: 'plungerAndToiletBrush',
              // 	name: 'Plunger & Toilet Brush',
              // 	count: 1,
              // 	actualCount: 1,
              // 	img: 'Plunger-and-Toilet-Brush.jpg',
              // 	checked: false,
              // },
              {
                itemId: 'plunger',
                name: 'Plunger',
                count: 1 * bathrooms,
                actualCount: 1 * bathrooms,
                img: 'Plunger.jpg',
                checked: false,
              },
              {
                itemId: 'toiletBrush',
                name: 'Toilet Brush',
                count: 1 * bathrooms,
                actualCount: 1 * bathrooms,
                img: 'Toilet-Brush.jpg',
                checked: false,
              },
              {
                itemId: 'hairDryer',
                name: 'Hair Dryer',
                count: 1,
                actualCount: 1,
                img: 'Hair-Dryer.jpg',
                checked: false,
              },
            ]
          }
        ]
      },
      {
        id: 'supplyCloset',
        name: 'Supply Closet',
        description: 'The damn supply closet',
        inventoryGroups: [
          {
            id: 'linensAndExtras',
            name: 'Linens and Extras',
            inventory: [
              {
                itemId: 'beachTowels',
                name: 'Beach Towels',
                count: 2 * beds,
                actualCount: 2 * beds,
                img: 'Beach-Towels.jpg',
                checked: false,
              },
              {
                itemId: 'bathTowels',
                name: 'Bath Towels',
                count: 1 * beds,
                actualCount: 1 * beds,
                img: 'Bath-Towels.jpg',
                checked: false,
              },
              // {
              //   itemId: 'handTowels',
              //   name: 'Hand Towels',
              //   count: 4,
              //   actualCount: 4,
              //   img: 'Hand-Towels.jpg',
              //   checked: false,
              // },
              {
                itemId: 'washCloths',
                name: 'Wash Cloths',
                count: 6,
                actualCount: 6,
                img: 'Wash-Cloths.jpg',
                checked: false,
              },
              // {
              // 	itemId: 'extraBedSheetsAndLinens',
              // 	name: 'Extra Bed Sheets & Linens',
              // 	count: 1,
              // 	actualCount: 1,
              // 	img: 'Extra-Bed-Sheets-and-Linens.jpg',
              // 	checked: false,
              // },
              {
                itemId: 'pillowAndComforter',
                name: 'Pillow & Comforter',
                count: 1,
                actualCount: 1,
                img: 'Bed-Sheets-Comforter.jpg',
                checked: false,
              },
              {
                itemId: 'iron',
                name: 'Iron',
                count: 1,
                actualCount: 1,
                img: 'Iron.jpg',
                checked: false,
              },
              {
                itemId: 'ironingBoard',
                name: 'Ironing Board',
                count: 1,
                actualCount: 1,
                img: 'Ironing-Board.jpg',
                checked: false,
              },
              {
                itemId: 'broom',
                name: 'Broom',
                count: 1,
                actualCount: 1,
                img: 'Broom.jpg',
                checked: false,
              },
              {
                itemId: 'sweepingPan',
                name: 'Sweeping Pan',
                count: 1,
                actualCount: 1,
                img: 'Sweeping-Pan.jpg',
                checked: false,
              },
            ]
          }
        ]
      },
      {
        id: 'general',
        name: 'General Supplies',
        description: 'General Stuff',
        inventoryGroups: [
          {
            id: 'livingRoom',
            name: 'Living Room',
            inventory: [
              {
                itemId: 'tvRemote',
                name: 'TV Remote',
                count: 1,
                actualCount: 1,
                img: 'TV-Remote.jpg',
                checked: false,
              },
            ]
          }
        ]
      },
    ]
  }
}
