import { Form } from "react-bootstrap";

export default function FileUploadField(props: {
    onFileAdd: (files: FileList) => void
    multiple: boolean
    fieldName: string
    disabled: boolean
}) {
    const {
        onFileAdd,
        multiple,
        fieldName,
        disabled,
    } = props

    return (
        <Form.Group className="mb-3">
            <Form.Label>File</Form.Label>
            <Form.Control
                type="file"
                name={fieldName}
                multiple={multiple}
                disabled={disabled}
                onChange={async (event: any) => {
                    if (event.currentTarget?.files) {
                        onFileAdd(event.currentTarget.files)
                    }
                }}
            />
        </Form.Group>
    )
}