import { Col, Container, Row } from "react-bootstrap";
import PropertyPriceForm from "../../../Components/Forms/PropertyPriceForm/PropertyPriceForm";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { useAddNewPropertyPriceMutation } from "../../../services/bClientApi";

export default function AddPropertyPricePage() {
  const [addNewPropertyPrice, {
    error,
    isLoading,
  }] = useAddNewPropertyPriceMutation()
  const dispatch = useAppDispatch()
  const handleSubmit = async (data: any) => {
    const values: any = {
      ...data
    }
    const newPropertyPrice = await addNewPropertyPrice(values).unwrap()
    if (newPropertyPrice._id) {
      dispatch(navigateTo(`/property-price/${newPropertyPrice._id}`))
    }
    return newPropertyPrice
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Add PropertyPrice</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          <PropertyPriceForm
            initialValues={{
                price: 0,
                date: undefined,
                propertyId: '',
            }}
            disabled={isLoading}
            onSubmit={handleSubmit}
          />

        </Col>
      </Row>
    </Container>
  )
}
