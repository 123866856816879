import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import BookingGuestEntry from "../../../Components/BookingGuests/BookingGuestEntry/BookingGuestEntry"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import { useGetBookingByIdQuery, useGetBookingGuestByIdQuery } from "../../../services/bClientApi"

export default function BookingGuestPage() {

  const { myProperties } = useMyProperties()
  const { bookingGuestId = '' } = useParams()
  // const { _id, bookingGuestData, message, loading, error } = useSelector(bookingGuestSelector)

  const {
    data,
    isLoading,
    error
  } = useGetBookingGuestByIdQuery(bookingGuestId, { skip: !bookingGuestId })

  const bookingGuestData = data || {}

  const { propertyId } = bookingGuestData

  const {
    data: bookingData,
    // isLoading: bookingDataLoading,
    // isFetching: bookingDataFetching,
  } = useGetBookingByIdQuery(bookingGuestData?.bookingId, { skip: !bookingGuestData?.bookingId })


  const propertyData: any = propertyId && myProperties[propertyId] ? myProperties[propertyId] : {}

  return (
    <Container>
      <Row>
        <Col>
          <h1>Single BookingGuest Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}

          {bookingGuestId && !isLoading && bookingGuestData && (
            <div className="booking-guestinfo">
              {/* <pre>
                {JSON.stringify(bookingGuestData, null, 2)}            
              </pre> */}
              <BookingGuestEntry
                bookingGuestInfo={bookingGuestData}
                bookingData={bookingData}
                propertyData={propertyData}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
