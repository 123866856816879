import { useState } from "react";
import { Button } from "react-bootstrap";
import { formatAddress, googleAddress } from "../../Helpers/Property/propertyAddressFormatters";
import timeService from "../../services/time/timeService";
import { PropertyTaskType } from "../../Types/propertyTaskType";
import { PropertyType } from "../../Types/propertyTypes";
import ImageUploadField from "../FormFields/ImageUpload/ImageUpload";
import QuickForm from "../Forms/QuickForm/QuickForm";
import PropertyTaskBadges from "./PropertyTaskBadges";
import './propertyTaskEntryView.scss';

export default function PropertyTaskEntryView(props: {
    taskInfo: PropertyTaskType,
    propertyInfo?: PropertyType,
    userRef?: any,
    occupancyInfo?: any,
    updateTask: (updateTaskPayload: {
        _id: string,
        propertyTaskInfo: Partial<PropertyTaskType>
    }) => void,
    disabled?: boolean,
    username?: string,
    role?: string,
    uploadImageMultiFn: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
    imageUploadProgress: string,
}) {

    const [showEndWorkForm, setShowEndWorkForm] = useState(false);
    const [workEditIndex, setWorkEditIndex] = useState(-1);

    const {
        taskInfo,
        propertyInfo,
        occupancyInfo,
        updateTask,
        disabled,
        username,
        role,
        uploadImageMultiFn,
        imageUploadProgress,
        userRef
    } = props;

    const {
        _id,
        // propertyId,
        title,
        description,
        picture,
        status,
        priority,
        dueDate,
        createdBy,
        createdAt,
        assignedTo,
        // completedDate,
        workLog,
        progressPics,
        tags,
    } = taskInfo || {};

    const [uploadingImage, setUploadingImage] = useState(false)

    const parseUserName = (userId: string) => {
        if (userRef && userRef[userId]) {
            return userRef[userId].firstName + ' ' + userRef[userId].lastName
        }
        return userId
    }

    const handleUpdateTask = (taskUpdates: Partial<PropertyTaskType>) => {
        if (!_id) { return; }
        updateTask({
            _id,
            propertyTaskInfo: taskUpdates,
        });
    }

    const getTimeWorked = (start: Date, end: Date) => {
        if (!start || !end) return 0;
        const timeWorked = timeService.timeDiff(start, end, { diffDuration: 'minutes' });
        return timeWorked > 30 ? `${Math.floor(timeWorked / 60)} hours ${Math.floor(timeWorked % 60)} minutes` : `${Math.floor(timeWorked)} minutes`
    }

    const totalTimeWorked = () => {
        if (!workLog) return 0;
        let totalTime = 0;
        workLog.forEach((log) => {
            const {
                start,
                end,
            } = log;
            if (!start || !end) {
                return;
            }
            totalTime += timeService.timeDiff(start, end, { diffDuration: 'minutes' });
        })
        return totalTime > 30 ? `${Math.floor(totalTime / 60)} hours ${Math.floor(totalTime % 60)} minutes` : `${Math.floor(totalTime)} minutes`
    }

    const workInProgress = () => {
        if (!workLog) return false;
        return workLog.some((log) => {
            return !log.end;
        })
    }

    const workInProgressMe = () => {
        if (!workLog) return false;
        return workLog.some((log) => {
            return !log.end && log.person === username;
        })
    }

    const startWork = () => {
        if (!username) { return; }
        const taskUpdates: Partial<PropertyTaskType> = {
            status: 'in progress',
            workLog: [
                ...workLog,
                {
                    start: new Date(),
                    // end: null,
                    person: username,
                    description: ''
                }
            ]
        }
        if (!assignedTo) {
            taskUpdates.assignedTo = username;
        }
        handleUpdateTask(taskUpdates);
    }

    const endWork = (workDescription: string) => {
        if (!username) { return; }
        if (!workLog) { return; }
        if (!workInProgressMe()) { return; }

        const taskUpdates: Partial<PropertyTaskType> = {
            workLog: workLog.map((log) => {
                if (log.person === username && !log.end) {
                    return {
                        ...log,
                        end: new Date(),
                        description: workDescription,
                    }
                }
                return log;
            })
        }
        handleUpdateTask(taskUpdates);
        setShowEndWorkForm(false);
    }

    const editWorkLogIndex = (index: number, hours: number) => {
        const editedWorkLog = workLog.map((log, idx) => {
            if (idx === index) {
                return {
                    ...log,
                    end: timeService.createLuxonDate(log.start).plus({ hours }).toJSDate(),
                }
            }
            return log;
        })
        handleUpdateTask({ workLog: editedWorkLog });
        setWorkEditIndex(-1);
    }

    return (
        <div className="property-task-entry-view">

            <small>
                Created By: {parseUserName(createdBy)} / &nbsp;
                Created At: {timeService.getFormattedTime(createdAt)} / &nbsp;
                Due Date: {dueDate ? timeService.getFormattedTime(dueDate) : 'n/a'}
            </small>


            <hr />

            <h3>{title}</h3>

            <PropertyTaskBadges
                status={status}
                priority={priority}
                tags={tags}
                occupancyInfo={occupancyInfo}
            />

            {description && (
                <div>
                    {description}
                </div>
            )}

            {propertyInfo && (
                <div>
                    <b>
                        {propertyInfo.title}
                    </b>
                    <br />
                    <a target="_blank" rel="noreferrer" href={`${googleAddress(propertyInfo.address)}`}>
                        {formatAddress(propertyInfo.address)}
                    </a>
                </div>
            )}

            <hr />
            {status === 'new' && (
                <Button
                    className="me-2 mb-2"
                    onClick={() => { handleUpdateTask({ status: 'approved' }) }}
                    disabled={disabled || role !== 'admin'}
                    color="primary">
                    Approve
                </Button>
            )}

            {status !== 'new' && !workInProgressMe() && (
                <Button
                    className="me-2 mb-2"
                    onClick={() => { startWork() }}
                    disabled={disabled}
                    color="primary">
                    Start Work
                </Button>
            )}

            {status !== 'new' && workInProgressMe() && !showEndWorkForm && (
                <Button
                    className="me-2 mb-2"
                    onClick={() => { setShowEndWorkForm(true) }}
                    disabled={disabled}
                    color="primary">
                    End Work
                </Button>
            )}

            {showEndWorkForm && (
                <>
                    <QuickForm
                        initialData={{
                            workDescription: '',
                        }}
                        disabled={disabled}
                        onSubmit={(data) => {
                            endWork(data.workDescription);
                        }}
                        onCancel={() => {
                            setShowEndWorkForm(false);
                        }}
                        fields={[
                            {
                                fieldName: 'workDescription',
                                fieldLabel: 'Work Description',
                                fieldType: 'text',
                                placeholder: 'Enter work description',
                                required: true,
                            },
                        ]}
                    />
                </>
            )}

            <hr />

            <h4>Work Log</h4>

            Time worked total: {totalTimeWorked()} <br /> <br />

            {workLog && workLog.map((log, index) => {
                const {
                    person,
                    start,
                    end,
                    description
                } = log
                return (
                    <div key={index} className="work-log-entry mb-2">
                        {parseUserName(person)}: {timeService.getFormattedTime(start)} - {end ? timeService.getFormattedTime(end) : 'n/a'} <br />
                        Hours Worked: {end ? getTimeWorked(start, end) : 'n/a'}
                        <div
                            role="button"
                            className="d-inline small"
                            onClick={() => {
                                setWorkEditIndex(index);
                            }}>
                            ⚙️
                        </div>
                        <br />
                        {description} <br />
                        {workEditIndex === index && (
                            <QuickForm
                                initialData={{
                                    hoursWorked: 0,
                                }}
                                disabled={disabled}
                                onSubmit={(data) => {
                                    editWorkLogIndex(index, data.hoursWorked)
                                }}
                                onCancel={() => {
                                    setWorkEditIndex(-1);
                                }}
                                fields={[
                                    {
                                        fieldName: 'hoursWorked',
                                        fieldLabel: 'Hours Worked',
                                        fieldType: 'number',
                                        placeholder: 'Enter hours worked',
                                        required: true,
                                    },
                                ]}
                            />
                        )}

                    </div>
                )
            })}

            <hr />
            <h4>Progress Pics</h4>
            {progressPics && (
                <div className="progress-pics-container">
                    {picture && (
                        <div className="progress-pic-entry">
                            <img src={picture} alt={title} />
                        </div>
                    )}
                    {progressPics.map((pic, index) => {
                        const {
                            imgUrl,
                            // timestamp,
                            description
                        } = pic
                        return (
                            <div key={index} className="progress-pic-entry">
                                <img src={imgUrl} alt={description} />
                                {/* {imgUrl} <br /> */}
                                {/* {timeService.getFormattedTime(timestamp)} <br /> */}
                                {/* {description} */}
                            </div>
                        )
                    })}
                </div>
            )}

            <hr />

            <h4>Add pictures</h4>
            {imageUploadProgress && (<>{imageUploadProgress} <br /></>)}
            <ImageUploadField
                key={'taskPictureUpload'}
                multipleImages={true}
                disabled={disabled || uploadingImage}
                uploadedImageCallback={async (imagesInfo: any[]) => {
                    setUploadingImage(true)
                    try {
                        const uploadedImageList = await uploadImageMultiFn(imagesInfo)
                        if (uploadedImageList) {
                            const mappedUploadedImages = uploadedImageList.map((imgUrl: string) => {
                                return {
                                    imgUrl: imgUrl,
                                    description: 'uploaded image',
                                    timeStamp: new Date()
                                }
                            })
                            const updatedProgressPics: any[] = [...progressPics, ...mappedUploadedImages]
                            if (updatedProgressPics) {
                                handleUpdateTask({ progressPics: updatedProgressPics })
                            }
                        }
                    } catch (err) {
                        // something went wrong
                    }
                    setUploadingImage(false)
                }}
            />

            <hr />

            {/* complete task button */}
            {status !== 'complete' && status !== 'new' && status !== 'confirmed' && !workInProgress() && (
                <Button
                    className="me-2 mb-2"
                    onClick={() => {
                        handleUpdateTask({ 
                            status: 'complete',
                            completedDate: new Date()
                        });
                    }}
                    disabled={disabled}
                    variant="primary">
                    Complete Task
                </Button>
            )}

            {role === 'admin' && status === 'complete' && (
                <>
                    {/* confirm task button */}
                    <Button
                        className="me-2 mb-2"
                        onClick={() => {
                            handleUpdateTask({ status: 'confirmed' });
                        }}
                        disabled={disabled}
                        variant="success">
                        Confirm Task
                    </Button>
                    {/* needs work button */}
                    <Button
                        className="me-2 mb-2"
                        onClick={() => {
                            handleUpdateTask({ status: 'approved' });
                        }}
                        disabled={disabled}
                        variant="danger">
                        Needs Work
                    </Button>
                </>
            )}

            <hr />
            ID: {_id}
        </div>
    )
}
