import { useState } from "react";
import { Container, Row, Col, Button, Collapse, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";
import PropertyTaskForm from "../../../Components/Forms/PropertyTaskForm/PropertyTaskForm";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import PropertyTaskByPersonTable from "../../../Components/PropertyTasks/PropertyTaskByPersonTable";
import PropertyTaskEntry from "../../../Components/PropertyTasks/PropertyTaskEntry";
import PropertyTaskEntryView from "../../../Components/PropertyTasks/PropertyTaskEntryView";
import tasksByPropertyHelper from "../../../Helpers/PropertyTasks/TasksByPropertyHelper";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useOccupancyRef from "../../../Hooks/UseOccupancyRef/UseOccupancyRef";
import useUserRefs from "../../../Hooks/UseUsersQuery/UseUserRefs";
import useUsersSelectValues from "../../../Hooks/UseUsersQuery/UseUsersSelectValues";
import { accountSelector } from "../../../redux/account/accountSlice";
import timeService from "../../../services/time/timeService";
import { PropertyTaskType } from "../../../Types/propertyTaskType";
import { useAddNewPropertyTaskMutation, useEditPropertyTaskMutation, useGetPropertyTasksQuery } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function PropertyTaskReporterPage() {
    const [
        addNewPropertyTask,
        {
            isLoading: addNewPropertyTaskLoading,
            error: addNewPropertyTaskError,
        }
    ] = useAddNewPropertyTaskMutation()

    const [
        editPropertyTask,
        {
            isLoading: editPropertyTaskLoading,
            error: editPropertyTaskError,
        }
    ] = useEditPropertyTaskMutation()
    const userRef = useUserRefs()
    const userSelectOptions = useUsersSelectValues(userRef)

    const { myProperties } = useMyProperties()
    const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)
    const propertyOccupancyRef = useOccupancyRef({ myProperties })

    const { email, role } = useSelector(accountSelector)

    const [taskModalSettings, setTaskModalSettings] = useState<any>({
        show: false,
    })

    const [openSections, setOpenSections] = useState<any>({})

    const {
        uploadImageFn,
        imageUploadProgress,
        uploadImageMultiFn,
        // isLoading: uploadImageLoading,
        error: uploadImageError,
      } = useUploadImage()

    const startDate = timeService.createLuxonDate(new Date()).startOf('month').toMillis()
    const endDate = timeService.createLuxonDate(new Date()).endOf('month').toMillis()
    const [activeQuery, setActiveQuery] = useState<any>({
        query: {
            // notStatus: ['completed', 'approved'],
            status: ['confirmed'],
            completedStart: startDate,
            completedEnd: endDate,
        },
        limit: 500,
        page: 1,
    })
    const {
        data,
        isLoading,
        error
    } = useGetPropertyTasksQuery({
        propertyTasksQuery: activeQuery.query,
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
        }
    })
    const propertyTasksList = data?.docs || []

    const toggleOpenSection = (sectionName: string) => {
        const newOpenSections = { ...openSections }
        if (newOpenSections[sectionName]) {
            delete newOpenSections[sectionName]
        } else {
            newOpenSections[sectionName] = true
        }
        setOpenSections(newOpenSections)
    }

    const clearTaskModal = () => {
        setTaskModalSettings({
            show: false,
        })
    }

    const toggleTaskTag = async (task: PropertyTaskType, tagValue: string) => {
        if (!task._id) { return }
        const tagSelected = task.tags.find((t: any) => t.tag === tagValue)
        let newTagList: { [s: string]: string }[] = [...task.tags]
        if (tagSelected) {
            newTagList = newTagList.filter((t: any) => t.tag !== tagValue)
        } else {
            newTagList.push({ tag: tagValue })
        }
        await editPropertyTask({ propertyTaskId: task._id, propertyTaskInfo: { tags: newTagList } }).unwrap()
    }

    const handleQuickView = (taskInfo: PropertyTaskType) => {
        setTaskModalSettings({
            show: true,
            quickView: taskInfo._id,
            taskInfo: taskInfo,
        })
    }

    const CUSTOM_BUTTONS = [
        {
            text: 'Invoice',
            variant: "success",
            onClick: (task: PropertyTaskType) => {
                toggleTaskTag(task, 'invoiced')
            },
            disabled: false,
        },
        {
            text: 'Absorb',
            variant: "warning",
            onClick: (task: PropertyTaskType) => {
                toggleTaskTag(task, 'absorbed')
            },
            disabled: false,
        },
        {
            text: 'Quick View',
            variant: "info",
            onClick: handleQuickView,
            disabled: false,
        },
    ]

    const handleFormSubmit = (data: any) => {
        const query: any = {
            status: ['confirmed'],
        }
        const excludeTags: string[] = []
        if (data.completedStart) {
            query.completedStart = timeService.createLuxonDate(data.completedStart).startOf('day').toMillis()
        }
        if (data.completedEnd) {
            query.completedEnd = timeService.createLuxonDate(data.completedEnd).endOf('day').toMillis()
        }

        if (data.hideInvoiced) {
            excludeTags.push('invoiced')
        }
        if (data.hideAbsorbed) {
            excludeTags.push('absorbed')
        }

        if (excludeTags.length) {
            query.excludeTags = excludeTags
        }

        setActiveQuery({
            query,
            limit: 500,
            page: 1,
        })
    }

    const tasksByProperty = tasksByPropertyHelper(propertyTasksList, myProperties)


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Property Task Reporter</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}
                    {addNewPropertyTaskError && (<>{JSON.stringify(addNewPropertyTaskError)}<br /></>)}
                    {editPropertyTaskError && (<>{JSON.stringify(editPropertyTaskError)}<br /></>)}
                    {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}
                    <QuickForm
                        initialData={{
                            completedStart: startDate,
                            completedEnd: endDate,
                            hideInvoiced: false,
                            hideAbsorbed: false,
                        }}
                        fields={PROPERTY_TASK_REPORTER_FORM_FIELDS}
                        onSubmit={handleFormSubmit}
                    />

                    <hr />

                    <h3>TOTAL Tasks by person:</h3>
                    <PropertyTaskByPersonTable
                        propertyTasksList={propertyTasksList}
                        tableProps={{
                            striped: true,
                            variant: 'dark',
                        }}
                        userRef={userRef} />

                    <hr />

                    {tasksByProperty && tasksByProperty.map((propertyTasks: {
                        propertyId: string,
                        shortTitle: string,
                        tasks: PropertyTaskType[]
                    }, idx: number) => {
                        // const propertyInfo = myProperties[propertyTasks.propertyId];
                        // const occupancyInfo = propertyOccupancyRef[propertyTasks.propertyId];
                        return (
                            <div className="task-list-section" key={`property-${propertyTasks.propertyId}-${idx}`}>
                                <Row className="task-list-section-title">
                                    <Col>
                                        <h3>
                                            {propertyTasks.shortTitle}
                                        </h3>
                                    </Col>
                                    {!openSections.all && (
                                        <Col xs="auto">
                                            <Button
                                                className="mr-2 mb-2 me-3"
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => toggleOpenSection(propertyTasks.propertyId)}>
                                                {openSections[propertyTasks.propertyId] ? 'Collapse' : 'Expand'}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                                <div className="task-count mb-3">
                                    <b>Tasks Completed:</b> {propertyTasks.tasks.length}
                                </div>

                                <Collapse in={openSections[propertyTasks.propertyId] || openSections.all}>
                                    <div className="task-list-entries">
                                        {propertyTasks.tasks.map((task: PropertyTaskType, jdx: number) => (
                                            <PropertyTaskEntry
                                                key={`property-${propertyTasks.propertyId}-task-${jdx}`}
                                                taskInfo={task}
                                                // propertyInfo={propertyInfo}
                                                userRef={userRef}
                                                showTaskByPerson={true}
                                                hideBody={true}
                                                hideButtons={PROPERTY_TASK_HIDE_BUTTONS}
                                                customButtons={CUSTOM_BUTTONS}
                                            />
                                        ))}
                                    </div>
                                </Collapse>
                                <h5>TOTALS:</h5>
                                <PropertyTaskByPersonTable
                                    propertyTasksList={propertyTasks.tasks}
                                    tableProps={{
                                        striped: true,
                                        variant: 'dark',
                                    }}
                                    userRef={userRef} />
                                <hr />
                            </div>
                        )
                    })}
                </Col>
            </Row>


            <Modal
                size={'lg'}
                show={taskModalSettings.show}
                onHide={clearTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Task Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {taskModalSettings.quickView && (
                        <PropertyTaskEntryView
                            taskInfo={taskModalSettings.taskInfo}
                            userRef={userRef}
                            propertyInfo={myProperties[taskModalSettings.taskInfo.propertyId]}
                            username={email}
                            role={role}
                            occupancyInfo={propertyOccupancyRef[taskModalSettings.taskInfo.propertyId]}
                            disabled={isLoading || editPropertyTaskLoading}
                            updateTask={async (updateTaskPayload: {
                                _id: string,
                                propertyTaskInfo: Partial<PropertyTaskType>
                            }) => {
                                const {
                                    propertyTaskInfo
                                } = updateTaskPayload
                                const updatedTask = await editPropertyTask({
                                    propertyTaskId: updateTaskPayload._id,
                                    propertyTaskInfo
                                }).unwrap()
                                if (
                                    (propertyTaskInfo.progressPics ||
                                        propertyTaskInfo.workLog) &&
                                    !propertyTaskInfo.status
                                ) {
                                    setTaskModalSettings({
                                        ...taskModalSettings,
                                        taskInfo: { ...updatedTask },
                                    })
                                } else {
                                    clearTaskModal()
                                }

                            }}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={uploadImageMultiFn}
                        />
                    )}

                    {email && role && taskModalSettings.quickTask && (
                        <PropertyTaskForm
                            initialValues={{
                                createdBy: email,
                                status: role === 'admin' ? 'approved' : 'new',
                            }}
                            quickTask={true}
                            disabled={addNewPropertyTaskLoading}
                            onSubmit={async (values) => {
                                const newTask = await addNewPropertyTask(values).unwrap()
                                setTaskModalSettings({ show: false })
                                return newTask
                            }}
                            propertyOptionList={myPropertySelectOptions}
                            userSelectOptions={userSelectOptions}
                            uploadImageFn={uploadImageFn}
                            imageUploadProgress={imageUploadProgress}
                            uploadImageMultiFn={uploadImageMultiFn}
                        />
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={clearTaskModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </Container>
    )
}

const PROPERTY_TASK_HIDE_BUTTONS = {
    view: true,
    delete: true,
}

const PROPERTY_TASK_REPORTER_FORM_FIELDS: FieldGeneratorFieldType[] = [
    {
        fieldName: 'completedStart',
        fieldType: 'date',
        fieldLabel: 'Start Date',
        required: true,
    },
    {
        fieldName: 'completedEnd',
        fieldType: 'date',
        fieldLabel: 'End Date',
        required: true,
    },
    {
        fieldName: 'hideInvoiced',
        fieldType: 'checkbox',
        fieldLabel: 'Hide Invoiced',
        required: false,
    },
    {
        fieldName: 'hideAbsorbed',
        fieldType: 'checkbox',
        fieldLabel: 'Hide Absorbed',
        required: false,
    },
]